<template>
    <div class="wout-login" @click="avancar">
        <div class="center">
            <div class="logo">
                <img v-show="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" alt="Logo hotel" crossorigin />
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['imgTipo','imgSrc'],
    data: () => {
        return {
        }
    },
    methods: {
        avancar() {
            this.$emit('direcionar', {from: 'introhotel', to: 'introwout'})
        }
    }
}
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 50vw;
    height: auto;
}
.logo {
    background-image: url('/img/circulo.svg');
    
}
</style>