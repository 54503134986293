<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-layout style="min-height: 90vh">
    <v-flex style="">
      <v-card class="pa-2" style="">
        <v-toolbar color="blue" class="ma-2" style="color: white">
          <div
            style="
              min-width: 100% !important;
              display: flex;
              justify-content: space-between;
            "
          >
            <span class="toolbar-title" style="width: 80%; padding: 10px">
              Configurações WebCheckout
            </span>
            <span style="width: 5%; padding: 10px">
              <BotaoTutorial
                urlVideo=""
                categoria=""
                legenda="Dúvidas Configurações"
              />
            </span>
          </div>
        </v-toolbar>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurações de Gateways</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="gotoGateways()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurações Credito | Débito | Pix</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setModalDebCredPix()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurações de Envios Automáticos de Web Checkout</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setModalFollowUpWebCheckout()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurações de Taxas</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setModalTaxasCheckout()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurar Checagem de Frigobar e Contestação de Ítens</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setModalFrigobarWebCheckout()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurar PDV's</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="buscarPDVsEAbrir()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurar Documentos</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="buscarDocsEAbrir()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurar Login do Hóspede</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="buscarLoginConfigEAbrir()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Configurações gerais</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="buscarConfigGeraisEAbrir()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Quantidade de parcelas por valor</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setExibirModalQuantidadeParcelas()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display: flex; justify-content: center">
          <v-row class="ma-2" style="max-width: 70%">
            <v-card class="pa-2" style="width: 100%; display: flex">
              <div
                style="
                  width: 70%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                <h3>Notificações Checkout</h3>
              </div>
              <div
                style="
                  width: 30%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <v-btn
                  @click="setExibirModalNotificacaoEmailsCheckout()"
                  style="color: white; font-weight: bold"
                  color="primary"
                >
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>
      </v-card>
    </v-flex>

    <ModalFollowupWebCheckout
      @setModalFollowUpWebCheckout="() => setModalFollowUpWebCheckout()"
      :exibirModalFollowupWebCheckout="exibirModalFollowupWebCheckout"
    />

    <ModalTaxasCheckout
      @setModalTaxasCheckout="() => setModalTaxasCheckout()"
      :exibirModalTaxasCheckout="exibirModalTaxasCheckout"
    />

    <ModalFrigobarWebCheckout
      @setModalFrigobarWebCheckout="() => setModalFrigobarWebCheckout()"
      :exibirModalFrigobarWebCheckout="exibirModalFrigobarWebCheckout"
    />

    <ModalDebCredPix
      @setModalDebCredPix="() => setModalDebCredPix()"
      :exibirModalDebCredPix="exibirModalDebCredPix"
    />

    <ModalPDVReservas
      :pdvs="pdvs"
      @setModalPDVReservas="() => setModalPDVReservas()"
      :exibirModalPDVReservas="exibirModalPDVReservas"
    />

    <ModalDocs
      :docs="docs"
      :carregando="carregando"
      @setModalDocs="() => setModalDocs()"
      :exibirModalDocs="exibirModalDocs"
      @update="() => buscarDocs()"
      @salvar="() => salvarDocs()"
      @salvarNovo="($event) => salvarDoc($event)"
    />

    <ModalLogin
      :loginConfig="loginConfig"
      :carregando="carregando"
      @setModalLogin="() => setModalLogin()"
      :exibirModalLogin="exibirModalLogin"
      @update="() => buscarLoginConfig()"
      @salvar="() => salvarLoginConfig()"
    />

    <ModalConfigGeraisWebCheckoutVue
      :exibirModalConfigGerais="exibirModalConfigGerais"
      @setExibirModalConfigGerais="buscarConfigGeraisEAbrir"
    />
    />

    <ModalQuantidadeParcelasValorVue
      :sistema="'webcheckout'"
      v-if="exibirModalQuantidadeParcelas"
      :exibirModalQuantidadeParcelas="exibirModalQuantidadeParcelas"
      @setExibirModalQuantidadeParcelas="setExibirModalQuantidadeParcelas"
    />

    <ModalEmailNotificacaoCheckinpay
      :sistema="'checkout'"
      v-if="exibirModalNotificacoesCheckinpay"
      :exibirModalNotificacoesCheckinpay="exibirModalNotificacoesCheckinpay"
      @setExibirModalNotificacaoEmailsCheckout="
        setExibirModalNotificacaoEmailsCheckout
      "
    />
  </v-layout>
</template>

<script>
import ModalFollowupWebCheckout from "@/components/DashViews/Modals/ModalFollowupWebCheckout";
import ModalTaxasCheckout from "@/components/DashViews/Modals/ModalTaxasCheckout";
import ModalFrigobarWebCheckout from "@/components/DashViews/Modals/ModalFrigobarWebCheckout";
import ModalDebCredPix from "@/components/DashViews/Modals/ModalDebCredPix";
import BotaoTutorial from "../material/BotaoTutorial.vue";
import ModalPDVReservas from "@/components/DashViews/Modals/ModalPDVReservas";
import ModalDocs from "@/components/DashViews/Modals/ModalDocs";
import ModalLogin from "@/components/DashViews/Modals/ModalLogin";
import ModalConfigGeraisWebCheckoutVue from "./Modals/ModalConfigGeraisWebCheckout.vue";
import ModalQuantidadeParcelasValorVue from "./Modals/ModalQuantidadeParcelasValor.vue";
import ModalEmailNotificacaoCheckinpay from "./Modals/ModalEmailNotificacaoCheckinpay.vue";

export default {
  data: () => ({
    exibirModalFollowupWebCheckout: false,
    exibirModalTaxasCheckout: false,
    exibirModalFrigobarWebCheckout: false,
    exibirModalDebCredPix: false,
    isWhatsAppSet: false,
    exibirModalPDVReservas: false,
    pdvs: [],
    exibirModalDocs: false,
    docs: [],
    exibirModalLogin: false,
    loginConfig: { tipo: "Documento" },
    defaultDoc: {
      doc: "1",
      doc_frente: true,
      doc_verso: false,
      ativo: true,
      emissao_nota_fiscal: false,
      rec_facial: false,
      descricao: "Novo Tipo Doc",
      doc_aux: false,
      rec_facial_obrigatorio: false,
      captura_obrigatoria: false,
    },
    carregando: false,
    dadosConfigGerais: null,
    exibirModalConfigGerais: false,
    exibirModalQuantidadeParcelas: false,
    exibirModalNotificacoesCheckinpay: false,
  }),
  components: {
    ModalFollowupWebCheckout,
    ModalTaxasCheckout,
    ModalFrigobarWebCheckout,
    ModalDebCredPix,
    BotaoTutorial,
    ModalPDVReservas,
    ModalDocs,
    ModalLogin,
    ModalConfigGeraisWebCheckoutVue,
    ModalQuantidadeParcelasValorVue,
    ModalEmailNotificacaoCheckinpay,
  },
  methods: {
    gotoOpcionais() {
      this.$router.push("/dashboard/opcionais");
    },
    gotoGateways() {
      this.$router.push("/dashboard/gateways");
    },
    setModalEmailAniversario() {
      this.exibirModalEditarEmailAniversario =
        !this.exibirModalEditarEmailAniversario;
    },
    setModalEditarTermo() {
      this.exibirModalEditarTermo = !this.exibirModalEditarTermo;
    },
    setModalEditarTermoLGPD() {
      this.exibirModalEditarTermoLGPD = !this.exibirModalEditarTermoLGPD;
    },
    setModalGeo() {
      this.exibirModalGeo = !this.exibirModalGeo;
    },
    setModalEditarFormSanitario() {
      this.exibirModalEditarFormSanitario =
        !this.exibirModalEditarFormSanitario;
    },
    setModalEditarPassosWebCheckin() {
      this.exibirModalEditarPassosWebCheckin =
        !this.exibirModalEditarPassosWebCheckin;
    },
    setModalFollowUpWebCheckout() {
      this.exibirModalFollowupWebCheckout =
        !this.exibirModalFollowupWebCheckout;
    },
    setModalTaxasCheckout() {
      this.exibirModalTaxasCheckout = !this.exibirModalTaxasCheckout;
    },
    setModalFrigobarWebCheckout() {
      this.exibirModalFrigobarWebCheckout =
        !this.exibirModalFrigobarWebCheckout;
    },
    setModalDebCredPix() {
      this.exibirModalDebCredPix = !this.exibirModalDebCredPix;
    },
    setModalEditarPassosWebCheckinPergunta() {
      this.exibirModalEditarPassosWebCheckinPergunta =
        !this.exibirModalEditarPassosWebCheckinPergunta;
    },
    setModalEditarFelizAniversarioWhats() {
      this.exibirModalEditarMensagemFelizAniversario =
        !this.exibirModalEditarMensagemFelizAniversario;
    },
    setModalEditarAPIWhatsApp() {
      this.exibirModalEditarAPIWhatApp = !this.exibirModalEditarAPIWhatApp;
    },
    setModalEditarContaEmail() {
      this.exibirModalEditarContaEmail = !this.exibirModalEditarContaEmail;
    },
    setModalEditarAparencia() {
      this.exibirModalEditarAparencia = !this.exibirModalEditarAparencia;
    },
    setExibirModalQuantidadeParcelas() {
      this.exibirModalQuantidadeParcelas = !this.exibirModalQuantidadeParcelas;
    },
    setExibirModalNotificacaoEmailsCheckout() {
      this.exibirModalNotificacoesCheckinpay =
        !this.exibirModalNotificacoesCheckinpay;
    },
    buscarPDVsEAbrir() {
      this.buscarPDVs().then(() => {
        this.setModalPDVReservas();
      });
    },
    setModalPDVReservas() {
      this.exibirModalPDVReservas = !this.exibirModalPDVReservas;
    },
    buscarDocsEAbrir() {
      this.buscarDocs().then(() => {
        this.setModalDocs();
      });
    },
    buscarConfigGeraisEAbrir() {
      this.exibirModalConfigGerais = !this.exibirModalConfigGerais;
    },
    setModalDocs() {
      this.exibirModalDocs = !this.exibirModalDocs;
    },
    async buscarLoginConfig() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "WEBCHECKOUT_CONFIG_LOGIN",
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              const obj = resp.data[0];
              const cfg = JSON.parse(obj.valor);
              this.loginConfig = cfg;
            }
          } else {
            console.error("Não foi possivel buscar a configuração.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buscarLoginConfigEAbrir() {
      this.buscarLoginConfig().then(() => {
        this.setModalLogin();
      });
    },
    setModalLogin() {
      this.exibirModalLogin = !this.exibirModalLogin;
    },
    salvarLoginConfig() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "WEBCHECKOUT_CONFIG_LOGIN",
          valor: JSON.stringify(this.loginConfig),
        })
        .then(() => {
          this.setModalLogin();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async buscarPDVs() {
      this.$http
        .post("webcheckout/buscarPDVs", {
          idhotel: localStorage.getItem("hotel"),
          tipo_integracao: localStorage.getItem("TIPOINTEGRACAO"),
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.pdvs = resp.data?.pdvs;
          } else {
            console.error("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async buscarDocs() {
      this.carregando = true;
      this.$http
        .post("webcheckout/documento/select", {})
        .then((resp) => {
          if (resp.status == 200) {
            this.docs = resp.data;
            this.carregando = false;
          } else {
            console.error("Não foi possivel buscar os documentos");
            this.carregando = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregando = false;
        });
    },
    async salvarDocs() {
      this.carregando = true;
      this.$http
        .post("webcheckout/documentos/salvar", this.docs)
        .then((resp) => {
          if (resp.status == 200) {
            this.docs = resp.data;
            this.carregando = false;
            this.buscarDocs();
          } else {
            console.error("Não foi possivel buscar os documentos");
            this.carregando = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregando = false;
        });
    },
    async salvarDoc(novoDoc) {
      let novoTipoDoc = Object.assign({}, this.defaultDoc);
      novoTipoDoc.descricao = novoDoc;
      //console.error(JSON.stringify(novoTipoDoc))
      this.carregando = true;
      this.$http
        .post("webcheckout/documentos/salvar", [novoTipoDoc])
        .then((resp) => {
          if (resp.status == 200) {
            //this.docs = resp.data
            this.carregando = false;
            this.buscarDocs();
          } else {
            console.error("Não foi possivel buscar os documentos");
            this.carregando = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.carregando = false;
        });
    },
  },
  mounted() {
    console.log("POR AQUI");

    this.buscarDadosAPI();
  },
};
</script>

<style></style>
