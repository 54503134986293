<template>
    <div>
      <v-dialog
        v-model="show"
        width="60%"
        transition="dialog-top-transition"
        persistent
      >
        <v-card>
          <v-card-title>
            Copiar dados
          </v-card-title>
          <v-card-text>
            <v-row class="s-row pa-2">
              <!-- Reserva -->
              <v-text-field 
                type="text" label="Reserva"
                v-model="reservaSearch"
                style="max-width:110px!important;"
                @keyup="check"
              ></v-text-field>
              <!-- Botão Pesquisar -->
              <v-btn class="mx-2" @click="$emit('search',reservaSearch)">
                <strong>Pesquisar</strong>
              </v-btn>
            </v-row>
            <v-row>
              <!-- Hóspedes da Reserva -->
              <v-data-table 
                :headers="headers" :footer-props="footerProps"
                :items="items"
                no-data-text="Digite um número de reserva e clique em 'Pesquisar'"
              >
                <template v-slot:item="{item}">
                  <tr :class="item.id==hospede.id?'table-row selected':'table-row'" 
                    @click="selecionarHospede(item)">
                    <td>
                      {{item.nome}}
                    </td>
                    <td>
                      {{item.sobrenome}}
                    </td>
                  </tr>
                </template>              
              </v-data-table>
            </v-row>
          </v-card-text>
          <!-- <v-row> -->
            <template v-if="Object.keys(hospede).length==0 && items.length>0">
              <div class="dica">Selecione um dos nomes da lista para copiar seus dados.</div>
            </template>
          <!-- </v-row> -->
          <v-card-actions>
            <!-- Tipo -->
            <v-radio-group v-model="tipo" class="mx-2" row :label="nomehospede+' é:'">
              <v-radio label="Acompanhante" value="0"></v-radio>
              <v-radio label="Criança" value="1"></v-radio>
            </v-radio-group>
            <v-spacer></v-spacer>
            <v-btn @click="copiar" :disabled="Object.keys(hospede).length==0">
              <strong>Copiar Dados</strong>
              <v-icon color="green">mdi-check</v-icon>
            </v-btn>
            <v-btn @click="fechar">
              <strong>Fechar</strong>
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>      
    </div>
</template>
<script>
export default {
  name: "CopiarDados",
  props: ['show','items','numeroreserva','idreserva','idhospede','idhospedepms','nomehospede','docfrente','docverso'],
  data: () => ({
    reservaSearch: '',
    headers: [
      // { text: "Nome", value: "nome", width: "120px", },
      { text: "Nome", value: "nome" },
      // { text: "Sobrenome", value: "sobrenome", width: "120px", },
      { text: "Sobrenome", value: "sobrenome" }
    ],
    footerProps: {
      'items-per-page-options': [8,16,24,{ text: "Todas", value: -1 }],
      'items-per-page-text': 'Linhas por página',
    },
    tipo: '0',
    hospede: {},
  }),
  methods: {
    selecionarHospede(item) {
      this.hospede = Object.assign({}, item);
    },
    check() {
      if (this.reservaSearch.trim() == '') {
        this.$emit('search',null)
        this.hospede = {}
      }
    },
    copiar() {
      var params = {
        "reserva": {
          "id": this.idreserva,
          "hotel": localStorage.getItem("hotel")
        },
        "hospede": {
          "id": this.idhospede,
          "idhospedepms": this.idhospedepms,
          "paisorigem": this.hospede.idpaisorigem,
          "cidadeorigem": this.hospede.idcidadeorigem,
          "paisdestino": this.hospede.idpaisdestino,
          "cidadedestino": this.hospede.idcidadedestino,
          "horariochegada": this.hospede.horariochegada,
          "horariopartida": this.hospede.horariopartida,
          "motivo": this.hospede.idmotivo,
          "motivototvs": this.hospede.motivototvs,
          "transporte": this.hospede.idtransporte,
          "transportetotvs": this.hospede.transportetotvs,
          "placa": this.hospede.placa,
          "modelo": this.hospede.modelo,
          "empresareservante": this.hospede.empresareservante,
          "idempresareservante": this.hospede.idempresareservante,
          "idempresareservantepms": this.hospede.idempresareservantepms,
          "enderecos": [
            {
              "tipo": 2,
              "endereco": this.hospede.endereco,
              "numero": this.hospede.numero,
              "complemento": this.hospede.complemento,
              "cep": this.hospede.cep,
              "bairro": this.hospede.bairro,
              "cidade": this.hospede.idcidade,
              "pais": this.hospede.idpais
            }
          ]
        }
      }
      // Se for criança copiar documentos e contatos também
      if (this.tipo == '1') {
        // Temporariamente comentado para resolver a questão de duplicidade de documentos
        // params.hospede.documentos = [
        //   {
        //     "tipo": this.hospede.idtipodocumento,
        //     "documento": this.hospede.documento,
        //     "orgaoexpedidor": this.hospede.documentoorgao,
        //     "dataexpedicao": this.hospede.documentoexpedicao
        //   }
        // ];
        if(!this.docfrente){
          params.hospede.docfrente = this.hospede.docfrente
        }

        if(!this.docverso){
          params.hospede.docverso  = this.hospede.docverso
        }
        params.hospede.assinatura = this.hospede.assinatura
        params.hospede.telefoneddi = this.hospede.telefoneddi
        params.hospede.telefoneddd = this.hospede.telefoneddd
        params.hospede.telefone = this.hospede.telefone
        params.hospede.celularddi = this.hospede.celularddi
        params.hospede.celularddd = this.hospede.celularddd
        params.hospede.celular = this.hospede.celular
        params.hospede.whatsapp = this.hospede.whatsapp
        params.hospede.facebook = this.hospede.facebook
        params.hospede.instagram = this.hospede.instagram
        params.hospede.site = this.hospede.site
        params.hospede.email = this.hospede.email
      }
      this.$emit('copy',params)
    },
    fechar() {
      this.reservaSearch = ''
      this.$emit('close')
    }
  }
};
</script>

<style scoped>
.s-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
label.v-label.v-label--active.theme--light.primary--text {
  color: grey!important;
}
.table-row {
  cursor: pointer;
}
.selected {
  background-color: rgb(154, 209, 253)!important;;
}
.table-row:hover {
  /* background-color: rgb(33, 150, 243)!important; */
  background-color: rgb(202, 202, 202)!important;
  color: white;
  font-weight: bold;
}
.dica {
  display: flex;
  color: orange;
  justify-content: center;
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>