<template>
    <v-dialog v-model="exibirModalHistoricoSolicitacoes" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Histórico de Respostas das Solicitações
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalHistoricoSolicitacoes',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                
                <v-card>
                    <!-- <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        >
                        </v-text-field>
                    </v-card-title> -->

                    <v-data-table
                        :headers="headers"
                        :items="respostas"
                        :search="search"
                    >         
                        

                        <template v-slot:item.dataresposta="{ item }">
                            {{item.dataresposta | formatarDataHora}}
                        </template>      

                    </v-data-table>
                </v-card>

            </v-card-text>
            
        </v-card>

    </v-dialog>
</template>          

<script>


// import ModalSolicitacoesResponder from './ModalSolicitacoesResponder'
// import ModalHistoricoSolicitacoes from './ModalHistoricoSolicitacoes'

export default {
    data: () => ({
        // exibirModalResponder:false,
        solicitacao:{},
        search: '',
        headers: [
            {text: 'Solicitacao',align: 'start',sortable: false,value: 'descricao'},
            {text: 'Resposta',sortable: false,value: 'resposta'},
            {text: 'Data',sortable: false,value: 'dataresposta'},
        ],
        respostas: []
    }),
    props:["exibirModalHistoricoSolicitacoes","solicitacoes","itemselecionado","flagatualizar"],
    components:{
        // ModalSolicitacoesResponder,
        // ModalHistoricoSolicitacoes
    },
    watch:{
        itemselecionado: function (){
            this.consultarSolicitacoes()
        },
        flagatualizar: function (){
            this.consultarSolicitacoes()
        }
    },
    methods: {
        consultarSolicitacoes(){
            this.$http.post("reserva/solicitacao/historico/select", { idreservahospede:this.itemselecionado.id }).then(resp =>{
                this.respostas = resp.data
            }).catch(error =>{
                console.error(error)
            })
        },
        // setExibirModalResponder(item){
        //     this.solicitacao = item
        //     this.setModalResponder(!this.exibirModalResponder)
        // },
        // setModalResponder(valor){
        //     // alert(JSON.stringify(this.solicitacao))
        //     this.exibirModalResponder = valor
        // },
    },
    mounted() {
        console.log(JSON.stringify(this.itemselecionado))
        this.consultarSolicitacoes()
        // await this.$http.post('reserva/solicitacao/select',{
        //             reservahospede:1
        //         })

    },
};
</script>