<template>

   <div class="" style="flex-wrap:wrap;width:100%; overflow:auto;">
       
        <h3 class="text-center" style="margin-bottom:20px;">{{ $t('webcheckin.covid.formulario') }}</h3>

            <div class="cardInputs">
                <label class="labels">{{perguntas_covid[0].texto}}</label>
                <select v-model="resposta1.texto" class="inputs" data-cy="select-covid1" style="font-size:1.2em;" >
                        <option value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option value="SIM" >SIM</option>
                        <option value="NÃO" >NÃO</option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{perguntas_covid[1].texto}}</label>
                <select v-model="resposta2.texto" class="inputs" data-cy="select-covid2" style="font-size:1.2em;" >
                        <option value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option value="SIM" >SIM</option>
                        <option value="NÃO" >NÃO</option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{perguntas_covid[2].texto}}</label>
                <select v-model="resposta3.texto" class="inputs" data-cy="select-covid3" style="font-size:1.2em;" >
                        <option value="SELECIONE" >SELECIONE UMA OPÇÃO</option>
                        <option value="SIM" >SIM</option>
                        <option value="NÃO" >NÃO</option>
                </select>
            </div>

        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-covidvoltar">
                    {{ $t('webcheckin.covid.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-covidavancar">
                    {{ $t('webcheckin.covid.avancar') }}
            </button>
        </div> 
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>
    </div>    

</template>

<script>

// import CONSTANTS from '../constantes'
// import axios from 'axios'

export default {
    props:["dados","dadosAcompanhantes","hospedePrincipal"],
    data: () =>{
        return{
            perguntas_covid:[],
            respostas_covid:[],
            snack: false,
            snackColor: '',
            snackText: '',
            resposta1:'SELECIONE',
            resposta2:'SELECIONE',
            resposta3:'SELECIONE',
        }
    },
    mounted(){
        this.buscarPerguntas()
        
        /* eslint-disable no-console */
        // axios.get(CONSTANTS.API_URL + '/wcheckin/perguntascovid',{
        //     headers: {  
        //             'Authorization': 'Bearer ' + this.tokenJWT
        //             }, timeout: 120000} ) 
        //     .then(response => {
        //         this.perguntas_covid = response.data
        //         console.log({ perguntas_covid: this.perguntas_covid });
        //         this.perguntas_covid.forEach( (it) => {
        //             it.resposta = ""  
        //         })
        //     }).catch((error) => {
        //         this.$store.dispatch("setar_erromsg", "Falha Buscar Perguntas na tela de Covid: "+error.message)
        //         this.$swal("Falha Buscar Perguntas", 
        //                                         " msg: "+JSON.stringify(error.message)
        //                                         // +" <br> --- dt:"+JSON.stringify(error.config.data)
        //                                         // +" <br> --- at:"+JSON.stringify(error.config.headers.Authorization)
        //                                         // +" <br> --- tk: "+JSON.stringify(this.tokenJWT)
        //                                         ,"error");
        //         this.$router.push('/Checkin/Reiniciar')
        //     }) 
    },
    methods: {
        buscarPerguntas(){
            this.$http.post("formulario/select",{id:1}).then(async resp =>{

                    this.perguntas_covid = await resp.data[0].perguntas.filter(p => {
                        return p.texto != undefined;
                    }).sort((a, b) => (a.texto > b.texto) ? 1 : -1);

                    this.perguntas_covid = await this.perguntas_covid.map(p => {
                        p.texto = p.texto.toUpperCase();
                        return p;
                    })

                    this.buscarRespostas()

            })
        },
        buscarRespostas(){
            this.$http.post("formulario/resposta/select",{id:1,resposta:{idrespondente:this.dados.idpessoa}}).then(resp =>{
                    // console.log("RESPOSTAS")
                    // console.log(JSON.stringify(resp.data))
                    this.respostas_covid = resp.data[0].respostas
                    this.respostas_covid = this.respostas_covid.map(p => {
                        p.texto = p.texto.toUpperCase();
                        return p;
                    })


                    this.resposta1 = 
                        this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[0].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[0].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta2 = this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[1].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[1].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta3 = this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[2].texto) ?
                            this.respostas_covid.find(resposta => resposta.pergunta == this.perguntas_covid[2].texto): {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}


              
                    

            }).catch(()=>{
                    this.resposta1 = {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta2 = {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

                    this.resposta3 = {id:null,idrespondente:this.dados.idpessoa,ativo:true,texto:'SELECIONE'}

            })
        },

        validarFormulario(){

            if(typeof this.resposta1 == "string"){
                if(this.resposta1 == 'SELECIONE' || this.resposta2 == 'SELECIONE' || this.resposta3 == 'SELECIONE' ){
                    // this.$swal(this.$t('Todas as respostas são obrigatórias'),this.$t('As respostas do formulário sobre a COVID-19 são obrigatórias.'),"error");
                    alert("Todas as respostas são obrigatórias!")
                    return false
                }
            }else{
                if(this.resposta1.texto == 'SELECIONE' || this.resposta2.texto == 'SELECIONE' || this.resposta3.texto == 'SELECIONE' ){
                    // this.$swal(this.$t('Todas as respostas são obrigatórias'),this.$t('As respostas do formulário sobre a COVID-19 são obrigatórias.'),"error");
                    alert("Todas as respostas são obrigatórias!")
                    return false
                }
            }

            return true
            
        },
        async salvarDados(){
            if(this.resposta1.id){
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta1.id,pergunta:this.perguntas_covid[0].id, resposta:{resposta:this.resposta1.texto}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta1.ativo, idioma:'pt-BR'})
            }else{
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[0].id, resposta:{resposta:this.resposta1.texto}, respondente:this.dados.idpessoa,
                        ativo:this.resposta1.ativo, idioma:'pt-BR'})
            }

            if(this.resposta2.id){
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta2.id,pergunta:this.perguntas_covid[1].id, resposta:{resposta:this.resposta2.texto}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta2.ativo, idioma:'pt-BR'})
            }else{
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[1].id, resposta:{resposta:this.resposta2.texto}, respondente:this.dados.idpessoa,
                        ativo:this.resposta2.ativo, idioma:'pt-BR'})
            }

            if(this.resposta3.id){
                await this.$http.post("formulario/resposta/upinsert",
                        {id:this.resposta3.id,pergunta:this.perguntas_covid[2].id, resposta:{resposta:this.resposta3.texto}, 
                        respondente:this.dados.idpessoa,ativo:this.resposta3.ativo, idioma:'pt-BR'})
            }else{
                await this.$http.post("formulario/resposta/upinsert",
                        {pergunta:this.perguntas_covid[2].id, resposta:{resposta:this.resposta3.texto}, respondente:this.dados.idpessoa,
                        ativo:this.resposta3.ativo, idioma:'pt-BR'})
            }

            // controle de direcionamento 
            if(this.dados.exibirPassosObservacao){
                this.$emit('direcionar','observacoes')
            }else{
                if(this.dados.exibirPassosHorario){
                    this.$emit('direcionar','horario')
                }else{
                    if(this.dados.exibirPassosPerguntaExtra){
                        this.$emit('direcionar','perguntaextra')
                    }else{

                        // if(this.hospedePrincipal && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                        if(this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                            // this.$emit('salvarDados',dadossalvar,'hospedes')   
                            this.$emit('direcionar','hospedes') 
                        }else{

                            // this.$emit('salvarDados',dadossalvar,'fim')
                            this.$emit('direcionar','fim') 
                        }
                    }

                }
            }
            
        },
        async avancar(){
           
            if( await this.validarFormulario() ){
                this.salvarDados()
            } 
            
            
            
        },
        voltar(){
            // this.$emit('direcionar','assinatura')
            if(this.dados.crianca){
                this.$emit('direcionar','dadopessoal')
            }else{
                this.$emit('direcionar','assinatura')
            }
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>