<template>
  <v-dialog v-model="show" width="90vw" max-width="800px" persistent>
    <Loading v-if="loading"/>
    <div style="display: flex; flex-direction: column; background-color: white;" v-if="!loading">
      <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
        <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
          <span class="toolbar-title" style="width:80%;padding:10px;"> Email Pré Guest </span>
          <span style="width:5%"> 
            <v-btn x-small color="red" @click="() => this.$emit('fecharModalEmailWebCheckin')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <div style="padding: 10px; width: 100%;">
        <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
          <v-switch v-model="switch1" style="border-radius:5px;padding:5px;"></v-switch>
          <label>Enviar de e-email Pré Guest liberado?</label>  
        </div>
        <v-text-field label="Assunto E-Mail" v-model="mensagem" style="width: 100%; margin-right: 50px;" outlined></v-text-field>
        <p style="font-size: small;">Corpo do E-mail (Opcional)</p>
        <quill-editor :options="editorOptions"  v-model="content" />
        <div style="margin: 20px 0 0 0">
          <p style="font-size: small;">Anexar PDF a ser enviado via anexo.</p>
          <input type="file" accept=".pdf" @change="handleFileUpload">
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column; padding: 10px; width: 100%; align-items: flex-end; background-color: rgb(255, 255, 255);">    
      <p style="font-size: 12px; margin-top: 5px;" v-if="configSalvas">Configurações salvas com sucesso!</p>
      <button class="buttonsalvar" @click="uploadFileAndSave">{{ textoBotao }}</button>
    </div>
   </v-dialog>
  </template>
  
  <script>
    import Loading from './Loading.vue';
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import ImageResize from 'quill-image-resize'
    import Quill from 'quill'

    Quill.register('modules/imageResize', ImageResize)
   export default {
    props:['show'],
    components: {
      Loading,
      quillEditor
    },
    data() {
      return {
        content: '',
        loading: false,
        textoBotaoSalvar: 'Salvar',
        switch1: false,
        switch2: false,
        id: null,
        mensagem: null,
        textoBotao: 'Salvar Configurações',
        configSalvas: false,
        file: null,
        editorOptions: {
          modules: {
            toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ align: [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ],
            imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
              },
              modules: ['Resize', 'DisplaySize']
            }
          },
        },
      }
    },
    methods: {

      convertClassesToInlineStyles() {
      const container = document.createElement('div');
      container.innerHTML = this.content;

      const images = container.querySelectorAll('img');

      const elements = container.querySelectorAll('[class]')
      console.log(elements)
      elements.forEach(element => {
        const classes = element.classList

        for (let i = classes.length - 1; i >= 0; i--) {
          const className = classes[i]
          console.log('classname aqui', className)
          const inlineStyle = this.getInlineStyleForClass(className)

          if (inlineStyle) {
            element.removeAttribute('class')
            element.setAttribute('style', inlineStyle)
            console.log('element final', element)
          }
        }
      })


      images.forEach((img) => {
        const align = img.getAttribute('data-align');
        if (align) {
          let style = img.getAttribute('style') || '';

          if (align === 'left') {
            style += 'float: left; margin-right: 10px; max-width: 800px;';
          } else if (align === 'right') {
            style += 'float: right; margin-left: 10px; max-width: 800px;';
          } else if (align === 'center') {
            style += 'display: block; margin: 0 auto; text-align: center; max-width: 800px;';
          } else {
            console.log('entrou aqui')
            style += 'max-width: 800px;';
          }

          img.removeAttribute('data-align');
          img.setAttribute('style', style);
        } else {
          let style = img.getAttribute('style') || '';
          style += 'max-width: 800px;';
          img.removeAttribute('data-align');
          img.setAttribute('style', style);
        }
      });
      this.html = `<div style="max-width: 800px;">${container.innerHTML}</div>`;
      return container.innerHTML;
    },

    getInlineStyleForClass(className) {
      const styles = {
        'ql-indent-8': 'margin-left: 2rem;',
        'ql-size-small': 'font-size: 14px;',
        'ql-size-large': 'font-size: 20px;',
        'ql-size-huge': 'font-size: 30px;',
        'ql-align-center': 'text-align: center;',
        'ql-align-right': 'text-align: right;',
        'ql-font-serif': 'font-family: serif;',
        'ql-font-monospace': 'font-family: monospace;'
      }
      return styles[className] || ''
    },

  
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
          this.file = file;
        } else {
          this.file = event.target.value = ''
          alert('Envie um documento do tipo PDF.')
        }
      },

      uploadFileAndSave() {
      let formData = new FormData();
      if (!this.file) {
        alert('É Obrigatório anexar um PDF.');
        return;
      }

      if (!this.mensagem || this.mensagem === '') {
        alert('É obrigatório o assunto do E-mail');
        return;
      }

      this.convertClassesToInlineStyles();
      
      formData.append('file', this.file);
      this.textoBotao = 'Aguarde...'
      this.$http.post('/preguest/upload', formData)
        .then(async () => {
          try {
            await this.$http.post('/preguest/save', {
              config: `${this.switch1}`,
              assunto: this.mensagem,
              html: this.html,
            })
            this.textoBotao = 'Salvar Configurações'
              this.configSalvas = true;
            setTimeout(() => {
              this.configSalvas = false;
                console.log("ok");
              }, "4000")
          } catch (error) {
            this.textoBotao = 'Salvar Configurações'
            alert('Erro ao salvar config, tente novamente mais tarde');
          }
        })
        .catch(() => {
          this.textoBotao = 'Salvar'
          alert('Erro ao salvar config, tente novamente mais tarde');
        });
      },

      async requisitarConfig() {
        try {          
          const result = await this.$http.post('/preguest/config');
        if(result.data.length === 2) {
          for (const config of result.data) {
            if (config.chave === "ENVIOPREGUESTLIBERADO") {
              this.switch1 = config.valor === 'false' ? false : true;
            } else {
              this.mensagem = config.valor;
              this.content = config.valor2;
            }
          }
        }
        } catch (error) {
          console.log(error)
        }
      },

      async salvarConfig() {
        try {          
          this.textoBotao = 'Aguarde...'
          await this.$http.post('/configWebCheckin/salvarConfigEmailWebCheckin', {
            id: this.id,
            mensagem: this.mensagem,
            anexo_termo: this.switch2,
            enviaremail: this.switch1,
          });
          await this.requisitarConfig();
          this.textoBotao = 'Salvar'
          this.configSalvas = true;
         setTimeout(() => {
          this.configSalvas = false;
            console.log("ok");
          }, "4000")
        } catch (error) {
          console.log(error)
        }
      }
    },
    async mounted() {
      this.loading = true;
      await this.requisitarConfig();
      this.loading = false;
    }
   }
  </script>
  
  
  <style scoped>
  .buttonsalvar {
    background-color: #2B81D6;
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
    font-weight: 100;
  }
  </style>