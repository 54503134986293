<template>
  <v-app-bar id="core-toolbar" app style="background: #1d4596; max-height: 60px !important; left: 0px important;">
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="font-weight-light text-general"
        style="color: white;"
      >
        <v-btn data-nome="btn-menu" class="default v-btn--simple" icon @click.stop="onClickBtn">
          <v-icon style="color: white;">mdi-view-list</v-icon> 
        </v-btn>
        <span class="toolbar-title" @click.stop="onClickBtn">{{nomeHotel}}</span>
        <!-- {{ title }} -->
      </v-toolbar-title>
    </div>
    <v-img width="150" height="50" src="/img/FichaLogo-m.png" contain ml2 pa2/>
    <v-spacer />
    <v-toolbar-items style="max-height: 50px !important;">
      <v-flex align-center layout py-2 m2 style="max-height: 50px !important;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <template >
              <v-icon v-bind="attrs" v-on="on" class="toolbar-items mr-2"  @click="desconectarRobo()" :color="roboAtivoNoTempo?'green':'red'">{{roboAtivoNoTempo ?'mdi-robot':'mdi-robot-off'}}</v-icon>
            </template>
          </template>
          <span>{{roboAtivoNoTempo?'Robô ligado.':'Robô desligado'}}</span>
        </v-tooltip>
        <router-link v-ripple class="toolbar-items mr-2" to="/whatsapp" v-if="this.$store.getters.get_pagina != 'EXPRESS'">
          <v-icon color="white">mdi-whatsapp</v-icon>
        </router-link>
        <router-link v-ripple class="toolbar-items mr-2" to="/" v-if="this.$store.getters.get_pagina != 'EXPRESS'">
          <v-icon color="white">mdi-home</v-icon>
        </router-link>
        <router-link v-ripple class="toolbar-items mr-2" to="/pagamentos" v-if="this.$store.getters.get_pagina != 'EXPRESS'">
          <v-badge :color="qtdeNotificacoesPagtos > 0 ? 'success' : ''" overlap>
              <template slot="badge">{{ qtdeNotificacoesPagtos ? qtdeNotificacoesPagtos+'+' : null }}</template>
              <v-icon color="white">mdi-cash</v-icon>
          </v-badge>
        </router-link>
        <div @click="zerarNotificacaoMensagem()">
          <router-link v-ripple class="toolbar-items mr-2" to="/dashboard/mensagensNaoLidas" v-if="this.$store.getters.get_pagina != 'EXPRESS'">
            <v-badge :color="qtdeNotificacoesMensagensNaoLidas > 0 ? 'success' : ''" overlap>
                <template slot="badge">{{ qtdeNotificacoesMensagensNaoLidas ? qtdeNotificacoesMensagensNaoLidas+'+' : null }}</template>
                <v-icon color="white">mdi-message-text</v-icon>
            </v-badge>
          </router-link>
        </div>
        <v-menu v-if="this.$store.getters.get_pagina != 'EXPRESS'"
          bottom
          left
          content-class
          offset-y
          transition="slide-y-transition"
        >
          <router-link 
            v-ripple
            slot="activator"
            
            class="toolbar-items mr-2"
            to="/dashboard/notifications"
          >
            <v-badge :color="qtdeNotificacoes > 0 ? 'error' : ''" overlap>
              <template slot="badge">{{ qtdeNotificacoes ? qtdeNotificacoes+'+' : null }}</template>
              <v-icon color="white">mdi-bell</v-icon>
            </v-badge>
          </router-link>
          <v-card>
<!--             <v-list dense>

            </v-list> -->
          </v-card>
        </v-menu>
         <router-link v-if="this.$store.getters.get_pagina != 'EXPRESS'"
          v-ripple
          class="toolbar-items mr-2"
          to="/dashboard/user-profile"
        >
          <v-icon color="white">mdi-account</v-icon>
        </router-link>

        <v-icon class="toolbar-items mr-2" color="white" @click="logout"
          >mdi-power</v-icon
        >
      </v-flex>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    intervalorVerificarRobo: null,
    nomeHotel:'',
    notifications: [
      "Mike, Thanos is coming",
      "5 new avengers joined the team",
      "You're now friends with Capt",
      "Another Notification",
      "Another One - Dj Khalid voice",
    ],
    title: "Dashboard",
    responsive: false,
    responsiveInput: false,
    qtdeNotificacoes:0,
    roboativo: false,
    tempoLoopRoboAtivo:15000,
    tempoRoboAtivo:3,
    idIntervalRobo: null,
    qtdeNotificacoesPagtos:0,
    qtdeNotificacoesMensagensNaoLidas: 0
  }),

  computed: {
    ...mapGetters(["authorized"]),
    roboAtivoNoTempo:function(){
      return this.tempoRoboAtivo < 2
    },
    usarobo() {
      return localStorage.getItem('usarobo') == "true";
    }
  },

  watch: {
    $route(val) {
      this.title = val.meta.name;
    },
  },

  created() {
    // this.idIntervalRobo = setInterval(this.checkRobo, 4000);


  },


   async mounted() {
    
    if(!(localStorage.getItem("userid") >0)){
        this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    }

    await this.buscarQuantidadeMensagensNaoLidas();
 
    

    this.nomeHotel = localStorage.getItem('nomehotel')
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);

        //Conexao Socket
        this.$socket.disconnect();
        this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
        this.$socket.io.opts.query.tipo = 'DASHBOARD'
        this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
        this.$socket.connect();
        // this.$store.dispatch("setHiddenDrawer", true)

        this.sockets.subscribe("notificartopo", (dados) => {
              this.qtdeNotificacoes += 1
              console.log(dados)
        });
        this.sockets.subscribe("zerarnotificartopo", () => {
              this.qtdeNotificacoes = 0
        });

        this.sockets.subscribe("notificarMensagem", async () => {
          await this.buscarQuantidadeMensagensNaoLidas();
      })

        this.sockets.subscribe("notificartopopagtos", (dados) => {
              this.qtdeNotificacoesPagtos += 1
              console.log(dados)
        });

        this.sockets.subscribe("zerarnotificartopopagtos", () => {
              this.qtdeNotificacoesPagtos = 0
        });

 
        this.sockets.subscribe("backend_to_dash_respostarobo", () => {
          this.tempoRoboAtivo = 0
          this.roboativo = true
        })

        this.intervalorVerificarRobo = setInterval(() => {
          this.verificarRobo(this.$socket);
        }, this.tempoLoopRoboAtivo);

        this.$http.post("/notificacao/select", {idusuario:localStorage.getItem('userid'), naolidos:true})
        .then((response) => {
            this.qtdeNotificacoes = response.data.length
        })
        .catch((error) => console.log(error));

  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
    // clearInterval(this.idIntervalRobo);
    clearInterval(this.intervalorVerificarRobo);
  },

  methods: {
    desconectarRobo(){
        alert('desconectarRobo')
        this.$socket.disconnect();
        this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
        this.$socket.io.opts.query.tipo = 'DASHBOARD'
        this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
        this.$socket.connect();

        this.$socket.emit("desconectarRobos" ,{hotel: localStorage.getItem("idhotelpms")})
        // this.$socket.emit("dash_to_backend_verificar_robo")
    },
    zerarNotificacaoMensagem() {
      this.qtdeNotificacoesMensagensNaoLidas = 0
    },

    async buscarQuantidadeMensagensNaoLidas() {
      await this.$http
        .post("/whatsapp/buscarMensagensNaoVisualizadas")
        .then((response) => {
          this.qtdeNotificacoesMensagensNaoLidas = Number(response.data[0].count)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    verificarRobo(socket) {
      this.tempoRoboAtivo += 1
      console.log("verificarRobo")
      console.log("socket",socket)
       
        socket.emit("dash_to_backend_verificar_robo", {
          hotel: localStorage.getItem("idhotelpms"),
          email: localStorage.getItem("useremail"),
        });
    },
    // checkRobo() {
    //   this.$http.post("/robo/status", {
    //       idhotel: localStorage.getItem('hotel'), 
    //       idhotelpms: localStorage.getItem('idhotelpms')
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         this.roboativo = response.data.secondspassed && response.data.secondspassed < 5.0
    //         localStorage.setItem('roboativo',this.roboativo);
    //       }
    //     })
    //     .catch((error) => console.log(error));
    // },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    ...mapActions([
      'logout'
    ]),
    onClick() {
      //
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        window.location.href = "/"
        //this.$router.push("/");
      });
    },
  },
};
</script>

<style lang="scss">
.core-toolbar a {
  text-decoration: none;
}
</style>
