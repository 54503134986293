<template>
    <v-card style="width: 70vw!important; height: 80vh!important;"> 
        <v-toolbar color="blue" style="width:100%;">
            <span style="width:95%">Escolha o tipo de documento</span>
            <span style="width:5%"> 
                <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                    <v-icon x-small style="color:white;">mdi-close</v-icon>
                </v-btn>
            </span>
        </v-toolbar>
        <div class="captura_container" >
            <div class="captura_container_tipos py-4">
                <a @click="setarPasso(2,'RG')" class="tipos" >RG</a>
                <a @click="setarPasso(2,'PASSAPORTE')" class="tipos" >PASSAPORTE</a>
                <a @click="setarPasso(2,'CNH')" class="tipos" >CNH</a>
                <a @click="setarPasso(2,'DNI')" class="tipos" >DNI</a>
                <a @click="setarPasso(2,'RNE')" class="tipos" >RNE</a>
                <a @click="setarPasso(2,'OUTROS')" class="tipos" >OUTROS</a>
            </div>
        </div>
    </v-card>
</template>

<script>
import QRScanner from 'qr-code-scanner';
export default {
  data: () => ({
    image: ''
  }),
  methods: {
    setarPasso(valor,TipoDoc){
        this.$emit('setarTipoDocSelecionado',TipoDoc)
        this.$emit('setarPasso',valor)
    },
    lerQRCODE(){
        
        QRScanner.initiate({
        onResult: (result) => {
            console.log("RESULTADO DO SCANNER")
            console.log(result)
             },
        onError: function (err) { console.error('ERR :::: ', err); }, // optional
        onTimeout: function () { console.warn('TIMEOUT'); },
        timeout: 10000,
    });
    }
  },
  mounted() {
   
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        background-color:white;
        /* width:100vw; */
        /* height:90vh; */
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        /* width:80vw; */
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#1d4596;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        margin-bottom:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>