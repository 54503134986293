<template>
  <div style="max-width: 800px; margin: auto; margin-top: 20px; border: 1px solid rgb(185, 185, 185)">
      <div style="background-color: white;">
           <v-toolbar  color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <span style="width:95%;color:white;font-weight:bold;" >
                     Envios de E-mail Personalizados
                  </span>
              </v-flex>
          </v-toolbar>
          <div style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; padding: 10px;">
            <v-text-field label="E-mail" v-model="email" style="width: 50%; margin-right: 50px;" outlined></v-text-field>
            <v-text-field label="Assunto" v-model="assunto" style="width: 50%; margin-right: 50px;" outlined></v-text-field>
          </div>
          <div style="padding: 20px; width: 800px; margin:auto;">
              <p>Use a sua criatividade e monte um layout que será exibido no e-mail do hóspede :)</p>
              <quill-editor :options="editorOptions"  v-model="content" />
          </div>
            <div style="width: 100%; display: flex; justify-content: flex-end; padding: 10px;">
              <button class="buttonCadastrar" @click="convertClassesToInlineStyles">Enviar E-mail</button>
            </div>
          </div>
    </div>
</template>  
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize'
import Quill from 'quill'

Quill.register('modules/imageResize', ImageResize)
 export default {
  components: {
    quillEditor
  },

  name: 'ModalCadastrarContratoWebCheckin',
  data: () => ({
    content: '',
    empresasReservantes: null,
    mensagem: null,
    assunto: null,
    html: null,
    email: null,
    update: false,
    msgBotaoCadastrarAtualizar: 'Cadastrar Contrato',
    editorOptions: {
      modules: {
        toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ align: [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
      ],
      imageResize: {
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white'
      },
      modules: ['Resize', 'DisplaySize']
    }
      },
    },
  }),

  props: ['exibirModalCadastrarContratoWebCheckin', 'dados'],

  methods: {

    convertClassesToInlineStyles() {
      const container = document.createElement('div');
      container.innerHTML = this.content;

      const images = container.querySelectorAll('img');

      const elements = container.querySelectorAll('[class]')
      console.log(elements)
      elements.forEach(element => {
        const classes = element.classList

        for (let i = classes.length - 1; i >= 0; i--) {
          const className = classes[i]
          console.log('classname aqui', className)
          const inlineStyle = this.getInlineStyleForClass(className)

          if (inlineStyle) {
            element.removeAttribute('class')
            element.setAttribute('style', inlineStyle)
            console.log('element final', element)
          }
        }
      })


      images.forEach((img) => {
        const align = img.getAttribute('data-align');
        if (align) {
          let style = img.getAttribute('style') || '';

          if (align === 'left') {
            style += 'float: left; margin-right: 10px; max-width: 800px;';
          } else if (align === 'right') {
            style += 'float: right; margin-left: 10px; max-width: 800px;';
          } else if (align === 'center') {
            style += 'display: block; margin: 0 auto; text-align: center; max-width: 800px;';
          } else {
            console.log('entrou aqui')
            style += 'max-width: 800px;';
          }

          img.removeAttribute('data-align');
          img.setAttribute('style', style);
        } else {
          let style = img.getAttribute('style') || '';
          style += 'max-width: 800px;';
          img.removeAttribute('data-align');
          img.setAttribute('style', style);
        }
      });
      this.html = `<div style="max-width: 800px;">${container.innerHTML}</div>`;
      this.enviarEmail();
      return container.innerHTML;
  },
  async enviarEmail() {
    try {
      if (!this.email || this.email === '') {
        alert('É Obrigatório informar o e-mail.')
        return;
      }

      if (!this.assunto || this.assunto === '') {
        alert('É obrigatório informar o assunto.')
        return;
      }

      this.$http.post('email/enviaremailpersonalizado', {
        email: this.email,
        assunto:this.assunto,
        html: this.html
      })
      console.log('email enviado com sucesso!');
      alert('E-mail enviado com sucesso.')
    } catch (error) {
      alert('Erro ao enviar e-mail!');
    }
  },
  getInlineStyleForClass(className) {
    console.log('1',className)
      const styles = {
        'ql-indent-8': 'margin-left: 2rem;',
        'ql-size-small': 'font-size: 14px;',
        'ql-size-large': 'font-size: 20px;',
        'ql-size-huge': 'font-size: 30px;',
        'ql-align-center': 'text-align: center;',
        'ql-align-right': 'text-align: right;',
        'ql-font-serif': 'font-family: serif;',
        'ql-font-monospace': 'font-family: monospace;'
      }
      console.log(styles[className])
      return styles[className] || ''
    },

    async buscarEmpresasReservantes() {
      try {
        const result = await this.$http.post("/contrato/selecionarEmpresaReservante");
        console.log(result.data)
        this.empresasReservantes= result.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
 }
</script>

<style scoped>
.buttonCadastrar {
  background-color: #1D4596;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0 0 0;
}
</style>