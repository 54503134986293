<template>
  <div>
  <v-card>
    <v-toolbar color="blue" style="padding-top:10px;">
      <v-flex style="display: flex" class="pa-2">
        <div style="width:95%;color:white;font-weight:bold;" >
          <span>Faça a Leitura do QR Code ou via Email: 
            <!-- <span :style="possuiEmpresaReservante?'':'color:orange;'">
              {{ textoEmpresaReservante }}
            </span> -->
          </span>

        </div>
        <div style="width:5%;padding:10px;padding-top:1px;"> 
            <v-btn x-small color="red" @click="fecharModal()" title="Fechar">
                <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
        </div>
      </v-flex>
    </v-toolbar>

    <v-dialog
      v-model="dialog"
      width="70vw"
      transition="dialog-top-transition"
      style="background: white"
    >
      <v-card style="width: 70vw!important; height: 80vh!important;">
        <div v-if="step == 0">
          <!-- <captura-documento v-bind:id="editedItem.id"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar" /> -->
          <VerificarPeloCPF v-bind:numeroreserva="editedItem.numeroreserva" v-bind:idreserva="editedItem.idreserva"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar"
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 1">
          <!-- <captura-documento v-bind:id="editedItem.id"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar" /> -->
          <CapturaDocumento v-bind:idreserva="editedItem.idreserva"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar"
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 2">
          <DadosPessoais v-bind:idreserva="editedItem.idreserva" v-bind:numeroreserva="editedItem.numeroreserva" v-bind:id="editedItem.id" v-bind:idhospede="editedItem.idhospede" @avancar="avancar" 
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 3">
          <EnderecoFd v-bind:idreserva="editedItem.idreserva"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar" 
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 4">
          <OrigemDestinoFd v-bind:idreserva="editedItem.idreserva"  v-bind:idhospede="editedItem.idhospede" @avancar="() => avancar()" 
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 5">
          <ObservacaoFd v-bind:idreserva="editedItem.idreserva"  v-bind:idhospede="editedItem.idhospede" @avancar="avancar" 
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante" />
        </div>

        <div v-if="step == 6">
          <AssinaturaFd v-bind:idhospede="editedItem.idhospede" v-bind:idreserva ="editedItem.idreserva" v-bind:id ="editedItem.id" @avancar="avancar" 
            @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante"/>
        </div>

        <div v-if="step == 7">
          <FichaDigital v-bind:id="editedItem.id"  v-bind:idhospede="editedItem.idhospede"   @update="updateList()"
            @fechar="dialog=false" :possuiEmpresaReservante="possuiEmpresaReservante"/>
        </div>
        <v-footer absolute style="margini-left: auto !important; magini-right: auto !important; width:100%;position:fixed!important">
            <v-spacer />
            <v-btn :disabled="step==0" class="mr-2" @click="voltar" color="light-green" style="color:white;"
              data-cy="btn-voltar"><v-icon small>mdi-arrow-left</v-icon>Voltar</v-btn
            >
            {{ step }}
            <v-btn :disabled="step==7" class="ml-2" @click="avancar" color="light-green" style="color:white;"
              data-cy="btn-pular">Pular<v-icon small>mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn class="ml-2" @click="dialog = false" color="warning"
            data-cy="btn-sair"
              ><v-icon small>mdi-logout</v-icon>Sair
            </v-btn>
            <v-spacer />
          
          </v-footer>
      </v-card>
    </v-dialog>

    <v-dialog
          v-model="deleteDialog"
          width="50vw"
          transition="dialog-top-transition"
          style="background: white"
        >
          <v-card style="width: 70vw!important; height: 50vh!important;">
            <v-card-title>
            Excluir definitivamente os dados deste hóspede?
            </v-card-title>
            <v-card-text>
              <br>
              <h1>Atenção, os dados do hóspede selecionado serão excluídos. 
              Esta funcionalidade deve ser usada com cautela.</h1>
              <br>
              <span><h2 class="orange rounded px-2">{{reservaHospedeExcluir.nome}} {{reservaHospedeExcluir.sobrenome}}</h2></span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn data-cy="btn-excluir"
                color="primary" @click="excluirReservaHospede" >
                Sim, Excluir
              </v-btn>
              <v-btn data-cy="btn-cancelar"
                color="secondary" @click="deleteDialog=!deleteDialog" >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>

    <v-data-table :headers="headers" :items="ReservasList" class="elevation-1" 
    no-data-text="Carregando dados..." >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="purple" class="mr-2" @click="editItem(item)">
          mdi-login
        </v-icon>
        <v-icon color="brown" @click="notify(item)"> mdi-email-send </v-icon>
        <v-icon color="blue" @click="notify(item)">mdi-chat-alert</v-icon>
      </template> -->

      <template v-slot:[`item.numeroreserva`]="{ item }">
        <v-icon style="margin-bottom:1px;color:#1976d2;margin-right:2px;" :title="item.idhospedepms" small>mdi-qrcode</v-icon>
        <span @click="setQrCodeModal(item)" style="color:#1976d2;cursor:pointer;" >QR CODE</span>
      </template> 


      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon color="purple"
          @click="editItem(item)" :data-cy="`acao-editarfichadigital_${item.numeroreserva}`" :title="possuiEmpresaReservante?'Iniciar o preenchimento da Ficha Digital':'Sem empresa reservante, reimportar a reserva!'">
          mdi-login
        </v-icon> -->
        <v-icon color="brown"
        style="margin-right: 10px;"
          @click="notify(item), listarDados(item)" :data-cy="`acao-notificarinner_${item.numeroreserva}`" title="Envie notificações por email e WhatsApp">
          mdi-email-send
        </v-icon>
        <v-icon color="green" v-if="item.numeroreserva>0"
                    @click="whatsappClickToChat(item)" title="Solicitações do hóspede!">
                    mdi-whatsapp
        </v-icon>
        <!-- <v-icon color="blue" v-if="item.totalsolicitacoes>0" 
          @click="exibirSolicitacoes(item)" title="Solicitações do hóspede!">
          mdi-chat-alert
        </v-icon>
        <v-icon color="red" 
          @click="confirmarExcluirReservaHospede(item)" title="Excluir este hóspede da reserva.">
          mdi-delete
        </v-icon> -->
      </template>

      <template v-slot:[`item.nome`]="{ item }">
        <span v-if="item.dadosmerge > 0" title="Dados importados do PMS a espera de sua Avaliação." 
          style="color:#ff9800;display:flex;">
          <!-- <v-icon style="margin-bottom:1px;color:#ff9800;margin-right:2px;" :title="item.idhospedepms" small>mdi-exclamation</v-icon> -->
          <!-- {{item.nome}} -->
          <a @click="direcionarWebCheckin(item)" :title="item.idhospedepms" >{{item.nome}}</a>
        </span>
        <span v-else :title="item.idhospedepms" >
          <!-- <v-icon class="pb-1" v-if="item.principal" small color="orange" title="Hóspede principal.">
            mdi-crown
          </v-icon> -->
          {{item.nome}}
          <!-- <a @click="direcionarWebCheckin(item)" :title="item.idhospedepms" >{{item.nome}}</a> -->
        </span>
      </template>
      <template v-slot:[`item.sobrenome`]="{ item }">
        <span v-if="item.dadosmerge > 0" title="Dados importados do PMS a espera de sua Avaliação." style="color:#ff9800;">
          {{item.sobrenome}}
          <!-- <a @click="direcionarWebCheckin(item)" :title="item.idhospedepms" >{{item.sobrenome}}</a> -->
        </span>
        <span v-else >
          {{item.sobrenome}}
          <!-- <a @click="direcionarWebCheckin(item)" :title="item.idhospedepms" >{{item.sobrenome}}</a> -->
        </span>
      </template>

      <template v-slot:[`item.iconepreenchido`]="{ item }">
        <v-icon v-if="item.iconepreenchido" color="green" title="Ficha 100% preenchida!"> 
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="red" :title="`${item.percentualpreenchido}% preenchido. ${item.faltampreencher}.`">
          mdi-account-alert
        </v-icon>
        <v-icon v-if="item.enviadopms" color="orange" title="Ficha enviada ao PMS.">
          mdi-clipboard-check
        </v-icon>
      </template>

      <template v-slot:[`item.datacheckinprevisto`]="{ item }">
        {{item.datacheckinprevisto | formatarData}}
      </template>

      <template v-slot:[`item.datacheckoutprevisto`]="{ item }">
        {{item.datacheckoutprevisto | formatarData}}
      </template>

    </v-data-table>

    <ModalQrCode @setModalQrCode="(valor) => setModalQrCode(valor)" :exibirModalQrCode="exibirModalQrCode" v-if="exibirModalQrCode"/>

    <ModalQrCodeWebcheckin @setModalQrCodeWC="(valor) => setModalQrCodeWC(valor)" :exibirModalQrCodeWC="exibirModalQrCodeWC"  :itemSelecionadoQRcode="itemSelecionadoQRcode" v-if="exibirModalQrCodeWC" />

    <ModalSolicitacoes @consultarSolicitacoes="(valor) => consultarSolicitacoes(valor)" 
              @setModalSolicitacoes="(valor) => setModalSolicitacoes(valor)" :exibirModalSolicitacoes="exibirModalSolicitacoes" 
              :solicitacoes="solicitacoes" :itemselecionado="itemselecionado" />
              
    <ModalDirectMail :email="email" :exibirModalDirectMail="exibirModalDirectMail" @setModalDirectMail="setModalDirectMail()"/>

    <ModalDirectWhatsApp :numeroWhatsapp="this.ddi+this.ddd+this.telefone" :exibirModalDirectWhatsApp="exibirModalDirectWhatsApp" @setModalDirectWhatsApp="setModalDirectWhatsApp()"/>
          
          
  </v-card>

<!-- DIALOG 2 -->
          <v-dialog v-model="dialog2" width="500">
            <v-card style="max-width: 500px">
              <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex;" class="pa-2">
                  <span>
                    Reserva: {{ editedItem.numeroreserva }}
                   <!-- {{ editedItem.datacheckinprevisto }}  ||
                    {{ editedItem.datacheckoutprevisto }} -->
                    <br />
                    Hóspede: {{ editedItem.nome }} {{ editedItem.sobrenome }}
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn x-small color="red" @click="dialog2 = false" title="Fechar"
                    ><v-icon x-small color="white">mdi-close</v-icon></v-btn
                  >
                </v-flex>
              </v-toolbar>
              <v-card-text >
                <div>
                  <v-row >
                    <v-flex style="display: flex!important;">
                      <v-text-field
                        v-model="email"
                        append-icon="mdi-email"
                        type="email"
                        label="Email"
                        style="width: 250px !important"
                        class="mr-2"
                        
                        :rules="emailRules"
                      />

                      <!-- <v-btn
                        x-small
                        class="mr-2"
                        color="#00bb2d"
                        title="Enviar e-mail direto..."
                        @click="setModalDirectMail(), salvarDadosEmail(editedItem)"
                        
                        ><v-icon small>mdi-email-send</v-icon></v-btn
                      > -->
                        <v-btn large class="mr-2" color="orange" title="Enviar e-mail Web Check-IN" style="color:white;font-weight:bold;font-size:40px;" @click="enviarEmail()" >
                          ENVIAR LINK WEB CHECKIN
                        </v-btn>
                      <!-- <v-btn
                        x-small
                        class="mr-2"
                        color="purple darken"
                        title="Enviar e-mail Check-OUT Express"
                        ><v-icon small>mdi-cash-multiple</v-icon></v-btn
                      >
                      <v-btn
                        x-small
                        class="mr-2"
                        color="blue"
                        title="Enviar e-mail Check-IN Pay"
                        ><v-icon small>mdi-counter</v-icon></v-btn
                      > -->
                    </v-flex>
                  </v-row>
                  <!-- <v-row>
                    <v-flex xs12 style="display: flex">
                      <v-text-field
                        v-model="ddi"
                        label="DDI"
                        style="min-width: 20px !important;"
                        class="mr-2"
                        @keypress="isNumber($event)"
                      />
                      <v-text-field
                        v-model="ddd"
                        label="DDD"
                        style="min-width: 20px !important;"
                        class="mr-2"
                        @keypress="isNumber($event)"
                      />
                      <v-text-field
                        v-model="telefone"
                        append-icon="mdi-whatsapp"
                        label="WhatsApp"
                        style="min-width: 200px !important;"
                        class="mr-2"
                        @keypress="isNumber($event)"
                      />
                      
                      <v-btn
                        x-small
                        class="mr-2"
                        color="#00bb2d"
                        title="Enviar WhatsApp Direto"
                        @click="setModalDirectWhatsApp(), salvarDadosFone(editedItem)"
                        :disabled="!isWhatsAppSet"
                        ><v-icon small>mdi-whatsapp</v-icon></v-btn
                      >
                      <v-btn
                        x-small
                        class="mr-2"
                        color="orange"
                        title="Enviar WhatsApp Web Check-IN"
                        :disabled="!isWhatsAppSet"
                        ><v-icon small>mdi-email-plus</v-icon></v-btn
                      >
                      <v-btn
                        x-small
                        class="mr-2"
                        color="purple darken"
                        title="Enviar WhatsApp Check-OUT Express"
                        :disabled="!isWhatsAppSet"
                        ><v-icon small>mdi-cash-multiple</v-icon></v-btn
                      >
                      <v-btn
                        x-small
                        class="mr-2"
                        color="blue"
                        title="Enviar WhatsApp Check-IN Pay"
                        :disabled="!isWhatsAppSet"
                        ><v-icon small>mdi-counter</v-icon></v-btn
                      >
                    </v-flex>
                    
                  </v-row> -->
                  <div style="height : 20px!important;">
                    <span >{{ respostaAcao }}</span>
                  </div>
                  <div style="height: 20px!important;">
                    {{ isWhatsAppSetText }}
                  </div>                  
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="exibirmodalWhatsappApiGratis" width="400px" max-width="80vw">
      <div style="background-color: white; width: 100%; height: 100%; display: flex; flex-direction: column;">
        <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex;padding: 10px;">
                  <span>
                    Whatsapp
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn x-small color="red" @click="exibirmodalWhatsappApiGratis = false" title="Fechar"
                    ><v-icon x-small color="white">mdi-close</v-icon></v-btn
                  >
                </v-flex>
              </v-toolbar>
       <div style="padding:20px">
         <div style="display: flex; flex-direction: row;">
                         <v-text-field
                           v-model="ddi"
                           label="DDI"
                           style="min-width: 20px !important;"
                           class="mr-2"
                           @keypress="isNumber($event)"
                         />
                         <v-text-field
                           v-model="ddd"
                           label="DDD"
                           style="min-width: 20px !important;"
                           class="mr-2"
                           @keypress="isNumber($event)"
                         />
                         <v-text-field
                           v-model="telefone"
                           append-icon="mdi-whatsapp"
                           label="WhatsApp"
                           style="min-width: 200px !important;"
                           class="mr-2"
                           @keypress="isNumber($event)"
                         />
                </div>
                <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
                  <v-btn style="background-color:green;color:white; width: 30%;" @click="abrirWhatsappClickToChat(reservaSelecionadaWhatsapp)">Abrir</v-btn>
                </div>              
                </div>       
              </div>
          </v-dialog>

  </div>
  
</template>
<script>
import ModalQrCode from '../DashViews/Modals/ModalQrCode'
import ModalQrCodeWebcheckin from '../DashViews/Modals/ModalQrCodeWebcheckin'
import FichaDigital from './FichaDigital.vue';
import AssinaturaFd from './AssinaturaFd.vue';
import VerificarPeloCPF from "./VerificarPeloCPF.vue";
import CapturaDocumento from "./CapturaDocumento.vue";
import DadosPessoais from "./DadosPessoais.vue";
import EnderecoFd from "./EnderecoFd.vue";
import ObservacaoFd from "./ObservacaoFd.vue";
import OrigemDestinoFd from "./OrigemDestinoFd.vue";
import ModalDirectMail from '../DashViews/Modals/ModalDirectMail.vue';
import ModalDirectWhatsApp from '../DashViews/Modals/ModalDirectWhatsApp.vue';
import ModalSolicitacoes from '../DashViews/Modals/ModalSolicitacoes'

export default {
  props: ['numeroreserva','ativarImportacoes'] ,
  name: "ReservaFD",
  components: {
    EnderecoFd,
    CapturaDocumento,
    VerificarPeloCPF,
    DadosPessoais,
    OrigemDestinoFd,
    ObservacaoFd,
    AssinaturaFd,
    FichaDigital,
    ModalQrCode,
    ModalQrCodeWebcheckin,
    ModalSolicitacoes,
    ModalDirectMail,
    ModalDirectWhatsApp,
    
  },
  data: () => ({
    exibirmodalWhatsappApiGratis: false,
    reservaSelecionadaWhatsapp: null,
    idreservapms:null,
    idhospedepms:null,
    url_leitor_webcheckin:null,
    itemSelecionadoQRcode:null,
    textobtnatualizarreserva:"Reimportar Reserva",
    editedIndex: -1,
    editedItem: {
      nome: "",
      sobrenome: "",
      email: "",
      isAdmin: true,
      isActive: true,
      empresareservante :  ""
    },
    dialog2 : false,
    respostaAcao : "",
    telefone : "",
    ddi :"",
    idhotelEs: null,
    ddd : '',
    email : "",
    defaultItem: {},    
    exibirModalQrCode:false,
    exibirModalQrCodeWC:false,
    dialog: false,
    step: 0,
    maxStep: 7,
    btnVoltarDisabled : false,
    btnPularDisabled : false,
    fichaDigital: {},
    itemselecionado:null,
    exibirModalSolicitacoes:false,
    exibirModalSolicitacoesResponder:false,
    exibirModalDirectMail : false,
    exibirModalDirectWhatsApp : false,
    isWhatsAppSet: false,
    isWhatsAppSetText : "",
    solicitacoes:[],
    emailRules : [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],    
    headers: [
      { text: "QR CODE", value: "numeroreserva", width: "100px" },
      { text: "EMAIL/WHATSAPP", value: "actions", width: "100px", sortable: false},
    //   { text: "UH", value: "uh", width: "70px" },
      { text: "Nome", value: "nome", width: "150px" },
      { text: "Sobrenome", value: "sobrenome", width: "150px" },
    //   { text: "Status", value: "iconepreenchido", width: "80px" },
    //   { text: "St.Reserva", value: "descricaostatusreserva", width: "80px" },
      { text: "Check-IN", value: "datacheckinprevisto", width: "115px" },
    //   { text: "Check-OUT", value: "datacheckoutprevisto", width: "115px" },
      // { text: "Cliente", value: "empresareservante", width: "150px" },
    //   { text: "Tipo Hóspede", value: "tipohospede", width: "100px" },
    //   { text: "Pensão", value: "tipopensao", width: "190px" }
    ],
    ReservasList: [],
    deleteDialog: false,
    idToDelete: 0,
    reservaHospedeExcluir: {}
  }),
  computed: {
    textoEmpresaReservante () {
      var textToReturn = this.buscarEmpresaReservante()
      if (!textToReturn) textToReturn = '( Sem empresa reservante! Clique em "REIMPORTAR RESERVA" )'
      return textToReturn
    },
    possuiEmpresaReservante () {
      return this.buscarEmpresaReservante()
    }
  },
  watch: {
    numeroreserva: function () {
      this.buscaHospedesReserva();
    },
    step: function () {
      if(this.step >= this.maxStep){
        this.btnPularDisabled = true
      }else{
        this.btnPularDisabled = false
      }

      if(this.step <= 0){
        this.btnVoltarDisabled = true
      }else{
        this.btnVoltarDisabled = false
      }

    }
  },
  methods: {
    whatsappClickToChat(item){
      this.exibirmodalWhatsappApiGratis = true;
      this.reservaSelecionadaWhatsapp = item
      this.ddd = item.celularddd.trim();
      this.ddi = item.celularddi.trim();
      this.telefone = item.celular.trim().replace('-','').replace('-','')
    },
    async abrirWhatsappClickToChat() {
        try {
          let item = this.reservaSelecionadaWhatsapp;
          await this.salvarDadosFone(item)
      const telefoneReplace = this.telefone.trim().replace('-','').replace('-','')
        
        let ddi = this.ddi.trim()
        let ddd = this.ddd.trim()
        let telefone = telefoneReplace
        let telefoneCompleto = ddi+ddd+telefone
        console.log('telefone completo >>>>>>>', telefoneCompleto)
        let urlwebcheckin;
          urlwebcheckin = `https://webcheckin.fd.economysoftware.com.br/${this.idhotelEs}/${item.idreserva}`;
        let urlzap = `https://wa.me/${telefoneCompleto}?text=Olá, evite filas ao chegar no hotel, segue o link para o seu web checkin no *${localStorage.getItem('nomehotel')}*: ${urlwebcheckin}.`
        window.open(urlzap,'_blank')

      } catch (error) {
        alert('Não foi possível enviar, verifique o telefone do hospede.') 
      }
    },
    confirmarExcluirReservaHospede(reservahospede) {
      this.deleteDialog = true;
      this.idToDelete = reservahospede.id;
      this.reservaHospedeExcluir = reservahospede;
      //console.error(reservahospede)
    },
    excluirReservaHospede() {
        this.$http.post('reserva/hospede/delete',{
          idreservahospede: this.idToDelete
        })
        .then(resp =>{
          console.log(resp.data)
          if (resp.status == 200) {
            this.updateList();
          }
          this.deleteDialog = false;
        })
        .catch((e)=>{
          console.error(e);
          this.deleteDialog = false;
        })

    },
    buscarEmpresaReservante() {
      var retVal = null
      for (var i = 0; i < this.ReservasList.length; i++) {
        var er = this.ReservasList[i]['empresareservante']?this.ReservasList[i]['empresareservante']:''
        er = er.trim()
        if (er) {
          retVal = this.ReservasList[i]['empresareservante']
          break;
        }
      }
      return retVal
    },
    direcionarWebCheckin(item){
        // alert(JSON.stringify(item.id))
        let urlwebcheckin = this.url_leitor_webcheckin+item.id
        window.open(urlwebcheckin,'_blank')
    },
    setQrCodeModal(item){
      // this.exibirModalQrCodeWC = !this.exibirModalQrCodeWC
      this.setModalQrCodeWC(true)
      localStorage.setItem('idreserva', item.idreserva)
      this.itemSelecionadoQRcode = item
    },
    fecharModal(){
      this.ReservasList = []
      this.$emit('fechar')
    },
    updateList() {
      this.buscaHospedesReserva(true);
    },
    atualizarReserva(){
        this.textobtnatualizarreserva = "Importando..."
        // alert('A')
        this.$http.get('reserva/importarReservaViaRobo/'+localStorage.getItem('hotel')+'/'+this.numeroreserva)
                    .then(async () =>{
                      // alert('B')
                      await this.buscaHospedesReserva()
                      // alert('C')
                      this.textobtnatualizarreserva = "Reimportar Reserva"
                    })
    },
    setModalSolicitacoes(valor){
        this.exibirModalSolicitacoes = valor
    },
    async consultarSolicitacoes(reservahospede){
        await this.$http.post('reserva/solicitacao/select',{
                    reservahospede:reservahospede
            })
            .then(resp =>{
              //console.log(resp.data)
              this.solicitacoes = resp.data
            })
    },
    async exibirSolicitacoes(item) {

      this.itemselecionado = item
      this.exibirModalSolicitacoes = !this.exibirModalSolicitacoes
      this.exibirModalSolicitacoesResponder = false
      // this.dialog2 = dbox;
      await this.consultarSolicitacoes(item.id)
    },
    setModalQrCode(valor){
        this.exibirModalQrCode = valor
    },
    setModalQrCodeWC(valor){
        this.exibirModalQrCodeWC = valor
    },
    setModalDirectMail(){
      if(/.+@.+\..+/.test(this.email)){
        this.exibirModalDirectMail = !this.exibirModalDirectMail
      }else{
        this.respostaAcao = 'Informe o e-mail corretamente.'
      }
    },
    setModalDirectWhatsApp(){
      if(this.telefone.length > 0){
        this.exibirModalDirectWhatsApp = !this.exibirModalDirectWhatsApp
        this.respostaAcao = ""
      }else{
        this.respostaAcao = 'Informe numero WhatsApp corretamente.'
      }
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;

            if(!this.isWhatsAppSet){
              this.isWhatsAppSetText = "Verifique as configuração da API WhatsAPP."
            }
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },        
    avancar() {
      if (this.step < this.maxStep) {
        this.step++;
      }
    },

    voltar() {
      if (this.step > 0) {
        this.step--;
      }
    },
    isNumber($event){
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
          $event.preventDefault();
      }
    },    
    buscaHospedesReserva(emitupdate=false) {
      
      //let numreserva = this.numeroreserva;
      if(this.numeroreserva){
        this.$http
          .post("/reserva/select", { numeroreserva: this.numeroreserva, orderby: 'ordem',porid:1 })
          .then((response) => {
            this.ReservasList = response.data;
            if (emitupdate) this.$emit('update')
          })
          .catch((error) => console.log(error));
      }
    },
    listarDados(item) {
      console.log(item)
      console.log('Iniciando')
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: item.idhospede,
          idreserva: item.idreserva,
          porid:1
        })
        .then((response) => {
          //console.log(JSON.stringify(response.data))
          this.ddi = response.data[0].celularddi;
          this.ddd = response.data[0].celularddd;
          this.telefone = response.data[0].celular;
          this.email = response.data[0].email;
          this.idreservapms = response.data[0].idreservapms;
          this.idhospedepms = response.data[0].idhospedepms;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salvarDadosFone(item) {
       if(!this.ddi.length > 0 && !this.ddd.length > 0 && !this.telefone.length > 0){
        return
      }


      let body = {
        reserva: {
          id: item.idreserva,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          id: item.idhospede,
          celularddi: this.ddi,
          celularddd: this.ddd,
          celular: this.telefone
        },
      };

      //console.log("body")
      //console.log(body)
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log("Telefone salvo na ficha do hóspede.")
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          alert('Erro ao salvar dados na ficha do hóspede.')
        });
    },
    salvarDadosEmail(item) {
      if(!this.email.length > 0){
        return
      }
      let body = {
        reserva: {
          id: item.idreserva,
          hotel: localStorage.getItem("hotel"),
        },
        hospede: {
          id : item.idhospede,
          email: this.email
        },
      };

      //console.log("body")
      //console.log(body)
      this.$http
        .post("/reserva/upinsert", body)
        .then((response) => {
          if (response.status == 200) {
            console.log('Email salvo na ficha do hóspede.')
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          console.log('Erro ao salvar Email na ficha do hóspede')
        });
    },

    editItem(item, dbox = true) {
      this.editedIndex = this.ReservasList.indexOf(item);
      this.step = 0;
      this.editedItem = Object.assign({}, item);
      this.fichaDigital = this.editedItem;
      this.dialog = dbox
    },
    enviarEmail(){
      if(this.email.length > 0){
      
        this.$http.post('/reserva/setReenvioWebcheckin', { 
            id:this.editedItem.id,
            idpessoa:this.editedItem.idpessoa,
            idtipo:7,
            email:this.email
            }).then(() =>{
            
            this.respostaAcao = "E-mail enviado com sucesso."
            setTimeout(() => { 
              this.dialog2 = false
            }, 1000);
            
            
            this.email = ""
          }).catch(error=> {
            console.log(error)
            console.log(error.data)
          })

     
        }else{
          this.respostaAcao = "E-mail não pode estar vazio."
          return
        }


      // if(this.email.length > 0){
      //   this.respostaAcao = ""
      //   let link = window.location.origin+'/webcheckin/'+this.editedItem.id
      //   let body = {
      //     destinatario : this.email,
      //     assunto : "Checkin sem filas",
      //     text :  ` Clique e Realize seu Web Checkin <a href='${link}' target='_blank' > Preencher Web Checkin</a>`,
      //     nomeusuario : "Hotel Modelo",
      //     hotel:localStorage.getItem("hotel")
      //   }
       

      //     this.$http.post('/email/enviarEmail', body).then(response =>{
      //       if (response.status == 200) null
      //       this.respostaAcao = "E-mail enviado com sucesso."
      //       setTimeout(() => { 
      //         this.dialog2 = false
      //       }, 1000);
            
            
      //       this.email = ""
      //     }).catch(error=> {
      //       console.log(error)
      //       console.log(error.data)
      //     })
      //   }else{
      //     this.respostaAcao = "E-mail não pode estar vazio."
      //     return
      //   }
        
    },

    notify(item, dbox = true) {
      this.editedIndex = this.ReservasList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog2 = dbox;
      this.respostaAcao = ""
    },
  },

 async  mounted() {
    this.buscaHospedesReserva()
    const result = await this.$http.post('/configWebCheckin/buscarIdHotelEs');
      localStorage.setItem('idHotelEs', result.data[0].id_hotel_cliente_es)
      this.idhotelEs = result.data[0].id_hotel_cliente_es;
    //Conexao Socket
    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = 'DASHBOARD'
    this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
    this.$socket.connect();
    this.buscarDadosAPI();

    this.$http.post("configuracao/buscarParametro", { idhotel: localStorage.getItem("hotel"),chave: "URLWEBCHECKIN" })
        .then(resp => {
            this.url_leitor_webcheckin = resp.data[0].valor
        })

    // this.$store.dispatch("setHiddenDrawer", true)
  },
};
</script>