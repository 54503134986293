<template>
 <div class="wrapper">
      <div class="page">
    <div class="card-wrapper">
      <div class="card">
           <img v-if="bandeira == 'VISA'" src="@/assets/img/visa.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'MASTER'" src="@/assets/img/master.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'AMEX'" src="@/assets/img/amex.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'DINERS'" src="@/assets/img/diners.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'DISCOVER'" src="@/assets/img/discover.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'JCB'" src="@/assets/img/jcb.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'ELO'" src="@/assets/img/elo.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'HIPERCARD'" src="@/assets/img/hipercard.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
            <img v-if="bandeira == 'OUTRO'" src="@/assets/img/outro.png" width="auto" height="130" alt="Imagem Cartão" crossorigin />
      </div>
    </div>
    <div class="cc-form">
      <div class="cc-row">
        <div class="cc-center">Insira os dados</div>
      </div>
            <div class="cc-row">
                <div class="cc-col-12">
                    <label for="i-nometitular">Nome do titular:</label>
                    <v-text-field id="i-nometitular" placeholder="Insira seu nome" filled rounded dense
                        v-model="nometitular"
                        :rules="[validarNome]"
                        @keyup="corrigirNome"
                        autocomplete="off"
                        data-cy="input-nome"
                    ></v-text-field>
                </div>
            </div>
            <div class="cc-row">
                <div class="cc-col-12">
                    <label for="i-numerocartao">Número do cartão:</label>
                    <v-text-field id="i-numerocartao" filled rounded dense
                        autocomplete="off"
                        v-model="numerocartao"
                        :rules="[validarNumeroCartao]"
                        @keyup="event => corrigirNumeroCartao(event)"
                        placeholder="Insira o número do cartão"
                        @blur="formatarNumeroCartao"
                        data-cy="input-cartao"

                    ></v-text-field>
                </div>
            </div>
            <div class="cc-row">
                <div class="cc-col-6">
                    <label for="i-mmaa">MM/AA:</label>
                    <v-text-field id="i-mmaa" filled rounded dense
                        autocomplete="off"
                        v-model="mmaa"
                        :rules="[validarValidade]"
                        placeholder="Insira a validade"
                        @keyup="event => corrigirValidade(event)"
                        data-cy="input-validade"
                    ></v-text-field>
                </div>
                <div class="cc-col-6">
                    <label for="i-cvccvv">CVC/CVV:</label>
                    <v-text-field id="i-cvccvv" filled rounded dense
                        autocomplete="off"
                        v-model="cvccvv" type="number"
                        placeholder="Insira o código"
                        data-cy="input-codigo"
                        :rules="[validarCVCCVV]"
                        @keyup="event => corrigirCVCCVV(event)"
                    ></v-text-field>
                </div>
                <div class="cc-col-6">
                    <label for="i-parcela">PARCELAS:</label>
                    <v-text-field id="i-parcela" filled rounded dense
                        v-model="parcelas" type="number"
                    ></v-text-field>
                </div>
            </div>
            <!-- Saldo -->
            <div class="cc-row mb-2" >
                <div class="cc-col-6 text-center white cpay-saldo">
                    <div>R$ {{ dados.saldo.replace(',', '').replace('$', '') | formatarMoeda }}</div>
                    <div class="cpay-label-saldo"><b>Valor a pagar</b></div>
                </div>
            </div>                
        <button class="field submit-btn" @click="confirmar"  data-cy="btn-pagar">
          <span class="money-icon"><v-icon color="#F2F2F2">mdi-currency-usd</v-icon></span>
          <p>Efetuar Pagamento <span>Total</span></p>
          <span class="arrow-icon"><v-icon color="#8C8B99">mdi-chevron-right</v-icon></span>
        </button>
        </div>

     <!-- Modal de confirmação -->
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600" v-model="confirmMessage"
        >
            <template v-slot:default="confirmMessage">
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;padding:10px;">
                            <img src="@/assets/img/confirmarcartao.svg" 
                                width="150" height="auto"
                                alt="Confirmando..." crossorigin />
                                <br>
                            <div class="contest-message">Confirma os dados do seu cartão?
                                <br>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-spacer />
                        <!-- Confirmar (Sim) -->
                        <button class="sim-btn"
                            v-on:click="registrarPagamento"
                            data-cy="btn-registrar-pagamento"
                        >
                            <img src="@/assets/img/sim.svg" 
                                width="25" height="auto"
                                alt="Confirmar..." crossorigin />
                            <span class="px-2">Sim</span>
                        </button>
                        <v-spacer />
                        <!-- Cancelar (Não) -->
                        <button class="nao-btn"
                            v-on:click="confirmMessage.value = false"
                            data-cy="btn-negar-pagamento"

                        >
                            <img src="@/assets/img/nao.svg" 
                                width="25" height="auto"
                                alt="Cancelar..." crossorigin />
                            <span class="px-2">Não</span>
                        </button>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog> 
  </div></div>  
</template>

<script>
export default {
  props:["dados"],
  data(){
    return {
        hospedesreserva:[],
        validanome:null,
      nometitular:null,
      numerocartao:null,
      mmaa:null,
      cvccvv:null,
      bandeira: 'VISA',
      bandeiraSrc: '@/assets/img/visa.png',
      confirmMessage: false,
      parcelas:1,
      parcelasMaxima:1,
    }
  },
  methods: {
        confirmar() {
            
            if(this.validInput && this.validanome){
                

                if(this.validanome !== true){
                    alert("O nome do titular deve ser igual ao do hospede")
                }else{
                    if(localStorage.getItem('tipoPgto') == 1){
                        if(this.parcelas>0 && this.parcelasMaxima >= this.parcelas){
                            this.confirmMessage = true
                        }else{ 
                            alert("A Quantidade de Parcela deve está entre 1 e "+this.parcelasMaxima+"!");
                        }
                    }else{
                        this.confirmMessage = true
                    }
                }
            }else{
                alert('Todos os campos são obrigatorios')
            }
            
        },
        getBandeiraCartao(cardNumber){
            var regexVisa = /^4[0-9]{12}(?:[0-9]{3})?/;
            var regexMaster = /^5[1-5][0-9]{1,14}/;
            var regexAmex = /^3[47][0-9]{1,13}/;
            var regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{1,11}/;
            var regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{1,12}/;
            var regexJCB = /^(?:2131|1800|35\d{3})\d{1,11}/;
            var regexElo = /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/
            var regexHipercard = /^606282|^637095|^637599|^637568/

            if(regexVisa.test(cardNumber)){
                return 'VISA';
            }
            if(regexMaster.test(cardNumber)){
                return 'MASTER';
            }
            if(regexAmex.test(cardNumber)){
                return 'AMEX';
            }
            if(regexDiners.test(cardNumber)){
                return 'DINERS';
            }
            if(regexDiscover.test(cardNumber)){
                return 'DISCOVER';
            }
            if(regexJCB.test(cardNumber)){
                return 'JCB';
            }
            if(regexElo.test(cardNumber)){
                return 'ELO';
            }
            if(regexHipercard.test(cardNumber)){
                return 'HIPERCARD';
            }

            return 'OUTRO';
        },
        corrigirNome() {
            this.nometitular = this.nometitular.replace(/[^a-zA-Z ]/g, '');
            this.nometitular = this.nometitular.replace(/ {2}/g, ' ');
            this.nometitular = this.nometitular.toUpperCase()
        },
        validarNumeroCartao() {
            var msg = 'Este campo é obrigatório, verifique os números do cartão.'
            if (!this.numerocartao) return msg
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            var limiteInf = 13
            var limiteSup = 19
            if (this.bandeira == 'AMEX') {
                limiteInf = 15
                limiteSup = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    limiteInf = 14
                    limiteSup = 14
                } else {
                    if (this.bandeira == 'MASTER' 
                    || this.bandeira == 'DISCOVER'
                    || this.bandeira == 'JCB'
                    || this.bandeira == 'ELO'
                    || this.bandeira == 'HIPERCARD'
                    || this.bandeira == 'VISA') {
                        limiteInf = 16
                        limiteSup = 16
                    }
                }
            }
            return (!!numeroSemBarra && numeroSemBarra.length>=limiteInf && numeroSemBarra.length<=limiteSup || msg)
        },
        corrigirNumeroCartao(event) {
            if (!this.numerocartao) return
            var code = (event.which) ? event.which : event.keyCode;
            // console.error(code)
            // Retirar tudo que não é digito ou traço...
            this.numerocartao = this.numerocartao.replace(/[^0-9-]/g, '');
            // Identificar a bandeira do cartão (somente dígitos)
            this.bandeira = this.getBandeiraCartao(this.numerocartao.replace(/[^0-9]/g, ''))
            this.bandeiraSrc = `@/assets/img/${this.bandeira.toLowerCase()}.png`
            // Se estiver editando não mexer...
            if (code == 8) { return }
            // Se digitar o traço, retirar...
            if (code == 109) {
                this.numerocartao = this.numerocartao.slice(0, -1)
                return
            }
            // Inserir os traços automaticamente (se for AMEX a formatação é diferente)...
            var posSegundaBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 8
            var posTerceiraBarra = (this.bandeira == 'AMEX'||this.bandeira == 'DINERS') ? 10 : 12
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if ((numeroSemBarra.length == 4)
                || (numeroSemBarra.length == posSegundaBarra)
                || (numeroSemBarra.length == posTerceiraBarra)) {
                this.numerocartao +='-'
            }
            // Limitar a 16 números (existem cartões de 13 a 19 dígitos)
            var tamanhoLimite = 19
            if (this.bandeira == 'AMEX') {
                tamanhoLimite = 15
            } else {
                if (this.bandeira == 'DINERS') {
                    tamanhoLimite = 14
                } else {
                    if (this.bandeira == 'MASTER' 
                    || this.bandeira == 'DISCOVER'
                    || this.bandeira == 'JCB'
                    || this.bandeira == 'ELO'
                    || this.bandeira == 'HIPERCARD'
                    || this.bandeira == 'VISA') {
                        tamanhoLimite = 16
                    }
                }
            }
            if (numeroSemBarra.length > tamanhoLimite) {
                this.numerocartao = this.numerocartao.slice(0, -1)
            }
            // Retirar eventuais duplicidades do traço...
            this.numerocartao = this.numerocartao.replace('--', '-')
        },
        formatarNumeroCartao() {
            if (!this.numerocartao) return
            var numeroSemBarra = this.numerocartao.replace(/[-]/g, '')
            if (numeroSemBarra) {
                if (this.bandeira=='AMEX'||this.bandeira == 'DINERS') {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,10)
                        + '-' + numeroSemBarra.substring(10)
                } else {
                    this.numerocartao = numeroSemBarra.substring(0,4)
                        + '-' + numeroSemBarra.substring(4,8)
                        + '-' + numeroSemBarra.substring(8,12)
                        + '-' + numeroSemBarra.substring(12)
                }
            }
        },
        checarNomesReserva() {
            let peoples = this.hospedesreserva
            
            // this.idreservahospede = localStorage.getItem('checkout-idreservahospede')
            let valid = '´Nome é um campo obrigatório.';
            let namesCard = this.nometitular.split(' ')
            
            if (namesCard && namesCard.length > 0 && peoples && peoples.length > 0) {
            
                valid = 'O nome do titular deve ser um dos hóspedes da reserva.';
                // Primeiro tentar bater primeiro nome e último sobrenome...
                let firstNameCard = namesCard[0]
                let lastNameCard = namesCard.length > 1 ? namesCard[namesCard.length-1] : ''
                
                for (var i = 0; i < peoples.length; i++) {
                    
                    let aPerson = peoples[i]
                    let completPersonName = aPerson.nome + ' ' + aPerson.sobrenome
                    let personNames = completPersonName.split(' ')
                    if (personNames.length > 0) {
                        let personFirstName = personNames[0]
                        let personLastName = personNames.length > 1 ? personNames[personNames.length-1] : ''







                        if(firstNameCard && personFirstName && lastNameCard && personLastName){

                            if (firstNameCard.toUpperCase() == personFirstName.toUpperCase() && lastNameCard.toUpperCase() == personLastName.toUpperCase()) {
                                
                                console.log("OK")
                                //console.error('nome sobrenome, id:'+aPerson.id)
                                // this.idreservahospede = aPerson.id
                                //console.error('this.idreservahospede:'+this.idreservahospede)
                                valid = true;
                                break;
                            }

                        }
                    }
                }
                
                // Se não encontrou bater primeiro nome com primeiro nome...
                if (typeof(valid)=='string') {

                    console.log(" não encontrou bater primeiro nome com primeiro nome ")
                    
                    for (i = 0; i < peoples.length; i++) {
                        
                        let aPerson = peoples[i]
                        let completPersonName = aPerson.nome + ' ' + aPerson.sobrenome
                        let personNames = completPersonName.split(' ')
                        if (personNames.length > 0) {
                            let personFirstName = personNames[0]
                            if (firstNameCard == personFirstName) {
                                //console.error('primeiro nome, id:'+aPerson.id)
                                // this.idreservahospede = aPerson.id
                                //console.error('this.idreservahospede:'+this.idreservahospede)
                                valid = true;
                                break;
                            }
                        }
                    }
                }
            }
            // this.validName = valid
            return valid
        },
        async validarNome() {

            this.validanome = this.checarNomesReserva()
            
            return (   this.validanome)
        },
        validarValidade() {
            var validDateYear = true
            if (this.mmaa) {
                var parts = this.mmaa.split('/')
                if (parts.length == 2) {
                    try {
                        var month = parseInt(parts[0])
                        var year = parseInt(parts[1])
                        validDateYear = month >= 1 && month <= 12
                        validDateYear = validDateYear && year > 20 && year < 99
                    } catch (e) {
                        // console.error(e)
                        validDateYear = false
                    }
                } else {
                    validDateYear = false
                }
            }
            return (!!this.mmaa && validDateYear) || 'Digite mês e ano de validade.'
        },
        corrigirValidade(event) {
            if (this.mmaa) {
                var code = (event.which) ? event.which : event.keyCode;
                // console.error(code)
                // Retirar tudo que não é digito ou '/'...
                this.mmaa = this.mmaa.replace(/[^0-9/]/g, '');
                // Se estiver editando não mexer...
                if (code == 8) { return }
                // Se digitar a barra, retirar...
                if (code == 111) {
                    this.mmaa = this.mmaa.slice(0, -1)
                    return
                }
                // Inserir a barra automaticamente...
                var mmaaSemBarra = this.mmaa.replace(/[/]/g, '')
                if (mmaaSemBarra.length == 2) {
                    this.mmaa +='/'
                }
                // Limitar a 4 números
                if (mmaaSemBarra.length > 4) {
                    this.mmaa = this.mmaa.slice(0, -1)
                }
                // Retirar eventuais duplicidades da barra...
                this.mmaa = this.mmaa.replace('//', '/')
            }
        },
        validarCVCCVV() {
            var re
            if (this.bandeira == 'AMEX') { 
                re = /\b[0-9]{4}\b/g 
            } else {
                re = /\b[0-9]{3}\b/g 
            }
            return !!(this.cvccvv && this.cvccvv.match(re)) || 'Digite os dígitos de controle.'
        },
        corrigirCVCCVV() {
            // Limitar a 3 ou 4 números
            if (this.cvccvv) {
                var limit = (this.bandeira == 'AMEX') ? 4 : 3
                if (this.cvccvv.length > limit) {
                    this.cvccvv = this.cvccvv.slice(0, -1)
                }
            }
        },
        logout(){
            this.$emit('direcionar', {to:'login',from:'pagamento'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio',from:'pagamento'})
        },
        registrarPagamento() {
            var mmaaParts = this.mmaa.split('/')
            if (mmaaParts.length == 2) {
                var ano = parseInt(mmaaParts[1]) + 2000
                var validade = mmaaParts[0] + '/' + ano
                console.log(validade)
                
                //api para registrar pagamento
                this.$emit('registrarPagamento', {
                    nome: this.nometitular,
                    numerocartao: this.numerocartao.replace(/[-]/g, ''),
                    validade: validade,
                    cvccvv: this.cvccvv,
                    valorapagar: this.dados.saldo,
                    bandeira: this.bandeira=='OUTRO'?'VISA':this.bandeira,
                    saldo: this.dados.saldo,
                    idhotel: this.dados.hotel,
                    nomehotel: this.dados.nomehotel,
                    cabecalhos: this.dados.cabecalhos,
                    consumos: this.dados.consumos,
                    idhospede: this.dados.idhospede,
                    idreservahospede: this.dados.id,
                    numeroreserva: this.dados.numeroreserva,
                    parcelas: this.parcelas,
                })
            }
            this.confirmMessage = false
            //this.$emit('direcionar',{to: 'pagamentoaprovado'})
        }
        },
      computed: {
        validInput() {
            return (
                // typeof(this.validarNome()) == 'boolean' && 
                typeof(this.validarNumeroCartao()) == 'boolean'
                && typeof(this.validarValidade()) == 'boolean'
                && typeof(this.validarCVCCVV()) == 'boolean'
            )
        },
        // valorAPagar() {
        //     var result = 0
        //     try {
        //         var diariaStr = this.dados.valortarifa.replace(',', '')
        //         diariaStr = diariaStr.replace('R$', '')
        //         diariaStr = diariaStr.replace('$', '')
        //         var diaria = parseFloat(diariaStr)
        //         //console.log(diaria)
        //         var dias = parseInt(this.dados.qtdedias)
        //         //console.log(dias)
        //         result = diaria * dias
        //     } catch (e) {
        //         result = 0
        //     }
        //     return (result)
        // }
    },
    async mounted(){
        window.scrollTo(0, 0);
        
        await this.$http.post("/checkinpay/gethospedesdareserva",{numeroreserva:this.dados.numeroreserva,idhotel:this.dados.hotel},{ headers: {'x-access-token': this.$route.params.token}})
        .then(resp => {
            this.hospedesreserva = resp.data
        })
        
        this.$http.post("configuracao/buscarParametro", {
            idhotel: localStorage.getItem("hotel"),
            chave: "DEBCREDCONFIGCHECKINPAY",
        }).then((resp) => {
            if (resp.status == 200) {
                this.parcelasMaxima = resp.data[0]?resp.data[0].valor2:1
            } else {
                alert("Não foi possivel buscar os dados");
            }
        })
        .catch((error) => {
          console.log(error);
        });   
    }
}
</script>

<style lang="scss" scoped>
.wrapper{
    max-width: 30vw;
    margin: 0 auto;
    @media screen and (max-width: 768px){
        max-width: 100vw;
    }
}
.page{
  background: #EBEBF5;
  height: 100vh;
  overflow: hidden;
}

.card-wrapper{
  height: auto;
  background: #1760e5;
  border-radius: 0 0 2em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 2em 1em;

  .card{
    width: auto;
    height: 130px;
    border-radius: .7125em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    img{
        width: auto;
        height: 130px;
        @media screen and (min-width: 768px) {
            width: auto;
            height: 130px;
        }
    }
    
  }
}

.form{
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    font-size: 18px !important;
    color: #8C8B99;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 1em;
  }
  padding: 1em;
}

  .field{
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #F2F2F2;
    width: 100%;
    padding: .5em;
    margin-bottom: .5em;

    &.half{
      width: 48%;
    }

    &.submit-btn{
      margin: 0 auto;
      width: 90%;
      height: 3em;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p{
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-left: .5em;

        >span{
          color: #2DA63F;
        }
      }
    }

    label{
      color: #8C8B99;
    }
    input{
      outline: none;
    }
  }
//css-indesejados

  .money-icon{
    background: #2DA63F;
    border-radius: 50%;
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-icon{
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
.v-application p{
  margin-bottom: unset;
}

.mdi-menu{
  display: flex !important;
  align-items: flex-start !important;
}
.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}
</style>