<template>
    <div :title="tagTitle"
        style="min-width:300px;width:24vw;min-height:120px;max-height:120px;border-radius:5px;padding:10px;margin-top:40px;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;margin-right:3px;">
        <div style="width:100%;height:60%;display:flex;padding-right:10px;">
            <div 
            :style="styleIcone">
            <v-icon style="color:white!important;font-weight:bold;font-size:35px;">{{icone}}</v-icon>  
            </div>
            <div style="margin-left:10px;display:flex;width:20vw">
            <div style="min-width:150px;width:70%;">
                <h1 style="color:#464646;">{{titulo}}</h1>
            </div>
            <div style="width:30%;display:flex;justify-content:center;" :title="tagTitle">
                <h2 style="color:#464646;">{{valor}}</h2>
            </div>
            
            </div>

        </div>
        <div style="width:100%;height:40%;padding-left:7px;padding-top:5px;border-top:1px solid #bfbfbf;display:flex;">
            <div style="width:10%;text-align:center;">
                <v-icon style="font-weight:bold;">{{iconesecundario}}</v-icon>
            </div>
            <div :style="downloadData?'width:80%':'width:90%'">
                <h3 style="padding-left:10px;padding-top:1px;">{{titulosecundario}}</h3>
            </div>
            <div v-if="downloadData" style="width:10%;text-align:center;cursor:pointer;"
                @click="JSON2Excel(downloadData, titulo)">
                <v-icon style="font-weight:bold;">mdi-download</v-icon> 
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data: () => ({
        styleIcone:''
    }),
    methods: {
        JSON2Excel(JSONData, ReportTitle) {
            // Se não tiver dados retorna...
            if (JSONData.length == 0) return;
            // Número de campos para agrupar as células do título
            var num_campos = Object.keys(JSONData[0]).length
            var HTML = `
            <table>
                <tr>
                    <th colspan="${num_campos}">
                        ${ReportTitle}
                    </th>
                </tr>
                <tr></tr>
            `
            // Extrair os nomes dos campos
            var row = '<tr>'
            for (var field in JSONData[0]) {
                row += '<th>' + field + '</th>';
            }
            row += '</tr>\r\n'
            HTML += row
            // Primeiro loop para as linhas
            for (var i = 0; i < JSONData.length; i++) {
                row = "<tr>";
                // Segundo loop para as colunas
                for (field in JSONData[i]) {
                    row += '<td>' + JSONData[i][field] + '</td>';
                }
                // Quebra de linha...
                row += '</tr>\r\n';
                HTML += row;
            }
            HTML += '</table>';
            // Gerar um nome para o arquivo
            var fileName = "";
            // Substituir os espaços em branco do título por underline
            fileName += ReportTitle.replace(/ /g, "_");
            // Inicializar com o tipo de arquivo (xls)
            var uri = 'data:application/vnd.ms-excel;charset=utf-8, ' + escape(HTML);
            // Criar um link temporário <a /> tag
            var link = document.createElement("a");
            link.href = uri;
            // Tornar o link invisível
            link.style = "visibility:hidden";
            link.download = fileName + ".xls";
            // Acrescentar o link à página
            document.body.appendChild(link);
            // Iniciar o download
            link.click();
            // Excluir o link temporário
            document.body.removeChild(link);
        }
    },
    props:["cor","icone","titulo","valor","iconesecundario","titulosecundario","tagTitle","downloadData"],
    mounted() {
        this.styleIcone = "background-color:"+this.cor+";position:relative;width:80px;height:70px;border-radius:5px;top:-25px!important;display:flex;justify-content:center;"
    }
}
</script>
