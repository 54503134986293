<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <material-card color="general">
          <v-toolbar
            color="blue"
            class="ma-4"
            style="color: white; margin-bottom: 10px"
          >
            <span class="toolbar-title"> Minha Conta </span>
          </v-toolbar>
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-avatar slot="offset" class="mt2 mx-auto d-block" size="130">
                  <img
                    v-if="!imgSrc"
                    src=""
                  />
                  <img
                    v-if="imgSrc"
                    :src="'data:image.jpeg;base64,' + imgSrc"
                    class="imgUpload"
                  />
                </v-avatar>
                <v-card-text class="text-xs-center">
                  <v-spacer />
                  <input
                    id="file-upload"
                    type="file"
                    style="display: none"
                    accept="image/*"
                    @change="onFileChange"/>
                  <div class="mt2 mx-auto d-block">
                  <label style="font-weight: bold; font-size: 18px"
                    >Altere a foto do seu Perfil:</label
                  ><br />
                  <label for="file-upload">
                    <div
                      style="
                        background-color: #2196f3 !important;
                        border-radius: 3px;
                        width: 100px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        font-weight: bold;
                        font-size: 16px;"
                        class="text-xs-center">
                      Foto
                    </div>
                    </label>
                    </div>
                    <v-spacer />
                </v-card-text>
                <v-flex xs12 md4>
                  <v-text-field label="Nome" v-model="perfil.nome" />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    class="purple-input"
                    label="Nome de Usuário"
                    readonly
                    v-model="perfil.apelido"
                  />
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Email ( read only )"
                    readonly
                    v-model="perfil.email"
                    class="purple-input"
                    :rules="emailRules"

                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Senha"
                    type="password"
                    v-model="senha0"
                    class="purple-input"
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Confirme sua senha"
                    type="password"
                    v-model="senha1"
                    class="purple-input"
                  />
                </v-flex>

                <v-flex xs12 md2>
                  <!-- <v-text-field
                    label="Idioma"
                    v-model="perfil.idioma"
                    class="purple-input"
                  /> -->
                  <v-select
                    :items="idiomas"
                    v-model="perfil.idioma"
                    label="Idioma"
                    item-text="descricao"
                    item-value="codigoidioma"
                  ></v-select>
                </v-flex>
                
                
              </v-layout>
              <div style="display:flex;justify-content:flex-end;">
                  <v-btn @click="salvar()"
                    class="mx-0"
                    color="primary"
                    style="font-weight: bold!important;color:white;">
                    {{textobotaosalvar}}
                  </v-btn>
                </div>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <v-btn text @click="snack = false">Fechar</v-btn>
      </v-snackbar>

    </v-layout>
  </v-container>
</template>

<script>

import {serializarImagem} from '../../utils/serializar'

export default {
  data: () => ({
    textobotaosalvar : "SALVAR",
    senha0: "",
    senha1: "",
    isImg: null,
    imgSrc: null,
    imgTipo: null,
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    perfil: {
     
    },
    snack: false,
    snackColor: "",
    snackText: "",
    idiomas:[]
  }),
  methods: {

    buscarIdiomas(){
      
      this.$http.post("/geo/idiomas/suportados").then((response) => {
                this.idiomas = response.data 
            }).catch(error =>{
                console.log(error)
            });
    },
    validarFormulario(){

            if(!this.perfil.nome ){ 
                // this.$swal(this.$t('alert.notification_document_number_required.title'),this.$t('alert.notification_document_number_required.content'),"info"); 
                alert("Nome é obrigatorio!")
                return false
            }
            
            if(!this.perfil.apelido ){ 
                // this.$swal(this.$t('alert.notification_document_number_required.title'),this.$t('alert.notification_document_number_required.content'),"info"); 
                alert("Apelido é obrigatorio!")
                return false
            }

            if(!this.perfil.email ){ 
                // this.$swal(this.$t('alert.notification_document_number_required.title'),this.$t('alert.notification_document_number_required.content'),"info"); 
                alert("Email é obrigatorio!")
                return false
            }
            
            

            if(!this.senha0){ 
                // this.$swal(this.$t('alert.notification_name_required.title'),this.$t('alert.notification_name_required.content'),"info"); 
                alert("Senha é obrigatorio!")
                return false
            }

            if(!this.senha1){ 
                // this.$swal(this.$t('alert.notification_surname_required.title'),this.$t('alert.notification_surname_required.content'),"info"); alert("Documento é obrigatorio!")
                alert("Confirmação de senha é obrigatorio!")
                return false
            }

            if(this.senha0 != this.senha1){ 
                // this.$swal(this.$t('alert.notification_name_required.title'),this.$t('alert.notification_name_required.content'),"info"); 
                alert("As Senhas não estão iguais!")
                return false
            }

            if(!this.perfil.idioma){ 
                // this.$swal(this.$t('alert.notification_profession_required.title'),this.$t('alert.notification_profession_required.content'),"info"); 
                alert("Idioma é obrigatorio!")
                return false
            }

            return true

    },
    async salvar(){

        if( await this.validarFormulario() ){
            this.textobotaosalvar = "SALVANDO..."
            let dados = {
                id:this.perfil.id,
                nome:this.perfil.nome,
                apelido:this.perfil.apelido,
                email:this.perfil.email,
                senha:this.senha1,
                idioma:this.perfil.idioma
            }
            
            this.$http.post("/perfil/usuario/upinsert", dados).then((response) => {
                console.log(response)
                this.textobotaosalvar = "SALVAR"
                this.snack = true;
                this.snackColor = "success";
                this.snackText = "Salvo com sucesso!";
            }).catch(error =>{
                console.log(error)
            });

        } 
    },
    async buscaPerfil() {
      const email = localStorage.getItem("useremail");
      await this.$http
        .post("/perfil/usuario/select", { email: email })
        .then(async (response) => {
          this.perfil = response.data[0];

          await this.$http.post("/imagem/select", { id: this.perfil.avatar })
            .then((response) => {
              if (response.status == 200) {
                this.imgSrc = String.fromCharCode.apply(
                  null,
                  response.data[0].dados.data
                );
                this.imgTipo = response.data[0].tipo;
              }
            })
            .catch(error => {
                console.log(error)
            });
        })
        .catch((error) => console.log(error));
    },
    async salvaLogo(tipoImg) {
      if (!this.imgSrc.length > 0) {
        console.log("invalido");
        return;
      }

      let dados = {
        tipo: tipoImg,
        nome: "ImagemUsuario",
        dados: this.imgSrc,
        tabela: "usuario",
        fk: "avatar",
        chave: "id",
        valor: this.perfil.id,
      };
      await this.$http
        .post("/imagem/upinsert", dados, { timeout: 60000 })
        .then((response) => {
          if (response.status == 200) {
            console.log("Foto do Usuario Salvo com Sucesso!");
          } else {
            console.log("Não foi possivel salvar a Foto do Usuario!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async setImage(e) {
      const file = e.target.files[0];

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.isImg = true;
          this.imgSrc = event.target.result;
          let index = event.target.result.indexOf(",") + 1;
          this.imgSrc = event.target.result.substring(
            index,
            event.target.result.length
          );
          if (this.imgSrc) {
            this.salvaLogo(file.type);
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onFileChange(e) {
      // this.setImage(e);
      serializarImagem(e, 0.5).then(resp =>{
          this.imgSrc = resp.dados
          this.salvaLogo(resp.tipo);
      }).catch(error =>{
          this.loading = false
          this.snack = true
          this.snackColor = "error" 
          this.snackText = error.message
      })
    },
  },
  mounted() {
    this.buscaPerfil();
    this.buscarIdiomas()
  },
};
</script>
