<template>
  <v-carousel v-model="model"
    cycle dark height="80vh"
    show-arrows-on-hover
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(imagem, index) in imagens"
      :key="index"
    >
      <v-sheet
        height="100%"
        tile style="background-color:black;text-align:center;"
      >
      <h1 class="es-title" v-if="imagens.length>0">{{imagem.nomehotel}}</h1>
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div>
            <img
              v-if="imagem.imgSrc"
              :src="'data:' + imagem.tipo + ';base64,' + imagem.imgSrc"
              class="imgUpload"
              height="700px"
              width="auto"
            />
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    props: ['imagens'],
    data: () => ({
      model: 0
    }),
  }
</script>
<style scoped>
    .es-title {
      position: relative;
      font-size: 3rem!important;
      top: 0px;
      color:white;
    }
    @media screen and (max-width: 900px) {
      .es-title {
        /* font-size: 3rem!important; */
        top: 6rem;
      }
    }
</style>