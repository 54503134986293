<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
    <v-layout style="min-height:90vh;">
         <v-flex style=""> 
            <v-card class="pa-2" style="">
                <v-toolbar color="blue" class="ma-2" style="color: white">
                    <span class="toolbar-title"> Edição FAQ </span>
                </v-toolbar>


                <v-toolbar v-for="menu in listaMenus" :key="menu.id" color="#6187a5" class="ma-2" style="color: white">
                    <span class="toolbar-title" style="width:40%!important;"> {{menu.name}} </span>
                    <div style="width:60%;margin-left:20px;display:flex;justify-content:flex-end;align-items:center;height:100%;">
                        <v-btn @click="exibirFaqs(menu)">
                            Editar
                        </v-btn>
                    </div>
                </v-toolbar>
                
                
            </v-card>
            
               

                
            
        </v-flex> 
        <ModalFaqConfigListagem 
            @setModalFaqConfigListagem="() => setModalFaqConfigListagem()"
            :exibirModalFaqConfigListagem="exibirModalFaqConfigListagem"
            :dados="dados"
            />
    </v-layout>  
</template>

<script>
import ModalFaqConfigListagem from '@/components/DashViews/Modals/ModalFaqConfigListagem'
export default {
    data: () => ({
        exibirModalFaqConfigListagem:false,
        listaMenus:[],
        dados:{menu:0,nome:''}
    }),
    components:{
       ModalFaqConfigListagem
    },
    methods: {
       setModalFaqConfigListagem(){
           this.exibirModalFaqConfigListagem = !this.exibirModalFaqConfigListagem
       },
       exibirFaqs(menu){
           this.dados={menu:menu.id,nome:menu.name}
           this.setModalFaqConfigListagem()
       }
    },
    mounted() {
        this.$http.post("/menu/select", { id: 50, idioma: localStorage.getItem('idioma') })
        .then((response) => {
          this.listaMenus = JSON.parse(response.data[0]["objeto"]).children;
        })
        .catch((error) => {
          console.log(error);
        });
    },
};
</script>

<style>
</style>
