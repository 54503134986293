<template>
  <v-card style="height: 400px !important; width: 500px">
    <v-toolbar color="blue">
      <span style="width:95%">Buscando o endereço</span>
      <span style="width:5%;margin-right:10px;"> 
        <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
          <v-icon x-small style="color:white;">mdi-close</v-icon>
        </v-btn>
      </span>
    </v-toolbar> 
    <v-card-text>
      <p class="ma-2">
        <span
          >Digite os dados para pesquisa de endereço seguindo o modelo
          <strong>Rua conhecida, 1278</strong>.
          <a @click="dialog = true">Saiba mais</a>.</span
        >
      </p>
      <vue-google-autocomplete
        ref="refs"
        class="pa-2 ma-4"
        id="mapGoogleAutoComplete"
        classname="form-control"
        placeholder="Rua qualquer, 1234 ..."
        v-on:placechanged="getAddressData"
        v-model="address"
        data-cy="vue-google-autocomplete"
        :focus="true"
        
      >
      </vue-google-autocomplete
      ><v-icon>mdi-magnify</v-icon><v-icon color="red" title="Limpar" @click="address = ''">mdi-eraser</v-icon>
      <p style="height: 50px;">
        <span v-if="exibeCepField">CEP: {{ endereco.cep }} </span>
      </p>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      width="500"
      transition="dialog-top-transition"
      style="background: white"
    >
      <v-card>
        <v-toolbar color="blue" class="mb-4"
          >Informações sobre a busca de endereços</v-toolbar
        >
        <v-card-text>
          <p class="ma-2 text-justify">Ao pesquisar endereços, o sistema espera que o usuário sempre informe a rua e o número do endereço (<strong>Rua pesquisada, 1234</strong>) para que os dados localizados também tenham dados de CEP presentes e atendam o requisito de composição elementar do endereço(endereço completo). 
              Em ruas, avenidas ou vias muito extensas que possuem mais de um CEP, a presença do número do endereço é importante para localizar o CEP do treço pesquisado no campo de busca.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="dialog = false" small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogErro"
      width="250"
      transition="dialog-top-transition"
      style="background-color: white;"
    >
    <v-card>
      <v-toolbar color="red" class="pb-4">Erro</v-toolbar>
    
    <v-card-text class="mt-4">
      <p>Por favor, informe o endereço corretamente conforme modelo (<strong>Rua Prudente de Morais, 521</strong>) Considere digitar "rua a pesquisar e número do endereço informado". </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="dialogErro = false">Entendi</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green" @click="sobeEndereco" v-bind:disabled="!btnOk"
      data-cy="btn-ok-naoseicep"
        >OK</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "BuscaCep",
  data: () => ({
    dialog: false,
    dialogErro : false,
    address: "",
    exibeCepField: false,
    btnOk: false,
    endereco: {
      pais: "",
      uf: "",
      cidade: "",
      bairro: "",
      logradouro: "",
      numeroresidencia: "",
      cep: "",
    },
  }),
  components: {
    VueGoogleAutocomplete,
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {


      // console.log(JSON.stringify(placeResultData))
      // console.log(placeResultData)

      this.address = addressData;
      console.log(`id: ${JSON.stringify(id)}`);
      this.endereco.cep = ''
      for (var i = 0; i < placeResultData.address_components.length; i++) {
        var addrElement = placeResultData.address_components[i]
        if (addrElement.types.includes("street_number")) {
          this.endereco.numeroresidencia = addrElement.long_name
        }
        if (addrElement.types.includes("route")) {
          this.endereco.logradouro = addrElement.long_name
        }
        if (addrElement.types.includes("sublocality_level_1")
          || addrElement.types.includes("sublocality")
        ) {
          this.endereco.bairro = addrElement.long_name
        }
        if (addrElement.types.includes("administrative_area_level_2")) {
          this.endereco.cidade = addrElement.long_name
        }
        if (addrElement.types.includes("administrative_area_level_1")) {
          this.endereco.uf = addrElement.short_name
        }
        if (addrElement.types.includes("country")) {
          this.endereco.pais = addrElement.long_name
        }
        if (addrElement.types.includes("postal_code")) {
          this.endereco.cep = addrElement.long_name
        }
      }
      //console.error(this.endereco)
      if (this.endereco.cep) {
        this.exibeCepField = true;
        this.btnOk = true;
      }else{
        this.exibeCepField = false;
        this.btnOk = false;     
        this.dialogErro = true;
      }

      // this.endereco.logradouro = placeResultData.address_components[1].long_name;
      // this.endereco.pais = placeResultData.address_components[5].long_name;
      // this.endereco.uf = placeResultData.address_components[4].short_name;
      // this.endereco.cidade = placeResultData.address_components[3].long_name;
      // this.endereco.bairro = placeResultData.address_components[2].long_name;
      // this.endereco.numeroresidencia = placeResultData.address_components[0].long_name;

      // if (placeResultData.address_components.length > 6) {
      //   this.endereco.cep = placeResultData.address_components[6].long_name;
      //   this.exibeCepField = true;
      //   this.btnOk = true;
      // }else{
      //   this.exibeCepField = false;
      //   this.btnOk = false;     
      //   this.dialogErro = true   
      // }
    },
    sobeEndereco() {
      if(this.endereco.cep.length > 0){
        this.$emit("sobeEndereco", this.endereco);
      }else{
        this.dialogErro = true
      }
      
    },
  },
  mounted() {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    this.address = "";
    this.endereco.cep = ""
    this.exibeCepField = false;
    var addrInput = document.getElementById('mapGoogleAutoComplete');
    if (addrInput) addrInput.focus();
  },
};
</script>