<template>
    <v-dialog v-model="exibirModalRotacao" width="500px" max-width="500px">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Atenção
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalRotacione',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;padding-bottom:50px;">
                        <div style="font-size:20px;margin-bottom:10px;">Por Favor, antes de assinar, <b>ative a rotação automática</b> do seu Celular </div>
                        <div> <img src="@/assets/img/rotacaoauto2.png" width="auto" height="50" alt="Pagamento aprovado!" crossorigin /> </div>
                        <div style="font-size:20px;margin-top:10px;margin-bottom:10px;">e rotacione o dispositivo para a Posição Horizontal</div>
                        <div style="margin-bottom:20px;"> <img src="@/assets/img/movimentorotacao.png" width="auto" height="50" alt="Pagamento aprovado!" crossorigin /> </div>
                        
                        <v-btn color="info" @click="$emit('setModalRotacione',false)" title="Fechar">
                            Continuar
                        </v-btn>
                    </div>

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>

export default {
    data: () => ({
        // url_leitor_web:'http://192.168.1.64:8080/captureLogin/' +localStorage.getItem('userid')     
        url_leitor_web:null     
    }),
    props:["exibirModalRotacao"],
    methods: {
        
    },
    mounted() {
       
    },
};
</script>