<template>
  <div style="background-color: white;"> 
    <div>
      <v-toolbar color="blue" style="width:100%;">
          <span style="width:95%">Escolha o tipo de documento</span>
          <span style="width:5%"> 
              <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                  <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
          </span>
      </v-toolbar>
    </div>
    <div style="background: white; width: 100%;min-height: 300px; display: flex; flex-direction: column; padding: 20px;">
      <div style="display:flex;align-items:center;justify-content:center; gap:20px">
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
            <img width="300px" v-if="imgSrcFrente" :src="'data:image.png;base64,' + imgSrcFrente" class="imgUpload" style="cursor: pointer;"/>
        </div>
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
            <img width="300px"  v-if="imgSrcVerso" :src="'data:image.png;base64,' + imgSrcVerso" class="imgUpload"   style="cursor: pointer;" />
        </div>
      </div>
      <div style="min-height: 200px; display: flex; justify-content: center; align-items: center;" v-if="loading">
        <p>Carregando...</p>
      </div>

      <div style="min-height: 200px; display: flex; justify-content: center; align-items: center;" v-if="!imgSrcFrente && !loading">
        <p>Nenhuma imagem!</p>
      </div>
      <a v-if="!loading" @click="setarPasso('selecionar')" class="tipos" >Voltar</a>
    </div>
  </div>
</template>

<script>
export default {
props: ['item', 'tipoDoc', 'sistema'],
data: () => ({
  image: '',
  imgSrcFrente: null,
  imgSrcVerso: null,
  loading: false,

}),
methods: {
  setarPasso(valor){
      this.$emit('setarPasso',valor)
  },
  async listarDados() {
    this.loading = true;
    if (this.tipoDoc === 'cartao') {
      const idCartaoFrente = this.sistema === 'checkout' ? this.item.cartao_frente_checkout : this.item.cartao_frente_checkinpay;
      const idCartaoVerso = this.sistema === 'checkout' ? this.item.cartao_verso_checkout : this.item.cartao_verso_checkinpay
      await this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),ids: [idCartaoFrente, idCartaoVerso], id: [this.item.cartao_frente_checkinpay, this.item.cartao_verso_checkinpay]})
        .then(async (responseimg) => {
          // this.imgSrcFrente = String.fromCharCode.apply(null,responseimg.data[0].dados.data)
          if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
            const cartaoSistemaNomeImagemFrente = this.sistema === 'checkout' ? 'cartaoFrenteCheckout' : 'cartaoFrenteCheckinpay';
            const cartaoSistemaNomeImagemVerso = this.sistema === 'checkout' ? 'cartaoVersoCheckout' : 'cartaoVersoCheckinpay';
            const cartaoFrente = responseimg.data.filter((e) => e.nome === cartaoSistemaNomeImagemFrente)
            const cartaoVerso = responseimg.data.filter((e) => e.nome === cartaoSistemaNomeImagemVerso)

            if (cartaoFrente.length && cartaoVerso.length) {
              this.imgSrcFrente =  await Buffer.from(cartaoFrente[0].dados).toString()
              this.imgSrcVerso =  await Buffer.from(cartaoVerso[0].dados).toString()
            }
          }
          this.loading = false;
        })
        .catch(() => { 
          this.loading = false;
          alert('Erro ao buscar imagens')
         });
    }

    if (this.tipoDoc === 'comprovante') {
      const idComprovante = this.sistema === 'checkout' ? this.item.comprovante_residencia_checkout : this.item.comprovante_residencia_checkinpay
      await this.$http.post("/imagem/select", { hotel: localStorage.getItem("hotel"),id: idComprovante } )
        .then(async (responseimg) => {
          // this.imgSrcFrente = String.fromCharCode.apply(null,responseimg.data[0].dados.data)
          if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
            if (responseimg?.data?.length) {
              this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
            }
          }
          this.loading = false;
        })
        .catch((error) => { 
          console.log(error)
          this.loading = false;
          alert('Erro ao buscar imagens')
         });
    }

    if (this.tipoDoc === 'documento') {
      const idDocumentoFrente = this.sistema === 'checkout' ? this.item.documento_frente_checkout : this.item.documento_frente_checkinpay
      await this.$http.post("/imagem/select", { hotel: localStorage.getItem("hotel"),id: idDocumentoFrente } )
        .then(async (responseimg) => {
          // this.imgSrcFrente = String.fromCharCode.apply(null,responseimg.data[0].dados.data)
          if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
            if (responseimg?.data?.length) {
              this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
            }
          }
          this.loading = false;
        })
        .catch((error) => { 
          this.loading = false;
          console.log(error)
          alert('Erro ao buscar imagens')
         });
    }
  },
},
async mounted() {
 await this.listarDados();
},
};
</script>

<style scoped>
  .captura_container{
      background-color:rgb(161, 105, 105);
      height: 100%;
      padding: 10px;
  }

  .tipos{
      display: flex;
      justify-content: center;
      align-items:center;
      background-color:#1d4596;
      width:150px;
      max-width:90vw;
      height:40px;
      border-radius:5px;
      text-align:center;
      color:white;
      font-weight:bold;
      font-family:Arial, Helvetica, sans-serif;
      margin: 10px 10px 10px 10px;
  }

</style>