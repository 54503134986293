<template>
  <div>
    <div class="captura_container" v-if="imagens.length>0 && (!hospedeAtual || !hospedeAtual.id_fila)">
      <CapturaDocumentosGaleria v-if="imagens.length>0 && (!hospedeAtual || !hospedeAtual.id_fila)" :imagens="imagens"></CapturaDocumentosGaleria>
    </div>
    <div class="containerPrincipal" v-if="hospedeAtual && hospedeAtual.id_fila">
      <div class="calling">
          <h2 style="font-size: 40px!important;">{{hospedeAtual.nome_guiche ? `Comparecer ao guichê ${hospedeAtual.nome_guiche}:` : 'Comparecer a recepção'}}</h2>
          <p style="font-size: 80px!important;color:#1D4596">{{ hospedeAtual.nome }} {{ hospedeAtual.sobrenome }}</p>
        </div>
        <div class="queue">
          <h1>Fila de Atendimento</h1>
          <ul>
            <li v-for="(person, index) in fila" :key="index">{{ person.nome }} {{ person.sobrenome }}</li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
  import CapturaDocumentosGaleria from '../DashViews/CapturaDocAssinatura/CapturaDocumentosGaleria.vue';
  export default {
    components: {
      CapturaDocumentosGaleria
    },
    name: "ChamadosGuiche",
    data: () => ({
      atendimentos: null,
      ordemAtendimento: 0,
      chamados: [],
      fila: [],
      iniciarContagem: false,
      intervalId: null,
      imagens: [],
      hospedeAtual: null,
    }),
    methods: {    
      async buscarHospedesNaFila() {
        try {
          const atendimentos = await this.$http.get("/chamadosguiche/atendimento");
          this.atendimentos = atendimentos.data;
          this.verificarChamados(atendimentos.data);
          if (!this.iniciarContagem) {
            this.ordemAtendimento = 0;
            await this.avancarFila();
          }
        } catch (error) {
          console.log(error)
        }
      },

      verificarChamados(atendimentos) {
        if (atendimentos.length > 0) {

          const atendimentosDiferentes = atendimentos.filter(atendimento => {
            return !this.chamados.some((chamado) => atendimento.id_fila === chamado.id_fila);
          });

          this.chamados = [...this.chamados, ...atendimentosDiferentes];

          this.fila = [...this.fila, ...atendimentosDiferentes];

          if (!this.hospedeAtual || !this.hospedeAtual.id_fila) {
            this.hospedeAtual = this.fila[this.ordemAtendimento];
          }
          this.fila = this.fila.filter((e) => e.id_fila !== this.hospedeAtual.id_fila);

        }

      },

      async avancarFila() {
        if (this.fila.length > 0) {
          this.iniciarContagem = true;
          this.intervalId = setInterval(async () => {
            if (this.fila.length === 0) {
              const statusHospede = await this.buscarStatusHospede(this.hospedeAtual.id_fila);
              if (statusHospede[0].status_fila === 'atendido') {
                this.hospedeAtual = null;
                this.chamados = [];
                this.iniciarContagem = false;
                clearInterval(this.intervalId);
                return;
              }
              await this.alterarStatusHospedeChamando();
              this.chamados = [];
              this.iniciarContagem = false;
              clearInterval(this.intervalId);
              return
            }

            if (this.hospedeAtual.status_fila === 'em espera') {
              await this.alterarStatusHospedeChamando();
            }

            const statusHospede = await this.buscarStatusHospede(this.hospedeAtual.id_fila);

            this.hospedeAtual.status_fila = statusHospede[0].status_fila;

            const hospedeNovo = this.fila[0];

            if(this.fila.length === 1) {
              this.fila =  []
            } else {
              console.log(this.fila.splice(0, 1))
            }
            
            if (statusHospede[0].status_fila === "chamando") {
              this.fila = [...this.fila, this.hospedeAtual]
            }


            this.hospedeAtual = hospedeNovo
          }, 15000);
        }
      },

      async buscarStatusHospede(id_fila) {
        try {
          const body = {
            id_fila
          }
          const buscar = await this.$http.post("/chamadosguiche/buscarStatusHospedeFila", body);
          return buscar.data;
        } catch (error) {
          console.log(error);
        }
      },

      async alterarStatusHospedeChamando() {
        try {
          const body = {
            status: "chamando",
            id_fila: this.hospedeAtual.id_fila
          }
          await this.$http.post("/chamadosguiche/alterarStatusHospedeFila", body);
        } catch (error) {
          console.log(error);
        }
      },

      carregarImagens() {
      this.$http
        .post("perfil/hotel/imagem/select", {
          idhotel: localStorage.getItem("hotel")
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              this.imagens = resp.data;
              for (var i = 0; i < this.imagens.length; i++) {
                let imgObj = this.imagens[i]
                if(imgObj.dados.data) {
                  this.imagens[i].imgSrc = Buffer.from(imgObj.dados.data).toString();
                  delete imgObj.dados;
                }
              }
            }
          } else {
            console.log("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async verificarHospedeNaFila(data) {
      if (this.fila.length === 0 && this.hospedeAtual && this.hospedeAtual.id_fila) {
        if (data[0].id_fila === this.hospedeAtual.id_fila) {
          clearInterval(this.intervalId);
          this.chamados = [];
          this.iniciarContagem = false;
          this.hospedeAtual = null;
        }
      }

      if (this.fila.length > 0) {
        this.fila = this.fila.filter((e) => e.id_fila !== data[0].id_fila);
      }
    }

    },
    async mounted() {
      this.$socket.disconnect();
      this.$socket.connect();
      this.sockets.subscribe("notificarHospedeNaFilaGuiche", async () => {
          await this.buscarHospedesNaFila();
      })
      this.sockets.subscribe("notificarAtualizacaoStatusHospedeFila", async (data) => {
          await this.verificarHospedeNaFila(data)
      })
      await this.buscarHospedesNaFila();
      await this.carregarImagens();
    }
  }
</script>

<style scoped>
 .captura_container{
        background-color:black;
        width:100vw;
        height:100vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        /* padding-left:10%;
        padding-right:10%; */
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        opacity: 0.8;
    }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.containerPrincipal {
  display: flex;
  margin: 0;
  height: 100vh;
  background-color: #1D4596;
  color: #000000;
}

.queue {
  flex: 1;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 5px;
  margin-right: 25px;
  height: 90%;
  overflow-y: auto;
  max-width: 30%;
}

.calling {
  flex: 1;
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 5px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 70%;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 30px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 24px;
  font-weight: bold;
}

li:first-child {
  margin-top: 0;
}

li:last-child {
  margin-bottom: 0;
}

.calling h2 {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.calling p {
  font-size: 48px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .queue,
  .calling {
    margin: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .queue {
    max-width: none;
    flex: 1;
  }

  .calling {
    width: 100%;
  }
}


</style>