// https://vuex.vuejs.org/en/getters.html

// authorized lets you know if the token is true or not
export default {
  authorized: state => !!state.token,
  authstatus: state => state.authStatus,
  hiddenDrawer: state => state.hiddenDrawer,
  get_ultimostatus: state =>state.ultimostatus ,
  get_tipoacesso: state =>state.tipoacesso ,
  get_pagina: state =>state.pagina ,
}
