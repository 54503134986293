<template>
    <v-dialog v-model="exibirModalQrCodeWC" width="500px" max-width="500px" persistent>
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Leia o QR Code para acessar o Web Checkin
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalQrCodeWC',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div v-if="itemSelecionadoQRcode" style="display:flex;justify-content:center;padding-bottom:50px;">
                        <VueQrcode  style="width:200px" :value="liberarNovoWebCheckin ? urlNovoWebCheckin : url_leitor_webcheckin+itemSelecionadoQRcode.id" />
                    </div>

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>


import VueQrcode from 'vue-qrcode'
export default {
    data: () => ({
        // url_leitor_webcheckin:'http://192.168.1.64:8080/captureLogin/' +localStorage.getItem('userid')     
        url_leitor_webcheckin:null,
        liberarNovoWebCheckin: null,
        urlNovoWebCheckin: null
        // item:null
    }),
    props:["exibirModalQrCodeWC","itemSelecionadoQRcode"],
    components:{
        // ModalSolicitacoesResponder
        VueQrcode 
    },
    // watch: {
    //     itemSelecionadoQRcode(val){
    //         this.item = val
    //     }
    // },
    methods: {
        consultarSolicitacoes(reservahospede){
            console.log("PASS 1");
            this.$emit('consultarSolicitacoes',reservahospede)
        },
        setExibirModalResponder(item){
            this.solicitacao = item
            this.setModalResponder(!this.exibirModalResponder)
        },
        setModalResponder(valor){
            // alert(JSON.stringify(this.solicitacao))
            this.exibirModalQrCodeWC = valor
        },

        async buscarLiberarWebCheckin() {
            try {
            const result = await this.$http.post("configWebCheckin/buscarLiberarNovoWebCheckin/")
            console.log('config webcheckin', result.data)
            if(result.data.length > 0) {
                this.liberarNovoWebCheckin = result.data[0].valor === 'true' ? true : false
            } else {
                this.liberarNovoWebCheckin = false
            }
            } catch (error) {
                console.log(error);
            }
        },
    },
  async mounted() {
        this.urlNovoWebCheckin =  `https://webcheckin.fd.economysoftware.com.br/${localStorage.getItem('idHotelEs')}/${localStorage.getItem('idreserva')}`    

        await this.buscarLiberarWebCheckin();

        await this.$http.post("configuracao/buscarParametro", { idhotel: localStorage.getItem("hotel"),chave: "URLWEBCHECKIN" })
        .then(resp => {
            this.url_leitor_webcheckin = resp.data[0].valor
        })

    },
};
</script>