<template>
  <div>
    <v-card height="480">
      <v-toolbar color="blue">Dados Importados para Revisão</v-toolbar>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card-text>

            <div style="width:100%;min-height:50px;display:flex;">
                    <div style="width:50%;min-height:50px;padding:10px;">
                        <h3>Dados Atuais</h3>
                    </div>
                    <div style="width:50%;min-height:50px;padding:10px;">
                        <h3>Dados Reimportados</h3>
                    </div>
            </div>

            <div v-if="(dadosMerge.nomeAtual != null && dadosMerge.nomeNovo != null) && (dadosMerge.nomeAtual != dadosMerge.nomeNovo)" style="width:100%;min-height:50px;display:flex;">
                    <div @click="nomeselecionado = dadosMerge.nomeAtual" style="width:50%;min-height:50px;max-height:50px;padding:10px;">
                        <div :style="(nomeselecionado == dadosMerge.nomeAtual ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                            <div style="font-weight:normal;">Nome:</div> 
                            <div style="font-weight:bold;font-size:20px;">{{dadosMerge.nomeAtual}}</div> 
                        </div>
                    </div>
                    <div @click="nomeselecionado = dadosMerge.nomeNovo" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(nomeselecionado == dadosMerge.nomeNovo ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                           <div style="font-weight:normal;">Nome:</div> 
                            {{dadosMerge.nomeNovo}}
                        </div>
                    </div>
            </div>

            <div v-if="(dadosMerge.sobrenomeAtual != null && dadosMerge.sobrenomeNovo != null) && (dadosMerge.sobrenomeAtual != dadosMerge.sobrenomeNovo)" style="width:100%;min-height:50px;display:flex;">
                    <div @click="sobrenomeselecionado = dadosMerge.sobrenomeAtual" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(sobrenomeselecionado == dadosMerge.sobrenomeAtual ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                             <div style="font-weight:normal;">Sobrenome:</div> 
                            {{dadosMerge.sobrenomeAtual}}
                        </div>
                    </div>
                    <div @click="sobrenomeselecionado = dadosMerge.sobrenomeNovo" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(sobrenomeselecionado == dadosMerge.sobrenomeNovo ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                            <div style="font-weight:normal;">Sobrenome:</div> 
                            {{dadosMerge.sobrenomeNovo}}
                        </div>
                    </div>
            </div>


            <div v-if="(dadosMerge.telefoneAtual != null && dadosMerge.telefoneNovo != null ) && (dadosMerge.telefoneAtual != dadosMerge.telefoneNovo)" style="width:100%;min-height:50px;display:flex;">
                    <div @click="telefoneselecionado = dadosMerge.telefoneAtual" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(telefoneselecionado == dadosMerge.telefoneAtual ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                            <div style="font-weight:normal;">Telefone:</div>
                            {{dadosMerge.telefoneAtual}}
                        </div>
                    </div>
                    <div @click="telefoneselecionado = dadosMerge.telefoneNovo" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(telefoneselecionado == dadosMerge.telefoneNovo ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                           <div style="font-weight:normal;">Telefone:</div>
                            {{dadosMerge.telefoneNovo}}
                        </div>
                    </div>
            </div>

            <div v-if="(dadosMerge.emailAtual != null && dadosMerge.emailNovo != null) && (dadosMerge.emailAtual != dadosMerge.emailNovo)" style="width:100%;min-height:50px;display:flex;">
                    <div @click="emailselecionado = dadosMerge.emailAtual" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(emailselecionado == dadosMerge.emailAtual ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                            <div style="font-weight:normal;">Email:</div>
                            {{dadosMerge.emailAtual}}
                        </div>
                    </div>
                    <div @click="emailselecionado = dadosMerge.emailNovo" style="width:50%;min-height:50px;padding:10px;">
                        <div :style="(emailselecionado == dadosMerge.emailNovo ? 'background-color:#43a047;color:white;' :'background-color:#d9d9d9;') + 'width:80%;padding:10px;border-radius:5px;cursor:pointer;'">
                           <div style="font-weight:normal;">Email:</div>
                            {{dadosMerge.emailNovo}}
                        </div>
                    </div>
            </div>

               

            
          
          <div style="text-align: center;margin-top:20px;">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              @click="usarDados()"
              :disabled="!btn"
              >Utilizar Dados Selecionados</v-btn
            >
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
              <span>{{ snackText }}</span>
              <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>
      </v-form>
      <div v-if="isBirthDay">Aniversário aqui</div>
    </v-card>
  </div>
</template>
<script>
import { cpf } from 'cpf-cnpj-validator'; 
export default {
  props: ["idreserva", "idhospede","exibirMerge","dadosMerge"],
  name: "DadosPessoais",

  data: () => ({
    nomeselecionado:'',
    sobrenomeselecionado:'',
    telefoneselecionado:'',
    emailselecionado:'',
    nome: "",
    sobrenome: "",
    tipodocumento: "",
    numerodocumento: "",
    datanascimento: "",
    sexo: "",
    profissao: "",
    email: "",
    ano_aniversario: 0,
    mes_aniversario: 0,
    dia_aniversario: 0,
    isBirthDay: false,
    datacheckinprevisto: "",
    datacheckoutprevisto: "",
    profissoes: [],
    documentos: [],

    btn: false,
    valid: true,
    nameRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    surnameRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    numerodocumentoRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
      (v) => ((cpf.isValid(v)) && (v.length == 11) || "CPF inválido")
    ],
    datanascimentoRules: [
      (v) => !!v || "Campo Obrigatório",
      (v) => (v && v.length <= 150) || "Campo deve ser igual ou menor que 150 caracteres",
    ],
    selectsRules: [(v) => !!v || "Campo Obrigatório"],
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    select: null,

    snack: false,
    snackColor: "",
    snackText: "",

    ano_atual: 0,
    mes_atual: 0,
    dia_atual: 0,
    quantos_anos: 0,
    anosIdade: 0,
    sucesso: false,
  }),
  methods: {
    idade() {
      var d = new Date();
      this.ano_atual = d.getFullYear();
      this.mes_atual = d.getMonth() + 1;
      this.dia_atual = d.getDate();

      this.ano_aniversario = this.datanascimento.substr(0, 4);
      this.mes_aniversario = this.datanascimento.substr(5, 2);
      this.dia_aniversario = this.datanascimento.substr(9, 2);

      this.quantos_anos = this.ano_atual - this.ano_aniversario;

      if (
        this.mes_atual < this.mes_aniversario ||
        (this.mes_atual == this.mes_aniversario &&
          this.dia_atual < this.dia_aniversario)
      ) {
        this.quantos_anos--;
      }

      this.anosIdade = this.quantos_anos < 0 ? 0 : this.quantos_anos || 0;

      if (
        d.getDate(this.datanascimento) <= d.getDate(this.datacheckinprevisto) &&
        d.getDate(this.datanascimento) >= d.getDate(this.datacheckoutprevisto)
      ) {
        // this.isBirthDay = true
      }
    },
    listarDados() {
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: this.idhospede,
        })
        .then((response) => {
          this.nome = response.data[0].nome;
          this.sobrenome = response.data[0].sobrenome;
          this.tipodocumento = response.data[0].idtipodocumento
          this.numerodocumento = response.data[0].documento;
          if (response.data[0].nascimento != null) {
            let dataN = response.data[0].nascimento.substr(0, 10)
            this.datanascimento = this.moment(dataN).format("YYYY-MM-DD");
          }
          this.sexo = response.data[0].sexo;
          this.profissao = response.data[0].idprofissao;
          this.email = response.data[0].email;
          this.datacheckinprevisto = response.data[0].datacheckinprevisto;
          this.datacheckoutprevisto = response.data[0].datacheckoutprevisto;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listarDocumentos() {
      this.$http
        .post("/geo/documento/tipo/select")
        .then((response) => {
          this.documentos = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listarProfissoes() {
      this.$http
        .post("/geo/profissao/select",{hotel: localStorage.getItem("hotel")})
        .then((response) => {
          this.profissoes = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    usarDados() {
        // alert(this.nomeselecionado)
        // alert(this.sobrenomeselecionado)
        // alert(this.telefoneselecionado)
        this.$emit("atualizarDadosMerge",
            {nome:this.nomeselecionado,sobrenome:this.sobrenomeselecionado
                ,telefone:this.telefoneselecionado,email:this.emailselecionado});
        
    },
    nameToUpper() {
      this.nome = this.nome.toUpperCase();
    },
    surnameToUpper() {
      this.sobrenome = this.sobrenome.toUpperCase();
    },
    emailToLower() {
      this.email = this.email.toLowerCase();
    },
    validate() {
      this.btn = this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    
  },
  mounted() {
    this.nomeselecionado = this.dadosMerge.nomeAtual
    this.sobrenomeselecionado = this.dadosMerge.sobrenomeAtual
    this.telefoneselecionado = this.dadosMerge.telefoneAtual
    this.listarDados();
    this.listarDocumentos();
    this.listarProfissoes();
    this.validate();
  },
};
</script>
