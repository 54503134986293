<template>
  <v-dialog v-model="exibirModalQuantidadeParcelas" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width:95%;color:white;font-weight:bold;">
            Quantidade de parcelas por valor
          </span>
          <span style="width:5%">
            <v-btn id="fecharModalContrato" x-small color="red" @click="() => this.$emit('setExibirModalQuantidadeParcelas')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div style="display: flex; align-items: center;">
          <div style="margin: 0 30px 0 0">
            <v-text-field type="number" style="width: 100%;" label="Valor inicial" v-model="inicial" outlined hide-details></v-text-field>
          </div>
          <div style="margin: 0 30px 0 0">
            <v-text-field type="number" style="width: 100%;" label="Valor final" v-model="final" outlined hide-details></v-text-field>
          </div>
          <div style="margin: 0 30px 0 0">
            <v-text-field type="number" style="width: 100%;" label="Quantidade de parcelas" v-model="parcelas" outlined hide-details></v-text-field>
          </div>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" @click="adicionar">Adicionar</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="dados"
          no-data-text="Nenhum dado."
        >
        <template v-slot:[`item.acoes`]="{ item }">
              <button class="buttonEditarVisualizar" style="background: red;" @click="deletar(item)">Deletar</button>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>

 export default {
  data: () => ({
    headers: [
      { text: "Id", value: "id" },
      { text: "Valor inicial", value: "valor_inicial" },
      { text: "Valor final", value: "valor_final" },
      { text: "Quantidade de parcelas", value: "parcelas" },
      { text: "Ações", value: "acoes" },
    ],
    dados: [],
    inical: null,
    final: null,
    parcelas: null,
  }),

  props: ['exibirModalQuantidadeParcelas', 'sistema'],

  methods: {
    async deletar(item) {
      try {
        await this.$http.post('webcheckout/quantidade/parcelas/deletar', {
            id: item.id,
            sistema: this.sistema,
        })
        this.dados = await this.buscarQuantidadeParcelas();
        alert('Item deletado com sucesso!');
      } catch (error) {
        alert('Erro ao deletar item!')
      }
    },
    async buscarQuantidadeParcelas() {
      try {
        const result = await this.$http.post(`webcheckout/quantidade/parcelas/buscar/${this.sistema}`)
        return result.data;
      } catch (error) {
        console.log(error)
      }
    },
    async adicionar() {

      if (!this.inicial) return alert('É obrigatório digitar um valor inicial');

      if (!this.final) return alert('É obrigatório digitar um valor final');

      if (!this.parcelas) return alert('É obrigatório digitar a quantidade de parcelas');

      try {
        await this.$http.post('webcheckout/quantidade/parcelas/salvar', {
          inicial: this.inicial,
          final: this.final,
          parcelas: this.parcelas,
          sistema: this.sistema,
        })
        this.dados = await this.buscarQuantidadeParcelas();
        this.inical = null;
        this.final = null;
        this.parcelas = null;
      } catch (error) {
        console.log(error)
      }
    }
   },

  async mounted() {
    this.dados = await this.buscarQuantidadeParcelas();
    console.log(this.generos)
  }
 }
</script>

<style scoped>
.buttonEditarVisualizar {
  background-color: #2B81D6;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px!important;
}
</style>