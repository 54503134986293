<template>
    <v-dialog v-model="exibirModalEditarFormSanitario" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Formulario Sanitário
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarFormSanitario()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                <!-- <v-card> -->

                    <div style="margin-top:20px;font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="exibirFormSanitario" style="border-radius:5px;padding:5px;"></v-switch>
                        <label >Exibir Formulario Sanitário ( Recepção e Web Checkin ) ?</label>  
                        
                    </div>

                    <!-- <div style="display:flex;justify-content:center;padding-bottom:10px;">
                        <textarea v-model="textoEmail"  style="width:100%;min-height:200px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                        </textarea>
                    </div> -->
                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvarExibicaoTermo()" color="primary">
                            Salvar
                        </v-btn>
                    </div>
                <!-- </v-card> -->

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        textoEmail:"Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
        isImg:null,
        imgSrc:null,
        imgTipo:null, 
        exibirFormSanitario:null
    }),
    props:["exibirModalEditarFormSanitario"],
    components:{
        // ModalSolicitacoesResponder
  
    },
    methods: {
        salvarExibicaoTermo(){
            this.$http.post("configuracao/atualizarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EXIBIRFORMSANITARIO',
                "valor" : this.exibirFormSanitario
             }).then(resp =>{
                 if(resp.status == 200){
                     this.setModalEditarFormSanitario()
                 }else{
                     alert("Não foi alterar os dados de exibição do termos")
                 }
             })
             .catch(error =>{
                //  alert(error)
                 console.log(error)
             })
        },
        setModalEditarFormSanitario(){
            this.$emit('setModalEditarFormSanitario')
        },
    },
    watch:{
        exibirFormSanitario: function (val){
            this.exibirFormSanitario = val
            
        }
    },
    async mounted() {
        
        //busca o email de aniversariante configurado
        await this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EXIBIRFORMSANITARIO'
            }).then(resp =>{
                
                if(resp.status == 200){
                    this.exibirFormSanitario = resp.data[0].valor === 'true'? true: false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

</style>