<template>
  <v-container>
    <div style="display:flex;flex-direction:column;align-items:center;">
        
        <v-avatar slot="offset" class="mt2 mx-auto d-block" size="130">
            <img v-if="!imgSrc" src="" id="imagemcarregada" />
            <!-- <img v-if="imgSrc" :src="'data:image.jpeg;base64,' + imgSrc" class="imgUpload" /> -->
            <img v-if="imgSrc" :src="imgSrc" class="imgUpload"  id="imagemcarregada" />

            
        </v-avatar>
         -dsa-<img  :src="imgSrc2" class="imgUpload"  id="imagemcarregada2" />-da-
        <div>
            <input id="file-upload" type="file" style="display: none" accept="image/*" @change="onFileChange"/>
        </div>
        <div>
            <label for="file-upload">
                <div class="botaoUpload">Foto</div>
            </label>
        </div>
    </div>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <v-btn text @click="snack = false">Fechar</v-btn>
    </v-snackbar>

    <canvas width=897 height=1196 id="thecanvas"></canvas>

  </v-container>
</template>

<script>

import {serializarImagem} from '../../utils/serializar'
export default {
  data: () => ({
    isImg: null,
    imgSrc: null,
    imgSrc2: null,
    imgTipo: null,
    snack: false,
    snackColor: "",
    snackText: "",
    alturaimg:2000,
    larguraimg:2000
  }),
  methods: {

    buscarIdiomas(){
      
      this.$http.post("/geo/idiomas/suportados").then((response) => {
                this.idiomas = response.data 
            }).catch(error =>{
                console.log(error)
            });
    },

    async onFileChange(e) { 
        serializarImagem(e, 0.5).then(resp =>{
            this.imgSrc = resp.dados
        }).catch(error =>{
            console.log(error)
        })

    }
    
  },
  mounted() {
    
  },
};
</script>


<style scoped>
    .botaoUpload{
        margin-top:20px;
        background-color: #2196f3 !important;
        border-radius: 3px;
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
    }
</style>