<template>
  <v-card style="height:100%;">
    <!-- Cabeçalho -->
    <v-toolbar color="primary" dark flat>
      <!-- Título -->
      <v-toolbar-title style="width:100%;padding:10px;">
        <div style="display:flex;flex-direction:row;width:100%;flex-wrap:wrap;justify-content:space-between;">
          <div >
            <span>{{tutorial?tutorial.titulo:'(sem título)'}}</span>
            <!-- <h3>{{tutorial?tutorial.descricao:'(sem descrição)'}}</h3> -->
          </div>
          <div style="text-align:right!important;">
            <h3>{{ratingInicial>0?'Última avaliação: '+ratingInicial+' %':''}}</h3>
          </div>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Abas -->
      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="orange"></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab" style="height:90%;">
      <!-- Vídeo -->
      <v-tab-item key="Vídeo" style="height:100%;">
        <!-- <v-layout justify-center> -->
          <v-card flat style="display:flex;align-items:center;justify-content:center;width:100%;height:100%;">
            <iframe class="iframe-video" style="max-width:800px;"
              :src="tutorial?tutorial.video:'<sem link>'">
            </iframe>
          </v-card>
        <!-- </v-layout> -->
      </v-tab-item>
      <!-- Quiz -->
      <v-tab-item key="Quiz (perguntas)" style="height:100%;">
        <template v-if="concluir">
          <v-layout justify-center wrap>
            <v-card flat class="my-4">
              <template>
                <h1>{{tituloConclusao}}</h1>
                <h2>Você acertou {{rating}} % das perguntas do Quiz.</h2>
              </template>              
              <!-- <template v-if="(rating>ratingInicial)">
                <h1>Parabéns! Você superou o último aproveitamento!</h1>
                <h2>Você acertou <span style="color: green;">{{rating}}</span> % das perguntas do Quiz.</h2>
              </template>
              <template v-if="(ratingInicial>=rating)">
                <h1>Ainda não foi desta vez. Assista o vídeo e tente novamente!</h1>
                <h2>Você acertou {{rating}} % das perguntas do Quiz.</h2>
              </template> -->
              <v-layout justify-center wrap class="my-4">
                <v-btn @click="reiniciar" color="light-green" style="color:white;"
                  >Reiniciar<v-icon small>mdi-reload</v-icon>
                </v-btn>              
              </v-layout>
            </v-card>
          </v-layout>
        </template>
        <template v-else>
          <v-layout justify-center wrap>
            <v-card flat class="my-4">
              <h1>{{perguntaAtual.texto}}</h1>
              <v-radio-group v-model="respostaAtual">
                <v-radio
                  v-for="(resp,index) in respostasAceitas"
                  :key="index"
                  :label="`${resp.texto}`"
                  :value="resp.resposta?resp.resposta.index:null"
                  @change="registrarResposta(resp.item,index)"
                ></v-radio>
              </v-radio-group>          
            </v-card>
          </v-layout>
          <v-footer absolute style="margini-left: auto !important; magini-right: auto !important; width:100%;position:fixed!important">
              <v-spacer />
              <v-btn :disabled="step==1" class="mr-2" @click="voltar" color="light-green" style="color:white;"
                ><v-icon small>mdi-arrow-left</v-icon>Anterior</v-btn
              >
              {{ step + ' de ' + steps }}
              <v-btn :disabled="step==steps" class="ml-2" @click="avancar" color="light-green" style="color:white;"
                >Próxima<v-icon small>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn class="ml-2" color="warning" :disabled="(naoRespondidas>0)||(ratingInicial==100.00)"
                @click="concluirQuiz()"
                ><v-icon small>mdi-logout</v-icon>Concluir
              </v-btn>
              <v-spacer />
          </v-footer>
        </template>
      </v-tab-item>      
    </v-tabs-items>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
          <span>{{ snackText }}</span>
          <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
        </div>
    </v-snackbar>    
  </v-card>
</template>

<script>
import axios from 'axios'
import { tokenapies } from '@/constantes.js'
import {BUILD_APIES_URL} from '@/constantes.js'

export default {
    data () {
      return {
        id: (this.$route.params.id ? this.$route.params.id : 0),
        tutorial: null,
        tutorialx: {
          "id": 2,
          "sistema": "Ficha Digital",
          "video": "ap90npnxZzs",
          "titulo": "FDPREENCHER",
          "descricao": "Preenchendo os dados no Ficha Digital",
          "ativo": true,
          "perguntas": [
            {
              "id": 10,
              "texto": "Como chama o sistema de checkin digital?",
              "respostasaceitas": {
                "a": "FDGuest",
                "b": "Ficha",
                "c": "Cardapio"
              },
              "respostascorretas": {
                "b": "Ficha"
              },
              "ativo": true
            },
            {
              "id": 11,
              "texto": "Qual a empresa que produz o Ficha Digital?",
              "respostasaceitas": {
                "a": "Esmeralda",
                "b": "Economy",
                "c": "Xpto"
              },
              "respostascorretas": {
                "b": "Economy"
              },
              "ativo": true
            },
            {
              "id": 12,
              "texto": "Com quais PMS o FD se integra?",
              "respostasaceitas": {
                "a": "Totvs",
                "b": "Hits",
                "c": "Ambos"
              },
              "respostascorretas": {
                "c": "Ambos"
              },
              "ativo": true
            },
            {
              "id": 13,
              "texto": "Quem é Economy?",
              "respostasaceitas": {
                "a": "Uma rede de supermercados",
                "b": "Uma banco",
                "c": "Uma Software House"
              },
              "respostascorretas": {
                "c": "Uma Software House"
              },
              "ativo": true
            },
            {
              "id": 14,
              "texto": "Como chama o sistema de checkin digital?",
              "respostasaceitas": {
                "a": "Webcheckout",
                "b": "Webcheckin",
                "c": "Ficha Digital"
              },
              "respostascorretas": {
                "c": "Ficha Digital"
              },
              "ativo": true
            }
          ]
        },
        tab: null,
        respostaAtual: null,
        items: [
          'Vídeo', 'Quiz (perguntas)',
        ],
        step: 1,
        correta: false,
        naoRespondidas: 0,
        rating: 0,
        ratingInicial: 0,
        concluir: false,
        snack: false,
        snackColor: 'green',
        snackText: '',
        tituloConclusao: ''
      }
    },
    methods: {
      avancar() {
        this.step++
      },
      voltar() {
        this.step--
      },
      concluirQuiz(){
        this.rating = this.calculateRating();
        this.naoRespondidas = this.calculateNaoRespondidas();
        if (parseFloat(this.rating) > parseFloat(this.ratingInicial)) {
          this.tituloConclusao = 'Parabéns! Você superou o último aproveitamento!'
          this.salvarRespostas()
        } else {
          this.tituloConclusao = 'Ainda não foi desta vez. Assista o vídeo e tente novamente!'
        }
        this.concluir = true
      },
      reiniciar() {
        this.concluir = false
        this.step = 1
      },
      registrarResposta(respKey,index){
        this.perguntaAtual.resposta = {item: respKey, index: index};
      },
      calculateNaoRespondidas(){
        var notAnsweredCount = 0
        if (this.tutorial && this.tutorial.perguntas) {
          for (var i=0; i < this.tutorial.perguntas.length; i++) {
            var perg = this.tutorial.perguntas[i]
            if (!perg.resposta) {
              notAnsweredCount++;
            }
          }
        }
        return (notAnsweredCount)
      },
      calculateRating() {
        var correctCount = 0
        for (var i=0; i < this.tutorial.perguntas.length; i++) {
          var perg = this.tutorial.perguntas[i]
          if (this.correct(perg,perg.resposta)) {
            correctCount++;
          }
        }
        return ((correctCount/this.tutorial.perguntas.length)*100).toFixed(2)
      },
      correct(perg,val) {
        var result = false
        if (val) {
          var keys = Object.keys(perg.respostascorretas)
          for (var i=0; i<keys.length;i++) {
            if (keys[i] === val.item) {
              result = true
              break
            }
          }
        }
        return result
      },
      carregarTutorial(){
        const token = localStorage.getItem('token')
        if (token) {
            axios.defaults.headers.common['x-access-token'] = token
        }


        let baseURL = BUILD_APIES_URL
        axios.post(baseURL+"/tutorial/select", {idioma: 'pt-BR', id: this.id,'uid':localStorage.getItem('userid')},{headers:{'x-access-token':tokenapies}})
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length>0) {
              this.tutorial = response.data[0]
              this.correta = this.correct(this.perguntaAtual,this.perguntaAtual.resposta)
              this.rating = this.calculateRating();
              this.ratingInicial = this.rating
              this.naoRespondidas = this.calculateNaoRespondidas();
              this.respostaAtual = this.perguntaAtual.resposta?this.perguntaAtual.resposta.index:null
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      },
      salvarRespostas(){
        var respostas = []
        for (var i=0; i<this.tutorial.perguntas.length;i++) {
          var resp = this.tutorial.perguntas[i]
          var respUpdate = {
            "pergunta": resp.id,
            "resposta": resp.resposta
          }
          respostas.push(respUpdate)
          console.log(JSON.stringify(respUpdate))
        }
        this.salvarResposta(respostas)
      },
      salvarResposta(respostas){
        const token = localStorage.getItem('token')
        if (token) {
            axios.defaults.headers.common['x-access-token'] = token
        }
        
        let baseURL = BUILD_APIES_URL
        axios.post(baseURL+"/tutorial/resposta/upinsert", {respostas: respostas,'uid':localStorage.getItem('userid')},{headers:{'x-access-token':tokenapies}})
        .then((response) => {
          if (response.status == 200) {
              this.snack = true
              this.snackColor = 'green'
              this.snackText = 'Respostas armazenadas.'
              this.ratingInicial = this.rating
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    mounted() {
      // if (this.tutorial.perguntas.length>0) {
      //   this.perguntaAtual = this.tutorial.perguntas[0]
      // }
      this.carregarTutorial()
    },    
    computed: {
      perguntaAtual () {
        return this.tutorial.perguntas[this.step-1]
      },
      respostasAceitas () {
        var keys = Object.keys(this.perguntaAtual.respostasaceitas)
        var result = []
        for (var i = 0; i < keys.length; i++) {
          result.push({
            id: i,
            item: keys[i],
            texto: this.perguntaAtual.respostasaceitas[keys[i]]
          })
        }
        return result
      },
      steps () {
        return this.tutorial.perguntas.length
      },
    },    
    watch: {
      respostaAtual () {
        this.correta = this.correct(this.perguntaAtual,this.perguntaAtual.resposta)
        this.rating = this.calculateRating();
        this.naoRespondidas = this.calculateNaoRespondidas();
      },
      step () {
        this.respostaAtual = this.perguntaAtual.resposta?this.perguntaAtual.resposta.index:null
      }
    },    
  }
</script>

<style scoped>
.iframe-video {
  height: 100%;
  width: 100%;
}
</style>