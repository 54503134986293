<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
    <div style="max-width:100vw;padding-left:30px;padding-right:30px;">
   
        <v-toolbar color="blue">
            <div style="width:50%;min-height:100%;display:flex;justify-content:flex-start;align-items:center;">
                Duvidas Frequentes
            </div>
            <div style="width:50%;min-height:100%;display:flex;justify-content:flex-end;align-items:center;">
                <input type="text" v-model="textoPesquisa" style="color:#6c7175;padding-left:10px;width:70%;background-color:white;height:30px;border-radius:5px;border:1px solid #6b8aca;" />
                <!-- <v-btn @click="buscarFaqs()" style="height:30px;margin-left:10px;background-color:white;color:#1d4596;font-weight:bold;">Pesquisar</v-btn> -->
            </div>
          
        </v-toolbar>
      <div style="padding-top:20px;">
            <v-card v-for="categoria in Object.keys(menus)" :key="categoria">
                <v-toolbar color="#6187a5"> {{categoria}} </v-toolbar>
                <div style="padding:20px;" v-for="perg in menus[categoria]" :key="perg.id" class="faq">
                    <v-card style="padding:10px;">
                        <h3 style="">{{perg.pergunta}}</h3>
                        <h4 style="padding-left:30px;color:#676767;">{{perg.resposta}}</h4>
                    </v-card>
                </div>
            </v-card>
            
      </div>
  
  </div>
</template>

<script>
import axios from 'axios'

import { tokenapies } from '@/constantes.js'
import {BUILD_APIES_URL} from '@/constantes.js'

export default {
    data: () => ({
        menus:[],
        textoPesquisa:''
    }),
    components:{

    },
    methods: {
        groupByKey (list, key) {
            return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
        },
    

        // buscarFaqs(){
        //     let baseURL = BUILD_APIES_URL
            
        //     axios.post(
        //         baseURL+"/faq/pergunta/endpoint/select", 
        //         {pergunta: this.textoPesquisa},
        //         {headers:{'x-access-token':tokenapies}})
        //         .then((response) => {
        //         if (response.status == 200) {
        //             this.menus = this.groupByKey(response.data, 'nomecategoria')
        //         }
        //         })
        //         .catch((error) => {
        //         console.log(error);
        //         });
        // }
    },
    mounted() {

        if(localStorage.getItem('recarregou')){
           localStorage.removeItem('recarregou')
        }else{
            localStorage.setItem('recarregou','sim')
            location.reload()
        }

        let baseURL = BUILD_APIES_URL
            axios.post(
                baseURL+"/faq/pergunta/endpoint/select", 
                {idsistema: 1},
                {headers:{'x-access-token':tokenapies}})
                .then((response) => {
                if (response.status == 200) {
                    this.menus = this.groupByKey(response.data, 'nomecategoria')
                    console.log('this.menus', this.menus)
                }
                })
                .catch((error) => {
                console.log(error);
                });

        

        // if(document.getElementsByTagName('iframe').length > 0){
        //     document.querySelector('[title="chat widget"]').style.marginBottom = "40px";
        // }else{
        //     location.reload()
        // }
    },
    created(){
        //INCLUSAO DO CHAT
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        console.log("Tawk_LoadStart")
        console.log(Tawk_LoadStart)

        var s1=document.createElement("script")
        console.log("s1")
        console.log(s1)
        var s0=document.getElementsByTagName("script")[0];
        console.log("s0")
        console.log(s0)
        s1.async=true;
        s1.src='https://embed.tawk.to/60af9787de99a4282a19e931/1f6mufr75';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
     
        //FINAL DA INCLUSAO DO CHAT
    },
    beforeDestroy(){
        //EXCLUIR O CHAT
        var elementos = document.getElementsByTagName('iframe')
        while(elementos[0]) elementos[0].parentNode.removeChild(elementos[0])
        //FIM DO EXCLUIR CHAT
    }
};
</script>

<style>
</style>
