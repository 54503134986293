<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <!-- <div class="wout-topo">
            <div style="width:15%;">
                <button class="wout-menu-voltar-btn" @click="voltar()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="white--text text-subtitle-2 px-2" style="width:70%;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" style="color:white!important;" v-if="dados">
                    <v-row>
                        <v-col style="font-size:14px;font-weight:200;">
                             Apto: {{dados.uh}}
                        </v-col>
                        <v-col style="font-size:14px;font-weight:200;">
                            <span @click="reload()">Reserva</span>: {{dados.numeroreserva}}
                        </v-col>
                    </v-row>                    
                </div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div>
        </div> -->
        <Header :nome="dados.nome" :uh="dados.uh" :numeroreserva="dados.numeroreserva"
            @voltar="voltar()" @logout="logout()"
        ></Header>
        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
            <div class="wout-cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
                <div class="wout-row-center">
                    <div class="wout-campo-saldo">
                        <div class="wout-saldo">-R$ {{dados.saldo | formatarMoeda}}</div>
                        <div class="wout-label-saldo"><b>Saldo em aberto</b></div>
                    </div>
                </div>
            </div>
            <div class="wout-toggler" @click="exibirDetalhes=!exibirDetalhes">Extrato de Consumo 
                <v-icon>mdi-chevron-down</v-icon></div>
            <!-- Individual -->
            <div v-if="exibirDetalhes" class="wout-scrollable">
                <div class="wout-cartao-item" v-for="(consumo, index) in dados.consumos" :key="index">
                    <div class="wout-row">
                        <template v-if="hits">
                            <div class="wout-item-label" style="width:19%;padding:0px;padding-left:0.5rem;">Data:</div>
                            <div class="wout-item-label" style="width:18%;padding:0px;padding-left:0.5rem;">Cod.:</div>
                            <div class="wout-item-label" style="width:19%;padding:0px;padding-left:0.5rem;">Taxa(R$):</div>
                            <div class="wout-item-label" style="width:19%;padding:0px;padding-left:0.5rem;">Valor(R$):</div>
                            <div v-if="constestar_ativado" class="wout-item-label" style="width:25%;padding:0px;padding-left:0.5rem;"></div>
                        </template>
                        <template v-else>
                            <div class="wout-item-label" style="width:19%;padding:0px;padding-left:0.5rem;">Data:</div>
                            <div class="wout-item-label" style="width:24%;padding:0px;padding-left:0.5rem;">Cod.:</div>
                            <div class="wout-item-label" style="width:24%;padding:0px;padding-left:0.5rem;">Valor:</div>
                            <div v-if="constestar_ativado" class="wout-item-label" style="width:33%;padding:0px;padding-left:0.5rem;"></div>
                        </template>
                    </div>
                    <div class="wout-row">
                        <template v-if="hits">
                            <div class="wout-item-value" style="width:19%;">
                                <span>{{consumo.datalancamentopms  | formatarDataDDMM}}</span>
                            </div>
                            <div class="wout-item-value" style="width:18%;">
                                <span>{{consumo.codprodutopms}}</span>
                            </div>
                            <div class="wout-item-value" style="width:19%;">
                                <span> {{ consumo.taxa | formatarMoeda }}</span>
                            </div>
                            <div class="wout-item-value" style="width:19%;">
                                <span> {{ consumo.valorbruto | formatarMoeda }}</span>
                            </div>
                            <div v-if="constestar_ativado" class="item-btn" style="width:25%;">
                                <button class="contest-btn"
                                    v-on:click="contestar(consumo)"
                                >
                                    <span>{{consumo.idstatus==1?'Contestar Valor':'Processando'}}</span>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <div class="wout-item-value" style="width:19%;">
                                <span>{{consumo.datalancamentopms  | formatarDataDDMM}}</span>
                            </div>
                            <div class="wout-item-value" style="width:24%;">
                                <span>{{consumo.codprodutopms}}</span>
                            </div>
                            <div :class="consumo.descproduto === 'PAGAMENTO' ? 'wout-item-money-green' : 'wout-item-money'" style="width:24%;">
                                <span> {{ consumo.descproduto === 'PAGAMENTO' ?  `+R$ ${consumo.valortotal.split('-')[1] | formatarMoeda}` : `-R$ ${consumo.valortotal | formatarMoeda}`}}</span>
                            </div>
                            <div v-if="constestar_ativado" class="item-btn" style="width:35%;">
                                <button class="contest-btn"
                                    v-on:click="contestar(consumo)"
                                >
                                    <span>{{consumo.idstatus==1?'Contestar Valor':'Processando'}}</span>
                                </button>
                            </div>
                        </template>
                    </div>
                    <div class="wout-item-label-row">Descrição (Qtde): {{consumo.descproduto}} ({{consumo.quantidade}})</div>
                    <div class="row">
                        <div v-if="hits" class="col-6">
                            <div class="wout-item-label-row"><span>Depto.: {{consumo.departamento}}</span></div>
                        </div>
                        <div v-if="hits" class="col-6">
                            <div class="wout-item-label-row-bold">Total(R$): {{consumo.valortotal | formatarMoeda}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pagar Parcial -->
            <div style="display:flex;justify-content:center;padding-top:3vh;">
                <button class="wout-pagar-btn" :disabled="pending || dados.saldo <= 0.0 || dados.checagens > 0"
                    v-on:click="pagar()"
                    id="start_button"
                >
                    <span class="sinal-moeda">$</span>
                    Efetuar Pagamento 
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
        </div>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600" v-model="contestMessage"
        >
            <template v-slot:default="contestMessage">
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;">
                            <img src="@/assets/img/contestwout.png" 
                                width="150" height="auto"
                                alt="Contesting..." crossorigin />
                                <br>
                            <div class="wout-contest-message">Produto contestado com sucesso!
                                <br>
                                Constestação em análise...
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <!-- Voltar -->
                        <button class="wout-voltar-btn"
                            v-on:click="contestMessage.value = false"
                            id="start_button"
                        >
                            Voltar
                        </button>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <ModalConfirmacao :showModal="exibirModalTipoPagamento" :titulo="'Como deseja Pagar?'" :exibeBtnCancelar="true"
            textosim="Crédito" textonao="Débito" textoCancelar="Pix" 
                @sim="()=>setTipoPagamento(1)" @nao="()=>setTipoPagamento(2)"
                @fechar="exibirModalTipoPagamento=false" @cancelar="() =>setTipoPagamento(3)"  />

    </div>
</template>

<script>

import ModalConfirmacao from '../DashViews/Modals/ModalConfirmacao.vue'
import Header from '@/components/webcheckout/Header'
export default {
    props:["dados","hospedePrincipal"],
    data: () =>{
        return{
            exibirDetalhes: true,
            contestMessage: false,
            cobrartaxaservico:null,
            pctaxaservico:null,
            cobrartaxaturismo:null,
            pctaxaturismo:null,
            exibirModalTipoPagamento:false,
            tipoPagamento:1, // 1:Credito, 2:Debito, 3:Pix,
            hits: true,
            constestar_ativado: true
        }
    },
    components: {
		ModalConfirmacao, Header
	},
    methods: {
        carregarTipoIntegracao() {
            this.$http
            .post("configuracao/buscarParametro", {
                idhotel: this.$route.params.token,
                chave: 'TIPOINTEGRACAO',
            })
            .then((resp) => {
                if (resp.status == 200) {
                    if (resp.data&&resp.data.length>0) {
                        if (resp.data[0].valor?.trim()) {
                            this.hits = resp.data[0].valor === 'HITS';
                        }
                    }
                } else {
                    console.error("Não foi possivel buscar os dados");
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        logout(){
            this.$emit('direcionar', {to:'login', from:'individual'})
        },
        setTipoPagamento(tipo){
            this.tipoPagamento = tipo
            this.exibirModalTipoPagamento = false
            localStorage.setItem('tipoPgto',tipo)
            if(tipo != 3){
                this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            }else{
                this.$emit('direcionar', {dados: this.dados, to:'dadospix', from:'prepagamento'})
            }
        },
        contestar(consumo) {
            this.$http.post("/webcheckout/solicitarEstornoConsumo",{
                "numeroreserva": consumo.numeroreserva,
                "idhotel": consumo.hotel,
                "idcabecalho": consumo.idcabecalho,
                "iddetalhe": consumo.id
            } , { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
                    if (result.status == 200) {
                        consumo.idstatus = 2
                        this.contestMessage = true
                        console.log('item estornado')
                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                console.error(e)
            })            
        },
        pagar() {
            if(this.cobrartaxaservico || this.cobrartaxaturismo){
                this.$emit('direcionar', {dados: this.dados, to: 'prepagamento', from: 'individual'})
            }else{
                this.exibirModalTipoPagamento = true
                // this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            }
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio', from:'individual'})
        },
    },
    computed: {
        pending() {
            for (var i = 0; i < this.dados.consumos.length; i++) {
                if (this.dados.consumos[i]['idstatus'] == 2) {
                    return true
                }
            }
            return false
        }
    },
    created() {
        this.$http.post("webcheckout/buscarParametro", {  
                "idhotel" : this.$route.params.token,
                "chave" : 'WEBCKOUT_ATIVAR_CONTESTACAO'
            }).then(resp =>{
                if(resp.status == 200){
                    this.constestar_ativado  = resp.data[0].valor === 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            }) 
    },
    async mounted(){

       let idhotel = await this.$route.params.token    

       this.carregarTipoIntegracao();

       this.$http.post("webcheckout/buscarParametro", {  
                "idhotel" : idhotel,
                "chave" : 'TAXASCHECKOUT'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cobrartaxaservico  = resp.data[0].valor === 'true' ? true : false
                    this.pctaxaservico      = resp.data[0].valor2
                    this.cobrartaxaturismo  = resp.data[0].valor3 === 'true' ? true : false
                    this.pctaxaturismo      = resp.data[0].valor4
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            }) 
    }
}
</script>
