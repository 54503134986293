<template>
    <div class="containerPrincipal" v-if="recarregado" id="containerPrincipal">
        
          <div class="topo" id="topo-master">
                <div class="topoCampo" id="topoCampoweb" style="min-width:50%!important;">
                    <!-- <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin /> -->
                    <img v-if="imgSrc" :src="'data:' + imgTipo + ';base64,' + imgSrc" class="topoImglogo" alt="WEB CHECK-IN" crossorigin />
                </div>
                
                <div class="topoCampo" id="topoCampo" style="min-width:50%!important;">
                    <img src="@/assets/img/logowc.png" class="topoImglogo" alt="WEB CHECK-IN" crossorigin style="margin-right:10px;" />
                    <!-- <img v-if="this.$store.state.img_hotel" :src="this.$store.state.img_hotel" class="topoImglogo" alt="HOTEL LOGO" crossorigin /> -->

                    <div @click="exibirbandeira=true" style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                        <flag :iso="bandeira"  style="border-radius:100px;width:27px;height:27px;" />
                    </div>
                    
                </div>
          </div>
          <div class="conteudo" id="conteudo-master">
            <!-- {{componenteatual}} -->
            <!-- <keep-alive> -->
            <!-- <Inicio  @direcionar="(valor) => direcionar(valor)"  v-bind:tokenAuthWebCheckin="tokenAuthWebCheckin"    v-if="componenteatual == 'inicio'" /> -->
            <Inicio             v-if="componenteatual == 'inicio'"          v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <DocTipo            v-if="componenteatual == 'doctipo'"     v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <DocFrente          v-if="componenteatual == 'docfrente'"  :tipoDocSelecionado="tipodoc" :idHospede="idhospede" :idReserva="idreserva" :idReservaHospede="idreservahospede" :docfrente="docfrente" :imgSrc="imgDocFrenteSrc" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <DocVerso           v-if="componenteatual == 'docverso'"   :tipoDocSelecionado="tipodoc" :idHospede="idhospede" :idReserva="idreserva" :idReservaHospede="idreservahospede" :docverso="docverso" :imgSrc="imgDocVersoSrc" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Nacionalidade      v-if="componenteatual == 'nacionalidade'"   v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <CopiarDados        v-if="componenteatual == 'copiardados'"     v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Motivo             v-if="componenteatual == 'motivo'"          v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Endereco           v-if="componenteatual == 'endereco'"        v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Contato            v-if="componenteatual == 'contato'"         v-bind:dados="dados"   :selfie="parametroSelfie" v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <FotoSelfie         v-if="componenteatual == 'fotoselfie'"      v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <!-- <FotoFrente         v-if="componenteatual == 'fotofrente'"      v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" /> -->
            <!-- <FotoVerso          v-if="componenteatual == 'fotoverso'"       v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" /> -->
            <DadoPessoal        v-if="componenteatual == 'dadopessoal'"     v-bind:dados="dados"  :selfie="parametroSelfie"  v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Assinatura         v-if="componenteatual == 'assinatura'"      v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <FormularioCovid    v-if="componenteatual == 'formulariocovid'" v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Observacoes        v-if="componenteatual == 'observacoes'"     v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Horario            v-if="componenteatual == 'horario'"         v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:hospedePrincipalInicial="hospedePrincipalInicial"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <PerguntaExtra      v-if="componenteatual == 'perguntaextra'"   v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:hospedePrincipalInicial="hospedePrincipalInicial"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)" />
            <Hospedes           v-if="componenteatual == 'hospedes'"        v-bind:dados="dados"    v-bind:hospedePrincipal="hospedePrincipal"    v-bind:dadosAcompanhantes="dadosAcompanhantes" v-bind:componenteatual="componenteatual" @direcionar="(valor) => direcionar(valor)"  @emitirAlert="(valor) => emitirAlert(valor)"  @salvarDados="(valor,novapagina) => salvarDados(valor,novapagina)"  @recarregarDadosInicial="(idreservahospede) => recarregarDadosInicial(idreservahospede)"/>
            <Fim                v-if="componenteatual == 'fim'"/>
          <!-- </keep-alive> -->
          </div>
          

        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>


          <v-dialog v-model="loading" width="70%"  style="min-height:90vh;">
              <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                  
                  <h1>{{ $t('webcheckin.padrao.aguarde') }}</h1>
                  <div>{{ $t('webcheckin.padrao.salvandoseusdados') }}</div>
                  
              </v-card>
          </v-dialog>


          <v-dialog v-model="exibirbandeira" width="70%"  style="min-height:90vh;">
              <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;padding-left:10%;padding-right:10%;">
                  
                  
                    <div @click="setarIdioma('pt-BR')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                        <div style="width:30%;">
                            <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                                <flag iso="br" style="border-radius:100px;width:27px;height:27px;" />
                            </div>
                        </div>
                        <div style="width:70%;display:flex;align-items:center;">
                            Português<v-icon  v-if="bandeira == 'br'">check</v-icon>
                        </div>
                    </div>

                    <div @click="setarIdioma('en')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                        <div style="width:30%;">
                            <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                                <flag iso="us" style="border-radius:100px;width:27px;height:27px;" />
                            </div>
                        </div>
                        <div style="width:70%;display:flex;align-items:center;">
                            English<v-icon  v-if="bandeira == 'us'">check</v-icon>
                        </div>
                    </div>

                    <div @click="setarIdioma('es')" style="display:flex;width:100%;margin-bottom:20px;border:1px solid #878787;border-radius:20px;">
                        <div style="width:30%;">
                            <div style="margin-right:10px;border-radius:100px;padding:1px;background-color:white;width:35px;height:35px;display:flex;align-items:center;justify-content:center;">
                                <flag iso="es" style="border-radius:100px;width:27px;height:27px;" />
                            </div>
                        </div>
                        <div style="width:70%;display:flex;align-items:center;">
                            Spanish<v-icon  v-if="bandeira == 'es'">check</v-icon>
                        </div>
                    </div>


                    
                        <v-btn class="botaoVoltar" color="error" @click="exibirbandeira = false">FECHAR</v-btn>

                  
              </v-card>
          </v-dialog>

          <div v-if="modalLandScape" style="z-index: 5;position: fixed; left:0; top:0;bottom:0;right:0;display: grid;place-content: center; background: #1560e5">
            <v-icon color="#f9f9f2" size="82">mdi-phone-rotate-portrait</v-icon>
            <p style="color: #f9f9f2; margin-top: 1em; text-transform: uppercase; text-align: center">gire seu telefone para posição paisagem</p>
            
            <div style="display:flex;justify-content:center">
                <div style="height:100px;display:flex;justify-content:center;align-items:center;">
                    <v-btn color="white" v-on:click="modalLandScape = false" style="color:#1560e5;font-weight:bold;width:200px;font-size:14px!important;">Clique para Continuar</v-btn>
                </div>
            </div>

            <button type="button" style="border:none; 
            width: 2em; height: 2em; border-radius: 100%; display: grid; 
            place-content: center; background-color: #f9f9f2;
            position: absolute; top: 5%; right: 5%;">
              <v-icon color="#1560e5" @click="modalLandScape = false">mdi-close</v-icon>
            </button>
          </div>

    
    </div>
    <div v-else style="width:100%;height:100%;display:flex;align-items:center;justify-content:center;">AGUARDE</div>

</template>

<script>
  import Inicio from './webcheckin/Inicio'
  import DocTipo from './webcheckin/DocTipo'
  import DocFrente from './webcheckin/DocFrente'
  import DocVerso from './webcheckin/DocVerso'
  import Nacionalidade from './webcheckin/Nacionalidade'
  import CopiarDados from './webcheckin/CopiarDados'
  import Motivo from './webcheckin/Motivo'
  import Endereco from './webcheckin/Endereco'
  import Contato from './webcheckin/Contato'
  import FotoSelfie from './webcheckin/FotoSelfie'
  //import FotoFrente from './webcheckin/FotoFrente'
  //import FotoVerso from './webcheckin/FotoVerso'
  import DadoPessoal from './webcheckin/DadoPessoal'
  import Assinatura from './webcheckin/Assinatura'
  import FormularioCovid from './webcheckin/FormularioCovid'
  import Observacoes from './webcheckin/Observacoes'
  import PerguntaExtra from './webcheckin/PerguntaExtra'
  import Horario from './webcheckin/Horario'
  import Hospedes from './webcheckin/Hospedes'
  import Fim from './webcheckin/Fim'
  import Vue from 'vue';
  import dayjs from 'dayjs'

  import FlagIcon from 'vue-flag-icon'
   Vue.use(FlagIcon);

import {BUILD_TIPO_INTEGRACAO} from '@/constantes.js'

  export default {
    name: 'ViewWebCheckin',

    components: {
        Inicio,
        DocTipo,
        DocFrente,
        DocVerso,
        Nacionalidade,
        CopiarDados,
        Motivo,
        Endereco,
        Contato,
        FotoSelfie,
        //FotoFrente,
        //FotoVerso,
        DadoPessoal,
        Assinatura,
        FormularioCovid,
        Observacoes,
        PerguntaExtra,
        Horario,
        Hospedes,
        Fim
    },
    data: () =>{
        return{
            recarregado:false,
            bandeira:'br',
            exibirbandeira:false,
            imgSrc:null,
            imgTipo:null,
            imgDocFrenteSrc:null,
            imgDocVersoSrc:null,
            usuariologado:false,
            componenteatual:"inicio",
            dados:{},
            dadosNovoCadastro:{},
            hospedePrincipal:false,
            hospedePrincipalInicial:false,
            dadosAcompanhantes:{},
            snack: false,
            snackColor: '',
            snackText: '',
            idreservahospede:null,
            idhospede:null,
            idreserva:null,
            tipodoc:null,
            docfrente:null,
            docverso:null,
            loading:false,
            modalLandScape: false,
            parametroSelfie: false
        }
    },
    async created(){
        
        

        this.idreservahospede = await this.$route.params.token
        //console.error(`created() ===>>> this.idreservahospede: ${this.idreservahospede}`)
        await this.carregarDados(this.idreservahospede)
        if(this.$route.params.comp){
            this.componenteatual = this.$route.params.comp
        }
    },
    async mounted(){
        //await this.carregarDados(this.idreservahospede)
        //buscar necessidade de usurario fazer upload em selfie
        this.requisitarParametroSelfie()

        if(localStorage.getItem('recarregou')){
           this.recarregado = true
           localStorage.removeItem('recarregou')
        }else{
            
            setTimeout(() => {
                localStorage.setItem('recarregou','sim')
                location.reload()    
            }, 2000);
            
        }
    },
    async beforeMount(){
      //  import('./webcheckin/folhadeestilos/azul.css')
       await this.$http.post("configuracao/buscarParametro", {  "idhotel" : this.dados.hotel,"chave" : 'WCHECKINESTILO'})
                  .then(async () =>{
                      await import('./webcheckin/folhadeestilos/'+'azul'+'.css')
                        this.$forceUpdate();
                  }).catch(error =>{ console.log(error) })
    },
    methods: {
        requisitarParametroSelfie(){
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'NECESSIDADESELFIE'
            }).then(resp =>{
                if(resp.status == 200){
                    this.parametroSelfie = resp.data[0].valor == 'true' ? true : false
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        },
        setarIdioma(idioma){

            if(idioma == 'pt-BR'){
                this.$i18n.locale = 'pt-BR'
                this.bandeira = 'br'
            }

            if(idioma == 'en'){
                this.$i18n.locale = 'en'
                this.bandeira = 'us'
            }

            if(idioma == 'es'){
                this.$i18n.locale = 'es'
                this.bandeira = 'es'
            }

        },
        carregarDadosHotel() {
            this.$http.post("/perfil/hotel/select",{ id:this.dados.hotel }).then((response) => {

                if(response.data[0] && response.data[0].logo){
                    this.$http.post("/imagem/select", {id: response.data[0].logo}).then((response) => {
                        if (response.status == 200) {
                            var imgObj = response.data[0]
                            if (imgObj) {
                                this.imgSrc  =  Buffer.from(imgObj.dados).toString()
                                this.imgTipo =  imgObj.tipo;
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }); 
                }
            });
        },
        emitirAlert(dados){
            this.snack = dados.snack //true
            this.snackColor = dados.snackColor //'success'
            this.snackText = dados.snackText //'Data saved'
        },
        carregarImagem(imgId,lado){
            this.$http.post("/imagem/select", {id:imgId}, { timeout: 60000 })
            .then((response) => {
                if (response.status == 200) {
                    if(response.data.length > 0){
                        if (lado == 'frente') {
                            this.imgDocFrenteSrc = Buffer.from(response.data[0].dados).toString()
                        } else {
                            this.imgDocVersoSrc =  Buffer.from(response.data[0].dados).toString()
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(JSON.stringify(error));
            });
        },        
        direcionar(valor){
            this.componenteatual = valor
            if (valor == 'docfrente' && this.docfrente) {
                this.carregarImagem(this.docfrente,'frente')
            }
            if (valor == 'docverso' && this.docverso) {
                this.carregarImagem(this.docverso,'verso')
            }            
        },
        async realizarLogin(){
          let username = "webcheckin";
          let password = "FSD6&*)))4%8gf54dfh8sdfsdfs342854%$&99##3";
          await this.$store.dispatch("login", { username, password })
              .then(() => { 
                  this.usuariologado = true 
                  this.$store.dispatch("set_tipoacesso", 'webcheckin')
              })
              .catch((err) => {
                 console.error(JSON.stringify(err))
              });
        },
        async recarregarDadosInicial(idreservahospede){

            this.idreservahospede = idreservahospede
            this.$http.post("/reserva/select", {id:idreservahospede})
            .then(async resp =>{
                console.log('reserva teste', resp.data)
                  this.dados =  null
                  this.dados = await resp.data[0]

                    await this.carregarImagem(this.dados.docfrente,'frente')
                    await this.carregarImagem(this.dados.docverso,'verso')  
                    this.componenteatual = "inicio"

            })
            .catch(error =>{ 
                console.error(JSON.stringify(error)) 
            })
        },
        async carregarDados(idreservahospede){
            await this.realizarLogin();
            // this.$http.post("/reserva/select", {token:this.$route.params.token})
            //console.error(`carregarDados(${idreservahospede})`)
            this.$http.post("/reserva/select", {id:idreservahospede})
            .then(resp =>{
                console.log('reserva teste 2', resp.data)
                  this.dados = null
                  this.dados = resp.data[0]  
                  this.hospedePrincipal = resp.data[0].principal
                  this.idhospede = this.dados.idhospede
                  this.idreserva = this.dados.idreserva
                  this.tipodoc = this.dados.tipodoc
                  this.docfrente = this.dados.docfrente
                  this.docverso = this.dados.docverso

                  if(this.hospedePrincipal){
                      this.hospedePrincipalInicial = this.hospedePrincipal
                  }
                   

                  //se for hospede principal carrego os demais hospedes para seleção no final

                      this.$http.post("/reserva/select", { idreserva:this.dados.idreserva}).then(acompanhantes =>{
                            this.dadosAcompanhantes = acompanhantes.data
                            console.log(acompanhantes.data)
                      })

                //   if(this.hospedePrincipal){
                //       this.$http.post("/reserva/select", { idreserva:this.dados.idreserva,principal:false }).then(acompanhantes =>{
                //             this.dadosAcompanhantes = acompanhantes.data
                //       })
                //   }


                  //verifico parametrização de alguns passos
                  this.$http.post("configuracao/buscarParametro", {  "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIRCOVID'})
                  .then(async resp =>{
                      this.dados.exibirPassosCovid = await resp.data[0].valor == 'true' ? true : false
                  }).catch(error =>{ console.log(error) })

                  this.$http.post("configuracao/buscarParametro", {  "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIROBS'})
                  .then(async resp =>{
                      this.dados.exibirPassosObservacao = await resp.data[0].valor == 'true' ? true : false
                  }).catch(error =>{ console.log(error) })

                  this.$http.post("configuracao/buscarParametro", { "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIRHORARIO'})
                  .then(async resp =>{
                      this.dados.exibirPassosHorario = await resp.data[0].valor === 'true' ? true : false
                  }).catch(error =>{ console.log(error) })

                  this.$http.post("configuracao/buscarParametro", { "idhotel" : this.dados.hotel,"chave" : 'IDADECRIANCAWEBCHECKIN'})
                  .then(async resp =>{
                      this.dados.idadelimitecrianca = await resp.data[0].valor
                  }).catch(error =>{ console.log(error) })

                  this.$http.post("configuracao/buscarParametro", { "idhotel" : this.dados.hotel,"chave" : 'WCHECKINEXIBIRPERGUNTAEXTRA'})
                  .then(async resp =>{
                      if(resp.data.length > 0){
                        this.dados.exibirPassosPerguntaExtra = await resp.data[0].valor === 'true' ? true : false
                      }
                  }).catch(error =>{ console.error(JSON.stringify(error)) })



            })
            .catch(error =>{ console.error(JSON.stringify(error)) })

            this.carregarDadosHotel()
        },
        async carregarDadosAux(idreservahospede){

            await this.realizarLogin();
            await this.$http.post("/reserva/select", {id:idreservahospede})
            .then(resp =>{
                  this.dadosNovoCadastro = resp.data[0]  
                  console.log('this.dadosNovoCadastro >> ',JSON.stringify(this.dadosNovoCadastro,null,2))
            })
            .catch(error =>{ console.error(JSON.stringify(error)) })

        },
        finalizarAux(dados){
    
            if(dados.statusCode == 200){
                console.log("FINAL AUX 2")

                let body = {
                    reserva: {
                        id: this.dados.idreserva,
                        hotel: localStorage.getItem("hotel"),
                        fimfd:true
                    },
                    hospede: {
                        id: this.dados.idhospede,
                        idhospedepms: this.dados.idhospedepms,
                        enviadopmstimestamp: dayjs().format()
                    },
                };

                this.$http.post("/reserva/upinsert", body).then((response) => {
                    console.log(response)

                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(JSON.stringify(body));
                    });

            }else{
                
                alert(`Robô não respondeu de Forma Satisfatoria: \n - ${dados.resposta} `)
            }

            this.textobtnFinalizar = "Finalizar Ficha"

        },
        async finalizarFicha(){

            await this.carregarDadosAux(this.idreservahospede)
            this.$socket.disconnect();
            this.$socket.io.opts.query.usuario = this.dados.email
            this.$socket.io.opts.query.tipo = 'DASHBOARD'
            this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD_'+this.dados.email


            this.$socket.connect();


  
            let dadosreserva = this.dados
            dadosreserva.usuarioemail = this.dados.email
            dadosreserva.nacionalidade = dadosreserva.pais
            // dadosreserva.profissao = dadosreserva.profissao.descricao
            dadosreserva.profissao = this.dadosNovoCadastro.profissao
            dadosreserva.transportetotvs = this.dadosNovoCadastro.transportetotvs
            dadosreserva.transporte = this.dadosNovoCadastro.transporte
            dadosreserva.motivo = this.dadosNovoCadastro.motivo
            dadosreserva.motivototvs = this.dadosNovoCadastro.motivototvs
            dadosreserva.idmotivo = this.dadosNovoCadastro.idmotivo

            dadosreserva.paisorigem =  this.dadosNovoCadastro.paisorigem
            dadosreserva.idpaisorigem =  this.dadosNovoCadastro.idpaisorigem
            dadosreserva.uforigem =  this.dadosNovoCadastro.uforigem
            dadosreserva.iduforigem =  this.dadosNovoCadastro.iduforigem
            dadosreserva.cidadeorigem =  this.dadosNovoCadastro.cidadeorigem
            dadosreserva.idcidadeorigem =  this.dadosNovoCadastro.idcidadeorigem
            dadosreserva.paisdestino =  this.dadosNovoCadastro.paisdestino
            dadosreserva.idpaisdestino =  this.dadosNovoCadastro.idpaisdestino
            dadosreserva.ufdestino =  this.dadosNovoCadastro.ufdestino
            dadosreserva.idufdestino =  this.dadosNovoCadastro.idufdestino
            dadosreserva.cidadedestino =  this.dadosNovoCadastro.cidadedestino
            dadosreserva.idcidadedestino =  this.dadosNovoCadastro.idcidadedestino
            dadosreserva.horariochegada =  this.dadosNovoCadastro.horariochegada
            dadosreserva.horariopartida =  this.dadosNovoCadastro.horariopartida
            dadosreserva.placa = this.dadosNovoCadastro.placa
            dadosreserva.modelo = this.dadosNovoCadastro.modelo
            dadosreserva.nascimento = this.dadosNovoCadastro.nascimento
            dadosreserva.celularddi = this.dadosNovoCadastro.celularddi
            dadosreserva.celularddd = this.dadosNovoCadastro.celularddd
            dadosreserva.celular = this.dadosNovoCadastro.celular

         

            dadosreserva.nacionalidade = dadosreserva.pais
            // dadosreserva.profissao = dadosreserva.profissao.descricao
         


            if(BUILD_TIPO_INTEGRACAO == "HITS" || BUILD_TIPO_INTEGRACAO == "SILBECK"){
                
                dadosreserva.ufnome = dadosreserva.ufsigla
            }

            if(BUILD_TIPO_INTEGRACAO == "HITS" || BUILD_TIPO_INTEGRACAO == "ERBON"){
               
               if(!this.dadosNovoCadastro.enviadopms){

                    this.$http.post(`/integracao/finalizarFicha/${BUILD_TIPO_INTEGRACAO}/`+localStorage.getItem("hotel"), 
                        {reserva: dadosreserva} ).then((resp) => {
                            this.finalizarAux(resp.data)
                    }).catch((e)=>{
                        console.error(e);
                    })
                    
               }

            }else{
              
                this.$socket.emit('dash_to_backend_finalizarficha', {reserva:dadosreserva})  
                this.sockets.subscribe("backend_to_dash_finalizarficharetorno", (dados) => {
                    this.sockets.unsubscribe("backend_to_dash_finalizarficharetorno")
                    this.finalizarAux(dados)
                });

            }

                
        },
        async salvarDados(dadosnovos, novapagina){
            // console.error(dadosnovos)
            this.loading = await true
            
            this.dados = dadosnovos



            let body = {
                reserva: {
                    id: this.dados.idreserva,
                    numeroreserva: this.dados.numeroreserva,
                    hotel: this.dados.hotel,
                    idreservapms:this.dados.idreservapms,
                    endereco_compartilhado: this.dados.endereco_compartilhado
                },
                hospede: {
                    id: this.dados.idhospede,
                    nome: this.dados.nome,
                    sobrenome: this.dados.sobrenome,
                    
                    nascimento: this.dados.datanascimento,
                    nacionalidade : this.dados.idnacionalidade,
                    sexo: this.dados.sexo,
                    
                    // profissao: this.dados.profissao,
                    // cpf: this.dados.numerodocumento,
                    email : this.dados.email,
                    principal:this.dados.principal,
                    idhospedepms:this.dados.idhospedepms
                },
            };

            if (this.dados.tipodoc) {
                body.hospede.tipodoc = this.dados.tipodoc
                this.tipodoc = this.dados.tipodoc
            }

            if(this.dados.termolgpd){
                body.hospede.termolgpd = true
            }else{
                body.hospede.termolgpd = false
            }

            if(this.dados.motivo){
                body.hospede.motivo = this.dados.idmotivo
            }
            if(this.dados.transporte){
                body.hospede.transporte = this.dados.idtransporte
            }
            if(this.dados.placa){
                body.hospede.placa = this.dados.placa
            }
            if(this.dados.modelo){
                body.hospede.modelo = this.dados.modelo
            }
 
            if(this.dados.cep && this.dados.endereco && this.dados.cidade && this.dados.bairro && this.dados.pais
               && this.dados.iduf && this.dados.numero
              ){

                body.hospede.enderecos = [
                  {
                    tipo: 1,
                    cep: this.dados.cep,
                    endereco: this.dados.endereco,
                    cidade: this.dados.idcidade,
                    idcidade: this.dados.idcidade,
                    bairro: this.dados.bairro,
                    pais:this.dados.idpais,
                    idpais:this.dados.idpais,
                    iduf:this.dados.iduf,
                    uf:this.dados.iduf,
                    numero: this.dados.numero,
                    complemento: this.dados.complemento
                  }]

                body.hospede.paisorigem     = this.dados.idpais
                body.hospede.uforigem       = this.dados.iduf
                body.hospede.cidadeorigem   = this.dados.idcidade
                body.hospede.paisdestino    = this.dados.idpais
                body.hospede.ufdestino      = this.dados.iduf
                body.hospede.cidadedestino  = this.dados.idcidade

            }


            if(this.dados.celularddi){ body.hospede.celularddi    = this.dados.celularddi}
            if(this.dados.celularddd){ body.hospede.celularddd    = this.dados.celularddd}
            if(this.dados.celular){ body.hospede.celular  = this.dados.celular }
            if(this.dados.email){ body.hospede.email  = this.dados.email }

 
            if( this.dados.idtipodocumento && this.dados.tipodocumento && this.dados.documento && this.dados.nome
                 && this.dados.sobrenome && this.dados.idprofissao){

                  
                  body.hospede.nome = this.dados.nome
                  body.hospede.sobrenome = this.dados.sobrenome
                  body.hospede.profissao = this.dados.idprofissao                  

                  body.hospede.documentos = [
                    {
                      tipo:this.dados.idtipodocumento,
                      documento:this.dados.idtipodocumento==1?
                        this.dados.documento.replace(/[^0-9]/gi,'') :
                        this.dados.documento
                    }
                  ]
                 
            }

            if(this.dados.aceitetermo){ 
                body.hospede.aceitetermo    = this.dados.aceitetermo
            }

            if(this.dados.obs){ 
                body.hospede.obs = this.dados.obs
            }
 
            if(this.dados.horariochegada && this.dados.horariopartida){
                body.hospede.horariochegada = this.dados.horariochegada
                body.hospede.horariopartida = this.dados.horariopartida
            }
            else{
                if(body.hospede){
                    let horachegada = dayjs(body.hospede.datacheckinprevisto).format('YYYY-MM-DD')
                    let horasaida   = dayjs(body.hospede.datacheckinprevisto).format('YYYY-MM-DD')

                    body.hospede.horariochegada = horachegada+'T14:00:00'
                    body.hospede.horariopartida = horasaida+'T12:00:00'
                }
            }

            if(this.dados.datainiciowc){
                body.hospede.datainiciowc = this.dados.datainiciowc
            }

            if(this.dados.datafimwc){
                body.hospede.datafimwc = this.dados.datafimwc
            }

            if(this.dados.webcheckinfinalizado){
                body.hospede.webcheckinfinalizado = this.dados.webcheckinfinalizado
            }

            body.hospede.webcheckineditado = true

            if(this.dados.principal){
                body.hospede.hospedeprincipal = true
            }

            //configuro o hospede como sendo criança
            if(this.dados.crianca){
                body.hospede.crianca = true
            }
           
            this.$http.post("/reserva/upinsert", body)
              .then(async () => {

                    if((this.dados.assinado || body.hospede.crianca) && this.dados.webcheckinfinalizado){
                        try {
                            this.$http.post("configuracao/buscarParametro", {  
                                "idhotel" : localStorage.getItem('hotel'),
                                "chave" : 'WCHECKINFINALIZARFICHA'
                            }).then(async resp =>{
                                if(resp.status == 200){
                                    if(resp.data[0].valor === 'true'){
                                        await this.finalizarFicha()
                                    }
                                }else{
                                    alert("Não foi possivel buscar os dados")
                                }
                            })
                            .catch(error =>{
                                console.log(error)
                            })


                        } catch (error) {
                                console.log("Ficha não Finalizada")
                        }
                    }

                  if( this.dados.atualizartela){
                    
                      await this.carregarDados(this.idreservahospede)
                  }
                  //this.componenteatual = novapagina
                  this.direcionar(novapagina)
                  this.loading = false
              }) 
              .catch(() => {
                  this.loading = false
                  this.emitirAlert({ snack:true,snackColor:'error',snackText:'Não foi possível salvar os dados. Verifique!'})
              });
        }

    },
    watch: {
      componenteatual: function(){
        if(this.componenteatual == 'assinatura'){
          if(window.innerWidth < 450){
            this.modalLandScape = true
            document.querySelector('#topo-master').classList.remove('topo')
            document.querySelector('#topo-master').classList.add('topo-assinatura')

            document.querySelector('#topoCampoweb').classList.remove('topoCampo')
            document.querySelector('#topoCampoweb').classList.add('topoCampo-assinatura')

            document.querySelector('#topoCampo').classList.remove('topoCampo')
            document.querySelector('#topoCampo').classList.add('topoCampo-assinatura-column')

            document.querySelector('#containerPrincipal').classList.remove('containerPrincipal')
            document.querySelector('#containerPrincipal').classList.add('containerPrincipal-assinatura')

            document.querySelector('#conteudo-master').classList.remove('conteudo')
            document.querySelector('#conteudo-master').classList.add('conteudo-assinatura')
          }
        }else{
            document.querySelector('#topo-master').classList.add('topo')
            document.querySelector('#topo-master').classList.remove('topo-assinatura')

            document.querySelector('#topoCampoweb').classList.add('topoCampo')
            document.querySelector('#topoCampoweb').classList.remove('topoCampo-assinatura')

            document.querySelector('#topoCampo').classList.add('topoCampo')
            document.querySelector('#topoCampo').classList.remove('topoCampo-assinatura-column')

            document.querySelector('#containerPrincipal').classList.add('containerPrincipal')
            document.querySelector('#containerPrincipal').classList.remove('containerPrincipal-assinatura')

            document.querySelector('#conteudo-master').classList.add('conteudo')
            document.querySelector('#conteudo-master').classList.remove('conteudo-assinatura')
        }
      }
    }

  }
</script>

<style>

</style>
