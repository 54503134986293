<template>
    <v-dialog v-model="exibirModalFaqPergunta" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        PERGUNTA E RESPOSTA
                    </span>
                    <span style="width:5%;"> 
                        <v-btn x-small color="red" @click="setModalFaqPergunta()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-card style="padding:10px;margin-bottom:10px;">
                    <div style="width:100%;">
                        <div style="padding-bottom:10px;">
                            <h3>Pergunta</h3>
                            <textarea v-model="pergunta" placeholder="Digite a Pergunta" style="width:100%;height:150px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                            </textarea>
                        </div>
                        <div style="padding-left:0px;">
                            <h3>Resposta</h3>
                            <textarea v-model="resposta" placeholder="Digite a Resposta" style="width:100%;height:150px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                            </textarea>
                        </div>
                    </div>
                    <div style="margin-top:20px;width:100%;min-height:100%;display:flex!important;justify-content:center;align-items:center;">
                         <v-btn color="primary" @click="salvarFaq()">
                            SALVAR
                        </v-btn>
                    </div>
                </v-card>

                

            </v-card-text>
            
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        pergunta:this.faq.pergunta,
        resposta:this.faq.resposta,
        faqLocal:this.faq
    }),
    props:["exibirModalFaqPergunta","faq"],
    components:{
        // ModalSolicitacoesResponder
  
    },
    watch:{
        faq: function (faq){
            this.pergunta = faq.pergunta
            this.resposta = faq.resposta
            this.faqLocal = faq
        }
    },
    methods: {
        
        salvarFaq(){
            this.faqLocal.pergunta = this.pergunta
            this.faqLocal.resposta = this.resposta
            this.$http.post("faq/inserirAtualizarFaq", this.faqLocal ).then(resp =>{
                 if(resp.status == 200){
                     this.$emit('listarFaqs')
                     this.setModalFaqPergunta()
                 }else{
                     alert("Não foi possivel alterar o FAQ")
                 }
             })
             .catch(error =>{
                 console.log(error)
             })
        },
        setModalFaqPergunta(){
            this.$emit('setModalFaqPergunta')
            
        },
    },
    mounted() {
        
        //busca o email de aniversariante configurado
        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'EMAILANIVERSARIANTE'
            }).then(resp =>{
                if(resp.status == 200){
                    this.textoEmail = resp.data[0].valor
                }else{
                    alert("Não foi possivel alterar o texto do Email")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>