<template>
    <div>
        <v-card>
              <v-card-text>
                                    <v-row>
                      <v-flex xs12 sm6 md10>
                        <span>
                          Res Nº {{ editedItem.numreserva }} - {{ editedItem.nome }} {{ editedItem.sobrenome }}
                        </span>
                      </v-flex>
                    </v-row>
                <v-container grid-list-md>
                  <v-layout>
                    <v-row>
                      <v-flex xs12 sm6 md10>
                        <v-text-field
                          append-icon="mdi-email"
                          type="email"
                          label="Email"
                          style="width: 300px"
                        />
                      </v-flex>

                      <v-flex xs12 sm6 md10>
                        <v-text-field
                          append-icon="mdi-whatsapp"
                          label="WhatsApp"
                          style="width: 300px"
                        />
                      </v-flex>
                    </v-row>
                    <v-row>
                      <v-flex>
                        <v-btn color="green darken-1" small @click="this.close"
                          >OK</v-btn
                        >
                      </v-flex>
                      <v-flex>
                        <v-btn color="orange darken-1" small @click="this.close"
                          >Cancelar</v-btn
                        >
                      </v-flex>
                    </v-row>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
    </div>
</template>
<script>
export default {
  name: "NotificacaoFd",
  data: () => ({

  }),
};
</script>