<template>
 
    <div class="py-4" style="flex-wrap:wrap;width:100%;">
            <h1 style="text-align:center;padding-top:2vh;margin-bottom:20px;" >{{ $t('webcheckin.contato.comopodemosfalar') }}</h1>

            
            <div class="cardInputs" style="">
                <label class="labels">{{ $t('webcheckin.contato.celular') }}</label>
                <div style="display:flex;">
                    <!-- <select v-model="pais_celular" data-cy="select-ddi"  class="inputsAux ddi" style="">
                        <option  v-for="(pais, index) in lista_paises" :key="index" :value="pais" >
                            {{ pais.ddi }}
                        </option>
                    </select> -->

                    <input type="text"  data-cy="select-ddi" v-model="celularddi" v-mask="['##']" class="inputsAux ddi" placeholder="DDI" masked="false" 
                        style="" />

                    <input type="text" ref="celular_input" data-cy="input-ddd" v-model="celularddd" v-mask="['##']" class="inputsAux ddd" placeholder="DDD" masked="false" 
                        style="" />

                    <input type="text" ref="celular_input" data-cy="input-celular" v-model="celular" v-mask="maskTel()" class="inputsAux fone" masked="false" 
                    style=""/>                    
                 </div>
            </div>


            <div class="cardInputs">
                <label class="labels">E-MAIL</label>
                <input type="email" v-model="email" data-cy="input-email" class="inputs"  required />
            </div> 


            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-contatovoltar">
                      VOLTAR
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-contatoavancar">
                        CONTINUAR
                </button>
            </div> 
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span>{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
    </div>    

</template>

<script>

export default {
    props:["dados","selfie"],
    data: () => {
        return {
            celularddi:null,
            pais_celular:{},
            lista_paises: [],
            celularddd:null,
            celular:null,
            email:null,
            snack: false,
            snackColor: '',
            snackText: '',
        }
    },
    mounted(){
        
        this.listarPaises()
        // this.celularddd = this.dados.celularddd
        // this.celular = this.dados.celular
        this.celularddd = this.dados.celularddd
        this.celular = this.dados.celular
        this.email = this.dados.email

    },
    methods:{
        listarPaises(){
            this.$http.post("geo/pais/ddi/select")
            .then(async resp =>{

                    this.lista_paises = resp.data

                    this.lista_paises = this.lista_paises.filter(p => {
                        return p.ddi && p.ddi.trim() != ''
                    })

                    this.lista_paises.sort((a, b) => {
                            return parseInt(a.ddi.replace(' ','')) > parseInt(b.ddi.replace(' ','')) ? 1 : -1
                    });

                    this.pais_celular = await this.lista_paises.find(pais => pais.ddi == this.dados.celularddi)
                    this.celularddi =  this.pais_celular.ddi

            })
        },
        maskTel() {
            if(this.celular) {
                return this.celular.length == 10 ? '#####-####' : '####-#####'
            } else {
                return '####-####'
            }
        },

        validarFormulario(){

            if(!this.celularddi){ 
                // this.$swal(this.$t('alert.notification_telephone_required.title'),this.$t('alert.notification_telephone_required.content'),"info"); 
                alert("DDI é Obrigatório")
                return false
            }

            if(!this.celularddd){ 
                // this.$swal(this.$t('alert.notification_telephone_required.title'),this.$t('alert.notification_telephone_required.content'),"info"); 
                alert("DDD é Obrigatório")
                return false
            }


            if(!this.celular){ 
                // this.$swal(this.$t('alert.notification_telephone_required.title'),this.$t('alert.notification_telephone_required.content'),"info"); 
                alert("celular é Obrigatório")
                return false
            }

            if(!this.email){ 
                // this.$swal(this.$t('alert.notification_email_required.title'),this.$t('alert.notification_email_required.content'),"info"); 
                alert("Email é Obrigatório")
                return false
            }

            return true

        },
        salvarDados(){

            var dadossalvar = this.dados

            // dadossalvar.celularddi = this.pais_celular.ddi
            dadossalvar.celularddi = this.celularddi
            dadossalvar.celularddd = this.celularddd
            dadossalvar.celular = this.celular
            dadossalvar.email = this.email
            
            if(this.selfie){
                this.$emit('salvarDados',dadossalvar,'fotoselfie')
            }else{
                this.$emit('salvarDados',dadossalvar,'dadopessoal')
            }            
        },
        async avancar(){
           
            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        },
        voltar(){
           
            this.$emit('direcionar','endereco')
            
        }
    }
   
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>