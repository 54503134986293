<template>
  <v-container fluid grid-list-sm>
      <div>
        <div >
          <v-card class="pl-2 pr-2" style="min-width: 100%!important; display:flex!important;" >
          <v-select
          outlined
          label="Período"
          append-icon="mdi-arrow-down"
          :items="filtros"
          itemid="id"
          item-value="id"
          item-text="valor"
          style="max-width: 200px!important;"
          v-on:change="setCamposPesquisa()"
          v-model="periodoFixo"
          
          >

          </v-select>

          <input v-model="dataInicial" type="date"  :class="periodoFixo == 5 ? 'Visivel DashInputs':'Invisivel DashInputs'" placeholder="" >
          <input v-model="dataFinal" type="date"    :class="periodoFixo == 5 ? 'Visivel DashInputs':'Invisivel DashInputs'" placeholder="">
          <v-btn @click="consultarDados()" small color="primary" class="ml-2"
          style="color:white;min-height:40px;font-weight:bold; margin-top: 10px;"
          >
            <v-icon class="pr-1" small color="white">mdi-magnify</v-icon>
            Pesquisar
          </v-btn>
          </v-card>
        </div>
      </div>
    <v-layout wrap style="display:flex;justify-content:center;">

      

      
      <CardDashBoard  cor="green" icone ="mdi-percent" titulo="Ocupação" 
                      :valor="dadosDash.ocupacao.valor+'%'"
                      iconesecundario="mdi-calendar" titulosecundario="Atual"
                      tagTitle="Porcentagem de Ocupação Atual." />

      <CardDashBoard  cor="orange" icone ="mdi-home" titulo="Hospedagem" 
                      :valor="dadosDash.hospedagem.valor"
                      iconesecundario="mdi-tag" titulosecundario="Hospedes na Casa"
                      tagTitle="Quantidade de hóspedes atualmente na Casa." />

      <CardDashBoard  cor="red" icone ="mdi-access-point" titulo="Acesso à Internet"
                      :valor="dadosDash.acessointernet.valor+'%'"
                      iconesecundario="mdi-antenna" titulosecundario="Sessões Ativas" 
                      tagTitle="Quantidade de dispositivos conectados a rede." />

      <CardDashBoard  cor="blue" icone ="mdi-comment" titulo="Fale Conosco"
                      :valor="dadosDash.faleConosco.valor"
                      iconesecundario="mdi-comment" titulosecundario="Mens / Media"
                      tagTitle="Quantidade de Mensagens" />                      

      <CardDashBoard  cor="#9e10c1" icone ="mdi-login" titulo="Recepção In/Out" 
                      :valor="dadosDash.recepcaoIn.valor+ '/'+dadosDash.recepcaoOut.valor"
                      iconesecundario="mdi-login" titulosecundario="Checkins Realizados"
                      tagTitle="Quantidade de Checkins Realizados " />                      

      <!-- <CardDashBoard  cor="#ff768e" icone ="mdi-logout" titulo="Recepção Out" 
                      :valor="dadosDash.recepcaoOut.valor"
                      iconesecundario="mdi-logout" titulosecundario="Checkout Realizados"
                      tagTitle="Quantidade de Checkouts Realizados " />                       -->

      <CardDashBoard  cor="#76b3ff" icone ="mdi-account-edit" titulo="WebCheckins" 
                      :valor="dadosDash.webcheckinstime.valor"
                      iconesecundario="mdi-logout" :titulosecundario="dadosDash.webcheckinstime.valor2+ ' minutos por Hóspede'"
                      tagTitle="Tempo médio de Web Checkin gasto por hóspede " />                      

      <CardDashBoard  cor="#33ca3f" icone ="mdi-account-edit" titulo="WebCheckins"
                      :valor="dadosDash.webcheckins.valor"
                      iconesecundario="mdi-account-edit" titulosecundario="Preenchidos / Enviados"
                      tagTitle="Quantidade de web checkins Preenchidos / Quantidade de web checkins enviados" />

      <CardDashBoard  :cor="dadosDash.statusRobo.valor == 'ON' ? '#33ca3f':'black'"
                      :icone ="dadosDash.statusRobo.valor == 'ON' ? 'mdi-robot':'mdi-robot-off'" 
                      titulo="Robô"
                      :valor="dadosDash.statusRobo.valor"
                      iconesecundario="mdi-robot" 
                      :titulosecundario="dadosDash.statusRobo.valor == 'ON' ? 'ATIVADO':'DESLIGADO'"
                      tagTitle="Status Atual do robô de Integração Ficha Digital" />

      <CardDashBoard  cor="green" icone ="mdi-clock" titulo="Tempo Manual" 
                      :valor="dadosDash.webCheckinTempoMedioManual.valor+ ' min'"
                      iconesecundario="mdi-clock" 
                      titulosecundario="Tempo médio em minutos"
                      tagTitle="Tempo médio Gasto por Checkin Manual" />

      <CardDashBoard  cor="orange" icone ="mdi-clock" titulo="Tempo Web" 
                      :valor="dadosDash.webCheckinTempoMedioweb.valor+ ' min'"
                      iconesecundario="mdi-clock" 
                      titulosecundario="Tempo médio em minutos"
                      tagTitle="Tempo médio Gasto por Web Checkin" />

      <CardDashBoard  cor="red" icone ="mdi-account-multiple-plus" titulo="Qtde de leads" 
                      :valor="dadosDash.qtdeLeads.valor"
                      iconesecundario="mdi-account-multiple-plus" titulosecundario="Leads Coletados"
                      tagTitle="Quantidade de leads coletados" />

      <CardDashBoard  cor="blue" icone ="mdi-account-multiple-plus-outline" titulo="Fichas" 
                      :valor="dadosDash.qtdeFichasImportadas.valor"
                      iconesecundario="mdi-account-multiple-plus-outline" 
                      titulosecundario="Quantidade de Fichas Importadas"
                      tagTitle="Quantidade de Fichas Importadas" />

      <!-- <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="green"
          icon="mdi-percent"
          title="Ocupação"
          value="97%"
          small-value="%"
          sub-icon="mdi-calendar"
          sub-text="Atual" />
      </v-flex>
      <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="orange"
          icon="mdi-home"
          title="Hospedagem"
          value="547"
          small-value=""
          sub-icon="mdi-tag"
          sub-icon-color="white"
          sub-text="Hóspedes na Casa"
        />
      </v-flex>
      <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="red"
          icon="mdi-access-point"
          title="Acesso à Internet"
          value="75"
          sub-icon="mdi-antenna"
          sub-text="Sessões Ativas"
        />
      </v-flex>
      <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="info"
          icon="mdi-comment"
          title="Fale Conosco"
          value="17 / 4.3"
          sub-icon="mdi-comment"
          sub-text="Mens. / Média"
        >
        </material-stats-card>
      </v-flex> 

      <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="info"
          icon="mdi-login"
          title="Recepção"
          value="37 / 45"
          sub-icon="mdi-login"
          sub-text="Checkins Realizados"
        >
        </material-stats-card>
      </v-flex>

      <v-flex sm6 xs12 md6 lg3>
        <material-stats-card
          color="info"
          icon="mdi-logout"
          title="Recepção"
          value="22 / 26"
          sub-icon="mdi-logout"
          sub-text="Checkouts Realizados"
        >
        </material-stats-card>
      </v-flex>
      -->


      <!--antes-->
     
      <v-flex md12 sm12 lg4>
        <material-chart-card
          :data="dadosFaixaEtaria.data"
          :options="dadosFaixaEtaria.options"
          color="green"
          type="Bar"
        >
          <h4>Hóspedes por Idade</h4>
          <p class="category d-inline-flex font-weight-light my-1">
            <v-icon color="green" small style="margin-right:10px;"> mdi-home </v-icon>
            <span style="font-weight:bold;">Atualmente na Casa</span>
          </p>

          <!-- <template slot="actions">
            <v-icon class="mr-2" small> mdi-clock-outline </v-icon>
            <span class="caption grey-lighten-1--text font-weight-light"
              >updated 4 minutes ago</span
            >
          </template> -->
        </material-chart-card>
      </v-flex> <!--  -->

      

      
      <v-flex md12 sm12 lg4>
        <material-chart-card
          style="font-size: smaller;"
          :data="dadosHospedesPorTipo.data"
          :options="dadosHospedesPorTipo.options"
          :responsive-options="dadosHospedesPorTipo.responsiveOptions"
          color="red"
          type="Bar"
        >
          <h4>
            Hospedes por Tipo
          </h4>
          <p class="category d-inline-flex font-weight-light my-1">
           <v-icon color="green" small style="margin-right:10px;">mdi-home</v-icon>
           <span style="font-weight:bold;">Atualmente na Casa</span>
          </p>

          <!-- <template slot="actions">
            <v-icon class="mr-2" small> mdi-clock-outline </v-icon>
            <span class="caption blue-grey--text font-weight-light"
              >updated 10 minutes ago</span
            >
          </template> -->
        </material-chart-card>
      </v-flex>
      <!---->

      
      <v-flex md12 sm12 lg4>
        <material-chart-card
          :data="dadosHospedesPorEmpresaReservante.data"
          :options="dadosHospedesPorEmpresaReservante.options"
          color="indigo"
          type="Line"
        >
          <h4>Hospedes por Empresa Reservante</h4>
          <p class="category d-inline-flex font-weight-light my-1">
            <v-icon color="green" small style="margin-right:10px;">mdi-home</v-icon>
            <span style="font-weight:bold;">Atualmente na Casa</span>
          </p>

          <!-- <template slot="actions">
            <v-icon class="mr-2" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light"
              >campaign sent 26 minutes ago</span
            >
          </template> -->
        </material-chart-card>
      </v-flex> <!-- -->
      <!--  <v-flex md12 sm12 lg3>
        <material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          color="orange"
          type="Line"
        >
          <h3 class="title font-weight-light">Completed Tasks</h3>
          <p class="category d-inline-flex font-weight-light">
            Last Last Campaign Performance
          </p>

          <template slot="actions">
            <v-icon class="mr-2" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light"
              >campaign sent 26 minutes ago</span
            >
          </template>
        </material-chart-card>
      </v-flex> -->
      <!--aqui -->

<!--       <v-flex md12 lg9>
        <Reservas />
      </v-flex> -->

      <!-- <v-flex md12 lg3>
        <material-card class="card-tabs" color="green">
          <v-flex slot="header">
            <v-tabs v-model="tabs" color="transparent" slider-color="white">
              <v-tab class="mr-3">
                <v-icon class="mr-2">mdi-door</v-icon>
                UHS
              </v-tab>
            </v-tabs>
          </v-flex>

          <v-tabs-items style="heigth: 600px;">
                <div>
                  <v-data-table
                    :headers="headersUHs"
                    items="uhs"
                    item-key="idmovimentouh"
                    :items-per-page="15"
                    locale="pt-BR"
                    mobile-breakpoint="350"
                    loading="20"
                    loading-text="Carregando.."
                  >
                  </v-data-table>
                </div>

                <v-divider />
              
          </v-tabs-items>
        </material-card>
      </v-flex> -->

      <!-- INICIO -->
<!--       <v-flex md12 lg9>
        <material-card
          color="orange"
          title="Hóspedes na Casa"
          text="Listagem dos hóspedes na casa atualmente"
        >
          <v-btn @click="consultaHospedesCheckin" small color="light-blue"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
          <v-text-field v-model="searchHospede"></v-text-field>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="searchHospede"
          >
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="font-weight-light text-warning text--darken-3"
                v-text="header.text"
              />
            </template>
            <template slot="items" slot-scope="{ item }">
              <td>{{ item.coduh }}</td>
              <td>{{ item.numreserva }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.sobrenome }}</td>
              <td>{{ item.hospedeprincipaltxt }}</td>
              <td>{{ item.empresareservante }}</td>
              <td>{{ item.tipohospede }}</td>
              <td>{{ item.pensao }}</td>
              <td class="text-xs-right">{{ item.datacheckin }}</td>
              <td class="text-xs-right">{{ item.datacheckout }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex> -->
      <!-- FIM -->
      <v-flex md12 lg3>
<!--         <material-card class="card-tabs" color="green">
          <v-flex slot="header"
            >Hóspedes por:
            <v-tabs v-model="tabs" color="transparent" slider-color="white">
              <v-tab class="mr-3" @click="tab = 0">
                <v-icon class="mr-2">mdi-coffee</v-icon>
                Pensão
              </v-tab>
              <v-tab class="mr-3" @click="tab = 1">
                <v-icon class="mr-2">mdi-domain</v-icon>
                Cliente
              </v-tab>
              <v-tab @click="tab = 2">
                <v-icon class="mr-2">mdi-account</v-icon>
                Tipo
              </v-tab>
            </v-tabs>
          </v-flex>

          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="n in 3" :key="n">
              <v-list three-line>
                <div v-if="tab == 0">
                  <v-list-tile
                    @click="complete(0)"
                    v-for="p in tipopensao"
                    :key="p.pensao"
                  >
                    <v-list-tile-title>{{ p.pensao }}</v-list-tile-title>
                    <v-list-tile-title>{{ p.totalpensao }}</v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-eye</v-icon>
                        </v-btn>
                        <span style="color: white">Mais...</span>
                      </v-tooltip>
                                            <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon
                        >
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>
                    </div>
                  </v-list-tile>
                </div>
                <div v-if="tab == 1">
                  <v-list-tile
                    v-for="ec in empresareservante"
                    :key="ec.empresareservante"
                  >
                    <v-list-tile-title>
                      {{ ec.empresareservante }}
                    </v-list-tile-title>
                    <v-list-tile-title>
                      {{ ec.totalempresareservante }}
                    </v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-eye</v-icon>
                        </v-btn>
                        <span>Mais...</span>
                      </v-tooltip>
                                            <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon
                        >
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>
                    </div>
                  </v-list-tile>
                </div>
                <div v-if="tab == 2">
                  <v-list-tile v-for="th in tipohospede" :key="th.tipohospede">
                    <v-list-tile-title>
                      {{ th.tipohospede }}
                    </v-list-tile-title>
                    <v-list-tile-title>
                      {{ th.totaltipohospede }}
                    </v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-eye</v-icon>
                        </v-btn>
                        <span>Mais...</span>
                      </v-tooltip>
                                            <v-tooltip top content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon
                        >
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>
                    </div>
                  </v-list-tile>
                </div>
                <v-divider />
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </material-card> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CardDashBoard from './CardDashBoard'
export default {
  name: "Dashboard",
  components: {
    CardDashBoard
  },
  data() {
    let data = new Date();
    let mm = "";
    let dd = "";
    if (data.getMonth() < 10) {
      mm = "0" + (data.getMonth() + 1);
    } else {
      mm = data.getMonth() + 1;
    }

    if (data.getDate() < 10) {
      dd = "0" + data.getDate();
    } else {
      dd = data.getDate();
    }

    return {
      filtros : [  
        { id: "1",
          valor : "Hoje"
        },
        {
          id : 2,
          valor: "Ultimos 7 dias"
        },
        {
          id : 3,
          valor: "Ultimos 15 dias"
        },        
         {
          id : 4,
          valor: "Ultimos 30 dias"
        },       
         {
          id : 5,
          valor: "Personalizado"
        }
      ],
      periodoFixo: 1,
      dataInicial:null,
      dataFinal:null,
      dadosDash: null,
      searchHospede: "",
      percocupacao: "",
      uhsocupadas: "",
      uhspool: "",
      dataini: data.getFullYear() + "-" + mm + "-" + dd,
      datafim: data.getFullYear() + "-" + mm + "-" + dd,

      datainiReservas: data.getFullYear() + "-" + mm + "-" + dd,
      datafimReservas: data.getFullYear() + "-" + mm + "-" + dd,

      descstatusreserva: "Confirmada",

      totalhospedes: "",
      totalmensagens: 0,
      medianotamensagens: 0,

      empresareservante: [],
      tipopensao: [],
      tipohospede: [],

      tab: 0,

      headersUHs: [
        {
          text: "UH",
          align: "start",
          filterable: true,
          width: "10px",
          value: "coduh",
        },
        { text: "Status GOV", value: "descstatusgov", width: "100px" },
        { text: "Status UH", value: "descstatusuh", width: "100px" }
      ],
      uhs: [],
      /*
      fim do cód
      */
      dadosFaixaEtaria: {
        data: {
          labels: ["0-2", "3-11", "12-17", "18-59", ">60"],
          series: [[15, 26, 19, 300, 10]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },

      dadosHospedesPorTipo: {
        data: {
          labels: ["Meia Pensao", "Pensao Completa"],
          series: [[459,115]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },

      dadosHospedesPorEmpresaReservante: {
        data: {
          labels: ["Booking", "Decolar", "Trivago"],
          series: [[459,115,406]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },

      dailySalesChart: {
        data: {
          labels: [],
          series: [[]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          contGraph: 5,
          low: 0,
          high: 110, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      tipoHospedeCharts: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          contGraph: 5,
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 768x)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      headers: [
        { text: "UH", value: "coduh", width: "100px" },
        { text: "Nº Reserva", value: "numreserva", width: "100px" },
        { text: "Nome", value: "nome", width: "80px" },
        { text: "Sobrenome", value: "sobrenome", width: "80px" },
        { text: "Principal", value: "hospedeprincipaltxt", width: "80px" },
        { text: "Cliente", value: "empresareservante", width: "200px" },
        { text: "Tipo de Hóspede", value: "tipohospede", width: "150px" },
        { text: "Pensão", value: "pensao", width: "100px" },
        { text: "Check-IN", value: "datacheckin", width: "150px" },
        { text: "Check-OUT", value: "datacheckout", width: "150px" },
      ],
      items: [],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
      headersReservas: [
        { text: "UH", value: "coduh", width: "100px" },
        { text: "Nº Reserva", value: "numreserva", width: "100px" },
        { text: "Nome", value: "nome", width: "80px" },
        { text: "Sobrenome", value: "sobrenome", width: "80px" },
        { text: "Principal", value: "hospedeprincipaltxt", width: "80px" },
        { text: "Cliente", value: "empresareservante", width: "200px" },
        { text: "Tipo de Hóspede", value: "tipohospede", width: "150px" },
        { text: "Pensão", value: "pensao", width: "100px" },
        { text: "Check-IN", value: "datacheckin", width: "150px" },
        { text: "Check-OUT", value: "datacheckout", width: "150px" },
      ],
      itemsReservas: [],

    };
  },
  methods: {
    consultarDados(){
      let dados = {
        idHotel:1,
        tipoPeriodo:this.periodoFixo,
        dataInicial:this.dataInicial,
        dataFinal:this.dataFinal,
      }
      this.$http.post("/dashboard/buscarDados",dados)
      .then(resp => {
          console.log("RETORNO CONSULTAR DADOS")
          console.log(resp.data)
          
          this.dadosDash = resp.data

          //MONTA OS DADOS PARA O GRAFICO HOSPEDES POR FAIXA ETARIA
          this.dadosFaixaEtaria.data.labels = [
            Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[0],
            Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[1],
            Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[2],
            Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[3],
            Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[4],
          ]
          this.dadosFaixaEtaria.data.series = [[
            this.dadosDash.hospedesPorFaixaEtaria[Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[0]],
            this.dadosDash.hospedesPorFaixaEtaria[Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[1]],
            this.dadosDash.hospedesPorFaixaEtaria[Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[2]],
            this.dadosDash.hospedesPorFaixaEtaria[Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[3]],
            this.dadosDash.hospedesPorFaixaEtaria[Object.keys(this.dadosDash.hospedesPorFaixaEtaria)[4]],
            ]]



          //MONTA OS DADOS PARA O GRAFICO HOSPEDES POR TIPO
          this.dadosHospedesPorTipo.data.labels = [
            Object.keys(this.dadosDash.hospedesPorTipo)[0],
            Object.keys(this.dadosDash.hospedesPorTipo)[1]
          ]
          this.dadosHospedesPorTipo.data.series = [[
            this.dadosDash.hospedesPorTipo[Object.keys(this.dadosDash.hospedesPorTipo)[0]],
            this.dadosDash.hospedesPorTipo[Object.keys(this.dadosDash.hospedesPorTipo)[1]]
            ]]

          //MONTA OS DADOS PARA O GRAFICO HOSPEDES POR TIPO
          this.dadosHospedesPorEmpresaReservante.data.labels = [
            Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[0],
            Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[1],
            Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[2]
          ]
          this.dadosHospedesPorEmpresaReservante.data.series = [[
            this.dadosDash.hospedesPorEmpresaReservante[Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[0]],
            this.dadosDash.hospedesPorEmpresaReservante[Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[1]],
            this.dadosDash.hospedesPorEmpresaReservante[Object.keys(this.dadosDash.hospedesPorEmpresaReservante)[2]]
            ]]

      })
      .catch(error =>{
          console.log("ERROR RETORNO CONSULTAR DADOS")
          console.log(error)
      })
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },
    loaduhData() {
      this.$http("/dashboard/consultauhs", )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.length > 0) {
              this.uhs = response.data;
            } else {
              this.uhs = [];
            }
          } else {
            this.uhs = [];
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
    consultaOcupacao() {
      this.$http("/dashboard/consultaocupacao")
        .then((response) => {
          this.uhspool = response.data.uhspool;
          this.uhsocupadas = response.data.uhsocupadas;
          this.percocupacao = (this.uhsocupadas * 100) / this.uhspool;
          this.percocupacao = parseFloat(this.percocupacao).toFixed(1);
        })
        .catch((error) => console.log(error));
    },
    consultaFaleconosco() {
      this.$http("/portal/buscamensagens", {
        params: { dataini: this.dataini, datafim: this.datafim },
      })
        .then((response) => {
          this.totalmensagens = response.data.total;
          this.medianotamensagens =
            parseFloat(response.data.media).toFixed(1) || 0;
        })
        .catch((error) => console.log(error));
    },
    consultaTotalHospedes() {
      this.$http("/dashboard/buscatotalhospedes", {
        params: { dataini: this.dataini, datafim: this.datafim },
      })
        .then((response) => {
          this.totalhospedes = response.data.totalhospedes;
        })
        .catch((error) => console.log(error));
    },
    consultaHospedesCheckin() {
      this.$http("/ocupacao/hospedesnacasa", {
        params: { dataini: this.dataini, datafim: this.datafim },
      })
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => console.log(error));
    },
    buscaReservas() {
      this.$http("/pms/buscareservas", {
        params: {
          dataini: this.datainiReservas,
          datafim: this.datafimReservas,
          descstatusreserva: this.descstatusreserva,
        },
      })
        .then((response) => {
          this.itemsReservas = response.data;
        })
        .catch((error) => console.log(error));
    },
    consultaOcupacaoAnalitico() {
      this.$http("/ocupacao/ocupacaoanalitico", {
        params: { dataini: this.dataini, datafim: this.datafim },
      })
        .then((response) => {
          this.tipopensao = response.data["tipopensao"];

          let tp = 0;
          for (tp = 0; tp < response.data["tipopensao"].length; tp++) {
            this.dailySalesChart.data.labels.push(
              response.data["tipopensao"][tp].pensao
            );
            this.dailySalesChart.data.series[0].push(
              response.data["tipopensao"][tp].totalpensao
            );
          }

          this.dailySalesChart.options.high =
            Math.max.apply(null, this.dailySalesChart.data.series[0]) + 50;

          this.tipohospede = response.data["tipohospede"];

          let th = 0;
          for (th = 0; th < this.tipoHospedeCharts.options.contGraph; th++) {
            this.tipoHospedeCharts.data.labels.push(
              response.data["tipohospede"][th].tipohospede
            );
            this.tipoHospedeCharts.data.series[0].push(
              response.data["tipohospede"][th].totaltipohospede
            );
          }
          this.tipoHospedeCharts.options.high =
            Math.max.apply(null, this.tipoHospedeCharts.data.series[0]) + 50;

          this.empresareservante = response.data["empresareservante"];
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.consultarDados()
/*     this.consultaOcupacao();

    this.consultaFaleconosco();

    this.consultaTotalHospedes();

    this.consultaHospedesCheckin();

    this.consultaOcupacaoAnalitico();

    this.buscaReservas();

    this.loaduhData();

    setInterval(() => {
      this.consultaOcupacao();

      this.consultaFaleconosco();

      this.consultaTotalHospedes();
    }, 60000);

    //conecta dashboard ao socket do ficha
    this.$socket.disconnect();
    this.$socket.io.opts.query.usuario = localStorage.getItem('useremail')
    this.$socket.io.opts.query.tipo = 'DASHBOARD'
    this.$socket.io.opts.query.tipo_acesso = 'DASHBOARD'
    this.$socket.connect();

    }, 60000); */
  },
};
</script>

<style scoped>
.v-card__text {
  background-color: red!important;
  margin-top: 50px;
}

.DashInputs{
  /* background-color: red; */
  width: 250px;
  min-height:40px!important;
  border-radius: 5px;
  padding:5px;
  border:solid 1px #bdbdbd;
  margin-left:10px
  
}

.DashBtn{
  margin-left:10px;
  width: 20%;
  background-color: #2196f3!important;
  color:white!important;
  font-weight: bold;
}

.Visivel{
  display:inline!important;
  width: 180px!important;
  height: 20px;
  margin-top: 10px;
}

.Invisivel{
  display:none!important;
}
</style>

