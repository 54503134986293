<template>
    
    <div class="" style="flex-wrap:wrap;width:100%; overflow:auto;">
        
        <div style="text-align:center;margin-top:10px;">
           <img id="icone_relogio" style="max-height:10vh;" src="@/assets/img/relogio_icon.png" alt="">
        </div>

        <h1 style="text-align:center;">{{ $t('webcheckin.horario.previsao') }}</h1>

        <div class="cardInputs" style="margin-top:30px;">
            <label class="labels">{{ $t('webcheckin.horario.horariochegada') }}</label>
            <datetime  class="inputsHorario" data-cy="datetime-chegada" id="datetime-chegada"  value-zone="UTC-3" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="horariochegada">
            </datetime>
            <label 
                class="labels" 
                v-if="infohorario.checkin != null" 
                style="font-size:15px!important;margin-top:5px;margin-bottom:5px;color:#5c5c63;text-align:left;">
                    {{ infohorario.checkin }}
            </label>

            <!-- <input id="dt" type="datetime-local"/> -->
        </div>


        <div class="cardInputs" style="margin-top:30px;">
            <label class="labels">{{ $t('webcheckin.horario.horariovoo') }}</label>
            <label class="labels" style="font-size:15px!important;margin-top:5px;margin-bottom:5px;color:blue;">{{ $t('webcheckin.horario.infovoo') }}</label>
            <datetime class="inputsHorario"  data-cy="datetime-saida"  id="datetime-saida" value-zone="UTC-3" :input-style="{color:'black'}" :phrases="{ok: 'OK', cancel: 'CANCELAR'}" :minute-step="10" format="HH:mm" type="time" v-model="horariopartida">
            </datetime>
            <label 
                class="labels" 
                v-if="infohorario.checkout != null" 
                style="font-size:15px!important;margin-top:5px;margin-bottom:5px;color:#5c5c63;text-align:left;">
                    {{ infohorario.checkout }}
            </label>
        </div>

        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-horariosalvar">
                    {{ $t('webcheckin.horario.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-horarioavancar">
                    {{ $t('webcheckin.horario.avancar') }}
            </button>
        </div> 
        <!-- <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>         -->
    </div>
</template>

<script>

// import CONSTANTS from '../constantes'
// import axios from 'axios'

import moment from 'moment'

export default {
    props:["dados","dadosAcompanhantes","hospedePrincipal","hospedePrincipalInicial"],
    data: () =>{
        return{
            horariochegada:null,
            horariopartida:null,
            infohorario: {
                checkout: null,
                checkin: null
            }

        }
    },
    created(){
        //console.log("HORA NO CREATED")
        //console.log(this.dados.horariochegada)
        this.dados.horariochegada = this.dados.horariochegada.substring(0,19)
        //console.log(this.dados.horariochegada)
        this.horariochegada = this.dados.horariochegada.replace(".000Z","").replace(" ","T")
        this.dados.horariopartida = this.dados.horariopartida.substring(0,19)
        this.horariopartida = this.dados.horariopartida.replace(".000Z","").replace(" ","T")
    },
    mounted(){
        this.requisitarParametroHorario()
    },
    methods: {
        salvarDados(){
            
            var horachegada = null
            if(this.horariochegada){
                console.log("POSSUI HORA")
                horachegada = this.horariochegada.substring(0,19)
                console.log(horachegada)
                horachegada = horachegada.replace(".000Z","").replace(" ","T")
            }else{
                
                console.log("this.dados.datacheckinprevisto")
                console.log(this.dados.datacheckinprevisto)
                horachegada = this.dados.datacheckinprevisto.split("T")[0]+"T"+this.horariochegada.split("T")[1]
                console.log("HORA CHEGADA ANTES!")
                console.log(horachegada)
                if(horachegada){
                    horachegada = horachegada.substring(0,19)
                    horachegada = moment(horachegada).format("YYYY-MM-DD HH:mm:ss");   
                }
            } 

            var horapartida = null
            if(this.horariopartida){
                horapartida = this.horariopartida.substring(0,19)
                horapartida = horapartida.replace(".000Z","").replace(" ","T")
            }else{
                horapartida = this.dados.datacheckoutprevisto.split("T")[0]+"T"+this.horariopartida.split("T")[1]
                horapartida = horapartida.substring(0,19)
                horapartida = moment(horapartida).format("YYYY-MM-DD HH:mm:ss");   
            }

            
            var dadossalvar = this.dados
            dadossalvar.horariochegada = horachegada
            dadossalvar.horariopartida = horapartida
            dadossalvar.webcheckinfinalizado = true

            if(this.dados.exibirPassosPerguntaExtra){
                this.$emit('salvarDados',dadossalvar,'perguntaextra')
            }else{
                // if(this.hospedePrincipalInicial && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                if(this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                    console.log("BB")
                    this.$emit('salvarDados',dadossalvar,'hospedes')    
                }else{
                    console.log("CC")
                    this.$emit('salvarDados',dadossalvar,'fim')
                }
            }
            
           
        },
        async avancar(){
                this.salvarDados()
        },
        voltar(){ 
           
            // this.$emit('direcionar','observacoes')
            //controle de direcionamento 
            if(this.dados.exibirPassosObservacao){
                this.$emit('direcionar','observacoes')
            }else{
                if(this.dados.exibirPassosCovid){
                    this.$emit('direcionar','formulariocovid')
                }else{
                    this.$emit('direcionar','assinatura')
                }
            }
            
        },
        requisitarParametroHorario(){
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HORARIOPADRAOWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){
                    this.infohorario = {
                        checkin: resp.data[0].valor,
                        checkout: resp.data[0].valor2
                    }
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>