<template>
    <v-dialog v-model="exibirModalZoom" width="800px" max-width="800px">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Zoom
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalZoom',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                <v-card>
                    <v-card-title>
                        <!-- <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        >
                        </v-text-field> -->
                    </v-card-title>
                    <div style="display:flex;justify-content:center;padding-bottom:50px;">
                        <!-- {{imgZoom}} -->
                        <!-- <VueQrcode  style="width:200px" :value="url_leitor_web" /> -->
                        <img v-if="imgZoom" :src="'data:image.png;base64,' + imgZoom" style="width:700px"  />
                    </div>
                </v-card>

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>


// import VueQrcode from 'vue-qrcode'
export default {
    data: () => ({
        // url_leitor_web:'http://192.168.1.64:8080/captureLogin/' +localStorage.getItem('userid')     
        url_leitor_web:null     
    }),
    props:["exibirModalZoom","imgZoom"],
    components:{
        // ModalSolicitacoesResponder
        // VueQrcode 
    },
    methods: {
        consultarSolicitacoes(reservahospede){
            console.log("PASS 1");
            this.$emit('consultarSolicitacoes',reservahospede)
        },
        setExibirModalResponder(item){
            this.solicitacao = item
            this.setModalResponder(!this.exibirModalResponder)
        },
        setModalResponder(valor){
            // alert(JSON.stringify(this.solicitacao))
            this.exibirModalZoom = valor
        },
    },
    mounted() {
        this.url_leitor_web = window.location.origin+'/captureLogin/' +localStorage.getItem('userid')
        
        // await this.$http.post('reserva/solicitacao/select',{
        //             reservahospede:1
        //         })

    },
};
</script>