<template>
    <v-dialog v-model="exibirModalPDVReservas" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Configure os PDVs conforme utilizados no PMS
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalPDVReservas()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div class="select-pdvs">
                        <v-select dense id="selectHotelPadrao"
                            v-model="pdvsReservas"
                            data-cy="pdvsReservas"
                            :items="pdvs"
                            item-value="pointOfSaleId"
                            item-text="name"
                            attach
                            chips
                            label="PDV de Reservas"
                        ></v-select>
                    </div>

                    <div class="select-pdvs" >
                        <v-select dense id="selectpdvsFrigobar"
                            v-model="pdvsFrigobar" 
                            data-cy="pdvsFrigobar"
                            :items="pdvs"
                            item-value="pointOfSaleId"
                            item-text="name"
                            attach
                            chips
                            label="PDV do Frigobar"
                        ></v-select>
                    </div>

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>

export default {
    data: () => ({
        umdia:false,
        diariamente:false,
        cincodias:false,
        dezdias:false,
        trintadias:false,
        textobotaosalvar:"SALVAR",
        pdvsReservas: null,
        pdvsFrigobar: null
    }),
    props:["exibirModalPDVReservas", "pdvs"],
    components:{
        // ModalSolicitacoesResponder

    },
    methods: {
    
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarPDVReservas().then(()=>{
                this.salvarPDVFrigobar().then(()=>{
                    this.setModalPDVReservas()
                })
            })
            
        },
      
        salvarPDVReservas(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'HITSPDVRESERVA',
                    "valor" : this.pdvsReservas
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalPDVReservas(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalPDVReservas')
        },
        salvarPDVFrigobar(){
            return new Promise( (resolve,reject) =>{
                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'WEBCKOUTIDSPDVFRIGOBAR',
                    "valor" : this.pdvsFrigobar
                }).then(resp =>{
                    resolve(resp)
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        buscarParametro() {
            this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HITSPDVRESERVA'
            }).then(resp =>{
                if(resp.status == 200){
                    this.pdvsReservas = parseInt(resp.data[0].valor)
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        },
    },
    mounted() {
        this.buscarParametro();
    },
};
</script>

<style scoped>
.select-pdvs {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    font-size: 16px;
    margin-top: 10px;
}  
</style>