<template>
    <v-dialog v-model="exibirModalDirectWhatsApp" width="800px" max-width="800px">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        <v-icon large>mdi-whatsapp</v-icon> WhatsApp 
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalDirectWhatsApp')" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                
                <v-card>
                    <div style="padding: 10px;">
                        <v-text-field
                            readonly
                            label="Para:"
                            v-model="numeroWhatsapp"
                        >
                        </v-text-field>
                    </div>
                    <div class="containerMensagem">
                      <span>Olá,</span>
                      <input type="text" class="inputMensagem" v-model="mensagem" placeholder="Mensagem"/>
                      <span>Ficamos a disposição;</span>
                    </div>
                    <div class="buttonEnviar">
                      <v-btn color="green" class="mb-2 mr-2" @click="enviarWhatsApp()">
                          <v-icon small color="white">mdi-send</v-icon>
                          <span style="color: white;">Enviar</span>
                      </v-btn>
                    </div>
                    <v-card-actions>
                        
                        <span style="min-width: 250px;"> <strong>{{ respostaAcao }}</strong> </span>
                        <v-spacer />
                        
                        
                        
                    </v-card-actions>
                    
                    
                </v-card>
                
            </v-card-text>

            
        </v-card>
    </v-dialog>
</template>          

<script>


export default {
    data: () => ({      
        mensagem : "",
        respostaAcao : "",
    }),
    props:["exibirModalDirectWhatsApp", "numeroWhatsapp", "id_hospede", "idReservaHospede"],
    components:{
    },
    methods: {
        setModalDirectWhatsApp(){
            
            this.$emit('setModalDirectWhatsApp')
        },
        enviarWhatsApp(){
            if(this.mensagem.length > 0 && this.numeroWhatsapp.length > 0){

              const obj = {
                id_pessoa: this.id_hospede,
                mensagem: this.mensagem,
                tipo: 'Enviada'
              }

              this.$http.post('/whatsapp/cadastrarMensagem', obj).then(resp =>{
                  console.log(resp.data)
                })

                    const body = {
                       telefoneDestino: this.numeroWhatsapp ,
                       template:"geral",
                       dadosHeader:[],
                       dadosBody:[
                        {valor: this.mensagem},
                      ],
                      dadosButton:[],
                      idReservaHospede: this.idReservaHospede,
                    }
                this.$http.post('/whatsapp/enviarMensagem', body).then(resp =>{
                    console.log(resp)
                    this.respostaAcao = "Mensagem enviada"
                    setTimeout(() => { 
                        this.setModalDirectWhatsApp()
                        this.mensagem = ""
                    }, 2000);    
                })
            }else{
                this.respostaAcao = "Conteúdo da Mensagem não pode ser vazio."
            }
        },
        
    },
    mounted() {

        this.respostaAcao = ""
        this.mensagem = ""

    },
};
</script>

<style scoped>
.inputMensagem {
  background-color: white;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  flex-grow: 1;
}

.containerMensagem {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.buttonEnviar {
  margin-left: 10px;
  margin-top: 20px;
}
</style>