<template>
    <v-dialog v-model="exibirModalResponder" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" style="color:white;font-bold:weight;" class="mb-8">
               Solicitação
            </v-toolbar>

            <v-card-text>
                <div style="height:300px;display:flex;flex-direction:column;justify-content:center;">
                    <label>Envie uma Observação ao Hospede ( Opcional )</label>
                    <div style="width:100%;height:200px;display:flex;justify-content:center;align-items:center;">
                        <textarea v-model="resposta" placeholder="Responda aqui ( Opcional )" style="width:90%;height:90%;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                        </textarea>
                    </div>
                    <div style="height:100px;display:flex;justify-content:center;align-items:center;">
                        <div style="width:90%;height:90%;padding:10px;display:flex;">
                            <div style="height:70%;width:70%;">
                                <v-btn x-medium color="blue" style="color:white;font-weight:bold;" @click="$emit('setModalResponder',false)" title="Fechar">
                                    VOLTAR
                                </v-btn>
                            </div>
                            <div style="height:70%;width:30%;display:flex;justify-content:flex-end;">
                                <!-- <v-btn x-medium color="red" style="color:white;font-weight:bold;" @click="responderSolicitacao(false)" title="Fechar">
                                    NEGAR
                                </v-btn> -->

                                <v-btn x-medium color="green" style="color:white;font-weight:bold;background-color:blue;" @click="responderSolicitacao(true)" title="Fechar">
                                    RESPONDER
                                </v-btn>
                            </div>

                            

                            

                        </div>
                    </div>
                </div>

            </v-card-text>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span style="padding: 0px 10px">{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        resposta:null,
        snack: false,
        snackColor: '',
        snackText: '',
    }),
    props:["exibirModalResponder","solicitacao","itemselecionado"],
    components:{
        
    },
    methods: {
        responderSolicitacao (status){

            if(this.resposta){

                try {

                    this.$http.post("reserva/solicitacao/upinsert", {  
                        "id" : this.solicitacao.id,   
                        "reservahospede" : this.solicitacao.reservahospede,
                        "resposta":this.resposta,
                        "atendida":status,
                        "textodasolicitacao":this.solicitacao.descricao,
                        "emailnotificacao":this.itemselecionado.email
                    })
                    .then((response) => {
                        this.$emit('setFlagAtualizar')
                        if(response.status == 200){
                            console.log("Respondido com Sucesso!")
                            this.$emit('consultarSolicitacoes',this.solicitacao.reservahospede)
                            this.$emit('setModalResponder',false)
                        }else{
                            alert('Não foi possivel responder a solicitação')
                            this.$emit('consultarSolicitacoes',this.solicitacao.reservahospede)
                            console.log("Não foi possivel responder a solicitação")
                        }
                        this.resposta = null
                    })
                    .catch((error) => {

                        console.log(error)
                        this.resposta = null
                        this.$emit('consultarSolicitacoes',this.solicitacao.reservahospede)
                        alert('Não foi possivel responder a solicitação!!')
                    });

                } catch (error) {
                    alert(error)
                }

            }else{
                // alert("Informe uma resposta")
                this.snackText = "Informe um texto para a resposta";
                this.snackColor = 'error'
                this.snack = true;
            }

        }
    },
    mounted() {

    },
};
</script>