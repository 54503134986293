<template>
  <v-dialog v-model="exibirModalPerfisUsuarios" width="800px" max-width="800px">
    <v-card style="max-width: 100%" height="auto">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <!-- !--Ver a resolução movel-- -->
          <span style="width: 95%; color: white; font-weight: bold">
            <v-icon large class="mr-2" color="white">mdi-account-child</v-icon
            >Perfis de Usuários
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="$emit('setModalPerfisUsuarios')"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <v-card>
          <v-row justify="center">
            <div style="display: flex">
              <v-text-field
                style="width: 200px !important"
                class="ma-2"
                label="Nome do Perfil"
                v-model="desctipousuarioCampo"
                data-cy="input-nome"
              ></v-text-field>
              <v-btn
                color="green"
                class="mt-4"
                style="color: white"
                @click="gravaPerfil()"
                data-cy="gravaPerfil"
                >OK</v-btn
              >
            </div>
          </v-row>
        </v-card>
        <v-card class="mt-4 mb-2">
          <v-row no-gutters>
            <v-col>
              <v-card class="pa-2" outlined tile>
                <span style="font-size: 16px">Perfil</span></v-card
              >
            </v-col>
            <!--       <v-col order="12">
        <v-card
          class="pa-2"
          outlined
          tile
        >
          Second, but last
        </v-card>
      </v-col> -->
            <v-col order="1">
              <v-card class="pa-2" outlined tile>
                <span style="font-size: 16px">
                  Acessos {{ " : " + desctipousuario || "" }}
                </span>
                <v-spacer></v-spacer>
                <v-btn small color="green" @click="salvarAcessosPerfil()" data-cy="salvarAcessosPerfil"
                  >OK</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col order="1">
              <v-card
                style="display: flex"
                class="pa-2"
                outlined
                tile
                v-for="p in perfis"
                :key="p.id"
                data-cy="listarAcessosPerfil"
                @click="
                  [
                    (idperfil = p.id),
                    listarAcessosPerfil(),
                    (desctipousuario = p.desctipousuario),
                  ]
                "
              >
                {{ p.desctipousuario }}
                <v-spacer></v-spacer>
                <!--                 <v-btn
                  small
                  color="blue"
                  style="color: white"
                  @click="editarPerfil(p.id)"
                  >Editar</v-btn
                > -->
              </v-card>
            </v-col>
            <v-col order="1">
              <v-card class="pa-2" outlined tile v-for="a in menus" :key="a.id">
                {{ a.descricao }}
                <v-switch data-cy="switch" v-model="a.assoc" :value="a.assoc"> </v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      <div
        style="
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
        "
      >
        <span class="ma-2">{{ snackText }}</span>
        <v-btn
          style="background-color: green; color: white"
          @click="snack = false"
          >FECHAR</v-btn
        >
      </div>
    </v-snackbar>
  </v-dialog>
</template>          

<script>
export default {
  data: () => ({
    mensagem: "",
    assunto: "",
    respostaAcao: "",
    perfis: [],
    idperfil: 0,
    menus: [],
    acessos: [],
    ativos: true,
    desctipousuario: "",
    desctipousuarioCampo: "",
    selected: [],
    rulesDescTipo: [(v) => this.validarNomePerfil(v) || "JA CADASTRADADO"],
    snack: false,
    snackColor: "light-blue",
    snackText: "",
    color: "general",
  }),
  props: ["exibirModalPerfisUsuarios", "email"],
  components: {
    // ModalSolicitacoesResponder
  },
  methods: {
    setModalPerfisUsuarios() {
      this.$emit("setModalPerfisUsuarios");
    },
    listarPerfis() {
      let body = {
        ativo: this.ativos,
      };
      this.$http
        .post("/auth/listartiposusuarios", body)
        .then((response) => {
          if (response.status == 200) {
            this.perfis = response.data;
          } else {
            this.perfis = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listarAcessosPerfil() {
      let body = {
        id: this.idperfil,
      };
      await this.$http
        .post("/auth/listaracessostiposusuarios", body)
        .then(async (response) => {
          if (response.status == 200) {
            this.acessos = response.data.acessos;
            let arrayMenus = response.data.menus;
            this.selected = [];
            for (let i = 0; i < arrayMenus.length; i++) {
              arrayMenus[i].assoc = false;

              for (let a = 0; a < this.acessos.length; a++) {
                if (this.acessos[a].idmenu == arrayMenus[i].id) {
                  arrayMenus[i].assoc = true;
                }
                //this.selected.push(this.menus[a].id)
              }
            }
            this.menus = arrayMenus;
          } else {
            this.acessos = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async salvarAcessosPerfil() {
      let objAcessos = [];
      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].assoc == true) {
          objAcessos.push({
            idtipousuario: this.idperfil,
            idmenu: this.menus[i].id,
          });
        }
      }
      console.log(objAcessos);
      //console.log(this.menus)
      await this.$http
        .post("/auth/salvaracessostipousuario", objAcessos)
        .then((response) => {
          if (response.status == 200) {
            this.snackText = "OK, processado com sucesso";
            this.snack = true;
          }
        });
    },
    async validarNomePerfil() {
      let retorno = false;
      let params = { desctipousuario: this.desctipousuarioCampo };
      await this.$http("/auth/listartiposusuariosdesc", { params })
        .then((response) => {
          if (response.status == 200) {
            retorno = false;
          } else {
            retorno = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return retorno;
    },

    async gravaPerfil() {
      if (!(await this.validarNomePerfil())) {
        this.snackColor = "orange";
        this.snackText = "Perfil já cadastrado";
        this.snack = true;
        return;
      } else if (!this.desctipousuarioCampo.length > 0) {
        this.snackColor = "orange";
        this.snackText = "O nome do perfil é obrigatório";
        this.snack = true;
      } else {
        let body = {
          desctipousuario: this.desctipousuarioCampo.trim(),
        };
        await this.$http
          .post("/auth/gravatipousuarios", body)
          .then((response) => {
            if (response.status == 200) {
              this.respostaAcao = response.data.message;
              this.desctipousuarioCampo = "";
              this.snackText = response.data.message;
              this.snack = true;
              this.listarPerfis();
            } else {
              alert("Erro ao cadastrar");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editarPerfil(id) {
      alert(id);
    },
  },
  mounted() {
    this.respostaAcao = "";
    this.mensagem = "";
    this.assunto = "";
    this.listarPerfis();
  },
};
</script>