<template>
    <div class="py-4" style="flex-wrap:wrap;width:100%;">
        <h1 style="text-align:center;padding:1vh;margin:1vh;" >{{ $t('webcheckin.doctipo.titulo') }}</h1>

        <div class="resumoInicial" >
            <div class="captura_container_tipos py-4">
                <a @click="setarPasso('RG')" class="tipos" >RG</a>
                <a @click="setarPasso('PASSAPORTE')" class="tipos" >PASSAPORTE</a>
                <a @click="setarPasso('CNH')" class="tipos" >CNH / CPF</a>
                <!-- <a @click="setarPasso('DNI')" class="tipos" >DNI</a>
                <a @click="setarPasso('RNE')" class="tipos" >RNE</a>
                <a @click="setarPasso('OUTROS')" class="tipos" >OUTROS</a> -->
            </div>
        </div>

        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                {{ $t('webcheckin.endereco.voltar') }}
            </button>
        </div> 
        
    </div>    
</template>

<script>
export default {
    name: 'DocTipo',
    props:["dados"],
    data: () => ({
        
    }),
    methods: {
        setarPasso(TipoDoc){
            var dadossalvar = this.dados
            dadossalvar.tipodoc = TipoDoc
            this.$emit('salvarDados', dadossalvar, 'docfrente')
        },
        voltar() {
            this.$emit('direcionar', 'inicio')
        }
    },
    mounted() {
    
    },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:white; */
        /* width:100vw; */
        /* height:90vh; */
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        /* width:80vw; */
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#1d4596;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        margin-bottom:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>