<template>
  <v-dialog v-model="exibirModalEditarContaEmail" width="800" max-width="800">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Configurações da Conta de Email
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações da Conta de Email"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalEditarContaEmail()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <!-- <v-card> -->
        <v-flex>
          <div
            style="
              margin-top: 20px;
              font-size: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <v-switch
              v-model="contaEmail.ativo"
              data-cy="contaEmail.ativo"
              style="border-radius: 5px; padding: 5px"
            ></v-switch>
            <label>Ativo</label>
            <v-col>
              <v-row>
                <v-text-field
                  class="mr-2 ml-2"
                  label="Servidor/Host"
                  :disabled="!contaEmail.ativo"
                  v-model="contaEmail.host"
                  data-cy="contaEmail.host"
                >
                </v-text-field>

                <v-text-field
                  class="mr-2 ml-2"
                  label="Porta"
                  type="number"
                  :disabled="!contaEmail.ativo"
                  v-model="contaEmail.port"
                  data-cy="contaEmail.port"
                >
                </v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  class="mr-2 ml-2"
                  label="Usuário"
                  :disabled="!contaEmail.ativo"
                  v-model="contaEmail.user"
                  data-cy="contaEmail.user"
                >
                </v-text-field>

                <v-text-field
                  class="mr-2 ml-2"
                  label="Senha"
                  type="password"
                  :disabled="!contaEmail.ativo"
                  v-model="contaEmail.pass"
                  data-cy="contaEmail.pass"
                >
                </v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  class="mr-2 ml-2"
                  label="Responder para"
                  :disabled="!contaEmail.ativo"
                  :rules="emailRules"
                  v-model="contaEmail.replyto"
                  data-cy="contaEmail.replyto"
                >
                </v-text-field>

                <v-text-field
                  class="mr-2 ml-2"
                  label="Nome para exibição"
                  :disabled="!contaEmail.ativo"
                  v-model="contaEmail.nametoexibition"
                  data-cy="contaEmail.nametoexibition"
                >
                </v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  class="mr-2 ml-2"
                  label="Email Administrativo para Notificações"
                  :disabled="!contaEmail.ativo"
                  :rules="emailRules"
                  v-model="contaEmail.emailadmnotificacao"
                  data-cy="contaEmail.emailadmnotificacao"
                >
                </v-text-field>
              </v-row>
              <strong>
                <p style="padding-top: 40px !important; color: black">
                  {{ respostaAcao }}
                </p>
              </strong>
            </v-col>
          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 15px">
          <v-btn
            class="mr-2"
            @click="enviarEmailTeste()"
            data-cy="enviarEmailTeste"
            color="success"
            :disabled="!contaEmail.ativo"
          >
            <v-icon small class="mr-2">mdi-email-send</v-icon>{{txtBtnTestar}} 
          </v-btn>
          <v-btn
            class="ml-2 mr-2"
            @click="salvarDadosContaEmail()"
            data-cy="salvarDadosContaEmail"
            color="primary"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon> Salvar
          </v-btn>
          <v-btn class="ml-2" @click="setModalEditarContaEmail" color="warning"
            ><v-icon small class="mr-2">mdi-logout</v-icon>Sair
          </v-btn>
        </div>
        <!-- </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    contaEmail: {
      id: 0,
      host: "",
      port: 0,
      user: "",
      pass: "",
      ativo: true,
      replyto: "",
      nametoexibition: "",
      idusuario: localStorage.getItem("idusuario") || null,
      emailadmnotificacao: "",
    },
    txtBtnTestar:'Testar',
    ativo: false,
    respostaAcao: "",
    emailRules: [
      (v) => !!v || "Campo obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
  }),
  props: ["exibirModalEditarContaEmail"],
  components: {
    BotaoTutorial
},
  methods: {
    salvarDadosContaEmail() {
      if (
        this.contaEmail.host.length > 0 &&
        this.contaEmail.port > 0 &&
        this.contaEmail.user.length > 0 &&
        this.contaEmail.pass.length > 0 &&
        this.contaEmail.replyto.length > 0 &&
        this.contaEmail.nametoexibition.length > 0 &&
        this.contaEmail.emailadmnotificacao.length > 0
      ) {
        if (
          !/.+@.+\..+/.test(this.contaEmail.replyto) ||
          !/.+@.+\..+/.test(this.contaEmail.emailadmnotificacao)
        ) {
          this.respostaAcao =
            "Impossível prosseguir, insira email para resposta e notificação corretamente.";
          return;
        }
        this.$http
          .post("/email/cadastracontaemail", this.contaEmail)
          .then((resp) => {
            console.log(resp.data.message);
            this.respostaAcao = "Cadastrado/Atualizado com sucesso";
            setTimeout(() => {
              this.setModalEditarContaEmail();
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            this.respostaAcao = "Erro ao cadastrar a conta de Email";
          });
      } else {
        this.respostaAcao = "Preencha todos os campos";
        return;
      }
    },
    buscarDadosContaEmail() {
      this.$http
        .get("/email/buscacontaemail", this.contaEmail)
        .then((resp) => {
          if (resp.data[0] != null){
            this.contaEmail = resp.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    enviarEmailTeste() {
      this.txtBtnTestar = 'Enviando...'
      if (!/.+@.+\..+/.test(this.contaEmail.replyto)) {
        this.respostaAcao =
          "Impossível prosseguir, insira email para resposta corretamente.";
        return;
      }

      if (
        this.contaEmail.host.length > 0 &&
        this.contaEmail.port > 0 &&
        this.contaEmail.user.length > 0 &&
        this.contaEmail.pass.length > 0 &&
        this.contaEmail.replyto.length > 0 &&
        this.contaEmail.nametoexibition.length > 0 &&
        this.contaEmail.emailadmnotificacao.length > 0
      ) {
        this.respostaAcao = "";
        let mensagemTeste = "Testando email da Ficha Digital";
        let body = {
          destinatario: this.contaEmail.replyto,
          assunto: "Teste de Email - ES Ficha Digital.",
          text: mensagemTeste,
          hotel: localStorage.getItem("hotel"),
          //nomeusuario : "Hotel Modelo"
        };

        this.$http
          .post("/email/enviarEmail", body)
          .then((response) => {
            console.log(response);
            this.respostaAcao = "E-mail enviado com sucesso.";
            this.txtBtnTestar = 'Testar'
          })
          .catch((error) => {
            this.txtBtnTestar = 'Testar'
            this.respostaAcao = error.response.data.message;
          });
      } else {
        this.respostaAcao = "Preencha todos os campos";
        return;
      }
    },
    setModalEditarContaEmail() {
      this.$emit("setModalEditarContaEmail");
    },
  },
  mounted() {
    this.buscarDadosContaEmail();
    this.respostaAcao = ""
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>