<template>
  <v-dialog v-model="exibirModalListaExclusaoEmpresaReservante" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width:95%;color:white;font-weight:bold;">
            Lista Exclusão Empresas Reservantes
          </span>
          <span style="width:5%">
            <v-btn id="fecharModalContrato" x-small color="red" @click="() => this.$emit('fecharModalListaExclusao')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div style="width: 50%; display: flex; justify-content: center; align-items: center;">
          <v-select
            id="select-empresa-reservante"
            v-model="canalDeVenda"
            style="margin: 0 !important;"
            class="pa-0"
            :items="empresasReservantes"
            item-text="empresareservante"
            item-value="idempresareservante"
            hide-details
            label="Canais de venda"
            return-object
            outlined
          >
          </v-select>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" @click="adicionar">Adicionar</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="listaExclusao"
          class="elevation-1 ma-2"
          no-data-text="Nenhuma empresa reservante encontrado."
        >
          <template v-slot:[`item.acoes`]="{ item, index }">
            <button :id="'buttonEditarVisualizar'+index" class="buttonEditarVisualizar" @click="() => deletar(item)">Deletar</button>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>

 export default {
  name: 'ModalListaExlcusaoEmpresaReservante',
  data: () => ({
    headers: [
      { text: "Id", value: "id" },
      { text: "Empresa Reservante", value: "nome_empresa_reservante" },
      { text: "Ações", value: "acoes" },
    ],
    listaExclusao: [],
    canalDeVenda: null,
    empresasReservantes: []
  }),

  props: ['exibirModalListaExclusaoEmpresaReservante'],

  methods: {
    async buscarListaExclusao() {
      this.$http
        .get("empresareservante/buscar/lista-exclusao",)
        .then((resp) => {
          if (resp.status == 200) {
            console.log(resp.data)
            this.listaExclusao = resp.data;
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch(() => {
          alert('Erro ao processar solicitação, tente novamente mais tarde!');
        });
    },
    async deletar(item) {
      try {
        this.$http
        .post("empresareservante/deletar/empresas-reservantes/lista-exclusao", item)
        .then(() => {
          this.buscarListaExclusao()
        })
      } catch (error) {
        alert('Erro ao processar solicitação, tente novamente mais tarde!');
      }
    },
    async buscarEmpresasReservantes() {
      try {
        this.$http
        .get("empresareservante/selecionar/empresas-reservantes")
        .then((resp) => {
          this.empresasReservantes = resp.data
        })
      } catch (error) {
        alert('Erro ao processar solicitação, tente novamente mais tarde!');
      }
    },
    async adicionar() {
      console.log(this.canalDeVenda)
      if (!this.canalDeVenda) {
        return alert('É obrigatório selecionar um canal de venda!');
      }
      const verificarEmpresaAdicionada = this.listaExclusao.filter((e) => Number(e.id_empresa_reservante) === Number(this.canalDeVenda.idempresareservante));

      if (verificarEmpresaAdicionada?.length) {
        return alert('Empresa reservante já está adicionada na lista de exclusão');
      }

      try {
        this.$http
        .post("empresareservante/adicionar/empresas-reservantes/lista-exclusao", {
          id: this.canalDeVenda.idempresareservante,
          nome: this.canalDeVenda.empresareservante
        })
        .then(() => {
          this.buscarListaExclusao()
        })
      } catch (error) {
        alert('Erro ao processar solicitação, tente novamente mais tarde!');
      }


    }
  },

  async mounted() {
    this.buscarListaExclusao()
    this.buscarEmpresasReservantes()
  }
 }
</script>

<style scoped>
.buttonEditarVisualizar {
  background-color: #2B81D6;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px!important;
}
</style>