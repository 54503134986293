<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <div>
          <v-card class="pa-2">
            <v-toolbar color="light-blue" class="ma-2"
              style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
              <div style="min-width:100%!important;display:flex;justify-content:space-between;">
                <span class="toolbar-title" style="width:80%;padding:10px;"> 
                  Opcionais
                </span> 
                <v-btn @click="novoOpcional()" data-cy="novoOpcional" small class="mr-2" style="color:white;font-weight:bold;background-color:#0e5b7d;height:40px!important;">
                  + Novo Opcional
                </v-btn> 
                <span style="padding:10px;"> 
                  <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Opcionais"/>
                </span>
               </div>
            </v-toolbar>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              data-cy="filtro"
              single-line
              hide-details
            />
            <v-dialog v-model="dialog" max-width="70vw">
              <v-card height="50vh">
                <!-- Conteúdo do Diálogo -->
                <div style="height:80%;">
                  <v-card-text>
                    <template v-if="step==1">
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm6 md4>
                            <v-text-field
                              id="textApelido"
                              v-model="editedItem.descricao"
                              data-cy="editedItem.descricao"
                              label="Descrição"
                            />
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <span data-cy="editedItem.idioma-span">
                              <v-select
                                label="Idioma"
                                v-model="editedItem.idioma"
                                data-cy="editedItem.idioma"
                                :items="idiomas"
                                item-text="descricao"
                                item-value="codigoidioma"
                              ></v-select>
                            </span>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <v-switch
                              :label="editedItem.ativo?'Ativo':'Inativo'"
                              v-model="editedItem.ativo"
                              data-cy="editedItem.ativo"
                              style="border-radius: 5px; padding: 5px"
                            ></v-switch>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </template>
                  </v-card-text>
                </div>
                <!-- Botões -->
                <div style="height:20%;">
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" @click="fecharAtualizacao"
                    data-cy="fecharAtualizacao"
                      >Cancelar</v-btn
                    >
                    <v-btn color="primary" @click="atualizarOpcional"
                    data-cy="atualizarOpcional"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="opcionais"
              :footer-props="footerProps"
              :search="search"
              class="elevation-1"
            >
              <template slot="headerCell" slot-scope="{ header }">
                <span
                  class="subheading font-weight-light text-general text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template v-slot:[`item.ativo`]="{ item }">
                <v-simple-checkbox v-model="item.ativo" disabled>
                </v-simple-checkbox>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <p data-cy="item.id">{{item.id}}</p>
              </template>
              <template v-slot:[`item.descricao`]="{ item }">
                <p data-cy="item.descricao">{{item.descricao}}</p>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="green darken-1"
                  small style="color:white!important;height:30px!important;"
                  @click="editarOpcional(item)"
                  :data-cy="`editarOpcional_${item.id}`"
                  >
                  Editar
                </v-btn>
              </template>
              <template v-slot:items="props">
                <td>{{ props.item.id }}</td>
                <td class="justify-center">
                  <v-icon medium class="mr-2" @click="editarOpcional(props.item)"
                    >edit</v-icon
                  >
                  <v-icon medium @click="deleteItem(props.item)">delete</v-icon>
                </td>
                <td class="">{{ props.item.apelido }}</td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.email"
                    large
                    lazy
                    persistent
                    @save="saveInline"
                    @cancel="cancelInline"
                    @open="openInline"
                    @close="closeInline"
                  >
                    <div>{{ props.item.email }}</div>
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.email"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      />
                    </template>
                  </v-edit-dialog>
                </td>
                <td>
                  <div>{{ props.item.descricao }}</div>
                </td>
                <td class="">{{ props.item.ativo }}</td>
              </template>
            </v-data-table>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;margin-left:10px;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BotaoTutorial from '../material/BotaoTutorial.vue';
export default {
    data: () => ({
        step: 1,
        emailRules: [
            (v) => !!v || "E-mail é obrigatório",
            (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        ],
        snack: false,
        snackColor: "",
        snackText: "",
        max25chars: (v) => v.length <= 25 || "Input too long!",
        pagination: {},
        opcionais: [],
        senha0: "",
        senha1: "",
        checkboxAdmin: true,
        checkboxActive: true,
        rowsAmount: [
            15,
            20,
            25,
        ],
        dialog: false,
        search: "",
        headers: [
            { text: "Id", value: "id" },
            { text: "Actions", value: "actions", sortable: false },
            { text: "Descrição", value: "descricao" },
            { text: "Ativo", value: "ativo" }
        ],
        editedIndex: -1,
        editedItem: {},
        idiomas: [],
        hoteisStr: [],
        defaultItem: {
            id: 0,
            ativo: true,
            apelido: "",
            senha: "",
            email: "",
            nome: "",
            criadopor: 1,
            telefone: "",
            idioma: "pt-BR",
            setores: [],
            acessos: [],
            avatar: null,
            hotel: null
        },
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
    created() {
        this.loadOpcionais();
        this.loadIdiomas();
    },
    methods: {
        novoOpcional() {
            this.senha0 = "";
            this.senha1 = "";
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            this.dialog = true;
        },
        editarOpcional(item, dbox = true) {
            this.editedIndex = this.opcionais.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.senha0 = "";
            this.senha1 = "";
            this.dialog = dbox;
        },
        loadOpcionais() {
            this.$http
                .post("/reserva/solicitacaotipoadm/select")
                .then((response) => {
                this.opcionais = response.data;
            })
                .catch((error) => console.log(error));
        },
        loadIdiomas() {
            this.$http
                .post("/geo/idiomas/suportados")
                .then((response) => {
                this.idiomas = response.data;
            })
                .catch((error) => console.log(error));
        },
        focus(id) {
            var idEl = document.getElementById(id);
            if (idEl)
                idEl.focus();
        },
        validarFormulario() {
            if (!this.editedItem.descricao) {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "Descrição é um Campo Obrigatório";
                return false;
            }
            if (!this.editedItem.idioma) {
                this.snack = true;
                this.snackColor = "error";
                this.snackText = "Idioma é um Campo Obrigatório";
                return false;
            }
            return true;
        },
        atualizarOpcional() {
            if (this.validarFormulario()) {
                var params = {
                    descricao: this.editedItem.descricao,
                    idioma: this.editedItem.idioma,
                    ativo: this.editedItem.ativo
                };
                if (this.editedItem.id > 0) {
                    params.id = this.editedItem.id;
                }
                this.$http
                    .post("/reserva/solicitacaotipo/upinsert", params)
                    .then((response) => {
                    if (response.status == 200) {
                        this.loadOpcionais();
                        this.saveInline();
                        this.fecharAtualizacao();
                    }
                    else {
                        // Verificar se é o caso de Email ou apelido já existente.
                        if (response.status == 206) {
                            this.snack = true;
                            this.snackColor = "error";
                            this.snackText = response.data.message;
                        }
                        else {
                            console.log(JSON.stringify(response));
                        }
                    }
                })
                    .catch(error => {
                    console.log(error);
                    console.log(JSON.stringify(params));
                });
            }
        },
        callTableAction(item, endpoint, method) {
            const index = this.opcionais.indexOf(item);
            let tableItem = this.editedItem;
            this.$store
                .dispatch("updateTableItem", { endpoint, tableItem, method })
                .then((response) => {
                console.log(response);
                this.opcionais.splice(index, 1);
                this.saveInline();
            })
                .catch((error) => {
                console.log(error);
                this.cancelInline;
            });
        },
        deleteItem(item) {
            if (confirm("Are you sure you want to delete this item?")) {
                this.editedItem = Object.assign({}, item);
                let endpoint = `users/delete/${this.editedItem.username}`;
                let method = "delete";
                this.callTableAction(item, endpoint, method);
            }
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        fecharAtualizacao() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        saveInline() {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Registro salvo com Sucesso!";
        },
        cancelInline() {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Operação Cancelada";
        },
        reset() {
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Data reset to default";
        },
        openInline() {
            this.snack = true;
            this.snackColor = "info";
            this.snackText = "Dialog opened";
        },
        errorSave() {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Erro ao salvar usuário";
        },
        closeInline() {
            console.log("Dialog closed");
        },
    },
    components: { BotaoTutorial }
};
</script>
<style>
table.v-table thead tr {
  color: red !important;
}
.snackClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: center;
}
</style>