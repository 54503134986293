


<template>
    

    <v-flex md12 sm12 lg4>
        <material-chart-card
          style="font-size: smaller;"
          :data="data"
          :options="options"
          :color="cor"
          type="Bar"
        >
          <h4>
            {{titulo}}
          </h4>
          <p class="category d-inline-flex font-weight-light my-1">
           <v-icon color="green" small style="margin-right:10px;">{{iconesecundario}}</v-icon>
           <span class="legenda" style="font-weight:bold;">{{titulosecundario}}</span>
          </p>

        </material-chart-card>
    </v-flex>

</template>


<script>
export default {
    data: () => ({
        styleIcone:''
    }),
    props:["cor","icone","titulo","valor","iconesecundario","titulosecundario","tagTitle","data","options"],
    mounted() {
        this.styleIcone = "background-color:"+this.cor+";position:relative;width:80px;height:70px;border-radius:5px;top:-25px!important;display:flex;justify-content:center;"
    }
}
</script>
