<template>
  
    <div class="container">   
        
        <h4 style="text-align:center;">{{ $t('webcheckin.hospedes.cliqueemhospede') }}</h4>
        
        <div class="cardInputs" style="display:flex;flex-direction:column;justify-content:center;align-items:center;" >
            <button  v-on:click="()=>{ item['assinatura']? direcionarwebcheckin(item['id']): direcionarwebcheckin(item['id']) }" data-cy="btn-webcheckin"
                v-for="(item, index) in this.lista_hospedes" :key="index"
                :class="item['assinatura']? 'botaoHospedeFeito' : 'botaoHospede'" style="margin-bottom:15px;margin-left:5px;margin-right:5px;padding:10px;font-size:14px!important;" >
                {{item["nome"]+" "+item["sobrenome"] }}

                 <v-icon v-if="item['assinatura']" color="white" style="margin-bottom:5px;">mdi-check</v-icon>
                
            </button>
        </div > 

        <div class="cardInputsLinhaBetween" style="">
            <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-hospedesvoltar">
                    {{ $t('webcheckin.hospedes.voltar') }}
            </button>

            <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-hospedesavancar">
                    {{ $t('webcheckin.hospedes.avancar') }}
            </button>
        </div> 

    </div>
        
</template>

<script>

// import CONSTANTS from '../constantes'
// import axios from 'axios'

export default {
    props:["dados","dadosAcompanhantes","hospedePrincipal"],
    data: () =>{
        return{
            lista_hospedes:[],
        }
    },
    mounted(){
        console.log("this.dadosAcompanhantes")
        console.log(this.dadosAcompanhantes)
        // this.lista_hospedes = this.dadosAcompanhantes
        this.$http.post("/reserva/select", { idreserva:this.dados.idreserva, principal:false }).then(acompanhantes =>{
            // this.dadosAcompanhantes = acompanhantes.data
            this.lista_hospedes = acompanhantes.data
        })
    },
    methods: {
        direcionarwebcheckin(idreservahospede){
            //  this.$emit('recarregarDadosInicial',idreservahospede)
            window.location.href = window.origin+`/control/webcheckin/${idreservahospede}`
        },
        async avancar(){
            // this.$router.push('/Checkin/Fim')
            this.$emit('direcionar','fim')
        },
        voltar(){
            // this.$emit('direcionar','horario')
            if(this.dados.exibirPassosHorario){
                this.$emit('direcionar','horario')
            }else{
                if(this.dados.exibirPassosObservacao){
                    this.$emit('direcionar','observacoes')
                }else{
                    this.$emit('direcionar','formulariocovid')
                }
            }
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>