<template>
 <div class="wrapper">
      <div class="page">
            <div class="cc-form">
    <div class="wout-login">
        <div class="center">
            <button class="menu-voltar-btn" @click="voltar()">
                <v-icon color="white">mdi-arrow-left</v-icon>
            </button>
            <div class="contentarea">
                <div class="confirmar" style="color:#8C8B99!important;width:80%;max-width:500px;">
                    <span >Por gentileza, retire óculos ou máscara e centralize seu rosto para a foto!</span>
                </div>
                <div class="camera">
                    <video id="video" playsinline>Video stream not available.</video>
                    <button v-show="!previewPhoto" class="continuar" style="width:250px!important;" id="startbutton" @click="takepicture">
                        Tirar Self
                    </button>
                    <div v-show="previewPhoto" class="output">
                        <img id="photo" alt="The screen capture will appear in this box."> 
                       <div v-if="!this.flReconhecendo" style="display:flex;flex-direction:column;align-items:space-between;padding-top:20px;margin-top:20px;;backgroud-color:red;">
                            <button class="continuar" id="startbutton" @click="tirarOutra" style="margin-bottom:20px;width:250px!important;">
                                Tirar Outra
                            </button>
                            <button class="continuar" style="width:250px!important;" id="startbutton" @click="avancar">
                                Continuar
                            </button>
                        </div>
                        <div v-else style="display:flex;flex-direction:column;align-items:space-between;padding-top:20px;margin-top:20px;;backgroud-color:red;">
                            <button class="continuar" style="width:250px!important;" id="startbutton" disabled>
                                Reconhecendo... Aguarde...
                            </button>
                        </div>
                    </div>
                </div>
                <canvas id="canvas">
                </canvas>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
</template>
<script>
import { BUILD_FD_API_URL } from '@/constantes.js'
export default {
    props:["dados"],
    data: () => {
        return {
            flReconhecendo:false,
            streaming: false,
            width: 320,
            height: 240,
            imagePoolSize: 6,
            imagePool: [],
            previewPhoto: false,
            lastFace: {
                imagem: null
            }
        }
    },
    methods: {
        tirarOutra() {
            this.previewPhoto = false
        },
        voltar() {
            this.$emit('direcionar', {from: 'self', to: 'pagamento'})
        },
        avancar() {
            //this.$emit('registrarPagamento', this.dados)
            this.compararFaces()
        },
        showViewLiveResultButton() {
            if (window.self !== window.top) {
                // Ensure that if our document is in a frame, we get the user
                // to first open it in its own tab or window. Otherwise, it
                // won’t be able to request permission for camera access.
                document.querySelector(".contentarea").remove();
                const button = document.createElement("button");
                button.textContent = "View live result of the example code above";
                document.body.append(button);
                button.addEventListener('click', () => window.open(location.href));
                return true;
            }
            return false;
        },
        clearphoto() {
            var canvas = document.getElementById('canvas');
            var photo = document.getElementById('photo');
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);
            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
        },
        compararFaces() {
            this.flReconhecendo = true
            const baseURL = BUILD_FD_API_URL
            const params = {
                "idreservahospede" : this.dados.id,
                "dados" : this.lastFace.imagem,
                "tipo": "image/png",
                "nome": "imgcheckout_" + this.dados.id
            }

            console.error('Comparando com documentos...')
            // this.$http.post(`${baseURL}/checkinpay/compararfaces`, params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
            this.$http.post(`${baseURL}/checkinpay/compararfaces`, params, { headers: {'x-access-token':this.$route.params.token}})
            .then(async resp => {
                if (resp.status == 200) {
                    console.log(resp.data)
                    let objResponse = resp.data
                    if (objResponse && objResponse.FaceMatches && objResponse.FaceMatches.length > 0) {
                        // Similaridade (Rekognition) ou Distância (DeepFace) ?
                        let simiDist = `${objResponse.FaceMatches[0]['Similarity']?'Similarity: '+objResponse.FaceMatches[0]['Similarity']:'Distance: '+objResponse.Result.distance}`
                        console.log('OK - Matches: '+objResponse.FaceMatches.length+' - '+simiDist+' - id: '+this.lastFace.idreservahospede)
                        
                        // Para testar o reconhecimneto facial...
                        // TODO: Para código em produção, descomentar a linha abaixo
                        // this.$emit('pagamento', this.dados.dadoscartao)
                        this.flReconhecendo = false
                        this.$emit('direcionar', {to: 'pagamento'})

                    } else {
                        this.flReconhecendo = false
                        this.$emit('direcionar', {from: 'self', to: 'falhareconhecimento'})
                    }
                }
            }).catch(error =>{ console.log(error) })            
        },
        holdLastFace(e) {
            this.lastFace.imagem = e.src
        },
        // Captura a foto da câmera
        takepicture() {
            let photo = document.getElementById('photo');
            let video = document.getElementById('video');
            let canvas = document.getElementById('canvas');
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(video, 0, 0, this.width, this.height);
                var data = canvas.toDataURL('image/png');
                photo.onload = () => {
                    this.holdLastFace(photo)
                }
                photo.setAttribute('src', data);
            } else {
                this.clearphoto();
            }
            this.previewPhoto = true
        },
        startup() {
            if (this.showViewLiveResultButton()) { return; }
            let video = document.getElementById('video');
            let photo = document.getElementById('photo');
            let canvas = document.getElementById('canvas');
            
            navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(function(stream) {
                video.srcObject = stream;
                video.play();
            })
            .catch(function(err) {
                console.log("An error occurred: " + err);
            });
            // O 'this' dentro da função abaixo não se refere à instância Vue
            // Assim, é preciso guardar sua referência...
            const este = this
            video.addEventListener('canplay', function() {
                if (!este.streaming) {
                    este.height = video.videoHeight / (video.videoWidth/este.width);
                    // Bug do Firefox
                    if (isNaN(este.height)) {
                        este.height = este.width / (4/3);
                    }
                    video.setAttribute('width', este.width);
                    video.setAttribute('height', este.height);
                    canvas.setAttribute('width', este.width);
                    canvas.setAttribute('height', este.height);
                    photo.setAttribute('width', este.width);
                    photo.setAttribute('height', este.height);
                    este.streaming = true;
                }
            }, false);
            
            this.clearphoto();
        }        
    },
    mounted() {
        window.scrollTo(0, 0);
        this.startup()
    }
}
</script>

<style lang="scss" scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    font-weight: normal;
    color: white!important;
    margin-bottom: 1.5rem;
    width: 90vw;
    align-self: center;
}

.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

#video {
  /* border: 1px solid white;
  box-shadow: 0px 0px 30px 20px blue;
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: relative; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:250px;
  height:250px;
  border-radius: 50%;
  position: relative;
}

#photo {
  /* border: 1px solid black;
  box-shadow: 0px 0px 30px 20px blue;
  width: 90vw;
  height: auto;
  border-radius: 50%; */
  border: 1px solid white;
  box-shadow: 0px 0px 2px 2px blue;
  width:300px;
  height:300px;
  border-radius: 50%;
  margin-top:-50px;

}

#canvas {
  display:none;
}

.camera {
    position: relative;
    width: 100%;
    height:50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.output {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 90vw;
    display:inline-block;
    vertical-align: top; */
    position: absolute;
    top:10px;
    width: 70vh;
    height:70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#startbutton {
  display:block;
  position:relative;
  margin-left:auto;
  margin-right:auto;
  bottom:32px;
}

.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 30vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 25px;
    padding: 0.5rem;
}

.contentarea {
    display: flex;
    flex-direction: column;
    align-content: center;
    /*width: 760px;*/
}










.wrapper{
    max-width: 30vw;
    margin: 0 auto;
    @media screen and (max-width: 768px){
        max-width: 100vw;
    }
}
.page{
  background: #EBEBF5;
  height: 100vh;
  overflow: hidden;
}

.card-wrapper{
  height: auto;
  background: #1760e5;
  border-radius: 0 0 2em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1em 2em 1em;

  .card{
    width: auto;
    height: 130px;
    border-radius: .7125em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1000;
    img{
        width: auto;
        height: 130px;
        @media screen and (min-width: 768px) {
            width: auto;
            height: 130px;
        }
    }
    
  }
}

.form{
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    font-size: 18px !important;
    color: #8C8B99;
    font-weight: bold;
    letter-spacing: 0.02em;
    margin-bottom: 1em;
  }
  padding: 1em;
}

  .field{
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #F2F2F2;
    width: 100%;
    padding: .5em;
    margin-bottom: .5em;

    &.half{
      width: 48%;
    }

    &.submit-btn{
      margin: 0 auto;
      width: 90%;
      height: 3em;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p{
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-left: .5em;

        >span{
          color: #2DA63F;
        }
      }
    }

    label{
      color: #8C8B99;
    }
    input{
      outline: none;
    }
  }
//css-indesejados

  .money-icon{
    background: #2DA63F;
    border-radius: 50%;
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-icon{
    width: 2em; 
    height: 2em; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
.v-application p{
  margin-bottom: unset;
}

.mdi-menu{
  display: flex !important;
  align-items: flex-start !important;
}
.cc-center {
  color: #70708C;
  text-align: center;
  margin: 0px;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}
.cc-form {
  display: flex;
  flex-direction: column;
}
.cc-row {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: center;
}
.cc-col-6 {
  width: 50%;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.cc-col-12 {
  width: 100%;
}
.sim-btn, .nao-btn {
    display: flex;
    align-items: center;
    padding: 10px;
}
label {
    color: #70708C;
}
</style>