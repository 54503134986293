<template>
  <v-dialog
    v-model="exibirModalNotificacoesCheckinpay"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Notificações Email
          </span>
          <span style="width: 5%">
            <v-btn
              id="fecharModalContrato"
              x-small
              color="red"
              @click="
                () =>
                  this.sistema === 'checkout'
                    ? this.$emit('setExibirModalNotificacaoEmailsCheckout')
                    : this.$emit('setExibirModalNotificacoesCheckinpay')
              "
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div>
          <h2>Defina quais email receberão os alertas</h2>

          <div>
            <div
              v-for="(email, index) in configComandas.emailsReceber"
              :key="email"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-text-field
                v-model="email.email"
                label="Email"
                outlined
                dense
                class="mt-4"
              ></v-text-field>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 1em;
                  background-color: red;
                  color: white;
                  width: 2em;
                  height: 2em;
                  border-radius: 50%;
                  cursor: pointer;
                "
                @click="() => removerEmail(index)"
              >
                <v-icon color="white">mdi-delete</v-icon>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <v-btn
              color="primary"
              @click="() => salvarConfiguracoesNotificacoes()"
            >
              Salvar
            </v-btn>

            <v-btn color="success" @click="() => adicionarEmail()">
              Adicionar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "exibirModalNotificacoesCheckinpay",
    "sistema",
    "exibirModalNotificacaoEmailCheckout",
  ],
  async mounted() {
    this.carregando = true;
    await this.getConfiguracoesNotificacoes();
    this.carregando = false;
  },
  watch: {},
  data: () => ({
    carregando: true,
    configEmail: {
      host: "",
      port: "",
      user: "",
      pass: "",
      replyto: "",
      nametoexibition: "",
      chave: null,
    },
    configComandas: {
      emailsReceber: [],
    },
  }),
  methods: {
    async getConfiguracoesNotificacoes() {
      this.carregando = true;
      (this.chave =
        this.sistema === "checkout"
          ? "EMAILS_RECEBER_NOTIFICACOES_CHECKOUT"
          : "EMAILS_RECEBER_NOTIFICACOES_CHECKINPAY"),
        await this.$http
          .post("configuracao/buscarParametro", {
            idhotel: localStorage.getItem("hotel"),
            chave: this.chave,
          })
          .then((res) => {
            this.carregando = false;
            this.configComandas.emailsReceber =
              JSON.parse(res.data[0].valor) || [];
          })
          .catch((err) => {
            alert("Erro ao buscar emails");
            console.log(err);
          });
    },
    async salvarConfiguracoesNotificacoes() {
      this.carregando = true;

      const parametro = {
        idhotel: localStorage.getItem("hotel"),
        chave: this.chave,
        valor: JSON.stringify(this.configComandas.emailsReceber),
      };

      await this.$http
        .post("configuracao/atualizarParametro", parametro)
        .then(() => {
          alert("Emails atualizados com sucesso");
          this.carregando = false;
        })
        .catch(() => {
          alert(
            "Erro ao atualizar emails que receberao alertas de comandas não integradas"
          );
        });
    },
    removerEmail(index) {
      this.configComandas.emailsReceber.splice(index, 1);
    },
    adicionarEmail() {
      this.configComandas.emailsReceber.push({
        email: "",
      });
    },
  },
};
</script>
