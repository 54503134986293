<template>
  <v-card> 
      <v-toolbar color="blue" style="width:100%;">
          <span style="width:95%">Escolha o tipo de documento</span>
          <span style="width:5%"> 
              <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
                  <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
          </span>
      </v-toolbar>
      <div class="captura_container" >
              <a @click="setarPasso('cartao')" class="tipos" >Cartão de crédito/débito</a>
              <a @click="setarPasso('comprovante')" class="tipos" >Comprovante de residência</a>
              <a @click="setarPasso('documento')" class="tipos" >Documento com foto</a>
      </div>
  </v-card>
</template>

<script>
export default {
data: () => ({
  image: ''
}),
methods: {
  setarPasso(valor){
      this.$emit('setarPasso',valor)
  },
},
mounted() {
 
},
};
</script>

<style scoped>
  *{
      box-sizing: border-box;
      margin:0px;
      padding:0px;
  }

  h1{
      font-family:Arial, Helvetica, sans-serif;
      color:#808080;
      margin-bottom:10px;
  }

  .imgExemplo{
      width:200px;
      max-width:90vw;
  }

  .imgUpload{
      width:400px;
      max-width:90vw;
      margin-top:20px;
  }

  .captura_container{
      background-color:white;
      display:flex;
      flex-direction: column;
      align-items: center;
      font-family:Arial, Helvetica, sans-serif;
      padding: 10px;
  }

  .captura_container_tipos{
      /* background-color:red; */
      /* width:80vw; */
      /* height:60vh; */
      padding-left:5%;
      display:flex;
      flex-direction: row;
      flex-wrap:wrap;
      align-items:flex-start;
      justify-content:flex-start;
  }

  .captura_container_doc{
      /* background-color:red; */
      width:80vw;
      /* height:60vh; */
      padding-left:5%;
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center;
  }

  .tipos{
      display: flex;
      justify-content: center;
      align-items:center;
      background-color:#1d4596;
      width:600px;
      max-width:90vw;
      height:70px;
      border-radius:5px;
      margin-right:10px;
      margin-bottom:10px;
      /* padding-top:15px; */
      
      text-align:center;
      color:white;
      font-weight:bold;
      font-family:Arial, Helvetica, sans-serif;
  }

  .botaoRemover{
      background-color:red;
      height:50px!important;
      padding: 0px 10px 0px 10px;
      border-radius:5px;
      color:white;
      font-weight:bold;
      margin-top:10px;
  }
</style>