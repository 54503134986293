<template>
    <v-dialog v-model="exibirModalConfigGerais" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Configurações gerais
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModal()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcartaoDiferenteHospedeTitular" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Permitir Pagamento com Cartão de hospedes diferente do titular?</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flCapturaFotoCartao" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Capturar foto frente e verso do cartão?</label>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flCapturaComprovanteEndereco" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Capturar foto do comprovante de endereço?</label>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flCapturaFotoDocumento" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Permitir reconhecimento facial?</label>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcompararEndereco" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Comparar dados do comprovante de endereço com os dados informados como pagador?</label>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcompararDocumento" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Comparar dados do documento upado com os dados informados como pagador?</label>
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcompararCartao" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Comparar dados do cartão com os dados informados como pagador?</label>
                    </div>

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        checarpelomensageiro:false,
        ativarcontestacao:false,
        umdia:false,
        diariamente:false,
        cincodias:false,
        dezdias:false,
        trintadias:false,
        textobotaosalvar:"SALVAR",
        flcartaoDiferenteHospedeTitular: false,
        flCapturaFotoCartao: false,
        flCapturaComprovanteEndereco: false,
        flCapturaFotoDocumento: false,
        flcompararEndereco: false,
        flcompararDocumento: false,
        flcompararCartao: false,
    }),
    props:["exibirModalConfigGerais"],
    components:{
        // ModalSolicitacoesResponder

    },
    methods: {
    
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarConfig().then(()=>{
                    this.setModal()
            })
            
        },
      
        async salvarConfig(){
            await this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'CONFIGGERALCAPTURASCHECKOUT',
                    "valor" :  this.flcartaoDiferenteHospedeTitular ? 'true' : 'false',
                    "valor2" : this.flCapturaFotoCartao ? 'true' : 'false',
                    "valor3" : this.flCapturaComprovanteEndereco ? 'true' : 'false',
                    "valor4" : this.flCapturaFotoDocumento ? 'true' : 'false',
                    "valor5": localStorage.getItem('userid'),
                    "valor6": localStorage.getItem('user'),
            })

            await this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'CONFIGCOMPARACAOCHECKOUT',
                    "valor" :  this.flcompararEndereco ? 'true' : 'false',
                    "valor2" : this.flcompararDocumento ? 'true' : 'false',
                    "valor3" : this.flcompararCartao ? 'true' : 'false',
                    "valor4": localStorage.getItem('userid'),
                    "valor5": localStorage.getItem('user'),
            })
        },
        setModal(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setExibirModalConfigGerais')
        },
    },
    mounted() {
        this.$http.post("configuracao/buscarParametro", {  
                        "idhotel" : 1,
                        "chave" : 'CONFIGGERALCAPTURASCHECKOUT'
                }).then(resp =>{
                    if(resp.status == 200) {
                        this.flcartaoDiferenteHospedeTitular = resp.data[0].valor === 'true' ? true : false
                        this.flCapturaFotoCartao = resp.data[0].valor2 === 'true' ? true : false
                        this.flCapturaComprovanteEndereco = resp.data[0].valor3 === 'true' ? true : false
                        this.flCapturaFotoDocumento = resp.data[0].valor4 === 'true' ? true : false
                    } else{
                        alert("Não foi possivel buscar os dados")
                    }
                })
                .catch(error =>{
                    console.log(error)
                })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : 1,
                "chave" : 'CONFIGCOMPARACAOCHECKOUT'
        }).then(resp =>{
            if(resp.status == 200) {
                this.flcompararEndereco = resp.data[0].valor === 'true' ? true : false
                this.flcompararDocumento = resp.data[0].valor2 === 'true' ? true : false
                this.flcompararCartao = resp.data[0].valor3 === 'true' ? true : false
            } else{
                alert("Não foi possivel buscar os dados")
            }
        })
        .catch(error =>{
            console.log(error)
        })
    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>