<template>
    <v-dialog v-model="exibirModalDebCredPix" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Credito | Débito | Pix
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalDebCredPix()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcartaocredito" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Permitir Pagamento com Cartão de Credito?</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                        <label>Permitido até quantas parcelas?</label>   
                        <input type="number" step="1" class="inputs" v-model="numparcelas" placeholder="Quantidade de Parcelas" />
                    </div>
                    <br>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flcartaodebito" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Permitir Pagamento com Cartão de Débito?</label>    
                    </div>

                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="flpix" style="border-radius:5px;padding:2px;"></v-switch>
                        <label>Permitir Pagamento com Pix?</label>    
                    </div>
                    <div style="display:flex;">
                        <div style="margin-right:10px;font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                            <label>Tipo Chave PIX:</label>   
                            <input type="text"  class="inputs" v-model="pixtipochave" placeholder="CPF, EMAIL, TELEFONE" />
                        </div>
                        <div style="margin-right:10px;font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                            <label>Valor Chave PIX:</label>   
                            <input type="text"  class="inputs" v-model="pixvalorchave" placeholder="Valor da Chave" />
                        </div>
                        <div style="margin-right:10px;font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >
                            <label>Nome Exibido na Conta para Conferência:</label>   
                            <input type="text" class="inputs" style="width:300px!important;" v-model="pixnomeexibido" placeholder="Nome Exibido" />
                        </div>
                    </div>

                    
                    

                    

                     

                    <div style="display:flex;justify-content:flex-end;margin-top:50px;">
                        <v-btn @click="salvar()" color="primary">
                            {{textobotaosalvar}}
                        </v-btn>
                    </div>

            </v-card-text>
            
                        
            
        </v-card>
    </v-dialog>
</template>          

<script>



export default {
    data: () => ({
        textobotaosalvar:'Salvar',
        flcartaocredito:false,
        numparcelas:1,
        flcartaodebito:false,
        flpix:false,
        pixtipochave:'',
        pixvalorchave:'',
        pixnomeexibido:''

    }),
    props:["exibirModalDebCredPix"],
    components:{
        // ModalSolicitacoesResponder

    },
    methods: {
    
        salvar(){
            this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOWEBCHECKOUT().then(()=>{
                    this.setModalDebCredPix()
            })
            
        },
      
        salvarENVIOAUTOWEBCHECKOUT(){
            return new Promise( (resolve,reject) =>{

                this.$http.post("configuracao/atualizarParametro", {  
                    "idhotel" : localStorage.getItem('hotel'),
                    "chave" : 'DEBCREDCONFIG',
                    "valor" :  this.flcartaocredito ? 'true' : 'false',
                    "valor2" : this.numparcelas,
                    "valor3" : this.flcartaodebito ? 'true' : 'false'
                }).then(() =>{

                    this.$http.post("configuracao/atualizarParametro", {  
                            "idhotel" : localStorage.getItem('hotel'),
                            "chave" : 'PIXCONFIG',
                            "valor" :  this.flpix ? 'true' : 'false',
                            "valor2" : this.pixtipochave,
                            "valor3" : this.pixvalorchave,
                            "valor4" : this.pixnomeexibido
                        }).then(resp =>{
                            resolve(resp)
                        })
                        .catch(error =>{
                            reject(error)
                            console.log(error)
                        })
                })
                .catch(error =>{
                    reject(error)
                    console.log(error)
                })
            })
        },
        setModalDebCredPix(){
            this.textobotaosalvar = "SALVAR"
            this.$emit('setModalDebCredPix')
        },
    },
    mounted() {

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'DEBCREDCONFIG'
            }).then(resp =>{
                if(resp.status == 200){

                    this.flcartaocredito = resp.data[0].valor === 'true' ? true : false
                    this.numparcelas = resp.data[0].valor2
                    this.flcartaodebito = resp.data[0].valor3 === 'true' ? true : false

                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

        this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'PIXCONFIG'
            }).then(resp =>{
                if(resp.status == 200){

                    this.flpix = resp.data[0].valor === 'true' ? true : false
                    this.pixtipochave = resp.data[0].valor2
                    this.pixvalorchave = resp.data[0].valor3
                    this.pixnomeexibido = resp.data[0].valor4

                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })

    },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

  .inputs{
    background-color:white;
    border-radius:15px;
    min-height:8vh;
    padding:5px 20px 5px 20px;
    border: 1px solid #819ab4;
  }

  .cardInputs{
    display:flex;
    flex-direction:column;
    justify-content:left;
    width:100%;
    margin-bottom:20px;
    /* background-color:red; */
    
  }

  
  .inputsHorario{
    background-color:white;
    text-align:center;
    border-radius:15px;
    min-height:8vh;
    font-size:25px;
    padding:10px;
    /* padding:5px 20px 5px 20px; */
    border: 1px solid #819ab4;
    width:100px; 
    margin-right: 20px;
    margin-top:10px;    
    
  }
</style>