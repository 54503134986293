<template>
    <v-dialog v-model="exibirModalEditarEnvioEmail" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-1">
                <v-flex  style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Editar Formulario Envio Email Check-in Pay
                    </span>
                    <span style="width:5%">
                        <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Formulario Envio Email Check-in Pay"/>
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalEditarEnvioEmail()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>

                <!-- <v-card> -->

                    <div style="font-size: 16px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;" >

                      <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch v-model="enviarwebcheckinpay" data-cy="nodia" style="border-radius:5px;padding:2px;" id="check-liberar"></v-switch>
                        <label>Envio de Voucher do Checkin Pay Liberado?</label>
                        <div style="padding-left:1.5rem;" v-if="enviarwebcheckinpay">
                            <label>Apenas para este endereço de email (teste):</label>
                            <v-text-field dense v-model="emailwebcheckinpay"></v-text-field>
                        </div>
                    </div>

<v-switch
        v-model="nodia"
        label="No dia o cadastro da reserva" id="check-liberar-0" />
      <v-switch
        v-model="trintadias"
        label="30 dias antes do checkin" id="check-liberar-30" />
      <v-switch
        v-model="dezdias"
        label="10 dias antes do checkin" id="check-liberar-10" />
      <v-switch
        v-model="cincodias"
        label="5 dias antes do checkin" id="check-liberar-5" />
      <v-switch
        v-model="doisdias"
        label="2 dias antes do checkin" id="check-liberar-2" />
      <v-switch
        v-model="umdia"
        label="1 dia antes do checkin" id="check-liberar-1" />
                        
              </div>

                    <!-- <div style="display:flex;justify-content:center;padding-bottom:10px;">
                        <textarea v-model="textoEmail"  style="width:100%;min-height:200px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                        </textarea>
                    </div> -->
                    <div style="display:flex;justify-content:flex-end;margin-top:5px;">
                        <v-btn @click="salvar()" color="primary" id="btn-config-salvar-envio-auto">
                            Salvar
                        </v-btn>
                    </div>
                <!-- </v-card> -->

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>


import ConfigServico from '@/servicos/config.servico.js'
import BotaoTutorial from '../../material/BotaoTutorial.vue';

export default {
    data() {
        return {
            diaimportacao: null,
            nodia: false,
            umdia: false,
            doisdias: false,
            cincodias: false,
            dezdias: false,
            trintadias: false,
            idusuario: null,
            exibirModalEnvioEmail: null,
            enviarwebcheckinpay: null,
            emailwebcheckinpay: null,
        };
    },
    props: ["exibirModalEditarEnvioEmail"],
    methods: {
        salvar() {
            // this.textobotaosalvar = "SALVANDO..."
            this.salvarENVIOAUTOCHECKINPAY().then(() => {
                this.salvarENVIOAUTOCHECKINPAY1().then(() => {
                    this.salvarENVIOAUTOCHECKINPAY2().then(() => {
                        this.setModalEditarEnvioEmail();
                    });
                });
            });
        },
        salvarENVIOAUTOCHECKINPAY() {
            return new Promise((resolve, reject) => {
                this.$http.post("configuracao/atualizarParametro", {
                    "idhotel": localStorage.getItem("hotel"),
                    "chave": "ENVIOAUTOCHECKINPAY",
                    "valor": this.nodia ? "true" : "false",
                    "valor2": this.umdia ? "true" : "false",
                    "valor3": this.doisdias ? "true" : "false",
                }).then(resp => {
                    resolve(resp);
                })
                    .catch(error => {
                    reject(error);
                    console.log(error);
                });
            });
        },
        salvarENVIOAUTOCHECKINPAY1() {
            return new Promise((resolve, reject) => {
                this.$http.post("configuracao/atualizarParametro", {
                    "idhotel": localStorage.getItem("hotel"),
                    "chave": "ENVIOAUTOCHECKINPAY2",
                    "valor": this.cincodias ? "true" : "false",
                    "valor2": this.dezdias ? "true" : "false",
                    "valor3": this.trintadias ? "true" : "false",
                }).then(resp => {
                    resolve(resp);
                })
                    .catch(error => {
                    reject(error);
                    console.log(error);
                });
            });
        },
        salvarENVIOAUTOCHECKINPAY2() {
            return new Promise((resolve, reject) => {
                this.$http.post("configuracao/atualizarParametro", {
                    "idhotel": localStorage.getItem("hotel"),
                    "chave": "ENVIOAUTOCHECKINPAYLIBERADO",
                    "valor": this.enviarwebcheckinpay ? "true" : "false",
                    "valor2": this.enviarwebcheckinpay ? this.emailwebcheckinpay : ""
                }).then(resp => {
                    console.error("salvarENVIOAUTOCHECKINPAY1");
                    resolve(resp);
                })
                    .catch(error => {
                    reject(error);
                    console.log(error);
                });
            });
        },
        submeter(e) {
            e.preventDefault();
            let dadosusuario = {
                idusuario: this.idusuario,
                diaimportacao: this.diaimportacao,
                trintadias: this.trintadias,
                vintedias: this.vintedias,
                dezdias: this.dezdias,
                cincodias: this.cincodias,
                doisdias: this.doisdias,
                umdia: this.umdia,
            };
            ConfigServico.configurarEnvioEmail(dadosusuario)
                .then(resp => {
                console.log("resp", resp);
                if (resp.status === 200) {
                    this.setModalEditarEnvioEmail();
                }
            })
                .catch(erro => {
                console.log(erro);
                alert("erro configuracao de envio de emails");
            });
        },
        async requisitarConfiguracao(id) {
            return await ConfigServico.recuperarDadosConfiguracaoEmail(id).then(res => res.data.data);
        },
        setModalEditarEnvioEmail() {
            this.$emit("setModalEditarEnvioEmail");
        }
    },
    async mounted() {
        // this.idusuario = parseInt(localStorage.getItem('idusuario'))
        // const config = await this.requisitarConfiguracao(this.idusuario)
        // this.diaimportacao = config.diaimportacao
        // this.trintadias = config.trintadias
        // this.vintedias = config.vintedias
        // this.dezdias = config.dezdias
        // this.cincodias = config.cincodias
        // this.doisdias = config.doisdias
        // this.umdia = config.umdia
        this.$http.post("configuracao/buscarParametro", {
            "idhotel": localStorage.getItem("hotel"),
            "chave": "ENVIOAUTOCHECKINPAY"
        }).then(resp => {
            if (resp.status == 200) {
                this.nodia = resp.data[0].valor === "true" ? true : false;
                this.umdia = resp.data[0].valor2 === "true" ? true : false;
                this.doisdias = resp.data[0].valor3 === "true" ? true : false;
            }
            else {
                alert("Não foi possivel buscar os dados");
            }
        })
            .catch(error => {
            console.log(error);
        });
        this.$http.post("configuracao/buscarParametro", {
            "idhotel": localStorage.getItem("hotel"),
            "chave": "ENVIOAUTOCHECKINPAY2"
        }).then(resp => {
            if (resp.status == 200) {
                this.cincodias = resp.data[0].valor === "true" ? true : false;
                this.dezdias = resp.data[0].valor2 === "true" ? true : false;
                this.trintadias = resp.data[0].valor3 === "true" ? true : false;
                // alert(this.cincodias)
            }
            else {
                alert("Não foi possivel buscar os dados");
            }
        })
            .catch(error => {
            console.log(error);
        });
        this.$http.post("configuracao/buscarParametro", {
            "idhotel": localStorage.getItem("hotel"),
            "chave": "ENVIOAUTOCHECKINPAYLIBERADO"
        }).then(resp => {
            if (resp.status == 200) {
                if (resp.data.length > 0) {
                    this.enviarwebcheckinpay = resp.data[0].valor === "true" ? true : false;
                    this.emailwebcheckinpay = resp.data[0].valor2;
                }
            }
            else {
                alert("Não foi possivel buscar os dados");
            }
        })
            .catch(error => {
            console.log(error);
        });
    },
    components: { BotaoTutorial }
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
    height:200px;
}

</style>