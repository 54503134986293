<template>
  <div class="wout-login">
        <div class="center">
          <img style="width:95%; max-width: 300px;" :src="'data:image.jpeg;base64,' + dados" />
            <span class="confirmar">Seu documento está com boa qualidade?</span>
            <button class="continuar" @click="() => verificar(true)">
                <span>Sim</span>
                <v-icon large class="icone-continuar">mdi-chevron-right</v-icon>
            </button>
            <button class="continuar"  @click="() => verificar(false)">
                <span>Enviar outra foto</span>
                <v-icon large class="icone-continuar">mdi-chevron-right</v-icon>
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['dados'],
    data() {
      return {

      }
    },
    methods: {
      verificar(e) {
        this.$emit('verificar', e);
      }
    }
  }
</script>

<style scoped>
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 15px;
    margin-bottom: 20px;
}
.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}
</style>