<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <Header :nome="dados.nome" :uh="dados.uh" :numeroreserva="dados.numeroreserva"
            @voltar="voltar()" @logout="logout()"
        ></Header>
        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
            <div class="wout-cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
                <div class="wout-row-center" style="margin-top:20px;">
                    <div class="wout-campo-saldo">
                        <div class="wout-saldo">-R$ {{dados.saldo | formatarMoeda}}</div>
                        <div class="wout-label-saldo"><b>Saldo em aberto</b></div>
                    </div>
                </div>
            </div>


            <div class="wout-toggler" @click="exibirDetalhes=!exibirDetalhes">Acessar Consumo Individual 
                <v-icon>mdi-chevron-down</v-icon></div>
            <!-- Individual -->
            <div v-if="exibirDetalhes">
                <div class="wout-scrollable">
                    <div class="wout-cartao-item" v-for="(hospede, index) in dados.hospedes" :key="index">
                        <div class="wout-row" style="cursor:pointer;"
                            @click="exibirConsumoIndividual(hospede)"
                        >
                            <div style="width:70%;padding:0px;padding-left:0.5rem;">
                                <div>{{hospede.nome}} {{hospede.sobrenome}}</div>
                                <div class="green-label">Conferir extrato</div>
                            </div>
                            <div style="width:30%;text-align:right;padding:0px;padding-right:0.5rem;align-self:center;">
                                <span class="wout-saldo-individual">-R$ {{hospede.saldoaberto | formatarMoeda}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pagar Total -->
            <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="wout-pagar-btn" :disabled="dados.itenscontestados > 0 || dados.saldo <= 0.0 || dados.checagens > 0 || checagemenviada > 0"
                    v-on:click="pagar()"
                    id="start_button"
                >
                    <span class="wout-sinal-moeda">$</span>
                    Efetuar Pagamento <span class="green--text">Total</span>
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
            <div class="wout-alert" v-if="dados.checagens > 0">Aguardando checagem do frigobar!</div>
            <div class="wout-toggler" style="display:flex;justify-content:center;padding-top:2vh;"
                v-if="dados.itenscontestados > 0">
                Há ítens de consumo em contestação.
            </div>
            <!-- Consumo Frigobar -->
            <div style="display:flex;justify-content:center;padding-top:2vh;">
                <button class="wout-pagar-btn" @click="consumoFrigobar()" v-if="!dados.checarFrigobar">
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Incluir Consumo do Frigobar
                    <v-icon>mdi-arrow-right</v-icon>
                </button>

                <button :disabled="dados.checagens > 0 || checagemenviada > 0" class="wout-pagar-btn" @click="solicitarChecagemFrigobar()" v-else>
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Solicitar Checagem Frigobar
                    <v-icon>mdi-arrow-right</v-icon>
                </button>

            </div>
        </div>

        <ModalConfirmacao :showModal="exibirModalTipoPagamento" :titulo="'Como deseja Pagar?'" :exibeBtnCancelar="true"
        textosim="Crédito" textonao="Débito" textoCancelar="Pix" 
            @sim="()=>setTipoPagamento(1)" @nao="()=>setTipoPagamento(2)"
            @fechar="exibirModalTipoPagamento=false"    @cancelar="() =>setTipoPagamento(3)"  />
    </div>
</template>

<script>

// import PagamentosConfirmar from '@/components/DashViews/PagamentosConfirmar.vue';
import ModalConfirmacao from '@/components/DashViews/Modals/ModalConfirmacao.vue'
import { BUILD_TIPO_INTEGRACAO } from '../../constantes';
import Header from '@/components/webcheckout/Header'
export default {  
    name: "Inicio",
    components: { ModalConfirmacao, Header },  
    props:['dados'],
    data: () =>{
        return{
            mostrarCarregando:false,
            exibirDetalhes: true,
            checagemenviada:0,
            cobrartaxaservico:null,
            pctaxaservico:null,
            cobrartaxaturismo:null,
            pctaxaturismo:null,
            exibirModalTipoPagamento:false,
            tipoPagamento:1 // 1:Credito, 2:Debito, 3:Pix
        }
    },
    watch: {
        dados(val) {
            console.log('dados.val ',JSON.stringify(val,null,2))
            this.dados = val;
        },
    },
    methods: {
        async reload(){

            this.$socket.disconnect();
            this.$socket.io.opts.query.usuario = localStorage.getItem('checkout-email')
            this.$socket.io.opts.query.tipo = 'WEBCHECKOUT'
            this.$socket.io.opts.query.tipo_acesso = 'WEBCHECKOUT_'+localStorage.getItem('checkout-email')
            this.$socket.connect();

                if(BUILD_TIPO_INTEGRACAO === 'HITS'){

                        let params = {
                            idhotel:localStorage.getItem('checkout-hotel'),
                            uh:localStorage.getItem('checkout-uh'),
                            numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                            idreservahospede:localStorage.getItem('checkout-idreservahospede')
                        }
                        await this.$http.post('/webcheckout/importarConsumosCheckoutApi', params,{ headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                                .then(() =>{
                                    this.mostrarCarregando=false
                                    this.carregarDadosInicio({to:'inicio', from:'inicio'})
                                }).catch(()=>{
                                    this.mostrarCarregando=false
                                })

                }else{


                    this.$socket.emit("webckout_back_importarConsumo", {
                        idhotel:localStorage.getItem('checkout-hotel'),
                        numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                        usuario:localStorage.getItem('checkout-email'),
                        idreservahospede:localStorage.getItem('checkout-idreservahospede'),
                        uh:localStorage.getItem('checkout-uh')
                    });

                }
        },
        logout(){
            this.$emit('direcionar', {to:'login', from:'inicio'})
        },
        setTipoPagamento(tipo){
            this.tipoPagamento = tipo
            this.exibirModalTipoPagamento = false
            localStorage.setItem('tipoPgto',tipo)
            // this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            if(tipo != 3){
                this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            }else{
                this.$emit('direcionar', {dados: this.dados, to:'dadospix', from:'prepagamento'})
            }
        },
        voltar() {
            this.$emit('direcionar', {to:'login', from:'inicio'})
        },
        exibirConsumoIndividual(hospede) {
            
            hospede.checagens = parseInt(this.dados.checagens) + parseInt(this.checagemenviada)
            this.$emit('direcionar', {to: 'individual', from:'inicio', dados: hospede})
        },
        pagar() {
            // this.$emit('direcionar', {dados: this.dados, to: 'prepagamento', from: 'inicio'})
            if(this.cobrartaxaservico || this.cobrartaxaturismo){
                this.$emit('direcionar', {dados: this.dados, to: 'prepagamento', from: 'individual'})
            }else{
                this.exibirModalTipoPagamento = true
                // this.$emit('direcionar', {dados: this.dados, to:'pagamento', from:'prepagamento'})
            }
        },
        consumoFrigobar() {
            this.$emit('direcionar', {dados: this.dados, to: 'consumofrigobar', from: 'inicio'})
        },
        solicitarChecagemFrigobar() {
            let params = {
                idhotel:localStorage.getItem('hotel'),
                numeroreserva:localStorage.getItem('checkout-numeroreserva'),
                idreservahospede:localStorage.getItem('checkout-idreservahospede')
            }
            this.$http.post("/webcheckout/solicitarChecagemFrigobar", params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                .then(result => {
       
                    if (result.status == 200) {
                        this.infoMessage = true
                        this.checagemenviada = 1
                    } else {
                        console.error(JSON.stringify(result))
                    }
                })
            .catch((e) => {
                if (e.message.includes('403')) {
                    this.$emit('direcionar', {dados: this.dados, to: 'login', from: 'inicio'})
                } else {
                    console.error(e)
                }
            })     
        },
    },
    async mounted(){

       let idhotel = await this.$route.params.token    

       this.$http.post("webcheckout/buscarParametro", {  
                "idhotel" : idhotel,
                "chave" : 'TAXASCHECKOUT'
            }).then(resp =>{
                if(resp.status == 200){
                    this.cobrartaxaservico  = resp.data[0].valor === 'true' ? true : false
                    this.pctaxaservico      = resp.data[0].valor2
                    this.cobrartaxaturismo  = resp.data[0].valor3 === 'true' ? true : false
                    this.pctaxaturismo      = resp.data[0].valor4
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            }) 
    }
}
</script>