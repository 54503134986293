<template>
    <div class="wout-login">
        <div class="wout-center">
            <div>
                <img src="@/assets/img/prelogin.svg" 
                    width="auto" height="130" alt="Pré Login" crossorigin />
            </div>
            <span class="wout-confirmar">Deseja realizar o seu check-out express?</span>
            <button class="wout-continuar" @click="avancar">
                <span>Continuar</span>
                <v-icon large class="wout-icone-continuar">mdi-chevron-right</v-icon>
            </button>
        </div>
    </div>
</template>
<script>

export default {
    data: () => {
        return {
            
        }
    },
    methods: {
        avancar() {
            this.$emit('direcionar', {from: 'prelogin', to: 'login'})
        }
    }
}
</script>
