// https://vuex.vuejs.org/en/mutations.html

export default {
  auth_request (state) {
    state.authStatus = 'loading'
  },
  auth_success (state, { token, user }) {
    state.authStatus = 'success'
    state.token = token
    state.user = user
  },
  auth_error (state) {
    state.authStatus = 'error'
  },
  logout (state) {
    state.authStatus = ''
    state.token = ''
    state = {}
  },
  setTableList (state, tableList) {
    state.tableList = tableList
  },
  setHiddenDrawer (state, hiddenDrawer) { state.hiddenDrawer = hiddenDrawer },
  fichaDigital (state, fichaDigital){
    state.fichaDigital = fichaDigital
  },
  setPagina (state, pagina){
    state.pagina = pagina
  },
  mutate_ultimostatus(state, payload) {
    sessionStorage.setItem(`ultimostatus`, JSON.stringify(payload))
    state.ultimostatus = payload
  },
  mutate_tipoacesso(state, payload) {
    sessionStorage.setItem(`tipoacesso`, JSON.stringify(payload))
    state.tipoacesso = payload
  },
}
