<template>
  <v-dialog v-model="exibirModal" width="80vw" max-width="80vw">
    <v-card>
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            <v-icon large>{{ icone }}</v-icon>
            <span>{{ titulo }}</span>
          </span>
          <span style="width: 5%">
            <v-btn x-small color="red" @click="fecharModal" title="Fechar">
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>
      <v-card-text>
        <template v-if="objeto">
          <pre style="font-weight: bold">{{ objetoStr }}</pre>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalESObjeto",
  components: {},
  props: {
    exibir: {
      type: Boolean,
      default: false,
    },
    objeto: {
      type: Object,
      required: false,
      default: null,
    },
    icone: {
      type: String,
      required: false,
      default: "mdi-eye",
    },
    titulo: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    objetoStr() {
      return JSON.stringify(this.objeto, null, 4);
    },
  },
  methods: {
    fecharModal() {
      this.$emit("fechar");
    },
  },
  watch: {
    exibir(val) {
      this.exibirModal = val;
    },
  },
  data() {
    return {
      exibirModal: false,
    };
  },
};
</script>
