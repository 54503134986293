<template>
  <v-dialog v-model="exibirModalGeo" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Localizações
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Localizações"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalGeo()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div class="es-button-col">
            <!-- Importar Países -->
            <div class="es-button-row" v-if="tipoIntegracao !== 'ERBON'">
              <div class="es-button-left">
                <v-btn :disabled="processando_paises" width="200"
                  @click="importar('paises')" data-cy="paises" color="primary">
                  Importar Paises
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_paises">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_paises&&resultado_paises">
                    <span>{{resultado_paises}}</span>
                </div>
              </div>
            </div>
            <!-- Importar Estados -->
            <div class="es-button-row" v-if="tipoIntegracao !== 'ERBON'">
              <div class="es-button-left">
                <v-btn :disabled="processando_estados" width="200"
                  @click="importar('estados')" data-cy="estados"  color="primary">
                  Importar Estados
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_estados">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_estados&&resultado_estados">
                    <span>{{resultado_estados}}</span>
                </div>
              </div>
            </div>
            <!-- Importar Cidades -->
            <div class="es-button-row" v-if="tipoIntegracao !== 'ERBON'">
              <div class="es-button-left">
                <v-btn :disabled="processando_cidades" width="200"
                  @click="importar('cidades')" data-cy="cidades" color="primary">
                  Importar Cidades
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_cidades">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_cidades&&resultado_cidades">
                    <span>{{resultado_cidades}}</span>
                </div>
              </div>
            </div>
            <!-- Importar Cidades Ex -->
            <div v-if="tipoIntegracao == 'HITS'" class="es-button-row">
              <div class="es-button-left">
                <v-btn :disabled="processando_cidades_ex" width="200"
                  @click="importar('cidades_ex')" data-cy="cidade" color="primary">
                  Importar UF/CIDADES EX
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_cidades_ex">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_cidades_ex&&resultado_cidades_ex">
                    <span>{{resultado_cidades_ex}}</span>
                </div>
              </div>
            </div>
            <!-- Importar Profissões -->
            <div class="es-button-row">
              <div class="es-button-left">
                <v-btn :disabled="processando_profissoes" width="200"
                  @click="importar('profissao')" data-cy="profissao" color="primary">
                  Importar Profissões
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_profissoes">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_profissoes&&resultado_profissoes">
                    <span>{{resultado_profissoes}}</span>
                </div>
              </div>
            </div>
            <!-- Importar Status -->
            <div class="es-button-row" v-if="tipoIntegracao !== 'ERBON'">
              <div class="es-button-left">
                <v-btn :disabled="processando_status" width="200"
                  @click="importar('status')" data-cy="status" color="primary">
                  Importar Status
                </v-btn>
              </div>
              <div class="es-message-right">
                <div v-if="processando_status">
                    <span>Processo de importação em andamento...</span>
                </div>
                <div v-if="!processando_status&&resultado_status">
                    <span>{{resultado_status}}</span>
                </div>
              </div>
            </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';

export default {
  data: () => ({
    textoEmail:
      "Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
    isImg: null,
    tipoIntegracao:null,
    imgSrc: null,
    imgTipo: null,
    exibirTermoWebCheckin: null,
    processando_paises: (localStorage.getItem('processando_paises')==='true'||localStorage.getItem('processando_paises')==true),
    resultado_paises: (localStorage.getItem('resultado_paises')),
    processando_estados: (localStorage.getItem('processando_estados')==='true'||localStorage.getItem('processando_estados')==true),
    resultado_estados: (localStorage.getItem('resultado_estados')),
    processando_cidades: (localStorage.getItem('processando_cidades')==='true'||localStorage.getItem('processando_cidades')==true),
    resultado_cidades: (localStorage.getItem('resultado_cidades')),
    processando_cidades_ex: (localStorage.getItem('processando_cidades_ex')==='true'||localStorage.getItem('processando_cidades_ex')==true),
    resultado_cidades_ex: (localStorage.getItem('resultado_cidades_ex')),
    processando_profissoes: (localStorage.getItem('processando_profissoes')==='true'||localStorage.getItem('processando_profissoes')==true),
    resultado_profissoes: (localStorage.getItem('resultado_profissoes')),
    processando_status: (localStorage.getItem('processando_status')==='true'||localStorage.getItem('processando_status')==true),
    resultado_status: (localStorage.getItem('resultado_status')),
  }),
  props: ["exibirModalGeo"],
  components: {
    BotaoTutorial
},
  computed: {
    isProcessing(item) {
      return localStorage.getItem(item)==='true' || localStorage.getItem(item)==true;
    }
  },
  methods: {

   async importar(tipo){
      const params = {
        id_hotel: localStorage.getItem('idhotelpms'),
        useremail: localStorage.getItem('useremail')
      }

      if(tipo === 'paises') {
        if(this.tipoIntegracao.toLowerCase() == 'hits') {
          this.processando_paises = true
          await this.$http.post('/geo/hits/paises/importar/',params).then(() =>{
          //   localStorage.setItem('processando_paises',true)
          // this.processando_paises = false
          // localStorage.setItem('resultado_paises','Processo concluído.')
          this.resultado_paises = 'Processo concluído.'
          this.processando_paises = false;
          localStorage.setItem('processando_paises',this.processando_paises);
          localStorage.setItem('resultado_paises',this.resultado_paises);
          return
        }).catch( () =>{
          alert("Não foi possível carregar os dados de UFs!");
          return
        })
        return
        }

        this.$http.post('/geo/robo/paises/importar/',params).then(() =>{
          localStorage.setItem('processando_paises',true)
          this.processando_paises = true
          localStorage.setItem('resultado_paises','')
          this.resultado_paises = ''
        }).catch( () =>{
          alert("Não foi possível Carregar os dados de Paises!")
        })
        return
      }

      if(tipo === 'estados') {
        if(this.tipoIntegracao.toLowerCase() == 'hits') {
          this.processando_estados = true
          await this.$http.post('/geo/hits/estados/importar/',params).then(() =>{
          //   localStorage.setItem('processando_paises',true)
          // this.processando_paises = false
          // localStorage.setItem('resultado_paises','Processo concluído.')
          this.resultado_estados = 'Processo concluído.'
          this.processando_estados = false;
          localStorage.setItem('processando_estados',this.processando_estados);
          localStorage.setItem('resultado_estados',this.resultado_estados);
          return
        }).catch( () =>{
          alert("Não foi possível carregar os dados de UFs!");
          return
        })
        return
        }
        this.$http.post('/geo/robo/estados/importar/',params).then(() =>{
          localStorage.setItem('processando_estados',true)
          this.processando_estados = true
          localStorage.setItem('resultado_estados','')
          this.resultado_estados = ''
        }).catch( () =>{
          alert("Não foi possível carregar os dados de UFs!");
        })
        return
      }

      if(tipo === 'cidades') {

        if(this.tipoIntegracao.toLowerCase() == 'hits') {
          this.processando_cidades = true
          await this.$http.post('/geo/hits/cidades/importar/',params).then(() =>{
          //   localStorage.setItem('processando_paises',true)
          // this.processando_paises = false
          // localStorage.setItem('resultado_paises','Processo concluído.')
          this.resultado_cidades = 'Processo concluído.'
          this.processando_cidades = false;
          localStorage.setItem('processando_cidades',this.processando_cidades);
          localStorage.setItem('resultado_cidades',this.resultado_cidades);
          return
        }).catch( () =>{
          alert("Não foi possível carregar os dados de UFs!");
          return
        })
        return
        }

        this.$http.post('/geo/robo/cidades/importar/',params).then(() =>{
          localStorage.setItem('processando_cidades',true)
          this.processando_cidades = true
          localStorage.setItem('resultado_cidades','')
          this.resultado_cidades = ''
        }).catch( () =>{
          alert("Não foi possível carregar os dados de cidades!");
        })
        return
      }

      if(tipo === 'cidades_ex'){
        this.processando_cidades_ex = true;
        this.resultado_cidades_ex = '';
        localStorage.setItem('processando_cidades_ex',this.processando_cidades_ex);
        localStorage.setItem('resultado_cidades_ex',this.resultado_cidades_ex);
        this.$http.get('/geo/robo/importarufex/'+localStorage.getItem('hotel')).then(() =>{
          console.log("Carregando UFs via Socket");
          this.processando_cidades_ex = false;
          this.resultado_cidades_ex = 'Processo concluído.';
          localStorage.setItem('processando_cidades_ex',this.processando_cidades_ex);
          localStorage.setItem('resultado_cidades_ex',this.resultado_cidades_ex);
        }).catch( () =>{
          console.error("Não foi possível Carregar os dados de UFs!");
          this.processando_cidades_ex = false;
          this.resultado_cidades_ex = 'Ocorreu um erro durante o processo.';
          localStorage.setItem('processando_cidades_ex',this.processando_cidades_ex);
          localStorage.setItem('resultado_cidades_ex',this.resultado_cidades_ex);
        })
      }

      if(tipo === 'profissao'){
        this.processando_profissoes = true
        this.$http.post('/geo/robo/profissoes/importar/',params).then(() =>{
          this.processando_profissoes = false
          this.resultado_profissoes = 'Processo concluído.';
          localStorage.setItem('processando_profissoes',false)
          localStorage.setItem('resultado_profissoes',this.resultado_profissoes)
        }).catch( () =>{
          alert("Não foi possível carregar os dados de profissões!");
        })
        return
      }

      if(tipo === 'status'){

        if(this.tipoIntegracao.toLowerCase() == 'hits') {
          this.processando_status = true
          await this.$http.post('/geo/hits/status/importar/',params).then(() =>{
          //   localStorage.setItem('processando_paises',true)
          // this.processando_paises = false
          // localStorage.setItem('resultado_paises','Processo concluído.')
          this.resultado_status = 'Processo concluído.'
          this.processando_status = false;
          localStorage.setItem('processando_status',this.processando_status);
          localStorage.setItem('resultado_status',this.resultado_status);
          return
        }).catch( () =>{
          alert("Não foi possível carregar os dados de UFs!");
          return
        })
        return
        }

        this.$http.post('/geo/robo/status/importar/',params).then(() =>{
          localStorage.setItem('processando_status',true)
          this.processando_status = true
          localStorage.setItem('resultado_status','')
          this.resultado_status = ''
        }).catch( () =>{
          alert("Não foi possível carregar os dados de status!");
        })
        return
      }
    },
    salvarExibicaoTermo() {

      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "EXIBIRTERMOHOTEL",
          valor: this.exibirTermoWebCheckin,
        })
        .then((resp) => {
          // alert(JSON.stringify(resp))
          if (resp.status == 200) {
            this.setModalGeo();
          } else {
            alert("Não foi alterar os dados de exibição do termos");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setModalGeo() {
      this.$emit("setModalGeo");
    },
  },
  mounted() {
    //busca o email de aniversariante configurado
    this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "EXIBIRTERMOHOTEL",
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.exibirTermoWebCheckin = resp.data[0].valor;
        } else {
          alert("Não foi possivel buscar os dados");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "TIPOINTEGRACAO",
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.tipoIntegracao = resp.data[0].valor;
          console.log(this.tipoIntegracao)
        } else {
          alert("Não foi possivel buscar os dados");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    window.addEventListener('processando-paises-changed', (event) => {
      this.processando_paises = (event.detail.processando_paises==='true'||event.detail.processando_paises==true);
      this.resultado_paises = event.detail.resultado_paises;
    });
    window.addEventListener('processando-estados-changed', (event) => {
      this.processando_estados = (event.detail.processando_estados==='true'||event.detail.processando_estados==true);
      this.resultado_estados = event.detail.resultado_estados;
    });
    window.addEventListener('processando-cidades-changed', (event) => {
      this.processando_cidades = (event.detail.processando_cidades==='true'||event.detail.processando_cidades==true);
      this.resultado_cidades = event.detail.resultado_cidades;
    });
    window.addEventListener('processando-profissoes-changed', (event) => {
      this.processando_profissoes = (event.detail.processando_profissoes==='true'||event.detail.processando_profissoes==true);
      this.resultado_profissoes = event.detail.resultado_profissoes;
    });
    window.addEventListener('processando-status-changed', (event) => {
      this.processando_status = (event.detail.processando_status==='true'||event.detail.processando_status==true);
      this.resultado_status = event.detail.resultado_status;
    });

  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
.es-button-col {
  height:300px;
  /* font-size:16px; */
  display:flex;
  flex-direction:column;
}
.es-button-row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  margin-top:0.5rem;
}
.es-button-left {
  width:30%;
  align-self:center!important;
}
.es-message-right {
  color: white;
  background-color: rgb(33, 150, 243);
  width:70%;
  align-self:center;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
@media screen and (max-width: 900px) {
  .es-button-left {
    width: 40%;
  }
  .es-message-right{
    width:60%;
  }
}
@media screen and (max-width: 700px) {
  .es-button-col {
    height:500px;
  }
  .es-button-left {
    width: 100%;
  }
  .es-message-right{
    width: 100%;
    margin-top: 0.3rem;
  }
}
</style>