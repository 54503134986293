<template>
  <div class="page">
    <span class="not-found-wrapper">
      <div class="not-found-icon">
        <v-icon size="200">mdi-robot-dead-outline</v-icon>
        <p>400</p>
      </div>
      <button class="confirmar-btn">voltar</button>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
        window.scrollTo(0, 0);
        this.startup()
  }
}

</script>

<style lang="scss" scoped>
@import './folhaestilos/checkinpay.css';
.page{
    width: 100vw;
    height: 100vh;
    background-color: #f9f9fe;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found-icon{
  background-color:#2C2FE3!important;
  border-radius: 50%;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 270px;
  

  .mdi-robot-dead-outline{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p{
    color: white;
    font-size: 1.5em;
  }
}

.confirmar-btn{
  border: 4px solid #2C2FE3;
  background: white !important;
  color:#2C2FE3 !important;
  font-size: 1em !important;
  text-transform: capitalize;
}

.v-application p {
  margin-bottom: unset;
}

</style>