<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      justify-center>
      <v-flex xs12>
        <material-card
          color="general">
          <v-toolbar color="light-blue" class="ma-2" style="color: white;display:flex;flex-direction:column;">
            <div style="min-width:70%!important;">
              <span class="toolbar-title">Mensagens não lidas</span> 
            </div>
  
            
          </v-toolbar>

          <v-card>
            
            <v-data-table
            :headers="headers"
            :items="notificacoes"
            class="elevation-1 ma-2"
            no-data-text="Nenhuma Notificação Encontrada."
          >
          </v-data-table>
          </v-card>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>


export default {
  components: {
    
  },
  data: () => ({
    notificacoes:[],
    headers: [
      { text: "Nome", value: "nome_pessoa" },
      { text: "Telefone", value: "numero_telefone" },
      { text: "Mensagem", value: "mensagem" },
      { text: "Data", value: "data_mensagem" },
    ],
    color: null,
    colors: [
      'purple',
      'info',
      'success',
      'warning',
      'error'
    ],
    top: true,
    bottom: false,
    left: false,
    right: false,
    snack: false,
    snackColor: 'success',
    snackText: ''
  }),
  mounted(){
    this.$http.post("/whatsapp/buscarTodasMensagensNaoVisualizadas")
      .then((response) => {
            this.notificacoes = response.data
            this.mudarStatusMensagens()
          })
      .catch((error) => console.log(error));
  },
  methods: {

    async mudarStatusMensagens() {
      this.$http.post("/whatsapp/marcarTodasComoLidas")
      .then(() => {
           console.log('sucesso')
          })
      .catch((error) => console.log(error));
    },

    testenotificar(){
      let notificacao = {idusuario:localStorage.getItem('userid'),
                email:localStorage.getItem('useremail'),
                mensagem:'teste'}

      this.$http.post("/notificacao/inserirnotificacao", 
          notificacao)
          .then(() => {
            console.log("NOTIFICACAO INSERIDA COM SUCESSO")
              // this.$qtdeNotificacoes = 13  
          })
          .catch((error) => {
              console.log(error)
          });
    },
  }
}
</script>
