import axios from 'axios';

export async function downloadPdfTermoHotel(idHotel) {
  try {
    const response = await axios.get(`/download/termo/${idHotel}`);
    const { filePath } = response.data;
    const redirecionar = filePath.split('public/')[1];
    console.log(redirecionar)

    // Construir a URL completa do arquivo PDF
    const url = `http://localhost:3049/${redirecionar}`;

    console.log("URL: ", url)

    // Abrir o PDF em uma nova aba
    window.open(url, '_blank');
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
  }
}

export async function downloadPdfLgpd(idHotel) {
  try {
    const response = await axios.get(`/download/termo-lgpd/${idHotel}`);
    const { filePath } = response.data;
    const redirecionar = filePath.split('public/')[1];
    console.log(redirecionar)

    // Construir a URL completa do arquivo PDF
    const url = `http://localhost:3049/${redirecionar}`;

    console.log("URL: ", url)

    // Abrir o PDF em uma nova aba
    window.open(url, '_blank');
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
  }
}

