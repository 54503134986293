<template>
  <v-container class="v-container">
    <v-layout justify-left wrap>
      
      <v-flex xs12 md12>
        <v-card style="padding: 20px;">

          <!-- <v-card-title>
                <div style="min-width:100%!important;display:flex;justify-content:space-between;">
                    <span class="toolbar-title" style="width:80%;">Relatorios</span>
                </div> 
          </v-card-title> -->
            <v-container class="">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-select color v-model="relatorio" :items="listaDeRelatorios" item-text="nome" item-value="id" outlined label="Relatorio" return-object></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field type="date" label="Data Inicial"  v-model="datainicial" outlined color></v-text-field>                        
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field type="date" label="Data Final"  v-model="datafinal" outlined color></v-text-field>                        
                    </v-col>
                    <v-col cols="12" md="3" style="margin-top:5px;">
                            <v-btn small elevation="" color="info" block large @click="gerarRelatorio()">Gerar Relatorio</v-btn>
                    </v-col>
                    <v-col cols="12" md="2" style="margin-top:5px;">
                            <v-btn small elevation="" color="success" block large >Baixar</v-btn>
                    </v-col>
                </v-row>
            </v-container>

          <!-- <v-text-field v-model="search" append-icon="search" label="Buscar" style="padding: 20px;" single-line hide-details /> -->
          
          <v-data-table :headers="headers" :items="dadosrelatorio" :rows-per-page="all" :search="search" item-key="id" >
          </v-data-table>

          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
              </div>
          </v-snackbar>

        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
// import axios from 'axios'


export default {
    data: () => ({
        snack: false,
        snackColor: "",
        snackText: "",
        search: "",
        tutoriais: [],
        headers: [],
        dadosrelatorio:[],
        listaDeRelatorios:[
            {id:1, nome:'Sintetico de Edição de Fichas'}
        ],
        relatorio:{id:1, nome:'Sintetico de Edição de Fichas'},
        datainicial:null,
        datafinal:null,
    }),
    computed: {},
    watch: {},
    methods: {
        montarHeaderRelatorios(){
            if(this.relatorio && this.relatorio.id == 1){
                this.headers = [
                                { text: "Sistema", value: "sistema" },
                                { text: "Título", value: "titulo" },
                                { text: "Descrição", value: "descricao" },
                                { text: "Id do Video", value: "video" },
                            ]
            }
        },
        gerarHeadersSinteticoEdicao(){
            let headers = [
                                { text: "Total", value: "qtde" },
                                { text: "Total Preenchidos no Web Checkin", value: "qtde_webcheckin" },
                                { text: "Total Preenchidos na Recepção", value: "qtde_recepcao" },
                            ]
            return headers
        },
        gerarHeaders(){
            if(this.relatorio.id == 1){
                this.headers = this.gerarHeadersSinteticoEdicao()
            }
        },
        async gerarRelatorio(){
            this.headers = []
            let parametros = {
                idrelatorio:this.relatorio.id,
                datainicial:this.datainicial,
                datafinal:this.datafinal
            }

            await this.gerarHeaders()

            this.$http.post("/relatorio/gerarrelatorio", parametros).then((response) => {
                this.dadosrelatorio = response.data;
            })
                .catch((error) => {
                console.log(error);
            });
        }
       
    },
    mounted() {
        this.datainicial = dayjs().format('YYYY-MM-DD')
        this.datafinal = dayjs().format('YYYY-MM-DD')
    },
    components: { 
        
     }
};
</script>

<style scoped>

.table-row {
  cursor: pointer;
}
.selected {
  background-color: rgb(154, 209, 253)!important;;
}
.table-row:hover {
  background-color: rgb(33, 150, 243)!important;
  color: white;
  font-weight: bold;
}
</style>