<template>
    <form autocomplete="off" v-if="dados">
      <div class="form-header">
        <h2 class="form-header">Confirme seus dados</h2>
        <p class="form-subtitle">Analise com cuidado os dados abaixo:</p>
      </div>
      <div class="form-content">
        <div class="field">
          <label>Nome:</label>
          <input disabled type="text" v-model="nomecompleto" data-cy="input-nome-usuario"/>
        </div>
        <div class="field">
          <label>Nome do Hotel:</label>
          <input disabled type="text" v-model="dados.nomehotel" data-cy="input-nome-hotel"/>
        </div>
        <div class="field">
          <label>Número da Reserva:</label>
          <input disabled type="text" v-model="dados.numeroreserva" data-cy="input-reserva"/>
        </div>
        <div class="field">
          <label>Check-In / Out Previsto:</label>
          <div style="display:flex;">
            <input disabled type="date" v-model="dados.datacheckinprevisto" data-cy="input-checkin"/>
            <span style="margin-right:10px;">à</span>
            <input disabled type="date" v-model="dados.datacheckoutprevisto" data-cy="input-checkin"/>
          </div>
        </div>
        <!-- <div class="field">
          <label>Check-Out Previsto:</label>
          <input disabled type="date" v-model="dados.datacheckoutprevisto" data-cy="input-checkout"/>
        </div> -->
        <div class="field">
          <label>Quantidade de dias:</label>
          <input disabled type="number" v-model="dados.qtdedias" data-cy="input-qtdedias"/>
        </div>
        <div class="field">
          <label>Valor Tarifa:</label>
          <!-- <input disabled type="text" v-model="dados.valortarifa" data-cy="input-valortarifa"/> -->
          <div>R$ {{dados.valortarifa | formatarMoeda}}</div>
        </div>

        <div class="field">
          <label>Valor total:</label>
          <!-- <input disabled type="text" v-model="dados.valortarifa" data-cy="input-valortarifa"/> -->
          <div>R$ {{dados.valor_total | formatarMoeda}}</div>
        </div>
        
      </div>
      <!-- <button 
        data-cy="btn-confirmar"
        @click="$emit('direcionar',{to: 'pagamento'})" 
        type="button" 
        class="confirmar-btn">
        Confirmar
      </button> -->
      <button 
        data-cy="btn-confirmar"
        @click="$emit('exibirModalTpPagamento')" 
        type="button" 
        class="confirmar-btn">
        Confirmar
      </button>
      
    </form>
</template>

<script>

export default {
  props: ['dados'],
  data(){
    return {
      // dadosusuario: {
      //   nome: null,
      //   sobrenome: null,
      //   nomehotel: null,
      //   numeroreserva: null,
      //   datacheckinprevisto: null,
      //   datacheckoutprevisto: null,
      //   qtdedias: null,
      //   valortarifa: null,
      // },
    }
  },
  computed: {
    nomecompleto() {
      if (this.dados) {
        return `${this.dados.nome} ${this.dados.sobrenome}`
      } else {
        return ''
      }
    }
  },
  watch: {
    // dados(){
    //   this.dados.datacheckinprevisto = this.dados.datacheckinprevisto.substring(0,10)
    //   this.dados.datacheckoutprevisto = this.dados.datacheckoutprevisto.substring(0,10)
    //   this.dadosusuario = this.dados
    // }
  },
  mounted(){
    window.scrollTo(0, 0);
    // Object.keys(this.dadosusuario).map(key => {
    //   this.dadosusuario[key] = this.dados[key]
    // })
  }
}
</script>

<style lang="scss" scoped>
@import './folhaestilos/checkinpay.css';
.page{
    width: 100vw;
    height: 100%;
    background-color: #EBEBF5;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1em;
}
form{
  display: flex;
  flex-direction: column;
  padding: 1.2em .7125em 0 .7125em;
  background: #EBEBF5;
  box-shadow:  8px 8px 10px #dddde6,
             -8px -8px 10px #f9f9ff;
  width: 30vw;
  // border-bottom: 7px solid #1676D2;
  @media screen and (max-width: 768px) {
    width: 80vw;    
  }
}
.form-header{
  letter-spacing: 0.02em;
  font-size: clamp(1.1em, 1vw, 1.2em) !important;
  color: #8C8B99;
  font-weight: bold;
  letter-spacing: 0.02em;
}


.form-subtitle{
  letter-spacing: 0.02em;
  font-size: clamp(.7em, 1vw, 1em) !important;
}

.form-content{

  width: 100%;

  .field{
    display: flex;
    flex-direction: column;
    border-radius: 1em;
    background-color: #f9f9fe;
    width: 100%;
    padding: .5em;
    margin-bottom: .5em;

    &.half{
      width: 48%;
    }

    &.submit-btn{
      margin: 0 auto;
      width: 90%;
      height: 3em;
      margin-top: 2em;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p{
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-left: .5em;

        >span{
          color: #22A73A;
        }
      }
    }

    label{
      color: #8C8B99;
    }
  }

  .field-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

button[type="button"]{
  margin: 1em 0 !important;
  background-color: #1760e5 !important;
  display: flex;
  align-self: center;
  justify-content: center;
  @media screen and (min-width: 768px){
    position: unset;
    bottom: unset;
    margin: unset;
    margin-bottom: 1em !important;
    align-self: flex-end;
    transition: transform 200ms ease;
    margin: 0 auto;
    &:hover{
      transform: scale(.95);
      transition: transform 200ms ease;
    }
  }
}



//css indesejdo
.v-application .info {
    background-color: unset !important;
    border-color: unset !important;
}

</style>