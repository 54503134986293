<template>
  <v-dialog
    v-model="exibirModalEditarMensagemFelizAniversario"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Editar Mensagem de Feliz Aniversário Por WhatsApp
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas E-mail Aniversário Whatsapp"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalEditarFelizAniversarioWhats()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <!-- <v-card> -->
        <v-flex>
          <div
            style="
              margin-top: 20px;
              font-size: 16px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <v-switch
              v-model="envioAtivo"
              data-cy="envioAtivo"
              style="border-radius: 5px; padding: 5px"
            ></v-switch>
            <label>Felicitar Aniversário(WhatsApp)</label>
            <v-col>
              <v-text-field
                data-cy="mensagemPadrao"
                label="Mensagem Padrão"
                v-model="mensagemPadrao"
                :disabled="!envioAtivo"
              >
              </v-text-field>
            </v-col>
          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn
            @click="salvarDadosMensagem()"
            data-cy="salvarDadosMensagem"
            color="primary"
          >
            Salvar
          </v-btn>
        </div>
        <!-- </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    textoEmail:
      "Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
    isImg: null,
    imgSrc: null,
    imgTipo: null,
    mensagemPadrao: null,
    envioAtivo: false,
    EnviarMensagemAniversarioWhats: false,
  }),
  props: ["exibirModalEditarMensagemFelizAniversario"],
  components: {
    BotaoTutorial
},
  methods: {

    buscarDadosMensagem() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "MSGANIVERSARIOWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.envioAtivo = resp.data[0].valor == "true" ? true : false;
            this.mensagemPadrao = resp.data[0].valor2;
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
//////////////////////////
    salvarDadosMensagem() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "MSGANIVERSARIOWHATSAPP",
          valor: this.envioAtivo,
          valor2: this.mensagemPadrao,
        })
        .then((resp) => {
          console.log(resp);
          this.setModalEditarFelizAniversarioWhats();
        })
        .catch((error) => {
          console.log(error);
        });

//////////////////////////////

    },
    setModalEditarFelizAniversarioWhats() {
      this.$emit("setModalEditarFelizAniversarioWhats");
    },
  },
  mounted() {
    this.buscarDadosMensagem();
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>