<template>
  <v-dialog
    v-model="exibirModalGaleriaAssinatura"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Galeria de Imagens na Assinatura
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Galeria de Imagens na Assinatura"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalGaleriaAssinatura()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <div class="es-galery">
          <div class="galery-image">
            <modal-galeria-imagem :imagem="novaImagem" :indice="-1" :key="'mgi_nova_imagem'"
              @change="(e,ind)=>onFileChange(e,ind)"></modal-galeria-imagem>
          </div>          
          <div v-for="(imagem,index) in imagens" :key="'gi_'+index"
            class="galery-image"
          >
            <modal-galeria-imagem :imagem="imagem" :indice="index" :key="'mgi_'+index"
              @change="(e,ind)=>onFileChange(e,ind)"
              @delete="(ind)=>onDeleteImage(ind)"
              @text-changed="(ind)=>onTextChange(ind)"
              ></modal-galeria-imagem>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn :disabled="saving"
            @click="salvarImagens()"
            data-cy="salvarImagens"
            color="primary"
          >
            {{salvarTexto}}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import ModalGaleriaImagem from './ModalGaleriaImagem.vue';
import {serializarImagem} from '../../../utils/serializar'
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    salvarTexto: 'Salvar',
    imagens: [],
    novaImagem: {
      id: 0, 
      hotel: localStorage.getItem("hotel"),
      texto: '',
      imagem: null,
      changed: true
    },
    saving: false
  }),
  props: ["exibirModalGaleriaAssinatura"],
  components: {
    ModalGaleriaImagem,
    BotaoTutorial
},
  methods: {
    async carregarImagens() {
      this.$http
        .post("perfil/hotel/imagem/select", {
          idhotel: localStorage.getItem("hotel")
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              this.imagens = resp.data;
              for (var i = 0; i < this.imagens.length; i++) {
                let imgObj = this.imagens[i]
                if(imgObj.dados.data) {
                  this.imagens[i].imgSrc = Buffer.from(imgObj.dados.data).toString();
                  delete imgObj.dados;
                }
              }
            }
          } else {
            console.log("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onFileChange(e,index) {
      console.error('indice:'+index)
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        alert("Sem imagem.");
        return;
      }
      const file = files[0];
      if (!file.type.includes("image/")) {
        alert("Selecione um arquivo .jpg ou .png!");
        return;
      }
      if (typeof FileReader === "function") {
        if (index == -1) {
          // Nova imagem
          let newObj = {...this.novaImagem}
          newObj.id = -1;
          this.imagens.push(newObj);
          index = this.imagens.length - 1;
          this.novaImagem.texto = '';
        }
        const reader = new FileReader();
        reader.onloadend = (event) => {
          let commaPosition = event.target.result.indexOf(",") + 1;
          this.imagens[index].imgSrc = event.target.result.substring(commaPosition,event.target.result.length);
          this.imagens[index].tipo = file.type;
          this.imagens[index].nome = file.name;
          // As duas linhas abaixo é um truque para forçar um update do componente
          this.imagens[index].texto = this.imagens[index].texto + ' '
          this.imagens[index].texto = this.imagens[index].texto.trim()
          this.imagens[index].changed = true;
        };
        reader.readAsDataURL(file);
      } else {
          alert("Desculpe, a API FileReader não é suportada nesta plataforma!");
      }
    },
    onFileChangeX(e,index) {
      console.error('vai serializar...')
      serializarImagem(e, 1.0).then(resp =>{
        console.error('serializou, index:'+index)
          this.imagens[index].imgSrc = resp.dados
          this.imagens[index].tipo = resp.tipo
      }).catch(error =>{
          console.error(error)
      })
    },
    onDeleteImage(ind){
      console.log('vai deletar:'+ind);
      if (this.imagens[ind].id == -1) {
        // Se o id é igual a -1, a imagem não está salva, apagar apenas no front
        this.imagens.splice(ind,1);
        return;
      }
      this.$http.post("/perfil/hotel/imagem/delete", {id: this.imagens[ind].id})
        .then((resp) => {
          if (resp.status == 200) {
            this.imagens.splice(ind,1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onTextChange(ind){
      this.imagens[ind].changed = true;
    },
    async salvarImagens() {
      this.salvarTexto = 'Salvando imagens...';
      this.saving = true;
      let imagensModificadas = []
      for (var i = 0; i < this.imagens.length; i++) {
        if (this.imagens[i].changed) {
          imagensModificadas.push(this.imagens[i]);
        }
      }
      if (imagensModificadas.length > 0) {
      this.$http.post("/perfil/hotel/imagem/upinsert", {imagens: imagensModificadas})
        .then(async (resp) => {
          if (resp.status == 200) {
            this.setModalGaleriaAssinatura();
            await this.carregarImagens();
            this.salvarTexto = 'Salvar';
            this.saving = false;
          }
        })
        .catch((error) => {
          this.salvarTexto = 'Salvar';
          this.saving = false;
          console.log(error);
        });
      } else {
          this.salvarTexto = 'Salvar';
          this.saving = false;
      }
    },
    setModalGaleriaAssinatura() {
      this.$emit("setModalGaleriaAssinatura");
    },
  },
  mounted() {
    this.carregarImagens();
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
.es-galery {
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  height: 450px;
  overflow: scroll;
}
.galery-image {
  display: flex; 
  flex-direction: column;
  border: 5px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
  cursor: pointer;
  height: 60%;
}
.galery-image:hover {
  border: 5px solid green;
}
</style>