<template>
  <v-dialog v-model="exibirModalRespostaWhatsapp" width="50%">
      <v-card style="max-width: 100%">
            <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex;align-items: center;" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <div class="es-container-profile">
                      <v-avatar color="indigo">
                      <v-icon dark>
                       mdi-account-circle
                      </v-icon>
                      </v-avatar>
                      <span style="width:95%;color:white;font-weight:bold;margin-left: 20px;" >
                          {{nomeHospede}}
                      </span>
                    </div>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalRespostaWhatsapp" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            <div class="es-history-container">
              <div class="es-history-empty" v-if="loading">
                Carregando mensagens...
              </div>
        <div class="es-history">
          <div v-for="(msg,index) in mensagem" :key="index" 
              :class="{ 'left' : msg.tipo === 'Recebida', 'right' : msg.tipo === 'Enviada' }"
              class="es-history-message"
          >
              <div class="es-history-message-container">
                  <div :class="msg.tipo === 'Enviada' ? 'es-history-text' : 'es-history-text-left'">{{msg.mensagem}}</div>
                  <div class="es-history-author">{{msg.tipo === 'Recebida' ? msg.nome_pessoa : nomeHotel}} - {{msg.data_mensagem}}</div>
              </div>
          </div>
      </div>
      <div class="es-history-empty" v-if="semregistro">
          Histórico sem registros.
      </div>
  </div>
   <v-toolbar color="blue">
                <v-flex  style="display: flex;align-items: center;" >
                    <input v-model="textoMensagem" type="text" placeholder="Mensagem" @keyup.enter="enviarWhatsApp()" class="inputTextEnviarMensagem" />
                    <v-btn color="white" style="margin-left: 5px;" @click="enviarWhatsApp()">
                          <v-icon small color="blue">mdi-send</v-icon>
                    </v-btn>
                </v-flex>
            </v-toolbar>
      </v-card>
  </v-dialog>
</template>          

<script>


export default {
  data: () => ({      
      mensagem: null,
      respostaAcao : "",
      textoMensagem: "",
      intervalId: null,
      loading: true,
      headers: [
      { text: "nome", value: "nome" },
      { text: "Whatsapp", value: "numero_telefone"},
      { text: "data", value: "data_mensagem"},
      { text: "Tipo", value: "tipo"},
      { text: "Mensagem", value: "mensagem"},
    ],
    msgs: [
              {"id":333,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:05:40.898163","texto":"Atendida. Sua solicitação foi atendida!","idevento":null,"produtos":null},
              {"id":332,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:05:02.640138","texto":"Solicitação contestada.: foi não","idevento":null,"produtos":null},
              {"id":331,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:04:46.90049","texto":"Atendida. Sua solicitação foi atendida!","idevento":null,"produtos":null},
              {"id":330,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:04:27.028751","texto":"Solicitação cancelada.: deixa pra la","idevento":null,"produtos":null},
              {"id":329,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:03:53.695031","texto":"Solicitação visualizada.: Estamos cientes de sua solicitação que será atendida mediante disponibilidade!","idevento":null,"produtos":null},
              {"id":324,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 17:46:43.240696","texto":"Solicitação criada.. Gostaria de solicitar: Lençol: 1; ","idevento":null,"produtos":null}
          ],
          historico: null,
          semregistro: false,
  }),
  props:["id_hospede", "exibirModalRespostaWhatsapp", "nomeHotel", "nomeHospede", "numeroWhatsapp", "idReservaHospede"],
  components:{
  },
  methods: {
    setModalRespostaWhatsapp(){
          this.$emit('respostasWhatsapp')
          clearInterval(this.intervalId);
      }, 

      async enviarWhatsApp() {
            if(this.textoMensagem.length > 0 && this.numeroWhatsapp.length > 0){
              const obj = {
                id_pessoa: this.id_hospede,
                mensagem: this.textoMensagem,
                tipo: 'Enviada'
              }

             await this.$http.post('/whatsapp/cadastrarMensagem', obj).then(resp =>{
                  console.log(resp.data)
                })

                    const body = {
                       telefoneDestino: this.numeroWhatsapp ,
                       template:"geral",
                       dadosHeader:[],
                       dadosBody:[
                        {valor: this.textoMensagem},
                      ],
                      dadosButton:[],
                      idReservaHospede: this.idReservaHospede,
                    }
                this.$http.post('/whatsapp/enviarMensagem', body).then(() =>{
                    this.textoMensagem = "";
                })
            }else {
                alert("Conteúdo da mensagem não pode ser vazio") 
            }
          },
        
      async buscarMensagens() {
          await this.$http
          .post("/whatsapp/buscarMensagens", {id_hospede: Number(this.id_hospede)})
          .then((response) => {
            if(response.data.message === 'Nenhuma mensagem encontrada') {
              this.loading = false;
              return this.semregistro = true;
            }
            this.semregistro = false;
            this.loading = false;
           this.mensagem = response.data.reverse();
          })
          .catch((error) => {
            console.log(error);
          });
      },
  },
      
  async mounted(){
      this.buscarMensagens();
      this.sockets.subscribe("notificarMensagem", async () => {
          this.buscarMensagens();
      })
  },
  unmounted() {
    clearInterval(this.intervalId)

  },
  async beforeDestroy() {
    clearInterval(this.intervalId)
  },

  async destroyed() {
    clearInterval(this.intervalId)
  }
};
</script>

<style scoped>
.es-history-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.es-history {
  display: flex;
  flex-direction: column-reverse;
  background-color: azure;
  border-radius: 15px 15px 15px 15px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}
.es-history-message {
  display: flex;
  flex-direction: row;
}
.es-history-message-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: rgb(135, 225, 245);
  padding: 0.7rem;
  margin: 0.7rem;
}
.left .es-history-message-container {
  border-radius: 15px 15px 15px 0px;
  box-shadow: 0px 5px 10px rgb(151, 151, 151);
  background-color: #E4E6EB;
  color: white;
  border: 1px solid rgb(214, 214, 214);
}
.right .es-history-message-container {
  border-radius: 15px 15px 0px 15px;
  box-shadow: 0px 5px 10px rgb(151, 151, 151);
  background-color: #0084FF;
 
}
.es-history-message.left {
  display: flex;
  flex-direction: row;
}
.es-history-message.right {
  display: flex;
  flex-direction: row-reverse;
}
.es-history-text {
  white-space: pre-wrap!important;
  font-weight:normal;
  color: rgb(255, 255, 255);
  margin-bottom: 15px;
}

.es-history-text-left {
  white-space: pre-wrap!important;
  font-weight:normal;
  color: rgb(0, 0, 0);
  margin-bottom: 15px;
}
.es-history-author {
  color: rgb(88, 88, 88);
  font-size: 0.7rem;
  font-weight: bold;
}
.right .es-history-author {
  text-align: right;
  color: white;
}
.es-history-reload {
  text-align: center;
  color: black;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0.4rem;
}
.es-history-empty {
  text-align: center;
  color: orange;
}
.es-cancel {
  font-size: 1.9rem;
  color: grey;
}
.es-container-profile {
  display: flex;
  align-items: center;
  width: 95%;
}

.inputTextEnviarMensagem {
  background-color: white;
  border: 1px solid rgb(148, 148, 148);
  width: 90%;
  border-radius: 10px;
  padding: 1%;
}
</style>