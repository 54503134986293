<template>
  <v-dialog v-model="exibirModalDocs" width="1000" max-width="80vw">
    <div
      style="
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
      "
    >
      <v-card style="max-width: 100%">
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width: 95%; color: white; font-weight: bold">
              Documentos WebCheckout
            </span>
            <span style="width: 5%">
              <v-btn
                x-small
                color="red"
                @click="() => this.$emit('setModalDocs')"
                title="Fechar"
              >
                <v-icon x-small style="color: white">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>
        <Loading v-if="carregando" />
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background-color: white;
            margin: 15px;
          "
        >
          <v-btn
            @click="() => (modelAdicionarDoc = true)"
            title="Adicionar documento auxiliar"
            color="#1377f2"
            elevation="2"
            icon
            fab
          >
            <v-icon size="28s" color="#1377f2">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
        <v-data-table
          v-if="!carregando"
          :headers="headers"
          :items="docs"
          class="elevation-1"
        >
          <template v-slot:[`item.doc_frente`]="{ item }">
            <v-simple-checkbox
              v-model="item.doc_frente"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.doc_verso`]="{ item }">
            <v-simple-checkbox
              v-model="item.doc_verso"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.emissao_nota_fiscal`]="{ item }">
            <v-simple-checkbox
              :title="item.doc_aux && 'Documento não pode emitir nota fiscal'"
              :disabled="verificarDisabled(item)"
              v-model="item.emissao_nota_fiscal"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.rec_facial`]="{ item }">
            <v-simple-checkbox
              title="Selecione o reconhecimento facial apenas para documentos que possuem fotos"
              v-model="item.rec_facial"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.rec_facial_obrigatorio`]="{ item }">
            <v-simple-checkbox
              title="Selecione o reconhecimento facial apenas para documentos que possuem fotos"
              v-model="item.rec_facial_obrigatorio"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.captura_obrigatoria`]="{ item }">
            <v-simple-checkbox
              v-model="item.captura_obrigatoria"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.ativo`]="{ item }">
            <v-simple-checkbox
              v-model="item.ativo"
              @click="() => docSave(documentos)"
            >
            </v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          background-color: white;
          margin: 10px;
        "
      >
        <button class="buttonsalvar" @click="salvar">Salvar</button>
      </div>
    </div>
    <v-dialog v-model="modelAdicionarDoc" width="40vw" max-width="600px">
      <div
        style="
          width: 100%;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width: 100%; color: white; font-weight: bold">
              Adicionar Documentos Auxiliares
            </span>
            <span style="width: 5%">
              <v-btn
                x-small
                color="red"
                @click="() => (modelAdicionarDoc = false)"
                title="Fechar"
              >
                <v-icon x-small style="color: white">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>
        <div style="padding: 10px">
          <v-text-field
            label="Digite o nome do documento"
            persistent-hint
            variant="solo"
            v-model="docAux"
          ></v-text-field>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background-color: white;
            margin: 10px;
          "
        >
          <button class="buttonsalvar" @click="criarDocAux">
            {{ bntCriarDocAux }}
          </button>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="modalAlerta" width="35vw" max-width="600px">
      <div
        style="
          width: 100%;
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <p style="margin-bottom: 10px">
            Documento auxiliar cadastrado com sucesso :)
          </p>
          <h3>Configure na tabela da maneira mais adequada!</h3>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            margin: 10px;
          "
        >
          <button class="buttonsalvar" @click="modalAlerta = false">ok</button>
        </div>
      </div>
    </v-dialog>
  </v-dialog>
</template>          

<script>
import Loading from '../ConfiguracoesWebCheckin/Loading.vue';
export default {
  data: () => ({
    textobotaosalvar: "SALVAR",
    headers: [
      { text: "Doc", value: "descricao" },
      { text: "Ativo", value: "ativo" },
      { text: "Capturar frente", value: "doc_frente" },
      { text: "Capturar verso", value: "doc_verso" },
    ],
    docAux: null,
    modelAdicionarDoc: false,
    bntCriarDocAux: "Salvar",
    modalAlerta: false,
  }),
  props: ["exibirModalDocs", "docs", "carregando"],
  components: {Loading},
  methods: {
    salvar() {
      this.textobotaosalvar = "SALVANDO...";
      this.salvarDocs().then(() => {
        this.setModalDocs();
      });
    },

    salvarDocs() {
      this.$emit('salvar')
    },
    setModalDocs() {
      this.textobotaosalvar = "SALVAR";
      this.$emit("setModalDocs");
    },
    buscarParametro() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "HITSPDVRESERVA",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.pdvsReservas = parseInt(resp.data[0].valor);
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async criarDocAux() {
      if (!this.docAux || this.docAux.length === 0) {
        return;
      }
      this.$emit('salvarNovo', this.docAux)
      this.modelAdicionarDoc = false;
      // this.bntCriarDocAux = "Aguarde...";
      // await this.$http
      //   .post("webcheckout/documentos/salvar", { doc: this.docAux })
      //   .then(async () => {
      //     this.modelAdicionarDoc = false;
      //     this.modalAlerta = true;
      //     this.bntCriarDocAux = "Salvar";
      //     this.$emit('update')
      //   })
      //   .catch((error) => {
      //     this.modelAdicionarDoc = false;
      //     console.log(error.response.message);
      //   });
    },
  },
  mounted() {
    this.buscarParametro();
  },
};
</script>

<style scoped>
.buttonsalvar {
  background-color: #2B81D6;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: small;
  font-weight: 100;
  width: 100px;
}
</style>