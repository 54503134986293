<template>
  <v-dialog v-model="show" width="90vw" max-width="700px" persistent>
    <Loading v-if="loading"/>
    <div style="display: flex; flex-direction: column; background-color: white;" v-if="!loading">
      <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
        <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
          <span class="toolbar-title" style="width:80%;padding:10px;">Relatório meios de comunicação</span>
          <span style="width:5%"> 
            <v-btn x-small color="red" @click="() => this.$emit('fecharModalMeioComunicacao')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <v-data-table
              v-if="!loading"
              :headers="headers"
              :items="meiosComunicacao"
              class="elevation-1"
            >
            </v-data-table> 
    </div>
   </v-dialog>
  </template>
  
  <script>
    import Loading from './Loading.vue';
   export default {
    props:['show'],
    components: {
      Loading
    },
    data() {
      return {
        headers: [
            { text: "Meio de comunicação", value: "descricao" },
            { text: "Quantidade", value: "count" },
        ],
        meiosComunicacao: [],
        loading: true,
      }
    },
    methods: {
    async requisitarMeiosComunicacao() {
      try {
        const result = await this.$http.post('/informacoesadicionais/relatorioMeiosDeComunicao');
        this.meiosComunicacao = result.data;
      } catch (error) {
        console.log(error)
      }
    },
     
    },
    async mounted() {
      this.loading = true;
      await this.requisitarMeiosComunicacao();
      this.loading = false;
    }
   }
  </script>
  
  
  <style scoped>
  .buttonsalvar {
    background-color: #2B81D6;
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
    font-weight: 100;
    width: 100px;
  }
  </style>