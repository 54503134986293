<template>
  <v-container fill-height fluid grid-list-xl>
    <v-dialog v-model="showDialog" width="40%" style="min-height:90vh!important;" persistent>
      <v-card class="pa-2" height="100%">
        <!-- Título -->
        <v-card-title>
          {{titulo}} 
        </v-card-title>
        <v-card-text>
           <br>
          <!--<h1>{{texto}}</h1> -->
          <v-row>
            <v-col cols="12" md="2">Nome:</v-col>
            <v-col cols="12" md="10">{{itemAEstornar.nome+' '+itemAEstornar.sobrenome}}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">Valor:</v-col>
            <v-col cols="12" md="10">R$ {{itemAEstornar.valor | formatarMoeda}}</v-col>
          </v-row>
          <div>
              <v-textarea
                      outlined
                      color=""
                      placeholder="Motivo"
                      error-count=""
                      name=""
                      label="" style="margin-top:20px;"
                      v-model="motivoEstorno"
                    ></v-textarea> 
          </div>
        </v-card-text>
        <br>
        <v-card-actions>
          <template v-if="!hidebuttons">
            <v-spacer />
            <v-btn
              align-center
              justify-center
              @click="confirmarEstorno()"
              ><strong>Confirmar</strong>
              <v-icon color="green">mdi-check</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              align-center
              justify-center
              @click="$emit('cancelar')"
              ><strong>Cancelar</strong>
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
            <v-spacer />
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  props: ['show','titulo','texto','hidebuttons','itemAEstornar'],
  data: () => ({
    showDialog: false,
    motivoEstorno:null,
  }),
  methods:{

    confirmarEstorno(){
      if(this.motivoEstorno && this.motivoEstorno != '' && this.motivoEstorno.length > 5){
        this.$emit( 'confirmar', this.motivoEstorno)
      }else{
        alert('Informe um motivo válido')
      }
    }

  },
  mounted(){
    this.motivoEstorno = null
  },
  watch: {
    show(val) {
      this.showDialog = val
    }
  }
};
</script>
