<template>
    
    <div class="py-4" style="flex-wrap:wrap;width:100%;">

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.dadopessoal.tipodocumento') }}</label>
                <select v-model="tipodocumento" class="inputs" data-cy="select-tipodoc">
                    <option  v-for="(tipodocumento, index) in tiposdocumentos" :key="index" :value="tipodocumento">
                        {{tipodocumento.descricao}}
                    </option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.dadopessoal.numerodocumento') }}</label>
                <input v-if="tipodocumento&&tipodocumento.descricao == 'CPF'" data-cy="input-cpf" type="text" maxlength="14" v-mask="['###.###.###-##']" v-model="documento"
                                    class="inputs" placeholder="Número do documento" />
                <input v-if="tipodocumento&&tipodocumento.descricao != 'CPF'" type="text" style='text-transform: uppercase; ' v-model="documento"
                                    class="inputs" placeholder="Número do documento" />
            </div>


            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.dadopessoal.nome') }}</label>
                <input type="text" v-model="nome" class="inputs" data-cy="input-nome" placeholder="NOME" />
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.dadopessoal.sobrenome') }}</label>
                <input type="text" class="inputs" v-model="sobrenome" data-cy="input-sobrenome" placeholder="SOBRENOME" />
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.dadopessoal.profissao') }}</label>
                <!-- <select v-model="profissao" class="inputs" data-cy="select-profisao">
                        <option v-for="(profissao, index) in profissoes" :key="index" :value="profissao" >
                            {{profissao.descricao}}
                        </option>
                </select> -->
                <v-autocomplete filled rounded solo :items="profissoes" item-text="descricao" 
                    item-value="id" return-object v-model="profissao" hide-details style="border: 1px solid #819ab4; "></v-autocomplete>
            </div>

            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-dadospvoltar">
                      {{ $t('webcheckin.dadopessoal.voltar') }}
                </button>

                <button v-on:click="avancar()" class="botaoConfirmar" data-cy="btn-dadospavancar">
                        {{ $t('webcheckin.dadopessoal.avancar') }}
                </button>
            </div> 

            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span>{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>

    </div>

</template>

<script>

export default {
    props:["dados","selfie"],
    data: () =>{
        return{
            nome:null,
            sobrenome:null,
            profissoes:[],
            profissao:null,
            tiposdocumentos:[],
            tipodocumento:null,
            documento:null,
            snack: false,
            snackColor: '',
            snackText: '',
        }
    },
    watch:{
        tipodocumento(val){
            this.tipodocumento = val
        }
    },
    mounted(){
        
        this.profissao = null
        this.nome = this.dados.nome
        this.sobrenome = this.dados.sobrenome
        this.documento = this.dados.documento

        this.listarTipoDocs()
        this.listarProfissoes()
      
    },
    methods: {
        listarTipoDocs(){
            this.$http.post("geo/documento/tipo/select")
            .then(resp =>{

                this.tiposdocumentos = resp.data.filter(p => {
                                            return p.descricao != undefined;
                                        }).sort((a, b) => (a.descricao > b.descricao) ? 1 : -1);

                this.tiposdocumentos = this.tiposdocumentos.map(p => {
                                            p.descricao = p.descricao.toUpperCase();
                                            return p;
                                        })

                if(this.dados.tipodocumento){

                    if(typeof this.dados.tipodocumento === 'number'){
                        this.tipodocumento = this.tiposdocumentos.find(doc => doc.id == this.dados.tipodocumento)
                    }else{
                        this.tipodocumento = this.tiposdocumentos.find(doc => doc.descricao.toUpperCase().trim() == this.dados.tipodocumento.toUpperCase().trim())
                    }
                    
                }else{
                    this.tipodocumento = this.tiposdocumentos[0]
                }
            })

        },
        listarProfissoes(){
            this.$http.post("/geo/profissao/select", {hotel: this.dados.hotel})
            .then(resp =>{

                    this.profissoes = resp.data.filter(p => {
                        return p.descricao != undefined;
                    }).sort((a, b) => (a.descricao > b.descricao) ? 1 : -1);

                    this.profissoes = this.profissoes.map(p => {
                        p.descricao = p.descricao.toUpperCase();
                        return p
                    })
                    // console.log("PROF")
                    // console.log(JSON.stringify(this.profissoes))

                    if(this.dados.profissao){
                        this.profissao = this.profissoes.find(profissao => profissao.id == this.dados.idprofissao)
                    }

            })
        },
        validarFormulario(){

            if(!this.documento ){ 
                // this.$swal(this.$t('alert.notification_document_number_required.title'),this.$t('alert.notification_document_number_required.content'),"info"); 
                alert("Documento é obrigatorio!")
                return false
            }else{
                if(this.tipodocumento == 'CPF' || this.tipodocumento.descricao == 'CPF'){
                    if (this.documento.replace(/[^a-zA-Z0-9]/g, "").length != 11) {
                        // this.$swal(this.$t('alert.notification_document_number_invalid.title'), this.$t('alert.notification_document_number_invalid.content') ,"error");
                        alert("CPF é Invalido!")
                        return false
                    }                   

                    let cpfsempontos = this.documento
                    cpfsempontos = cpfsempontos.replace(".","").replace(".","").replace(".","")
                    cpfsempontos = cpfsempontos.replace("-","").replace("-","").replace("-","")
                    if (!this.validarCPF(cpfsempontos)) {
                        alert("CPF é Invalido!")
                        return false
                    } 

                }else{
                    if (this.documento.replace(/[^a-zA-Z0-9]/g, "").length <5) {
                        // this.$swal(this.$t('alert.notification_document_number_invalid.title'), this.$t('alert.notification_document_number_invalid.content') ,"error");
                        alert("Documento é Inválido!")
                        return false
                    }
                }
                
            }

            

            if(!this.nome){ 
                // this.$swal(this.$t('alert.notification_name_required.title'),this.$t('alert.notification_name_required.content'),"info"); 
                alert("Nome é obrigatorio!")
                return false
            }

            if(!this.sobrenome){ 
                // this.$swal(this.$t('alert.notification_surname_required.title'),this.$t('alert.notification_surname_required.content'),"info"); alert("Documento é obrigatorio!")
                alert("Sobrenome é obrigatorio!")
                return false
            }

            // if(!this.profissao){ 
            //     // this.$swal(this.$t('alert.notification_profession_required.title'),this.$t('alert.notification_profession_required.content'),"info"); 
            //     alert("Profissao é obrigatorio!")
            //     return false
            // }

            return true

        },
        validarCPF(strCPF) {
            var Soma;
            var Resto;
            Soma = 0;

            if (strCPF == "00000000000") return false;

            for (var i = 1; i <= 9; i++)
                Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;
            if (Resto == 10 || Resto == 11) Resto = 0;
            if (Resto != parseInt(strCPF.substring(9, 10))) return false;
            Soma = 0;
            for (i = 1; i <= 10; i++)
                Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if (Resto == 10 || Resto == 11) Resto = 0;
            if (Resto != parseInt(strCPF.substring(10, 11))) return false;
            return true;
        },
        salvarDados(){

            var dadossalvar = this.dados
            dadossalvar.tipodocumento = this.tipodocumento.descricao
            dadossalvar.idtipodocumento = this.tipodocumento.id
            dadossalvar.documento = this.documento
            dadossalvar.nome = this.nome
            dadossalvar.sobrenome = this.sobrenome
            dadossalvar.profissao = this.profissao
            dadossalvar.idprofissao = this.profissao.id
            dadossalvar.assinado = false
            dadossalvar.webcheckinfinalizado = true
            // this.$emit('salvarDados',dadossalvar,'assinatura')
            if(this.dados.crianca){

                // this.$emit('salvarDados',dadossalvar,'formulariocovid')
                if(this.dados.exibirPassosCovid){
                    this.$emit('salvarDados',dadossalvar,'formulariocovid')
                }else{
                    if(this.dados.exibirPassosObservacao){
                        // this.$emit('direcionar','observacoes')
                        this.$emit('salvarDados',dadossalvar,'observacoes')
                    }else{
                        if(this.dados.exibirPassosHorario){
                            // this.$emit('direcionar','horario')
                            this.$emit('salvarDados',dadossalvar,'horario')
                        }else{
                            // if(this.hospedePrincipal && this.dadosAcompanhantes && this.dadosAcompanhantes.length > 0){
                            if (this.dadosAcompanhantes && this.dadosAcompanhantes.length > 1){
                                // this.$emit('direcionar','hospedes') 
                                this.$emit('salvarDados',dadossalvar,'hospedes')
                            }else{
                                // this.$emit('direcionar','fim') 
                                this.$emit('salvarDados',dadossalvar,'fim')
                            }
                        }
                    }
                }
                
            }else{
                this.$emit('salvarDados',dadossalvar,'assinatura')
            }

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        },
        voltar(){
            if(this.selfie){
                this.$emit('direcionar','fotoselfie')
            }else{
                this.$emit('direcionar','contato')
            }

        }
    }
}
</script>


<style scoped>
    .v-text-field {
        /* background-color:red; */
        border-radius:15px;
        min-height:8vh;
        /* padding:0px 0px 0px 0px; */
        padding-bottom:0px!important;
        margin-bottom: 0px!important;
        /* border: 1px solid #819ab4; */
        text-align-last: left; 
        text-align: left; 
    }

</style>
