<template>
  <v-container fill-height fluid grid-list-sm>
    <v-layout>
      <v-flex md12 lg12>
        <ReservaListExpress />
      </v-flex>
      

    </v-layout>
  </v-container>
</template>
<script>
import ReservaListExpress from "./ReservaListExpress";
/* import uhs from './UHs' */
export default {
  components: {
    ReservaListExpress,
    /* uhs */
  },
  data: () => ({
 
    
  })
}

</script>