<template>
        <div class="captura_container" >
            <div style="padding-top:10px;width:100%;padding-right:5%;padding-left:5%;display:flex;justify-content:space-between;position:fixed;top:0px;">
                  <div v-if="tagSincronizada" style="color:white;font-size:18px;background-color:#2c9700;padding:10px;">TAG SINC.: {{tagSincronizada}}</div>
                  <div v-else style="color:white;font-size:18px;background-color:#009797;padding:10px;">QR CODE: {{apelido}}</div>
                  <v-btn large color="error" @click="logout()">Sair do Sistema</v-btn>
            </div>
            <div class="captura_container_doc">
                    <h1>FOTO VERSO - {{tipoDocSelecionado}}</h1>
                    <p v-if="!image" >modelo</p>
                    <img v-if="tipoDocSelecionado == 'RG' && !imgSrc" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'OUTROS' && !imgSrc" class="imgExemplo" src="/img/leitorweb/rg_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'CNH' && !imgSrc" class="imgExemplo" src="/img/leitorweb/cnh_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'PASSAPORTE' && !imgSrc" class="imgExemplo" src="/img/leitorweb/passaporte_foto_frente.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'DNI' && !imgSrc" class="imgExemplo" src="/img/leitorweb/dni_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'RNE' && !imgSrc" class="imgExemplo" src="/img/leitorweb/rne_foto_verso.jpg" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'OAB' && !imgSrc" class="imgExemplo" src="/img/leitorweb/oab.jfif" style="max-width:50vw;" >
                    <img v-if="tipoDocSelecionado == 'MEDICINA' && !imgSrc" class="imgExemplo" src="/img/leitorweb/rne_foto_verso.jpg" style="max-width:50vw;" >


                    <span v-if="fotoComCrop == false">
                        <!-- <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:300px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;"> -->
                        <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:400px;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                                <span style="font-size:18px;font-weight:bold;color:white;">Clique Aqui Para Enviar</span>
                                <!-- <i style="color:white!important;" class="fa fa-search"></i> -->
                        </label>    
                        <!-- <input id="file-upload" type="file" style="display:none;" accept="image/*"  @change="onFileChange" /> -->
                        <input id="file-upload" type="file" style="display:none;" accept="image/*"  capture="camera"  @change="onFileChangeUpDoc" />
                    

                        <!-- <img v-if="imgSrc"
                            :src="'data:image.png;base64,' + imgSrc"
                            class="imgUpload"
                            /> -->
                        <img v-if="imgSrcAux" :src="imgSrcAux" class="imgUpload" />
                        
                        <button v-if="image" @click="removeImage" type="button" class="botaoRemover">Remover imagem</button>

                    </span>
                    <span v-else>

                        <div style="display:flex;flex-direction:column;">
                            <label  v-if="!image" for="file-upload" style="margin-top:10px;background-color:#1560e5;width:100%;height:50px;display:flex;justify-content:center;align-items:center;border-radius:10px;">
                                    <span style="font-size:18px;font-weight:bold;color:white;">Clique Aqui Para Enviar</span>
                            </label>    
                            <v-btn large v-if="imgSrc"  small elevation="" color="success" style="margin-top:20px!important" @click="confirmarImagem()">{{textoBtnCortar}}</v-btn>    
                        </div>

                        <input id="file-upload" type="file" style="display:none;" accept="image/*"  capture="camera"  @change="onFileChange" />
                        <div style="max-height:100vh!important;max-width:90vw!important;margin-top:20px!important;padding:20px!important;">
                            <cropper v-if="imgSrc" style="margin-top:20px!mportant;" class="cropper" :src="'data:image.png;base64,' + imgSrc" :stencil-props="{ aspectRatio: 12/8 }" @change="change"></cropper>
                            <img  v-if="imgSrc" class="imgExemplo" id="imgSRC2"  ref="imgSRC2" :src="imgSRC2" style="max-width:50vw;margin-top:20px!mportant;display:none;" >
                        </div>
                    

                        <img v-if="image" class="imgUpload" :src="image" style="max-width:50vw;" >
                        <button v-if="image" @click="removeImage" type="button" class="botaoRemover">Remover imagem</button>

                    </span>

            </div>
        </div>
        
</template>

<script>
import {serializarImagemNew,resizeImage,serializarImagem} from '../../../utils/serializar'
import Compressor from 'compressorjs';

export default {
  data: () => ({
    fotoComCrop:false,
    image: '',
    imgSrc:'',
    imgSRC2:'',
    imgSrcAux:'',
    tagSincronizada:null,
    apelido:null,
    textoBtnCortar:'Cortar Imagem e Enviar',
    btnCortarHabilitado:true,
  }),
  props:["tipoDocSelecionado","idHospede","idReserva","idReservaHospede"],
  methods: {
    onFileChangeUpDoc(event){

        const file = event.target.files[0];
        // alert(1)
        if (file && file.type === 'image/jpeg') {
            this.file = file;
            this.imgSrcAux = URL.createObjectURL(event.target.files[0])
            // alert(2)
            this.uploadFileAndSave()
        } 

    },
    uploadFileAndSave() {

        var _self = this
        new Compressor(this.file, {
                quality: 0.6,
                mimeType: "image/jpeg",
                success(result) {

                        let formData = new FormData();
                        // formData.append('file', this.file);
                        formData.append('file', result, result.name);
                        formData.append('idHospede', _self.idHospede);
                        formData.append('idReserva', _self.idReserva);
                        formData.append('idReservaHospede', _self.idReservaHospede);
                        formData.append('tipo_doc', _self.tipoDocSelecionado);
                        formData.append('fk', 'docverso');
                        formData.append('nome', `DocumentoFrente_${_self.idReservaHospede}`);
                        formData.append('idtag', localStorage.getItem('tag'));
                        formData.append('emailusuario', localStorage.getItem('useremail'));
                        _self.$http.post('/imagem/upload', formData).then(async () => {
                            
                            try {
                                _self.manipularDirecionamentoAux()
                            } catch (error) {
                                alert('Erro ao salvar config, tente novamente mais tarde');
                            }

                        })
                        .catch(() => {
                            alert('Erro ao salvar config, tente novamente mais tarde');
                        });

                },
                error(err) {
                    console.log(err.message);
                },
        });

        

    },
    change({coordinates, canvas}) {
      console.log(coordinates, canvas)
      this.imgSRC2 = canvas.toDataURL('image/png')
    },
    logout(){
         this.$store.dispatch("logout").then(() => {
              window.location.href = "/"
        });
    },
    setarPasso(valor){
        this.$emit('setarPasso',valor)
    },
    async manipularDirecionamentoAux(){
        console.log("DOC TIPO:"+this.tipoDocSelecionado)
        // se for DNI ou RNE irá fazer leitura OCR da frente
        if(this.tipoDocSelecionado == "DNI"  
            || this.tipoDocSelecionado == "RNE"
            || this.tipoDocSelecionado == "OAB"
            || this.tipoDocSelecionado == "MEDICINA"
        ){
                
                //solicitar leitura OCR aqui
                /// -->
                // console.log("Enviado para leitura OCR")
                // await this.$http.post('ocr/lerDocAuthSimples',{
                //     token:'3c8478c895580214e0ff389448054854',
                //     tipo_doc:this.tipoDocSelecionado,
                //     lado:'VERSO',
                //     id:idImagem,
                //     idHospede:this.idHospede,
                //     idReserva:this.idReserva,
                //     idReservaHospede:this.idReservaHospede,
                // })
                // .then(resp =>{

                    this.textoBtnCortar = 'Cortar Imagem e Enviar'
                    this.btnCortarHabilitado = true
                    this.$emit('avancar')
                    this.setarPasso(0)
                    // Já existe o documento identificado, notificar o operador
                    // if (resp.status == 206) {
                    //     resp.data.proximopasso = 0
                    //     this.$emit('jaexiste',resp.data)
                    // } else {
                    //     if (resp.status == 200){
                    //         console.log("Leitura OCR VERSO REALIZADA")
                    //     }
                    //     this.$emit('avancar')
                    //     this.setarPasso(0)
                    // }
                // })



                // Finaliza
                this.$emit('avancar')
                this.setarPasso(0)

        }else{
            if(this.tipoDocSelecionado == "OUTROS"){
                this.setarPasso(8)
            }else{
                // se NÃO for DNI ou RNE então só finaliza
                this.$emit('avancar')
                this.setarPasso(0)
            }
        }
    },
    async manipularDirecionamento(idImagem){
        console.log("DOC TIPO:"+this.tipoDocSelecionado)
        // se for DNI ou RNE irá fazer leitura OCR da frente
        if(this.tipoDocSelecionado == "DNI"  
            || this.tipoDocSelecionado == "RNE"
            || this.tipoDocSelecionado == "OAB"
            || this.tipoDocSelecionado == "MEDICINA"
        ){
                
                //solicitar leitura OCR aqui
                /// -->
                console.log("Enviado para leitura OCR")
                await this.$http.post('ocr/lerDocAuthSimples',{
                    token:'3c8478c895580214e0ff389448054854',
                    tipo_doc:this.tipoDocSelecionado,
                    lado:'VERSO',
                    id:idImagem,
                    idHospede:this.idHospede,
                    idReserva:this.idReserva,
                    idReservaHospede:this.idReservaHospede,
                })
                .then(resp =>{

                    this.textoBtnCortar = 'Cortar Imagem e Enviar'
                    this.btnCortarHabilitado = true

                    // Já existe o documento identificado, notificar o operador
                    if (resp.status == 206) {
                        resp.data.proximopasso = 0
                        this.$emit('jaexiste',resp.data)
                    } else {
                        if (resp.status == 200){
                            console.log("Leitura OCR VERSO REALIZADA")
                        }
                        this.$emit('avancar')
                        this.setarPasso(0)
                    }
                })



                // Finaliza
                this.$emit('avancar')
                this.setarPasso(0)

        }else{
            if(this.tipoDocSelecionado == "OUTROS"){
                this.setarPasso(8)
            }else{
                // se NÃO for DNI ou RNE então só finaliza
                this.$emit('avancar')
                this.setarPasso(0)
            }
        }
    },
    async setImage(e) {
      const file = e.target.files[0];

      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.isImg = true;
          this.imgSrc = event.target.result;
          let index = event.target.result.indexOf(",") + 1;
          this.imgSrc = event.target.result.substring(index,event.target.result.length);
          if(this.imgSrc){
              this.salvaLogo(file.type)
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async salvaLogo(tipoImg) {
        if (!this.imgSrc.length > 0) {
            console.log("invalido");
            return;
        }

        let dados =   {
                        "token":'3c8478c895580214e0ff389448054854',
                        "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                        "tipo" : tipoImg,
                        "nome" : `DocumentoVerso_${this.idHospede}`,
                        "dados" : this.imgSrc,
                        "tabela": "hospede",
                        "fk": "docverso",
                        "chave": "id",
                        "valor": this.idHospede
                    }
        await this.$http.post("/imagem/upinsertAuthSimples",dados,
            {timeout:60000 }
            )
            .then((response) => {
                if(response.status == 200){
                    console.log("Documento Verso Salvo!")
                    this.manipularDirecionamento(response.data.result.imagem[0])
                }else{
                    console.log("Não foi possivel salvar Documento Verso!")
                }
            })
            .catch((error) => {
            console.log(error.response);
            });
    },
    async salvaLogoCropper(tipoImg) {
        
        if (!this.imgSrc.length > 0) {
            console.log("invalido");
            return;
        }

        let img2 = this.imgSRC2.split('base64,')[1]
        
        let dados =   {
                        "token":'3c8478c895580214e0ff389448054854',
                        "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                        "tipo" : tipoImg,
                        "nome" : `DocumentoVerso_${this.idHospede}`,
                        "dados" : img2,
                        "tabela": "hospede",
                        "fk": "docverso",
                        "chave": "id",
                        "valor": this.idHospede
                    }
        await this.$http.post("/imagem/upinsertAuthSimples",dados,
            {timeout:60000 }
            )
            .then((response) => {
        
                if(response.status == 200){
                    console.log("Documento Verso Salvo!")
                    this.manipularDirecionamento(response.data.result.imagem[0])
                }else{
                    console.log("Não foi possivel salvar Documento Verso!")
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    confirmarImagem(){
                    
            if(this.btnCortarHabilitado){

                    this.textoBtnCortar = 'Enviando...'
                    this.btnCortarHabilitado = false
                    var _self = this

                    resizeImage(this.imgSRC2, 2000).then(function (blob) {

                        var reader = new FileReader()
                        reader.readAsDataURL(blob); 
                        
                        let percentualReducao = 1
                        
                        serializarImagem(blob,percentualReducao).then(resp =>{
                                _self.salvaLogoCropper(resp.tipo)
                        })
                        
                        
        
                    }).catch(error =>{
                        console.log(error)
                    })

            }

    },
    onFileChange(e) {
        if(this.fotoComCrop){

        serializarImagemNew(e).then(resp =>{
            this.imgSrc = resp.dados
            // this.salvaLogo(resp.tipo);
        }).catch(error =>{
            this.loading = false
            this.snack = true
            this.snackColor = "error" 
            this.snackText = error.message
        })

        }else{

            serializarImagemNew(e).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaLogo(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
            
        }


    },
    createImage(file) {
        
        this.image = new Image();
        var reader = new FileReader();
        var vm = this;
       
        reader.onload = (e) => {
    
            //RESIZE
            var canvas_frente = document.createElement("canvas");
            var context_frente = canvas_frente.getContext("2d");
            const image_doc_frente = new Image();
            image_doc_frente.onload = () =>{

                    //se for deitado
                    if(image_doc_frente.width > image_doc_frente.height){
                        // canvas_frente.width = 1000;
                        // canvas_frente.height = 600;
                        canvas_frente.width = 1000;
                        canvas_frente.height = 600;
                        context_frente.drawImage(image_doc_frente, 0, 0, 1000, 600);
                    }else{
                        //se for em pe
                        // canvas_frente.width = 800;
                        // canvas_frente.height = 1000;

                        canvas_frente.width = 700;
                        canvas_frente.height = 800;
                        context_frente.drawImage(image_doc_frente, 0, 0, 700, 800);
                    }
                    

                    // this.realizarUpload(canvas_frente,this.$store.getters.get_id_web_checkin)
                    this.$emit('avancar')
                    this.setarPasso(0)
                    
            }
            image_doc_frente.src = e.target.result;
            //RESIZE
            vm.image = e.target.result;

        };
        reader.readAsDataURL(file);
    },
    removeImage: function () {
        this.image = '';
    }
  },
  mounted() {
    this.$store.dispatch("setHiddenDrawer", true)
    this.tagSincronizada = localStorage.getItem('tag')
    this.apelido = localStorage.getItem('apelido').toUpperCase()

    this.$http.post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "CAPTURAR_DOC_CROPPED",
    }).then((resp) => {

        if (resp.status == 200 && resp.data[0].valor && JSON.parse(resp.data[0].valor).cropped_ativado.toLowerCase() === 'true' ) {
            this.fotoComCrop = true
        }else{
            this.fotoComCrop = false
        } 

    }).catch((error) => {
        console.log(error);
    });

  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        color:#808080;
        margin-bottom:10px;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>