<template>
    <v-dialog v-model="exibirModalQrCode" width="500px" max-width="500px">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Leia o QR Code para Capturar Documentos e Assinatura do Hospede
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalQrCode',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <!-- <div style="display:flex;flex-direction:row;">
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch @click="toggleDoc()" id="doc-switch" v-model="doc" data-cy="doc" style="border-radius:5px;padding:5px;"></v-switch>
                        <span>Capturar Documentos</span>
                    </div>
                    <div style="font-size: 16px;display:flex;justify-content:flex-start;align-items:center;" >
                        <v-switch @click="toggleAss()" id="ass-switch" v-model="ass" data-cy="ass" style="border-radius:5px;padding:5px;"></v-switch>
                        <span>Capturar Assinatura</span>
                    </div>
                </div> -->
                <v-radio-group v-model="opcaoCaptura" row>
                    <v-radio label="Ambos" value="0"></v-radio>
                    <v-radio label="Documento" value="1"></v-radio>
                    <v-radio label="Assinatura" value="2"></v-radio>
                </v-radio-group>

                <div style="display:flex;justify-content:center;padding-bottom:50px;">
                    <VueQrcode  style="width:200px" :value="url_leitor_web" />
                </div>

            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>          

<script>


import VueQrcode from 'vue-qrcode'
export default {
    data: () => ({
        // url_leitor_web:'http://192.168.1.64:8080/captureLogin/' +localStorage.getItem('userid')     
        url_leitor_web: null,
        opcaoCaptura: "0",
    }),
    props:["exibirModalQrCode"],
    components:{
        // ModalSolicitacoesResponder
        VueQrcode 
    },
    methods: {
        toggleDoc(){
            //this.doc = !this.doc;
        },
        toggleAss(){
            //this.ass = !this.ass;
        },
        consultarSolicitacoes(reservahospede){
            console.log("PASS 1");
            this.$emit('consultarSolicitacoes',reservahospede)
        },
        setExibirModalResponder(item){
            this.solicitacao = item
            this.setModalResponder(!this.exibirModalResponder)
        },
        setModalResponder(valor){
            // alert(JSON.stringify(this.solicitacao))
            this.exibirModalQrCode = valor
        },
        setURL(val) {
            this.url_leitor_web = window.location.origin + '/captureLogin/' 
                + localStorage.getItem('userid')
                + '/' + val;
        }
    },
    watch: {
        opcaoCaptura(val) {
            this.setURL(val);
        }
    },
    mounted() {
        this.setURL(this.opcaoCaptura);
    },
};
</script>
<style scoped>
.text--black {
    color:rgb(170, 51, 51)!important;
}
</style>