<template>
  <v-dialog
    v-model="exibirModalHITS"
    width="1000"
    max-width="80vw"
  >
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 95%; color: white; font-weight: bold">
            Configurações HITS
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria="" legenda="Dúvidas Configurações HITS"/>
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="setModalHITS()"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <v-flex>
          <div
          >
          <v-row dense>
            <v-col cols="12">
              <label>URL Padrão do Ficha para o Hits</label>
              <v-text-field
                data-cy="urlPadrao"
                label="URL Padrão"
                v-model="urlapi"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <label>Property Code</label>
              <v-text-field
                data-cy="propertyCode"
                label="Property Code"
                v-model="propertycode"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <label>Tenant Name</label>
              <v-text-field
                data-cy="tenantName"
                label="Tenant Name"
                v-model="tenantname"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <label>Property ID</label>
              <v-text-field
                data-cy="propertyID"
                label="Property ID"
                v-model="propertyid"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <label>ID Cliente</label>
              <v-text-field
                data-cy="idCliente"
                label="ID Cliente"
                v-model="idcliente"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="4">
              <label>Versão API</label>
              <v-text-field
                data-cy="versaoAPI"
                label="Versão API"
                v-model="versaoapi"
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <label>URL API HITS</label>
              <v-text-field
                data-cy="urlAPIHITS"
                label="URL API HITS"
                v-model="urlapihits"
              >
              </v-text-field>
            </v-col>
          </v-row>

          </div>
        </v-flex>
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn
            @click="salvar()"
            data-cy="salvar"
            color="primary"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
export default {
  data: () => ({
    urlapi: '',
    propertycode: '',
    tenantname: '',
    propertyid: '',
    idcliente: '',
    versaoapi: '',
    urlapihits: ''
  }),
  props: ["exibirModalHITS"],
  components: {
    BotaoTutorial
},
  methods: {

    carregar() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "URLAPI",
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              this.urlapi = resp.data[0].valor;
            }
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "CONFIGURACAOHITS",
        })
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.length > 0) {
              this.propertycode = resp.data[0].valor;
              this.tenantname = resp.data[0].valor2;
              this.propertyid = resp.data[0].valor3;
              this.idcliente = resp.data[0].valor4;
              this.versaoapi = resp.data[0].valor5;
              this.urlapihits = resp.data[0].valor6;
            }
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });

    },

    salvar() {
      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "URLAPI",
          valor: this.urlapi
        })
        .then((resp1) => {
          console.log(resp1);
          this.$http
            .post("configuracao/atualizarParametro", {
              idhotel: localStorage.getItem("hotel"),
              chave: "CONFIGURACAOHITS",
              valor: this.propertycode,
              valor2: this.tenantname,
              valor3: this.propertyid,
              valor4: this.idcliente,
              valor5: this.versaoapi,
              valor6: this.urlapihits,
            })
            .then((resp) => {
              console.log(resp);
              this.setModalHITS();
            })
        })
        .catch((error) => {
          console.log(error);
        });

    },

    setModalHITS() {
      this.$emit("setModalHITS");
    },

  },
  mounted() {
    this.carregar();
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>