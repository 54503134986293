<template>
    <div class="wout-topo">
        <div style="width:15%;">
            <button class="wout-menu-voltar-btn" @click="$emit('voltar')">
                <v-icon color="white">mdi-arrow-left</v-icon>
            </button>
        </div>
        <div class="white--text text-subtitle-2 px-2" style="width:70%;">
            <div v-if="nome">{{nome}}</div>
            <div class="wout-apto-reserva" style="color:white!important;" v-if="uh||numeroreserva">
                <v-row>
                    <v-col cols="7" style="font-size:12px;font-weight:200;">
                        <span>Apto: {{uh}}</span>
                    </v-col>
                    <v-col cols="5" style="font-size:12px;font-weight:200;">
                        <span>Reserva: {{numeroreserva}}</span>
                    </v-col>
                </v-row>                    
            </div>
        </div>
        <div class="wout-power">
            <v-icon style="font-size: 28px!important;" color="white" @click="$emit('logout')">mdi-power</v-icon>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WebcheckoutHeader',
    props:["nome","uh","numeroreserva"],
    data: () =>{
        return{
        }
    },
    components: {
		
	},
    methods: {

    },
    computed: {

    },
    async mounted(){
       
    }
}
</script>

<style>
</style>