<template>

<div class="py-4" style="flex-wrap:wrap;width:100%; overflow:auto;">

            <div class="cardInputs" v-if="!naoLembroCEP" style="">
                <label class="labels">{{ $t('webcheckin.endereco.vocelembradoseucep') }}</label>
                <div class="labels" style="display:flex;">
                  <input v-model="cep_pesquisa" data-cy="input-cep" @change="buscarCEP()" 
                    type="text" class="inputs"  placeholder="Digite seu CEP" 
                    style="min-width:78%!important;max-width:78%!important;margin-right:2%;"  />
                  <button  data-cy="btn-cep" class="botaoCep" @click="buscarCEP()">
                         <!-- <i style="color:white!important;" class="fa fa-search"></i> -->
                         <v-icon style="color: white">mdi-magnify</v-icon>
                  </button>
                </div>
                <a @click="dialogCep = true" data-cy="btn-naoseimeucep" style="text-align:right;margin-top:10px;margin-right:5px;color:blue!important;font-weight:bold!important;text-decoration: underline;">{{ $t('webcheckin.endereco.naolembromeucep') }}</a>
            </div>

            <div class="cardInputs" v-if="naoLembroCEP">
                <label class="labels">Pesquise pelo Endereço</label>
                <vue-google-autocomplete ref="address" id="map"
                      @focus="adress_flag = true" @blur="adress_flag = false"
                      class="inputs" placeholder="Pesquise aqui" v-on:placechanged="getAddressData">
                </vue-google-autocomplete>
                <a @click="() => this.exibirPesquisaEndereco()" style="text-align:right;margin-top:10px;margin-right:5px;color:blue!important;font-weight:bold!important;text-decoration: underline;">Lembro do CEP</a>
            </div>

            <v-dialog v-model="dialogCep" width="500" transition="dialog-top-transition" style="background: white">
                <BuscaCep @sobeEndereco="sobeEndereco($event)" @fechar="dialogCep=false" />
            </v-dialog>
            
            <div class="cardInputs" v-if="naoLembroCEP">
                <label class="labels">CEP/ZIP CODE</label>
                <input v-model="cep" v-mask="'#####-###'" type="text" class="inputs" placeholder="CEP"/>
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.pais') }}</label>
                <select v-model="pais" data-cy="select-pais" class="inputs"  @change="listarEstados()" >
                            <option v-for="(pais, index) in paises" :key="index" :value="pais"  >
                                {{pais.nome}}
                            </option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.estado') }}</label>
                <select v-model="estado" data-cy="select-estado" class="inputs" @change="listarCidades()">
                            <option v-for="(estado, index) in estados" :key="index" :value="estado"  >
                                {{estado.nome}}
                            </option>
                </select>
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.cidade') }}</label>
                <select v-model="cidade" data-cy="select-cidade" class="inputs">
                            <option v-for="(cidade, index) in cidades" :key="index" :value="cidade"  style=" direction: rtl;" >
                                {{cidade.nome}}
                            </option>
                </select>
            </div>
            
            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.logradouro') }}</label>
                <input v-model="endereco" type="text" class="inputs"  />
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.bairro') }}</label>
                <input v-model="bairro" type="text" class="inputs" data-cy="input-bairro" />
            </div> 

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.complemento') }}</label>
                <input type="text" id="complemento" data-cy="input-complemento"  v-model="complemento" class="inputs" />
            </div>

            <div class="cardInputs">
                <label class="labels">{{ $t('webcheckin.endereco.numeroresidencial') }}</label>
                <input @keyup="nResidenciaValidate()" id="numero" v-model="numero" data-cy="input-numero" class="inputs" maxlength="8"/>
            </div>  


            <div class="cardInputsLinhaBetween" style="">
                <button v-on:click.stop.prevent="voltar()" class="botaoVoltar" data-cy="btn-enderecovoltar">
                      {{ $t('webcheckin.endereco.voltar') }}
                </button>

                <button v-on:click="toggleCopiarDados()" class="botaoConfirmar" data-cy="btn-enderecoavancar">
                        {{ $t('webcheckin.endereco.avancar') }}
                </button>
            </div> 

            <v-dialog v-model="copiardados" width="70%"  style="min-height:90vh;">
                <v-card style="min-height:50vh;display:flex;justify-content:center;align-items:center;flex-direction:column;">
                    <v-icon class="my-3">mdi-help</v-icon>
                    <h1 class="px-2">{{ $t('webcheckin.endereco.copiardados') }}?</h1>
                    <v-card-actions>
                        <button v-on:click="copiarAvancar(true)" class="botaoSim" data-cy="btn-enderecovoltar">
                            {{ $t('webcheckin.endereco.sim') }}
                        </button>

                        <button v-on:click="copiarAvancar(false)" class="botaoNao" data-cy="btn-enderecoavancar">
                                {{ $t('webcheckin.endereco.nao') }}
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                    <span>{{ snackText }}</span>
                    <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>           
</div>        

</template>

<script>
// import VueGoogleAutocomplete from "vue-google-autocomplete";

import orto from '../../utils/ortografia'
import {buscarEnderecoViaCep} from '../../utils/geo'

import BuscaCep from "../stepfd/BuscaCep.vue";
import { BUILD_TIPO_INTEGRACAO } from '../../constantes';

export default {
    components: {
        BuscaCep
    },
    props:["dados","componenteatual"],
    data: () =>{
        return{
            celularddd:null,
            dialogCep:false,
            naoLembroCEP:false,   
            // tokenJWT:null,
            cep_pesquisa:null,
            // adress_flag: false,
            // endereco_buscado: true,
            cep:null,
            endereco:null,
            bairro:null, 
            uf:null,
            // cidade:null,
            numero:null,
            complemento:null,
            paises:[],
            pais:null,
            estados:[],
            estado:null,
            cidades:[],
            cidade:null,
            endereco_compartilhado:true,
            // pais:null,
            // id_web_checkin:null,
            // step_web_checkin:null
            copiardados: false,
            snack: false,
            snackColor: '',
            snackText: '',

        }
    },
    async mounted(){

        this.cep = this.dados.cep
        this.endereco = this.dados.endereco
        this.bairro = this.dados.bairro
        this.uf = this.dados.ufsigla
        this.numero = this.dados.numero
        this.complemento = this.dados.complemento
        this.pais = this.dados.pais
        
        await this.listarPaises()
        await this.listarEstados()
        await this.listarCidades()

        

    },
    methods: {

        async sobeEndereco(dadosEndereco) {

            this.cep = dadosEndereco.cep;
            this.cep_pesquisa = this.cep;


            if(dadosEndereco.pais){
                    this.pais = await this.paises.find(pais => orto.removerAcentuacao(pais.nome.toUpperCase()) == orto.removerAcentuacao(dadosEndereco.pais.toUpperCase()))
            }

            this.ddi = this.pais.ddi
  
            if(dadosEndereco.uf){
                await this.listarEstados()
                this.estado = await this.estados.find(estado => orto.removerAcentuacao(estado.sigla.toUpperCase().trim()) == orto.removerAcentuacao(dadosEndereco.uf.toUpperCase().trim()))
            }

            if(dadosEndereco.cidade){
                await this.listarCidades()
                this.cidade = await this.cidades.find(cidade => orto.removerAcentuacao(cidade.nome.toUpperCase()) == orto.removerAcentuacao(dadosEndereco.cidade.toUpperCase()))    
            }

            this.bairro = dadosEndereco.bairro.toUpperCase();
            this.endereco = dadosEndereco.logradouro.toUpperCase();
            this.numero = dadosEndereco.numeroresidencia;
            this.complemento = dadosEndereco.complemento;
            this.dialogCep = false;
        },
        buscarCEP() {

            buscarEnderecoViaCep(this.cep_pesquisa).then(async resp =>{
                let data;
                if(BUILD_TIPO_INTEGRACAO === 'ADMH') {
                    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${resp.dados.uf}%20&components=country:BR&key=AIzaSyDLXJzzfwaQ25Q3vVfbET_1kflv5hvhsDE`
                    const response = await fetch(url)
                    data = await response.json();
                }
                this.cep = this.cep_pesquisa

                if(resp.dados.pais){
                    this.pais = await this.paises.find(pais => orto.removerAcentuacao(pais.nome.toUpperCase()) == orto.removerAcentuacao(resp.dados.pais.toUpperCase()))
                }

                if(resp.dados.uf){
                    await this.listarEstados()
                    if(BUILD_TIPO_INTEGRACAO === 'ADMH') {
                        this.estado = await this.estados.find(estado => orto.removerAcentuacao(estado.sigla.toUpperCase().trim()) == orto.removerAcentuacao(data.results[0].address_components[0].long_name.toUpperCase().trim()))
                    } else {
                        this.estado = await this.estados.find(estado => orto.removerAcentuacao(estado.sigla.toUpperCase().trim()) == orto.removerAcentuacao(resp.dados.uf.toUpperCase().trim()))
                    }
                }

                if(resp.dados.localidade){
                    await this.listarCidades()
                    this.cidade = await this.cidades.find(cidade => orto.removerAcentuacao(cidade.nome.toUpperCase()) == orto.removerAcentuacao(resp.dados.localidade.toUpperCase()))    
                }

                if(resp.dados.ddd){
                    this.celularddd = resp.dados.ddd
                }

                if(resp.dados.logradouro){
                    this.endereco = resp.dados.logradouro
                }

                if(resp.dados.bairro){
                    this.bairro = resp.dados.bairro
                }

                document.getElementById('numero').focus()

            }).catch(error => {
                 console.log("ERRO VIA CEP")
                console.log(error)
            })
          
        },
        validarFormulario(){

            if(!this.cep){
                alert("CEP é Obrigatorios")
                return false
            }

            if(!this.endereco){ 
                alert("Endereço é Obrigatorios")
                return false
            }

            if(!this.cidade){ 
                alert("Cidade é Obrigatorios")
                return false
            }

            if(!this.bairro){ 
                alert("Bairro é Obrigatorios")
                return false
            }

            if(!this.estado){ 
                alert("UF é Obrigatorios")
                return false
            }

            if(!this.numero){ 
                alert("Numero é Obrigatorios")
                return false
            }

            return true

        },
        salvarDados(){

            var dadossalvar = this.dados
            dadossalvar.cep = this.cep.replace('-', '').replace('-', '').replace('.', '').replace('.', ''),
            dadossalvar.endereco = this.endereco,
            dadossalvar.cidade = this.cidade.nome,
            dadossalvar.idcidade = this.cidade.id,
            dadossalvar.bairro = this.bairro,
            dadossalvar.iduf = this.estado.id,
            dadossalvar.ufsigla = this.estado.sigla,
            dadossalvar.ufnome = this.estado.nome,
            dadossalvar.idpais = this.pais.id,
            dadossalvar.pais = this.pais.nome,
            dadossalvar.numero = this.numero
            dadossalvar.complemento = this.complemento
            dadossalvar.endereco_compartilhado = this.endereco_compartilhado
            if(this.celularddd) {
                dadossalvar.celularddd = this.celularddd
            }

            this.$emit('salvarDados',dadossalvar,'contato')

        },
        toggleCopiarDados() {
            // Se for o hóspede principal, dá a opção de copiar o endereço
            if (this.dados.totalhospedes > 1 && this.dados.principal) {
                this.copiardados = !this.copiardados;
            } else {
                this.copiarAvancar(false);
            }
        },
        copiarAvancar(copiar) {
            this.endereco_compartilhado = copiar;
            this.avancar();
        },
        async avancar(){
            if( await this.validarFormulario() ){
                this.salvarDados()
            }            
        },
        voltar(){
            this.$emit('direcionar','motivo')
        },

        async listarPaises(){

            return new Promise((resolve, reject) => {
                this.$http.post("geo/pais/select", {
                        hotel: this.dados.hotel,
                        orderby: 'noaccent(nome)'})
                    .then(async resp =>{
                    
                                    this.paises = await resp.data.filter(p => {
                                        return p.nome != undefined;
                                    })

                                    this.paises = await resp.data.map(p => {
                                        p.nome = p.nome.toUpperCase();
                                        return p;
                                    })

                                    //this.paises = this.paises.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
                            
                                    if(this.dados.pais){
                                        // console.log(this.paises)
                                        let varpais = this.dados.pais
                                        // console.log(typeof varpais)
                                        if(typeof varpais === 'number'){
                                            this.pais = await this.paises.find(pais => pais.id == varpais)
                                        }else{
                                            this.pais = await this.paises.find(pais => pais.nome.toUpperCase() == this.dados.pais.toUpperCase())
                                        }
                                        
                                        // console.log("PAIS NA LISTARPAISES")
                                        // console.log(this.pais)
                                    }else{
                                        this.pais = await this.paises.find(pais => pais.nome.toUpperCase().trim() == 'BRASIL')
                                    }

                                    resolve(resp)

                            })
                            .catch(error =>{
                                console.log("ERRO AO BUSCAR PAISES")
                                console.log(error)
                                reject(error)
                            })
            })
            
        },
        async listarEstados(){
            return new Promise((resolve, reject) => {
                // console.log("PAIS")
                // console.log(this.pais)
                this.$http.post("geo/uf/select",{pais:typeof this.pais === 'object'?this.pais.id:this.pais})
                .then(async resp =>{


                        this.estados = await resp.data.filter(p => {
                            return p.nome != undefined;
                        });


 
                        this.estados = await this.estados.map(p => {
                            p.nome = p.nome.toUpperCase();
                            return p;
                        })


                        this.estados.forEach(estado => {
                            estado.nome = orto.removerAcentuacao(estado.nome.trim())
                        });

                        this.estados = this.estados.sort((a, b) => (orto.removerAcentuacao(a.nome.trim()) > orto.removerAcentuacao(b.nome.trim())) ? 1 : -1)

                        if(this.dados.ufnome){

                            this.estado = await this.estados.find(estado => orto.removerAcentuacao(estado.sigla.toUpperCase().trim()) == orto.removerAcentuacao(this.dados.ufsigla.toUpperCase().trim()))

                            if(!this.estado){
                                this.estado = this.estados[0]
                            }
                            await this.listarCidades()
                            
                        }else{
                            this.estado = this.estados[0]
                            await this.listarCidades()
                        }
                        resolve(resp)

                })
                .catch(error =>{
                    reject(error)
                })
            })
        },
        async listarCidades(){
            return new Promise((resolve, reject) => {
                // console.error(`listarCidades()`)
                // console.error(`this.pais.id: ${this.pais.id}`)
                // console.error(`this.estado: ${this.estado}`)
                if (this.estado) {
                    this.$http.post("geo/cidade/select",{
                        //uf: this.estado.sigla.toUpperCase(),
                        pais:typeof this.pais === 'object'?this.pais.id:this.pais,
                        uf: this.estado.id,
                        orderby: 'nome'
                    })
                    .then(async resp =>{
            
                            this.cidades = await resp.data.filter(p => {
                                return p.nome != undefined;
                            })

                            this.cidades = await resp.data.map(p => {
                                p.nome = p.nome.toUpperCase();
                                return p;
                            })

                            //this.cidades = this.cidades.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
                            //console.error(`this.dados.cidade: ${this.dados.cidade}`)
                            if(this.dados.cidade){
                                // this.cidade = await this.cidades.find(cidade => orto.removerAcentuacao(cidade.nome.toUpperCase()) == orto.removerAcentuacao(this.dados.cidade.toUpperCase()))
                                let varcidade = this.dados.cidade
                                // console.log(typeof varcidade)
                                //console.error(`varcidade: ${varcidade}`)

                                if(typeof varcidade === 'number'){
                                    this.cidade = await this.cidades.find(cidade => cidade.id == varcidade)
                                }else{
                                    this.cidade = await this.cidades.find(cidade => orto.removerAcentuacao(cidade.nome.toUpperCase()) == orto.removerAcentuacao(this.dados.cidade.toUpperCase()))
                                }

                            }else{
                                this.cidade = this.cidades[0]
                            }

                            resolve(resp)

                    })
                    .catch(error =>{
                        reject(error)
                    })
                } else {
                    resolve([])
                }
            })
        },

        nResidenciaValidate(){
            this.numero = this.numero.replace(/\D/g, "")
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
  
  
</style>