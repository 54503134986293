<template>
<v-dialog v-model="show" width="90vw" max-width="1000px" persistent>
  <Loading v-if="loading"/>
  <div style="display: flex; flex-direction: column; background-color: white;" v-if="!loading">
    <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
      <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
        <span class="toolbar-title" style="width:80%;padding:10px;"> Contatos Web Check-in </span>
        <span style="width:5%"> 
          <v-btn x-small color="red" @click="() => this.$emit('fecharModalContatosWebCheckin')" title="Fechar">
            <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
        </span>
      </div>
    </v-toolbar>
    <div style="display: flex; width: 100%; justify-content: flex-end; padding: 15px;">
      <v-btn title="Adicionar novo contato" color="#1377f2" elevation="2" icon fab @click="adicionarContato"> 
        <v-icon size="28s" color="#1377f2" >mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>
    <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="contatos"
            class="elevation-1"
          >
          <template v-slot:[`item.ativo`]="{ item }">
              <v-simple-checkbox v-model="item.ativo">
              </v-simple-checkbox>
          </template>

          <template v-slot:[`item.editar`]="{ item }">
            <button class="buttonsalvar" @click="() => editarContato(item)">Editar</button>
          </template>
      
          </v-data-table> 
  </div>
  <v-dialog v-model="modalAdicionarContato" width="60vw" max-width="500px" persistent>
  <div style="display: flex; flex-direction: column; background-color: white;">
    <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
      <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
        <span class="toolbar-title" style="width:80%;padding:10px;"> {{ textoModalAddContato }} </span>
        <span style="width:5%"> 
          <v-btn x-small color="red" @click="() => modalAdicionarContato = false" title="Fechar">
            <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
        </span>
      </div>
    </v-toolbar>
    <div style="padding: 10px; display: flex; flex-direction: column; align-items: flex-end;">
      <v-text-field v-model="novoContato.descricao" style="width: 100%;" label="Descrição" outlined></v-text-field>
      <v-text-field v-model="novoContato.contato" style="width: 100%;" label="Contato" outlined></v-text-field>
      <button class="buttonsalvar" @click="salvarEditarContato">{{ textoBotaoSalvar }}</button>
    </div>
  </div>
</v-dialog>
  <div style="display: flex; flex-direction: column; padding: 10px; width: 100%; align-items: flex-end; background-color: white;">    
    <button class="buttonsalvar" @click="salvar">Salvar</button>
  </div>
 </v-dialog>
</template>

<script>
  import Loading from './Loading.vue';
 export default {
  props:['show'],
  components: {
    Loading
  },
  data() {
    return {
      headers: [
          { text: "Descrição", value: "descricao" },
          { text: "Contato", value: "numeroendereco" },
          { text: "Ativo", value: "ativo" },
          { text: "Editar", value: "editar" },
      ],
      contatos: null,
      loading: true,
      modalAdicionarContato: false,
      novoContato: {
        id: null,
        descricao: null,
        contato: null,
        ativo: true,
      },
      textoModalAddContato: 'Novo contato',
      textoBotaoSalvar: 'Salvar'
    }
  },
  methods: {

    adicionarContato() {
      this.textoModalAddContato = 'Novo contato'
      this.novoContato = {
        id: null,
        descricao: null,
        contato: null,
        ativo: true,
      }
      this.modalAdicionarContato = true
    },

    async requisitarContatosHotel() {
      try {
       const result = await this.$http.post('/configWebCheckin/buscarDocHotel')
       console.log(result.data)
       this.contatos = result.data;
      } catch (error) {
        console.log(error)
      }
    },

    async salvarEditarContato() {
      if(!this.novoContato.contato || !this.novoContato.descricao) {
        return;
      }
      this.textoBotaoSalvar = 'Aguarde...'
      await this.$http.post('configWebCheckin/salvarContatosWebCheckin', 
      [this.novoContato])
      .then(async () => {
        this.modalAdicionarContato = false;
        await this.requisitarContatosHotel();
        this.novoContato = {
          id: null,
          descricao: null,
          contato: null,
          ativo: true,
        }
        this.textoBotaoSalvar = 'Salvar';
      })
      .catch((error) => {
        console.log(error)
        this.textoBotaoSalvar = 'Salvar';
      })
    },

    editarContato(contato) {
      this.textoModalAddContato = 'Editar contato'
      this.novoContato = {
        id: contato.id,
        descricao: contato.descricao,
        contato: contato.numeroendereco,
        ativo: contato.ativo
      }
      this.modalAdicionarContato = true;
    },

    async salvar() {
      this.loading = true;
      await this.$http.post('configWebCheckin/salvarContatosWebCheckin', 
      this.contatos)
      .then(async () => {
        this.loading = false;
        this.$emit('fecharModalContatosWebCheckin')
        this.novoContato = {
          id: null,
          descricao: null,
          contato: null,
          ativo: true,
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log(error)
      })
    }
  },
  async mounted() {
    await this.requisitarContatosHotel();
    this.loading = false;
  }
 }
</script>


<style scoped>
.buttonsalvar {
  background-color: #2B81D6;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: small;
  font-weight: 100;
  width: 100px;
}
</style>