<template>
  <v-dialog v-model="visualizarFNRH" width="1000" max-width="80vw" style="background-color: white;">
             <v-card style=" background: white">
                <v-toolbar color="blue" class="mb-8">
                 <v-flex  style="display: flex" class="pa-2">
                     <span style="width:95%;color:white;font-weight:bold;" >
                         FNRH
                     </span>
                     <div style="margin-right:50px;">
                     </div>
                     <span style="width:5%;"> 
                         <v-btn x-small color="red" @click="() => this.$emit('fecharModalVisualizarFNRH')"  title="Fechar">
                             <v-icon x-small style="color:white;">mdi-close</v-icon>
                         </v-btn>
                     </span>
                 </v-flex>
             </v-toolbar>  
                  <div style="width: 100%;padding-bottom:50px!important;padding-top:20px; padding: 10px"  >
                          <div style="display:flex;height:120px;padding:10px!important;padding-top:5px;padding-bottom:0px;">
                              <div style="width:30%">
                                  
                                  <img v-if="imgSrcLogoHotel" :src="'data:image/png;base64,' + imgSrcLogoHotel" style="width:150px!important;max-height:100px!important;"  />
      
                              </div>
                              <div v-if="this.dadosHotel && this.dadosHotel.length > 0" style="width:70%;max-height:100px;display:flex;flex-direction:column;font-size:12px;font-weight:bold!important">
                                  <div style="">FNRH - {{this.dadosHotel[0].nome}}</div>
                                  <div>CNPJ: {{this.dadosHotel[0].cnpj}} - FONE: {{this.dadosHotel[0].telefone}}</div>
                                  <div>{{this.dadosHotel[0].razaosocial}} - REG. EMBRATUR: {{this.dadosHotel[0].registroembratur}}</div>
                                  <div>{{this.dadosHotel[0].endereco}}, {{this.dadosHotel[0].numero}}, {{this.dadosHotel[0].bairro}}</div>
                                  <div>{{this.dadosHotel[0].cidade.nome}}/{{this.dadosHotel[0].uf.sigla}} - {{this.dadosHotel[0].pais.nome}} - CEP: {{this.dadosHotel[0].cep}}
                                      </div>
                              </div>
                          </div>
                          <div class="section" style="margin-top:10px;">Dados Pessoais</div>
                          <v-card-text style="padding-top:20px;padding-bottom:0px;padding-top:5px;padding-bottom:0px;">
                              <div>
                                      <v-row no-gutters>
                                          <v-col cols="4">
                                              <label class="label" for="nome">Nome Completo</label>
                                              <p class="field" id="nome" style="">{{reserva.nome}} {{reserva.sobrenome}}</p>
                                          </v-col>
                                          <v-col cols="2">
                                              <label class="label" for="sexo">Sexo</label>
                                              <p class="field" id="sexo" style="">{{reserva.sexo}}</p>
                                          </v-col>
                                          <v-col cols="3">
                                              <label class="label" for="nascimento">Nascimento</label>
                                              <p class="field" id="nascimento" style="">{{formatarData(reserva.nascimento)}}</p>
                                          </v-col>
                                          <v-col cols="3">
                                              <label class="label" for="tipodocumento">Tipo de Documento</label>
                                              <p class="field" id="tipodocumento" style="">{{reserva.tipodocumento}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters >
                                          <v-col>
                                              <label class="label" for="documento">Documento</label>
                                              <p class="field" id="documento" style="">{{reserva.documento}}</p>
                                          </v-col>
                                          <v-col>
                                              <label class="label" for="telefone">Telefone</label>
                                              <p class="field" id="telefone" style="">{{reserva.telefoneddi}} ({{reserva.telefoneddd}}) {{reserva.telefone}}</p>
                                          </v-col>
                                          <v-col>
                                              <label class="label" for="celular">Celular</label>
                                              <p class="field" id="celular" style="">{{reserva.celularddi}} ({{reserva.celularddd}}) {{reserva.celular}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters>
                                          <v-col>
                                              <label class="label" for="email">Email</label>
                                              <p class="field" id="email" style="">{{reserva.email}}</p>
                                          </v-col>
                                          <v-col>
                                              <label class="label" for="profissao">Profissão</label>
                                              <p class="field" id="profissao" style="">{{reserva.profissao}}</p>
                                          </v-col>
                                          <v-col>
                                              <label class="label" for="nacionalidade">Nacionalidade</label>
                                              <p class="field" id="nacionalidade" style="">{{reserva.nacionalidade}}</p>
                                          </v-col>
                                      </v-row>
                              </div>
                          </v-card-text>
                          <div class="section" style="margin-top:5px;">Endereço</div>
                          <v-card-text style="padding-top:5px;padding-bottom:0px;" >
                              <div>
                                  <v-row no-gutters>
                                      <v-col cols="6">
                                          <label class="label" for="endereco">Endereço</label>
                                          <p class="field" id="endereco" style="">{{reserva.endereco}}, {{reserva.numero}}{{reserva.complemento?' - '+reserva.complemento:''}}</p>
                                      </v-col>
                                      <v-col cols="3">
                                          <label class="label" for="bairro">Bairro</label>
                                          <p class="field" id="bairro" style="">{{reserva.bairro}}</p>
                                      </v-col>
                                      <v-col cols="3">
                                          <label class="label" for="cidade">Cidade / UF / País</label>
                                          <p class="field" id="cidade" style="">{{reserva.cidade}} / {{reserva.ufsigla}} / {{reserva.pais}}</p>
                                      </v-col>
                                  </v-row>
                              </div>
                          </v-card-text>
                          <div class="section" style="margin-top:-5px;">Viagem</div>
                          <v-card-text style="padding-top:5px;padding-bottom:0px;" >
                              <div>
                                  <v-row no-gutters>
                                      <v-col cols="4">
                                          <label class="label" for="empresareservante">Empresa reservante</label>
                                          <p class="field" id="empresareservante" style="">{{reserva.empresareservante}}</p>
                                      </v-col>
                                      <v-col cols="4">
                                          <label class="label" for="origem">Última procedência</label>
                                          <p class="field" id="origem" style="">{{reserva.cidadeorigem}} / {{reserva.uforigem}} / {{reserva.paisorigem}}</p>
                                      </v-col>
                                      <v-col cols="4">
                                          <label class="label" for="destino">Próximo destino</label>
                                          <p class="field" id="destino" style="">{{reserva.cidadedestino}} / {{reserva.ufdestino}} / {{reserva.paisdestino}}</p>
                                      </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                      <v-col cols="2">
                                          <label class="label" for="motivo">Motivo da viagem</label>
                                          <p class="field" id="motivo" style="">{{reserva.motivo}}</p>
                                      </v-col>
                                      <v-col cols="2">
                                          <label class="label" for="transporte">Meio de transporte</label>
                                          <p class="field" id="transporte" style="">{{reserva.transporte}}</p>
                                      </v-col>
                                      <v-col cols="2" v-if="reserva.modelo && (reserva.transporte.toLowerCase() === 'carro' || reserva.transporte.toLowerCase() === 'automóvel' || reserva.transporte.toLowerCase() === 'moto')">
                                          <label class="label" for="transporte">Modelo veículo</label>
                                          <p class="field" id="transporte" style="">{{reserva.modelo}}</p>
                                      </v-col>
                                      <v-col cols="2" v-if="reserva.placa &&  (reserva.transporte.toLowerCase() === 'carro' || reserva.transporte.toLowerCase() === 'automóvel' || reserva.transporte.toLowerCase() === 'moto')">
                                          <label class="label" for="transporte">Placa veículo</label>
                                          <p class="field" id="transporte" style="">{{reserva.placa}}</p>
                                      </v-col>
                                      <v-col cols="2">
                                          <label class="label" for="totalhospedes">Total de ocupantes</label>
                                          <p class="field" id="totalhospedes" style="">{{reserva.totalhospedes}}</p>
                                      </v-col>
                                      <v-col cols="2">
                                          <label class="label" for="numeroreserva">Número da reserva</label>
                                          <p class="field" id="numeroreserva" style="">{{reserva.numeroreserva}}</p>
                                      </v-col>
                                  </v-row>
                                  <v-row>
                                      <v-col cols="2">
                                          <label class="label" for="uh">UH</label>
                                          <p class="field" id="uh" style="">{{reserva.uh}}</p>
                                      </v-col>
                                      <v-col cols="3">
                                          <label class="label" for="checkin">Checkin</label>
                                          <p class="field" id="checkin" style="">{{formatarData(reserva.datacheckinprevisto)}}</p>
                                      </v-col>
                                      <v-col cols="3" v-if="reserva.horariochegada">
                                          <label class="label" for="horariochegada">Horário de Chegada</label>
                                          <p class="field" id="horariochegada" style="">{{formatarHora(reserva.horariochegada)}}</p>
                                      </v-col>
                                      <v-col cols="2">
                                          <label class="label" for="checkout">Checkout</label>
                                          <p class="field" id="checkout" style="">{{formatarData(reserva.datacheckoutprevisto)}}</p>
                                      </v-col>
                                      <v-col cols="2"  v-if="reserva.horariopartida">
                                          <label class="label" for="horariopartida">Horário de Partida</label>
                                          <p class="field" id="horariopartida" style="">{{formatarHora(reserva.horariopartida)}}</p>
                                      </v-col>
                                  </v-row>
                              </div>
                          </v-card-text>
                          <div v-show="formcovid" v-if="relatorioexibido=='COVID'" style="margin-top:-5px!important;" >
                              <div class="section" style="margin-top:10px;">{{formcovid.descricao}}</div>
                              <v-card-text>
                                  <div v-for="resposta in formcovid.respostas" :key="resposta.id">
                                      <v-row no-gutters>
                                          <v-col cols="8">
                                              <p class="field">{{resposta.pergunta}}</p>
                                          </v-col>
                                          <v-col cols="4">
                                              <p class="field">{{resposta.texto}}</p>
                                          </v-col>
                                      </v-row>
                                  </div>
                              </v-card-text>
                          </div>
                          <div v-if="relatorioexibido=='FNRH'" class="section" style="margin-top:10px;">Documento e Assinatura</div>
                          <v-card-text v-if="relatorioexibido=='FNRH'">
      
                              <div style="display:flex;">
                                  <div style="width:50%;display:flex;justify-content:center;">
                                      <img v-if="imgSrcFrente" :src="'data:image/png;base64,' + imgSrcFrente" style="max-height:200px;max-width:250px!important;" class="imgUpload" id="imgDocFrente" />
                                  </div>
      
                                  <div style="width:50%;display:flex;justify-content:center;">
                                      <img v-if="imgSrcVerso" :src="'data:image/png;base64,' + imgSrcVerso"   style="max-height:200px;max-width:250px!important;" class="imgUpload" id="imgDocVerso" />
                                  </div>
                              </div>
                              <div>
                                  
                                  <v-row no-gutters>
                                      <v-spacer />
                                      <v-col cols="6" style="display:flex;justify-content:center;">
                                    
                                          
                  
                                      </v-col>
                                      <v-col cols="6" style="display:flex;justify-content:center;">
      
                                          
                                      </v-col>
                                      <v-spacer />
                                  </v-row>
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:0px;margin-bottom:0px!important;">
                                      
                                      <v-col cols="4" style="display:flex;justify-content:center;" >
      
                                          <img v-if="imgSrcAssinatura" :src="'data:image/png;base64,' + imgSrcAssinatura" style="max-width:180px!important;min-width:200px!important;"  id="imgAssinatura" />
                                           
                                      </v-col>
                                      
                                  </v-row>
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;padding-bottom:10px!important;">
                                      
                                      <v-col style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
                                      
                                          <div style="width: 66.6%; text-align: center; display: block; border-bottom: 0px solid black;">
                                              __________________________________________
                                          </div>
                                          <div style="width: 66.6%; text-align: center; display: block;">{{reserva.nome}} {{reserva.sobrenome}}</div>
                                      
                                      </v-col>
                                      
                                  </v-row>
      
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:20px;margin-bottom:0px!important;">
                                      
                      
                                      <div >
                                          <input disabled="true" type="checkbox" id="endereco_compartilhado" name="endereco_compartilhado" 
                                              v-model="reserva.termolgpd" true-value="true" false-value="false" 
                                              style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                                          <label class="containerCheck" style="margin-left:20px;min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                                              <span class="checkmarkCheck" ></span>Aceito Termos da LGPD
                                          </label>
                                      </div>
                                  </v-row>
      
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:20px;margin-bottom:0px!important;">
                                      
                      
                                      <div>
                                          <input disabled="true" type="checkbox" id="endereco_compartilhado" name="endereco_compartilhado" 
                                              v-model="reserva.aceitetermo" true-value="true" false-value="false" 
                                              style="margin-left:10px;margin-right:0px;-ms-transform: scale(2);-webkit-transform: scale(2);-o-transform: scale(2);transform: scale(2);">
                                          <label class="containerCheck" style="margin-left:20px;min-width:100%;margin-top:20px;font-size:18px;margin-bottom:10px;">
                                              <span class="checkmarkCheck" ></span>Aceito os termos de responsabilidade do hotel
                                          </label>
                                      </div>
                                  </v-row>
                              </div>
                          </v-card-text>
      
      
                           <v-card-text v-if="relatorioexibido=='COVID'">
                              <div>
                                  <v-row no-gutters>
                                      <div>
                                          O usuário declara, sob as penas da lei, que os dados fornecidos
                                          no cadastro são verídicos e se referem à sua pessoa e/ou
                                          empresa, sendo o usuário, único e exclusivo responsável por
                                          qualquer dano causado pela inveracidade ou má-fe no
                                          fornecimento destes dados.
                                      </div>
                                  </v-row>
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;margin-top:0px;margin-bottom:0px!important;">
                                      
                                      <v-col cols="4" style="display:flex;justify-content:center;" >
      
                                          <img v-if="imgSrcAssinatura" :src="'data:image/png;base64,' + imgSrcAssinatura" style="max-width:200px!important;min-width:200px!important;"  />
                                           
                                      </v-col>
                                      
                                  </v-row>
                                  <v-row no-gutters style="display:flex;align-items:center;justify-content:center;padding-bottom:30px!important;">
                                      
                                      <v-col style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
                                      
                                          <div style="width: 66.6%; text-align: center; display: block; border-bottom: 0px solid black;">
                                              __________________________________________
                                          </div>
                                          <div style="width: 66.6%; text-align: center; display: block;">{{reserva.nome}} {{reserva.sobrenome}}</div>
                                      
                                      </v-col>
                                      
                                  </v-row>
                              </div>
                          </v-card-text>
                  </div>
              </v-card>
      </v-dialog>
</template>

<script>

  export default {
    data() {
      return {
        reserva: null,
        relatorioexibido:'FNRH',
        imgSrcFrente:'',
        imgSrcVerso:'',
        imgSrcAssinatura:'',
        imgSrcLogoHotel:'',
        dadosHotel:null,
      }
    },
    props: ['dados', 'visualizarFNRH'],
    watch: {
      dados: {
        handler: 'buscarDadosReserva',
        immediate: true
      }
    },
    methods: {
      formatarData(aDate) {
            return this.moment(aDate).format('DD/MM/YYYY')
        },
        formatarHora(aDate) {
            return this.moment(aDate).format('HH:MM')
        },
      async buscarDadosReserva(dados) {
        console.log('dados do compoenente >>>>>>>>>>', dados)
                        this.reserva = dados;
                        await this.$http.post('perfil/hotel/select', { id: localStorage.getItem('hotel') }).then(async (response) => {
                            this.dadosHotel = response.data

                            if(this.dadosHotel[0].logo){
                            
                                await this.$http.post('imagem/select', { id: this.dadosHotel[0].logo }).then(async (imghotel) => {
                                    this.imgSrcLogoHotel = await Buffer.from(imghotel.data[0].dados).toString()
                                    this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"
                                    await this.buscarImagem('docfrente')
                                    await this.buscarImagem('docverso')
                                    await this.buscarImagem('assinatura')
                                    
                                })

                            }else{
                                    this.nomedoRelatorio = this.reserva.numeroreserva+"-"+this.reserva.nome.replace(' ','-')+"-"+this.reserva.sobrenome.replace(' ','-')+"-"
                                    await this.buscarImagem('docfrente')
                                    await this.buscarImagem('docverso')
                                    await this.buscarImagem('assinatura')
                            }     
                        })
        },
        async buscarImagem(campo) {
            if(campo == "docfrente"){

                // imagem frente
                await this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
                .then(async (responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
                    // var img = document.getElementById(campo)
                    // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                  }
                }).catch((error) => { 
                    console.log(error); 
                });

            }

            if(campo == "docverso"){
                
                // imagem verso
                this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: this.reserva[campo],})
                    .then(async (responseimg) => {
                    if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                        this.imgSrcVerso = Buffer.from(responseimg.data[0].dados).toString()
                        // var img = document.getElementById(campo)
                        // img.src = await Buffer.from(responseimg.data[0].dados).toString()
                    }
                    })
                    .catch((error) => { console.log(error); });
            }

            if(campo == "assinatura"){

                if (this.reserva.idpessoa && this.reserva.id) {
                    this.$http.post('/imagem/select', { 
                        id: this.reserva[campo]
                    })
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.imgSrcAssinatura = Buffer.from(resp.data[0].dados).toString()
                        }
                    })
                } else {
                    alert('ainda sem reserva...')
                }
            }

            }
    }
  }
</script>

<style scoped>
.label {
    font-size: 8pt!important;
    font-weight: bold;
}
.field {
    font-size: 11pt!important;
    font-weight: bold;
}
.section {
    font-size: 11pt!important;
    color: white;
    background-color: #6187a5;
    padding: 0.2rem;
    border-radius: 5px 5px 0px 0px;
}
</style>