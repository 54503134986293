const dayjs = require('dayjs')

const formatarData = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YYYY')
    } else {
        return ''
    }
}

const formatarDataHora = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YYYY HH:mm')
    } else {
        return ''
    }
}

const formatarDataYYHora = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YY HH:mm')
    } else {
        return ''
    }
}

const formatarDataYYHHMMSS = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YY HH:mm:ss')
    } else {
        return ''
    }
}

const formatarDataDDMM = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM')
    } else {
        return ''
    }
}

const formatarDataDDMMAA = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD/MM/YY')
    } else {
        return ''
    }
}

const formatarMoeda = (value) => {
    if (value) {
        var v = parseFloat(value).toFixed(2);
        v = v.replace(".", ",");
        v = v.replace(/,(\d)\b/g, ",$10");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        return v;
    } else {
        return '0,00'
    }
}

module.exports = {
    formatarData,
    formatarDataHora,
    formatarDataYYHora,
    formatarDataYYHHMMSS,
    formatarDataDDMM,
    formatarDataDDMMAA,
    formatarMoeda
}