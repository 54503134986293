<template>
  <div>
    <v-card>
      <v-toolbar color="blue">
        <span style="width:90%">Endereço</span>
        <span style="width:5%">
          <BotaoTutorial style="margin-left:20px;" urlVideo="" categoria="" legenda="Dúvidas Endereço"/>
        </span>
        <span style="width:5%"> 
          <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
            <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
        </span>
      </v-toolbar>
      <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
      <v-form v-model="valid" lazy-validation ref="form">
        <v-card-text>
          <v-row>
            <v-text-field
              label="CEP / Zip Code"
              class="ml-2 mr-2"
              @change="[buscarEndereco(cep), validate()]"
              @keyup="[buscarEndereco(cep), validate()]"
              v-model="cep"
              :rules="cepRules"
              style="max-width: 100px"
              data-cy="endereco-cep"
              required
              maxlength="8"
              :disabled="carregado?false:true"
            />
            <v-btn
              small
              color="orange"
              class="mt-4 mr-4"
              title="Pesquisar CEP"
              data-cy="endereco-btnbuscarcep"
              @click="buscarEndereco(cep)"
              :disabled="carregado?false:true"
              ><v-icon small>mdi-magnify</v-icon></v-btn
            >
            <v-btn
              small
              color="orange"
              class="mt-4"
              title="Não sei o CEP"
              @click="dialogCep = true"
              :disabled="carregado?false:true"
              data-cy="endereco-btnnaoseicep"
              >Não sei o CEP</v-btn
            >
          </v-row>
          <v-row class="d-flex justify-space-between">
            
            <span data-cy="endereco-pais-span" style="width: 32.5%">
              <v-select
                data-cy="endereco-pais"
                id="endereco-pais"
                append-icon="mdi-map"
                label="País"
                :items="paises"
                item-value="id"
                item-text="nome"
                v-model="pais"
                class="ml-2 mr-2"
                @change="[validate(), listarUfs(pais.id)]"
                :rules="paises.length>0?selectsRules:[true]"
                required
                return-object
              >
              </v-select>
            </span>
            <span data-cy="endereco-uf-span" style="width: 32.5%">
              <div style="display: flex; align-items: center">
                <v-select
                  data-cy="endereco-uf"
                  id="endereco-uf"
                  append-icon="mdi-map-marker-multiple"
                  label="UF"
                  class="mr-2"
                  v-model="uf"
                  :items="ufList"
                  item-text="sigla"
                  item-value="id"
                  @change="[validate(), listarCidades(uf)]"
                  required
                >
                  <template v-slot:item="{ on, item }">
                      <v-list-item v-on="on" :disabled="!item.habilitado">
                        <div class="v-list-item__content">
                          <div class="v-list-item__title">
                            {{item.sigla}}
                          </div>
                        </div>
                      </v-list-item>
                  </template>
                </v-select>
                <v-btn small @click="abrirModalHabilitarDesabilitar('uf')">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </div>
              <v-dialog 
                v-model="modalHablitarDesabilitar" 
                v-if="tipoModalHabilitarDesabilitar == 'uf'" 
                width="60vw">
                  <v-card height="20vh" class="pa-5">
                    <v-card-title>Habilitar ou Desabilitar Estados</v-card-title>

                    <v-card-actions>
                      <v-autocomplete
                        append-icon="mdi-map-marker-multiple"
                        :items="ufList"
                        item-text="sigla"
                        item-value="id"
                        required
                        placeholder="Busque o estado"

                      >
                        <template v-slot:item="{ on, item }">
                            <v-list-item v-on="on" @click.stop="() => habilitarDesabilitarEstado(item)">
                              <div class="v-list-item__content">
                                <div class="v-list-item__title">
                                  <v-btn type="button" 
                                    dark
                                    dense
                                    :color="item.habilitado ? '#FF0000' : null"
                                    v-if="item.habilitado">
                                    <v-icon v-if="item.habilitado">mdi-archive-arrow-down-outline</v-icon>
                                    <v-icon v-if="!item.habilitado">mdi-archive-arrow-up-outline</v-icon>
                                  </v-btn>
                                  <v-btn type="button" 
                                    dark
                                    dense
                                    :color="!item.habilitado ? '#32CD32' : null"
                                    v-if="!item.habilitado">
                                    <v-icon v-if="item.habilitado">mdi-archive-arrow-down-outline</v-icon>
                                    <v-icon v-if="!item.habilitado">mdi-archive-arrow-up-outline</v-icon>
                                  </v-btn>
                                  {{item.sigla}}
                                </div>
                              </div>
                            </v-list-item>
                        </template>
                        </v-autocomplete>
                    </v-card-actions>
                  </v-card>
              </v-dialog>
            </span>
            <span data-cy="endereco-cidade-span" style="width: 32.5%">
              <div style="display: flex; align-items: center">
                <v-select
                  data-cy="endereco-cidade"
                  id="endereco-cidade"
                  label="Cidade"
                  :items="cidadeList"
                  item-value="id"
                  item-text="nome"
                  class="mr-2"
                  v-model="cidade"
                  @change="[validate()]"
                  required
                  :rules="cidadeList.length>0?selectsRules:[true]"
                  return-object
                >
                  <template v-slot:item="{ on, item }">
                      <v-list-item v-on="on" :disabled="!item.habilitado">
                        <div class="v-list-item__content">
                          <div class="v-list-item__title">
                            {{item.nome}}
                          </div>
                        </div>
                      </v-list-item>
                  </template>
                </v-select>
                <v-btn small @click="abrirModalHabilitarDesabilitar('cidade')">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
                <!-- <v-btn small @click="abrirModalCriarCidade" v-if="typeof uf == 'object' && uf.hasOwnProperty('sigla') && uf.sigla == 'EX'">
                  <v-icon>mdi-plus-thick</v-icon>
                </v-btn> -->
                
              </div>
              <v-dialog 
                v-model="modalHablitarDesabilitar" 
                v-if="tipoModalHabilitarDesabilitar == 'cidade'" 
                width="60vw">
                  <v-card height="20vh" class="pa-5">
                    <v-card-title>Habilitar ou Desabilitar Cidades</v-card-title>

                    <v-card-actions>
                      <v-autocomplete
                        append-icon="mdi-map-marker-multiple"
                        :items="cidadeList"
                        item-text="nome"
                        item-value="id"
                        required
                        placeholder="Busque a cidade"
                      >
                        <!-- <template slot="item" slot-scope="data">
                        
                        </template> -->
                        <template v-slot:item="{ on, item }">
                            <v-list-item v-on="on" @click.stop="() => habilitarDesabilitarCidade(item)">
                              <div class="v-list-item__content">
                                <div class="v-list-item__title">
                                  <v-btn type="button" 
                                    dark
                                    dense
                                    :color="item.habilitado ? '#FF0000' : null"
                                    v-if="item.habilitado">
                                    <v-icon v-if="item.habilitado">mdi-archive-arrow-down-outline</v-icon>
                                    <v-icon v-if="!item.habilitado">mdi-archive-arrow-up-outline</v-icon>
                                  </v-btn>
                                  <v-btn type="button" 
                                    dark
                                    dense
                                    :color="!item.habilitado ? '#32CD32' : null"
                                    v-if="!item.habilitado">
                                    <v-icon v-if="item.habilitado">mdi-archive-arrow-down-outline</v-icon>
                                    <v-icon v-if="!item.habilitado">mdi-archive-arrow-up-outline</v-icon>
                                  </v-btn>
                                  {{item.nome}}
                                </div>
                              </div>
                            </v-list-item>
                        </template>
                      </v-autocomplete>  
                    </v-card-actions>                  
                  </v-card>
              </v-dialog>
              <v-dialog 
                v-model="modalCriarCidade" 
                width="60vw">
                  <v-card height="20vh" class="pa-5">
                    <v-card-title>Criar Nova Cidade</v-card-title>

                    <v-card-actions>
                      <v-text-field 
                        v-on:keyup.enter="(e) => inserirCidade(e)"
                        placeholder="Insira uma nova cidade"
                      />
                    </v-card-actions>                  
                  </v-card>
              </v-dialog>              
            </span>
          </v-row>

          <v-row>
            <v-text-field
              label="Bairro"
              append-icon="mdi-text"
              class="ml-2 mr-2"
              v-model="bairro"
              data-cy="endereco-bairro"
              style="width: 150px"
              :rules="fieldsRules"
              @keypress="preventApostrophe"
              @input="removeApostrophe"
              @keyup="[validate(), bairroToUpper()]"
              @blur="validate()"
              required
            />
            <v-text-field
              label="Logradouro"
              class="mr-2"
              append-icon="mdi-text"
              v-model="logradouro"
              data-cy="endereco-logradouro"
              style="width: 200px"
              :rules="fieldsRules"
              @keypress="preventApostrophe"
              @input="removeApostrophe"
              @keyup="[validate(), logradouroToUpper()]"
              @blur="validate()"
              required
            />
            <v-text-field
              label="Nº"
              id="numeroresidencia"
              append-icon="mdi-counter"
              class="mr-2"
              v-model="numeroresidencia"
              maxLength="8"
              style="width: 50px"
              :rules="fieldsRules"
              @blur="validate()"
              required
              @keyup="nResidenciaValidate()"
              data-cy="endereco-numerores"
              v-if="tipoIntegracao === 'TOTVSSQLSERVER' || tipoIntegracao === 'TOTVSORACLE'"
              type="number"
              
            />
            <v-text-field
              label="Nº"
              id="numeroresidencia"
              append-icon="mdi-counter"
              class="mr-2"
              v-model="numeroresidencia"
              maxLength="8"
              style="width: 50px"
              :rules="fieldsRules"
              @blur="validate()"
              required
              @keyup="nResidenciaValidate()"
              data-cy="endereco-numerores"
              v-else
            />
            <v-text-field
              label="Complemento"
              append-icon="mdi-text"
              data-cy="endereco-complemento"
              v-model="complemento"
              class="ml-2 mr-2"
              @keyup="[complementoToUpper(), validate()]"
              @keypress="preventApostrophe"
              @input="removeApostrophe"
              @blur="validate()"
              required
            />
          </v-row>
          <v-row style="width: 400px; margin: 10px 0 20px -5px;" >
            <vue-phone-number-input ref="phoneNumberInput" :key="phoneNumberKey" id="telefoneHospede" :show-code-on-list="true" :default-country-code="defaultDDI" v-model="telefoneHospede" :required="true" :border-radius="10" size="lg" @update="validarNumero($event)"/>
            <!-- <v-text-field
              class="ml-2 mr-2"
              style="width: 10px"
              label="DDI"
              append-icon="mdi-phone"
              data-cy="endereco-ddi"
              v-model="ddi"
              :rules="fieldsRules"
              @keyup="[validate(), clearNonMumeric(ddi,'ddi')]"
              @blur="validate()"
              required
            />
            <v-text-field
              class="mr-2"
              style="width: 10px"
              label="DDD"
              append-icon="mdi-phone"
              v-model="ddd"
              data-cy="endereco-ddd"
              :rules="fieldsRules"
              @keyup="[validate(), clearNonMumeric(ddd,'ddd')]"
              @blur="validate()"
              required
            />
            <v-text-field
              class="mr-2"
              style="width: 50px"
              label="Celular"
              append-icon="mdi-phone"
              data-cy="endereco-celular"
              v-model="numerocelular"
              :rules="fieldsRules"
              @keyup="[validate(), clearNonMumeric(numerocelular,'numerocelular')]"
              @blur="validate()"
              required
            /> -->
          </v-row>
          <div style="text-align: center">
            <v-spacer></v-spacer>
            <!-- <v-btn style="color:white;"
              color="light-green"
              small
              @click="salvarPasso()"
              :disabled="!valid"
              >Salvar</v-btn
            > -->
            <v-btn style="color:white;" v-if="btnSalvarHabilidado&&possuiEmpresaReservante"
              color="light-green"
              medium
               class="mb-4 mt-4"
              @click="salvarPasso()"
              :disabled="!valid"
              data-cy="endereco-btnsalvar"
              >Salvar</v-btn
            >
            <v-btn style="color:white;font-weight:bold" v-else
              color="light-green"
              medium
               class="mb-4 mt-4"
              :disabled="true"
              >{{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}</v-btn
            >
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
            <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
              <span>{{ snackText }}</span>
              <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
            </div>
        </v-snackbar>
      </v-form>
    </v-card>
    <v-dialog
      v-model="dialogCep"
      width="500"
      transition="dialog-top-transition"
      style="background: white"
    >
      <busca-cep @sobeEndereco="sobeEndereco($event)" @fechar="dialogCep=!dialogCep" />
    </v-dialog>
  </div>
</template>
<script>
import buscaCep from "./BuscaCep";
import orto from '../../utils/ortografia'
import dayjs from 'dayjs'
import { BUILD_TIPO_INTEGRACAO } from '@/constantes.js'
import BotaoTutorial from "../material/BotaoTutorial.vue";
export default {
  props: ["idreserva", "idhospede", "possuiEmpresaReservante"],
  name: "EnderecoFd",
  data: () => ({
    carregado:false,
    idreservapms:null,
    idhospedepms:null,
    horaCheckin: '14:00',
    horaCheckout: '16:00',
    dialogCep: false,
    principal:null,
    cep: "",
    pais: "",
    paises: [],
    uf: "",
    ufList: [],
    cidade: "",
    cidadeList: [],
    bairro: "",
    logradouro: "",
    idAtualizarDataHospede: null,
    numeroresidencia: "",
    complemento: "",
    ddi: "",
    ddd: "",
    numerocelular: "",
    isWhatsApp: false,
    valid: true,
    cepRules: [(v) => !!v || "Campo Obrigatório"],
    fieldsRules: [(v) => !!v || "Campo Obrigatório"],
    selectsRules: [(v) => !!v || "Campo Obrigatório"],
    select: null,
    snack: false,
    snackColor: "",
    snackText: "",
    btnSalvarHabilidado:true,
    motivoViagemDefault: {id:1,descricao:'Turismo'},
    meioTransporteDefault: {id:1,descricao:'Avião'},
    modalHablitarDesabilitar: false,
    tipoModalHabilitarDesabilitar: '',
    listaModalHabilitarDesabilitar: [],
    modalCriarCidade: false,
    urlVideo: " ",
    titleModal: "Teste",
    defaultDDI: null,
    numeroValido: false,
    telefoneFormatoInternacional: null,
    telefoneHospede: null,
    phoneNumberKey: 0,
    tipoIntegracao: localStorage.getItem('tipointegracao')
  }),
  components: {
    buscaCep,
    BotaoTutorial
},
  methods: {
    preventApostrophe(event) {
      if (event.key === "'") {
        event.preventDefault();
      }
    },
    removeApostrophe(name) {
      const tipointegracao = localStorage.getItem('tipointegracao');
      if (tipointegracao.toUpperCase() !== 'HITS' && tipointegracao.toUpperCase() !== 'ADMH') {
        return name.replace(/'/g, '');
      }
      return name;
    },
    validarNumero(numero) {
        this.numeroValido = numero?.isValid
        this.ddi = numero?.countryCallingCode
        this.defaultDDI = numero?.countryCode;
    },
    abrirModalCriarCidade(){
      this.modalCriarCidade = true
    },
    abrirModalHabilitarDesabilitar(tipo){
      this.modalHablitarDesabilitar = true
      this.tipoModalHabilitarDesabilitar = tipo
    },
    inserirCidade(e){
      let novacidade = {
        codigoibge: 'null',
        nome: e.target.value,
        estado: 50,
        pais: 1001
      }
      this.$http.post('/geo/cidade/upinsert', novacidade)
      .then(() => this.listarCidades(50, null, null, 1001))
      .catch((e) => alert(e.response.data.message))
    },
    habilitarDesabilitarEstado(uf){
      this.$http.post('/geo/uf/habilitado', {uf}).then(() => {
        if(typeof this.pais == 'object'){
          this.listarUfs(this.pais.id)
        }else{
          this.listarUfs(this.pais)
        }
      }).catch(e => alert(JSON.stringify(e)))
    },
    habilitarDesabilitarCidade(cidade){
      this.$http.post('/geo/cidade/habilitado', {cidade}).then(() => {
        if(typeof this.uf == 'object'){
          if(this.uf.id == 50){
            this.listarCidades(this.uf.id, null, null, 1001)
            return
          }
          this.listarCidades(this.uf.id)
        }else{
          if(this.uf == 50){
            this.listarCidades(this.uf, null, null, 1001)
            return
          }
          this.listarCidades(this.uf)
        }
      }).catch(e => alert(JSON.stringify(e)))
    },
    clearNonMumeric(fieldValue,fieldName) {
      this[fieldName] = fieldValue.replace(/\D/g,'');
      return fieldValue
    },    
    translate(texto,find,subst) {
      var result = texto
      if (find.length == subst.length) {
        for (var j = 0; j < find.length; j++) {
          result = result.replace(find[j],subst[j])
        }
      }
      return result
    },
    noaccent(texto) {
      var aux = '';
      aux = this.translate(texto , 'áàâãä', 'aaaaa');
      aux = this.translate(aux , 'ÁÀÂÃÄ', 'AAAAA');
      aux = this.translate(aux , 'éèêë', 'eeee');
      aux = this.translate(aux , 'ÉÈÊË', 'EEEE');
      aux = this.translate(aux , 'íìîï', 'iiii');
      aux = this.translate(aux , 'ÍÌÎÏ', 'IIII');
      aux = this.translate(aux , 'óòõôö', 'ooooo');
      aux = this.translate(aux , 'ÓÒÕÔÖ', 'OOOOO');
      aux = this.translate(aux , 'úùûü', 'uuuu');
      aux = this.translate(aux , 'ÚÙÛÜ', 'UUUU');
      aux = this.translate(aux , 'ç', 'c');
      aux = this.translate(aux , 'Ç', 'C');	
      return aux;
    },
    prepare(x) {
      var y = ''
      if (x) {
        y = x.trim()
        y = this.noaccent(y)
        y = y.toLowerCase()
      }

      return y
    },
    async sobeEndereco(dadosEndereco) {

      this.cep = null
      this.pais = null
      this.ddi = null
      this.ddd = null
      this.uf = null
      this.cidade = null
      this.bairro = null
      this.logradouro = null
      this.numeroresidencia = null


      this.cep = dadosEndereco.cep;
      this.pais = this.buscarPais(dadosEndereco.pais)
      if (!this.pais) {
        alert('nao tem esse pais')
        this.pais = this.buscarPaisPadrao()
      }
      this.ddi = this.pais.ddi
      await this.listarUfs(this.pais.id)
      this.uf = await this.buscarUF(dadosEndereco.uf)  
      if(!Object.keys(this.uf).length){
        if(BUILD_TIPO_INTEGRACAO == 'HITS'){
          if(this.pais != 1){
            this.uf = this.ufList[0]
          }
          this.bairro = dadosEndereco.bairro.toUpperCase();
          this.logradouro = dadosEndereco.logradouro.toUpperCase();
          this.numeroresidencia = dadosEndereco.numeroresidencia;
          this.dialogCep = false;
          return
        }
        // percorrer lista de ufs e encontrar o primeiro uf com cidades e seta-lo 
        for(let i=0, leni = this.ufList.length;i<leni;i++){
          let uf = this.ufList[i]
          await this.listarCidades(uf.id, null, dadosEndereco.cidade)
          if(this.cidadeList.length > 0){
            this.uf = uf
            this.cidade = this.cidadeList[0].id
            break
          }
        }       
      }else{
        await this.listarCidades(this.uf.id, null, dadosEndereco.cidade,this.pais.id)
      }    
      this.bairro = dadosEndereco.bairro.toUpperCase();
      this.logradouro = dadosEndereco.logradouro.toUpperCase();
      this.numeroresidencia = dadosEndereco.numeroresidencia;
      this.dialogCep = false;
    },
    buscarPaisPadrao() {
      var result = null
      for (var i = 0; i < this.paises.length; i++) {
        if (this.paises[i]['padrao']) {
          result = this.paises[i]
          break
        }
      }
      if(result){
        return result
      }else{
        return this.paises[0]
      }
    },
    buscarPais(nomepais) {
      var result = null
      for (var i = 0; i < this.paises.length; i++) {
        if (this.prepare(this.paises[i]['nome']) == this.prepare(nomepais)) {
          result = this.paises[i]
          break
        }
      }
      return result
    },
    async buscarCidade(nomecidade) {
      // var result = {}
      let objetoRetorno = {}
      if(nomecidade && nomecidade.trim() != '') {
        
        // for (var i = 0; i < this.cidadeList.length; i++) {
        //   if (this.prepare(this.cidadeList[i]['nome']) === this.prepare(nomecidade)) {
        //     result = this.cidadeList[i]['id']
        //     break
        //   }
        // }

        objetoRetorno = await this.cidadeList.find((cidade) =>{ 
            return orto.removerAcentuacao(cidade.nome.trim().toUpperCase()) == orto.removerAcentuacao(nomecidade.trim().toUpperCase())
        })

        // await this.cidadeList.forEach((cidade)=>{
        //   console.log(cidade.id+" : -"+cidade.nome+"-")
        // })
        

      }
      if(objetoRetorno){
        return objetoRetorno.id
      }else{
        return 0
      }
    },
    buscarUF(ufsigla) {
      var result = {}
      console.log(this.ufList)
      for (var i = 0; i < this.ufList.length; i++) {
        if (this.prepare(this.ufList[i]['sigla']) == this.prepare(ufsigla)) {
          result = this.ufList[i]
          break
        }
      }
      return result
    },
    async set(endereco) {
      if (endereco) {

        this.pais = null
        this.uf = null
        this.cidade = null
        this.bairro = null
        this.logradouro = null
        this.complemento = null
        this.ddd = null
        this.ddi = null





        
        this.ddd = endereco.ddd
        this.pais = this.buscarPaisPadrao()
        this.ddi = this.pais ? this.pais.ddi : ''

        await this.listarUfs()
        if(BUILD_TIPO_INTEGRACAO === 'ADMH') {
          this.uf = await this.buscarUF(endereco.ufADMH)
        } else {
          this.uf = await this.buscarUF(endereco.uf)
        }

        this.logradouro = endereco.logradouro
        this.bairro = endereco.bairro
        this.complemento = endereco.complemento

        await this.listarCidades(this.uf, endereco.localidade, null)
        this.cidade = await this.buscarCidade(endereco.localidade)
        var el = document.getElementById('numeroresidencia')
        if (el) el.focus()
      } else {
        this.pais = this.buscarPaisPadrao()
        this.ddi = this.pais.ddi
        this.listarUfs()
        this.uf = ''
        this.logradouro = ''
        this.bairro = ''
        this.complemento = ''
      }
    },
    buscarEndereco(cep) {
      this.cep = this.cep.replace(/\D/g, "");
      if (cep.length >= 8) {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(this.cep)) {
          var req = new XMLHttpRequest()
          req.open('GET', `https://viacep.com.br/ws/${cep}/json/`)
          var end = null
          req.onreadystatechange = async () => {
            if (req.readyState === 4) {
              if (req.status === 200) end = JSON.parse(req.response)
              // const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${end.uf}%20&components=country:BR&key=AIzaSyDLXJzzfwaQ25Q3vVfbET_1kflv5hvhsDE`
              // const response = await fetch(url)
              // const data = await response.json();
              // end.ufADMH = data.results[0].address_components[0].long_name
              this.set(end)
            }
          }
          req.send()
        } else {
          this.snack = true;
          this.snackColor = "orange";
          this.snackText = "Formato de CEP inválido.";
        }
      } else {
        this.set(null)
      }
    },
    async atualizaDataAlteracaoHospede() {
      try {
        await this.$http.post('/reserva/atualizar-data-alteracao-reserva', {
          id: this.idAtualizarDataHospede
        })
      } catch (error) {
        alert('Erro ao atualizar data de atualização do hospede');
      }
    },
    listarDados() {
      this.$http
        .post("/reserva/select", {
          hotel: localStorage.getItem("hotel"),
          idhospede: this.idhospede,
          idreserva: this.idreserva
        })
        .then(async (response) => {
          this.idAtualizarDataHospede = response.data[0].id
          this.btnSalvarHabilidado = !response.data[0].enviadopms
          this.principal = response.data[0].principal
          this.cep = response.data[0].cep;
          if(response.data[0].idpais){
            this.pais = response.data[0].idpais;
          }

          this.idreservapms = response.data[0].idreservapms;
          this.idhospedepms = response.data[0].idhospedepms;

          await this.listarUfs(this.pais)
          this.uf = await this.buscarUF(response.data[0].ufsigla)

          await this.listarCidades(this.uf,response.data[0].cidade,null);
          this.cidade = await this.buscarCidade(response.data[0].cidade)

          this.bairro = this.removeApostrophe(response.data[0].bairro);
          this.logradouro = this.removeApostrophe(response.data[0].endereco);
          this.numeroresidencia = response.data[0].numero;
          this.complemento = this.removeApostrophe(response.data[0].complemento);
          this.defaultDDI = response.data[0].country_code;
          if (!this.defaultDDI) {
            this.defaultDDI = 'BR';
          }
          this.telefoneHospede = `${response.data[0].celularddd}${response.data[0].celular}`
          this.phoneNumberKey += 1;
          if (response.data[0].motivo) this.motivoViagemDefault = response.data[0].motivo;
          if (response.data[0].transporte) this.meioTransporteDefault = response.data[0].transporte;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    paisToUpper() {
      this.pais = this.pais.toUpperCase();
    },
    ufToUpper() {
      this.uf = this.uf.toUpperCase();
    },
    cidadeToUpper() {
      this.cidade = this.cidade.toUpperCase();
    },
    bairroToUpper() {
      this.bairro = this.bairro.toUpperCase();
    },
    logradouroToUpper() {
      this.logradouro = this.logradouro.toUpperCase();
    },
    complementoToUpper() {
      this.complemento = this.complemento.toUpperCase();
    },
    avisoCep() {
      this.snack = true;
      this.snackColor = "green";
      this.snackText = "Use a busca por CEP";
    },
    async listarPaises() {
      await this.$http.post("/geo/pais/select", {
        hotel: localStorage.getItem("hotel"),
        orderby: 'noaccent(nome)'}).then((response) => {
        this.paises = orto.ordenarArray(response.data,'nome');
       
        for(let i =0,len =this.paises.length; i < len; i++){
          if(this.paises[i].padrao == true){
             this.pais = this.paises[i].id
          }
        }

        if(!this.pais){
          this.pais = this.paises[0].id
        }
        
      });
    },
    async listarUfs(paisL) {

      if(BUILD_TIPO_INTEGRACAO == 'HITS' && 1==2){

        if(this.pais && typeof this.pais != "number" && (this.pais.nome.toUpperCase().trim() != 'BRASIL' && this.pais.nome.toUpperCase().trim() != 'BRAZIL')){

            this.ufList = [{
              codigoibge:"0",
              id:null,
              idioma:"pt-BR",
              nome:"EX",
              nomesemacento:"EX",
              pais:this.pais.id,
              sigla:"EX"
            }]
            this.uf = this.ufList[0]

            this.cidadeList = [{
              codigoibge:null,
              estado:0,
              habilitado:true,
              id:0,
              idioma:"pt-BR",
              nome:"EX",
              nomesemacento:"EX",
              pais:this.pais.id,

            }]
            this.cidade = this.cidadeList[0]
            // this.listarCidades(this.uf,null,null, this.pais.id)
          // });

            try {
              this.ufList = this.ufList.sort(function(x, y) {
                  return (x.habilitado === y.habilitado)? 0 : x.habilitado? -1 : 1;
              });
            } catch (error) {
              alert(error)
            }
            return 

        }


      }
      await this.$http.post("/geo/uf/select", { pais : paisL || this.pais.id }).then((response) => {
        this.ufList = response.data;
        if (this.uf) {
          var index = -1
          for (var i = 0; i < this.ufList.length; i++) {
            var obj = this.ufList[i]
            var valueToCompare = typeof(this.uf) == 'string' ? this.uf : this.uf.sigla
            if (obj.sigla == valueToCompare) {
              index = i
              break
            }
          }
          if (index == -1) {
            this.uf = ''
          }
        }

        try {
          this.ufList = orto.ordenarArray(this.ufList,'sigla')
        } catch (error) {
          alert(error)
        }
        
        try {
          this.ufList = this.ufList.sort(function(x, y) {
              return (x.habilitado === y.habilitado)? 0 : x.habilitado? -1 : 1;
          });
        } catch (error) {
          alert(error)
        }

        this.listarCidades(this.uf,null,null)
      });
    },
    async listarCidades(ufL,ibge,nomecidade, idpais) {
      // buscar as cidades de acordo com a uf e setar conforme o código do ibge informado
      var uf = ufL || this.uf
      if (uf) {
        var params = { 
          uf: typeof(uf) == 'object' ? uf.id : uf,
          orderby: 'nome',
          pais: idpais ? idpais : typeof(this.pais)=='object'?this.pais.id:this.pais
        }
        await this.$http
          .post("/geo/cidade/select", params)
          .then((response) => {
            this.cidadeList = response.data;
            var obj = {}
            var index = -1
            if (ibge) {
              index = -1
              for (var i = 0; i < this.cidadeList.length; i++) {
                obj = this.cidadeList[i]
                if (obj.codigoibge == ibge) {
                  index = i
                  break
                }
              }
            } else {
              if (nomecidade) {
                  for (var k = 0; k < this.cidadeList.length; k++) {
                    obj = this.cidadeList[k]
                    if (this.prepare(obj.nome.toUpperCase().trim()) == this.prepare(nomecidade.toUpperCase().trim())) {
                      index = k
                      break
                    }
                  }                
              } else {
                if (this.cidade) {
                  for (var j = 0; j < this.cidadeList.length; j++) {
                    obj = this.cidadeList[j]
                    if (obj.id == this.cidade.id) {
                      index = j
                      break
                    }
                  }
                }
              }
            }

           

            try {
              this.cidadeList = this.cidadeList.sort(function(x, y) {
                  return (x.habilitado === y.habilitado)? 0 : x.habilitado? -1 : 1;
              });
            } catch (error) {
              alert(error)
            }

            if (index > -1) {
              this.cidade = this.cidadeList[index]
            } else {
              this.cidade = {}
            }

            try {
              this.cidadeList = orto.ordenarArray(this.cidadeList,'nome')
            } catch (error) {
              alert(error)
            }

          });
      } else {
        this.cidadeList = []
        this.cidade = null
      }
    },
    async carregarHorarioPadrao() {
      this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HORARIOPADRAOWEBCHECKIN'
        }).then(resp =>{
            if(resp.status == 200){
              if(resp.data.length>0) {
                var horarioCheckin = dayjs(resp.data[0].valor).format("HH:mm")
                var horarioCheckout = dayjs(resp.data[0].valor2).format("HH:mm") 
                this.horaCheckin  = horarioCheckin ? horarioCheckin : '12:00'
                this.horaCheckout = horarioCheckout ? horarioCheckout : '15:00'
              }
            }else{
                alert("Não foi possivel buscar os dados")
            }
        })
        .catch(error =>{
            console.log(error)
        })
    },    
    async carregarDefaultMotivo() {
      await this.$http.post("/geo/motivo/select",{
        "idioma" : "pt-BR"
      }).then((response) => {
        if (response.data.length > 0)
          this.motivoViagemDefault = response.data[0];
      });
    },
    async carregarDefaultTransporte() {
      await this.$http.post("/geo/transporte/select",{
        "idioma" : "pt-BR"
      }).then((response) => {
        if (response.data.length > 0)
          this.meioTransporteDefault = response.data[0];
      });
    },

    salvarPasso() {

      if (!this.telefoneHospede) {
        alert('Telefone obrigatório!')
        return;
      }

      if (!this.numeroValido) {
        this.phoneNumberKey += 1;
          if (!this.numeroValido) {
            alert('Número de telefone inválido!')
            return
          }
      }

      var regexMumeroTeste = /^[0-9]+$/;

      const apenasNumeros = regexMumeroTeste.test(this.telefoneHospede);
      console.log('apenas Numeros', apenasNumeros)

      const regex = /(?:\((\d{1,2})\)|^(\d{1,2})\s|(\d+))(?:-(.+))?/;

      const match = regex.exec(this.telefoneHospede);
      console.log(match)
      let numero;
      let ddd;
      if (match && !apenasNumeros) {
        numero = this.telefoneHospede.slice(match?.index + match[0]?.length);
        console.log(numero)
        if (!numero || numero === '') {
          for (let i = 4; i <= 7; i++) {
             if (match[i]) {
               numero = match[i];
               break;
             }
           }
        }
        ddd = match[1] || match[2] || match[3];
      }
      if (!ddd || ddd === '') {
        ddd = this.telefoneHospede.substring(0, 2);
      }
      
      if (!numero || numero === '') {
        numero = this.telefoneHospede.slice(2);
      }
      this.ddd = ddd;
      this.numerocelular = numero.replace(/\s/g, '').replace(/-/g, "");
      let body = {
        reserva: {
          id: this.idreserva,
          hotel: localStorage.getItem("hotel"),
          idreservapms:this.idreservapms
        },
        hospede: {
          id: this.idhospede,
          idhospedepms:this.idhospedepms,
          principal:this.principal,
          celularddi: this.ddi.trim(),
          celularddd: this.ddd.trim(),
          celular: this.numerocelular.trim(),
          defaultDDI: this.defaultDDI,
          paisorigem: this.pais && typeof this.pais === 'object' ? this.pais.id:this.pais,
          cidadeorigem: this.cidade && typeof this.cidade === 'object' ? this.cidade.id:this.cidade,
          uforigem: this.uf && typeof this.uf   === 'object' ? this.uf.id:this.uf,
          paisdestino: this.pais && typeof this.pais === 'object' ? this.pais.id:this.pais,
          ufdestino : this.uf && typeof this.uf   === 'object' ? this.uf.id:this.uf,
          cidadedestino: this.cidade && typeof this.cidade === 'object' ? this.cidade.id:this.cidade,
          // horariochegada: "2025-12-31 " + this.horaCheckin,
          // horariopartida: "2025-12-31 " + this.horaCheckout,
          motivo: this.motivoViagemDefault.id,
          transporte: this.meioTransporteDefault.id,
          enderecos: [
            {
              tipo: 2,
              endereco: this.logradouro.trim(),
              numero: this.numeroresidencia,
              complemento: this.complemento ? this.complemento.trim() : null,
              cep: this.cep.replace("-","").replace(".","").replace("-","").replace(".","").trim(),
              bairro: this.bairro.trim(),
              cidade: this.cidade && typeof this.cidade === 'object' ? this.cidade.id:this.cidade,
              estado: this.uf && typeof this.uf   === 'object' ? this.uf.id:this.uf,
              pais: this.pais && typeof this.pais === 'object' ? this.pais.id:this.pais
            },
          ],
        },
      };

      //passo EXTREMAMENTE necessário devido a questão das duplicidades de cidades,estados e paises
      //baseado na cidade, vou verificar o estado e o pais real.
      if(body.hospede.enderecos[0].cidade){

          this.$http.get('/geo/checkpaisestado/'+body.hospede.enderecos[0].cidade).then(response =>{
            
              let dados = response.data
              if(dados && dados.length > 0 && dados.id > 0 && dados.estado > 0 && dados.pais > 0 ){

                  body.hospede.enderecos[0].pais    = dados.pais
                  body.hospede.enderecos[0].estado  = dados.estado
                  body.hospede.enderecos[0].cidade  = dados.id

                  body.hospede.paisorigem     = dados.pais
                  body.hospede.uforigem       = dados.estado
                  body.hospede.cidadeorigem   = dados.id

                  body.hospede.paisdestino     = dados.pais
                  body.hospede.ufdestino       = dados.estado
                  body.hospede.cidadedestino   = dados.id


              }
          })

        
      }

    body.tipo_edicao = 1; // 1 = Ficha; 2 = Web Checkin
    
      this.$http.post("/reserva/upinsert", body)
        .then(async (response) => {
          if (response.status == 200) {
            await this.atualizaDataAlteracaoHospede();
            this.snack = true;
            this.snackColor = "success";
            this.snackText = "Salvo com sucesso!";
            this.reset();
              //setTimeout(() => {
                this.$emit("avancar");
              //}, 1500);
            this.$emit("update")
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível salvar os dados. Verifique.";
        });
    },

    nResidenciaValidate(){
      this.numeroresidencia = this.numeroresidencia.replace(/\D/g, "")
    }

  },

  async mounted() {
    await this.carregarDefaultTransporte();
    await this.carregarDefaultMotivo();
    await this.carregarHorarioPadrao();
    await this.listarPaises();
    await this.listarUfs(this.pais);
    await this.listarDados();
    await this.validate();
    this.carregado = true
  },
};
</script>


<style lang="scss" scoped>
.btn-desabilitar{
  background: red  ;
}
.btn-habilitar{
  background: green ;
}

.pop-up_habilitar{
  position: fixed !important;
  inset: 0 !important;
  backdrop-filter: blur(2px) !important;
}


</style>
