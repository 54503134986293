// https://vuex.vuejs.org/en/state.html

export default {
  authStatus: '',
  token: localStorage.getItem('token') || '',
  ultimostatus: JSON.parse(sessionStorage.getItem('ultimostatus'))||{},
  tipoacesso: JSON.parse(sessionStorage.getItem('tipoacesso'))||{},
  pagina: JSON.parse(sessionStorage.getItem('pagina'))||{},
  user: {},
  tableList: [],
  hiddenDrawer: false,
  fichaDigital : {}
}
