


<template>
    

    <v-flex md12 sm12 lg4>
        <material-chart-card
          style="font-size: smaller;"
          :data="data"
          :options="options"
          :color="cor"
          type="Pie"
        >
          <h4>
            {{titulo}}
          </h4>
          <p class="category d-inline-flex font-weight-light my-1">
           <v-icon color="green" small style="margin-right:10px;">{{iconesecundario}}</v-icon>
           <span style="font-weight:bold;">{{titulosecundario}}</span>
          </p>

        </material-chart-card>
    </v-flex>

</template>


<script>
export default {
    data: () => ({
        styleIcone:'',
        data: null,
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
    }),
    props:["cor","icone","titulo","valor","iconesecundario","titulosecundario","tagTitle","data","options"],
    mounted(){
        this.styleIcone = "background-color:"+this.cor+";position:relative;width:80px;height:70px;border-radius:5px;top:-25px!important;display:flex;justify-content:center;"
        if (this.data && this.data.series) {
          this.data.series = this.data.series[0]
        }
    },
    watch: {
      data (val) {
        if (val) {
          if (val.series && Array.isArray(val.series)) { 
            val.series = val.series.length>0 ? [...val.series[0]] : val.series
          }
          return val
        }
      }
    }
}
</script>
