<template>
  <v-dialog v-model="exibirModalEditarTermo" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width: 90%; color: white; font-weight: bold">
            Editar o Termo de Serviço do Hotel
          </span>
          <span style="width:5%">
            <BotaoTutorial style="margin-right:30px;" urlVideo="" categoria=""
              legenda="Dúvidas Termo de Serviço do Hotel" />
          </span>
          <span style="width: 5%">
            <v-btn x-small color="red" @click="setModalEditarTermo()" title="Fechar">
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <!-- <v-card> -->
        <div style="font-size: 16px; display: flex; flex-direction: column">
          <label style="margin-bottom: 10px">Faça o Upload do Termo de Serviço ( PDF )</label>
          <input type="file" data-cy="inputfile" id="inputFile" accept="application/pdf" @change="onFileChange" />
        </div>
        <div v-if="nomeTermo"
          style="margin-top:10px;font-weight:bold;background-color:#c9e7ff;color:#14254299;height:30px;display:flex;align-items:center;padding-left:10px;">
          Arquivo Adicionado: {{nomeTermo}}
        </div>
        <div style="margin-top:10px;">
          <v-btn @click="savePdf()" :disabled="this.nomeTermo === null">
            <v-icon color="primary">mdi-download</v-icon>
            <b>Baixar</b>
            <!-- <v-icon color="red">mdi-file-pdf</v-icon> -->
          </v-btn>
        </div>

        <div style="margin-top:20px;font-size: 16px;display:flex;justify-content:flex-start;align-items:center;">
          <v-switch v-model="exibirTermoWebCheckin" data-cy="exibirtermowebcheckin"> </v-switch>
          <label>Exibir Termo no Web Checkin?</label>
          <!-- <select v-model="exibirTermoWebCheckin" style="border:1px solid #868686;border-radius:5px;padding:5px;">
                            <option value="True">SIM</option>
                            <option value="False">NÃO</option>
                        </select> -->
        </div>

        <div style="margin-top:20px;font-size: 16px;display:flex;justify-content:flex-start;align-items:center;">
          <v-switch v-model="enviarTermo" data-cy="enviarTermo"> </v-switch>
          <label>Enviar Termo ao hóspede quando finalizar a ficha?</label>
        </div>

        <!-- <div style="display:flex;justify-content:center;padding-bottom:10px;">
                        <textarea v-model="textoEmail"  style="width:100%;min-height:200px;background-color:white;padding:10px;border:1px solid #bdbaba;border-radius:10px;">
                        </textarea>
                    </div> -->
        <div style="display: flex; justify-content: flex-end; margin-top: 50px">
          <v-btn @click="salvarExibicaoTermo()" data-cy="salvarExibicaoTermo" color="primary">
            Salvar
          </v-btn>
        </div>
        <!-- </v-card> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>          

<script>
import BotaoTutorial from '../../material/BotaoTutorial.vue';
import { downloadPdfTermoHotel } from '../../../servicos/download.servico';
var httpaux;
const salvarTermoAux = async (tipoImg,imgSrc) => {

      if (!imgSrc.length > 0) {
        console.log("invalido");
        return;
      }

      let dados = {
        tipo: tipoImg,
        nome: "termo",
        dados: imgSrc,
        tabela: "hotel",
        fk: "termo",
        chave: "id",
        valor: localStorage.getItem("hotel"),
      };

      await httpaux.post("/imagem/upinsert", dados, { timeout: 60000 })
        .then((response) => {
          if (response.status == 200) {
            console.log("Foto do Usuario Salvo com Sucesso!");
          } else {
            console.log("Não foi possivel salvar a Foto do Usuario!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });

    }
export default {
  
  data: () => ({
    textoEmail:
      "Olá @nome, te desejamos um feliz aniversário repleto de felicidades.",
    isImg: null,
    imgSrc: null,
    imgTipo: null,
    exibirTermoWebCheckin: null,
    enviarTermo: null,
    nomeTermo:null
  }),
  props: ["exibirModalEditarTermo"],
  components: {
    BotaoTutorial
},
  methods: {
    savePdf() {
      let idHotel = localStorage.getItem("hotel");
      downloadPdfTermoHotel(idHotel)
    },
    onFileChange() {

      //Read File
        var selectedFile = document.getElementById("inputFile").files;
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                this.imgSrc = fileLoadedEvent.target.result;
 

                salvarTermoAux("application/pdf",this.imgSrc)
                // Print data in console
                console.log(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    },
    salvarExibicaoTermo() {
      // console.log("SALVAR EXIBIÇÃO TERMO: ", this.exibirTermoWebCheckin)
      // console.log("SALVAR ENVIAR TERMO: ", this.enviarTermo)

      this.$http
        .post("configuracao/atualizarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "EXIBIRTERMOHOTEL",
          valor: this.exibirTermoWebCheckin,
        })
        .then((resp) => {
          // alert(JSON.stringify(resp))
          if (resp.status == 200) {
            this.$http
              .post("configuracao/atualizarParametro", {
                idhotel: localStorage.getItem("hotel"),
                chave: "ENVIARTERMOHOTEL",
                valor: this.enviarTermo,
              })
              .then((result) => {
                if (result.status == 200) {
                  this.setModalEditarTermo();
                } else {
                  alert("Não foi alterar os dados de exibição do termos");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert("Não foi alterar os dados de exibição do termos");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setModalEditarTermo() {
      this.$emit("setModalEditarTermo");
    },
    carregarExibirTermo(){
      this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "EXIBIRTERMOHOTEL",
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.exibirTermoWebCheckin = resp.data && resp.data.length > 0 &&
            (resp.data[0].valor == true || resp.data[0].valor == 'true');
        } else {
          alert("Não foi possivel buscar os dados");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    carregarEnviarTermo(){
      this.$http
      .post("configuracao/buscarParametro", {
        idhotel: localStorage.getItem("hotel"),
        chave: "ENVIARTERMOHOTEL",
      })
      .then((resp) => {
        if (resp.status == 200) {
          this.enviarTermo  = resp.data && resp.data.length > 0 &&
            (resp.data[0].valor == true || resp.data[0].valor == 'true');
        } else {
          alert("Não foi possivel buscar os dados");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    httpaux = this.$http
    //busca o email de aniversariante configurado

    // console.log("EXIBIR TERMO MOUNTED: ", this.exibirTermoWebCheckin)
    // console.log("ENVIAR TERMO MOUNTED: ", this.enviarTermo)
    
    this.carregarExibirTermo()

    this.carregarEnviarTermo()

    this.$http.post("perfil/hotel/select",{id:localStorage.getItem("hotel")})
    .then(resp => {
      console.log("resp.data 12")
      console.log(resp.data[0].termo) 
        if(resp.data[0].termo){
            this.$http.post("/imagem/select", {id:resp.data[0].termo}, { timeout: 60000 })
                                  .then((response) => {
                                    this.nomeTermo = response.data[0].nome
                                      // const uint8Array = new Uint8Array(response.data[0].dados.data);
                                      // this.termoSrc = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
                                      // this.termoTipo = response.data[0].tipo;
                                  })
                                  .catch((error) => {
                                      console.log(error);
                                  });
        }
    })
    .catch((error)=>{
      console.log(error)
    })
  },
};
</script>


<style scoped>
/* Customize the label (the container) */
.inputcheckbox {
  height: 200px;
}
</style>