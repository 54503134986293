function buscarEnderecoViaCep(cep){
    return new Promise((resolve, reject) =>{

        try {
            
                cep = cep.replace(/\D/g, "");
                if (cep.length == 8) {

                    var validacep = /^[0-9]{8}$/;
        
                    if (validacep.test(cep)) {

                            var req = new XMLHttpRequest()
                            req.open('GET', `https://viacep.com.br/ws/${cep}/json/`)
                            var endereco = null

                            req.onreadystatechange = () => {

                                if (req.readyState === 4) {

                                    if (req.status === 200) {
                                        endereco = JSON.parse(req.response)
                                        endereco.pais = "Brasil"
                                        //console.log("Endereço via CEP")
                                        //console.log(endereco)
                                        resolve({status:200, dados:endereco}) 
                                    }else{
                                        resolve({status:400, msg:"CEP não Encotrado"}) 
                                    }
                                    
                                }
        
                            }

                            req.send()
        
                    } else {
                        console.log("error 1")
                        reject({status:400, msg:"CEP Inválido"}) 
                    }
        
                } else {
                    console.log("error 2")
                    reject({status:400, msg:"CEP com Tamanho Inválido"}) 
                }

        } catch (error) {
            reject({status:400, msg:error})
        }


    })
    
}

function validarCPF (strCPF) {
    var Soma;
    var Resto;
    Soma = 0;

    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export {
    buscarEnderecoViaCep,
    validarCPF
}