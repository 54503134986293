<template>
  <div class="header-wrapper">
    <!-- <button type="button" data-cy="menu-btn">
      <v-icon color="#f9f9fe" large>mdi-menu</v-icon>
    </button> -->
    <div class="header-info" v-if="dados">
      <h1>{{dados.nome}}</h1>
      <span>
        <p>{{dados.uh}}</p>
        <p>{{`Reserva: ${dados.numeroreserva}`}}</p>
      </span>
    </div>
    <div class="grow"/>
    <!-- <button type="button">
      <v-icon color="#f9f9fe" large>mdi-account-circle-outline</v-icon>
    </button> -->
  </div>
</template>

<script>
export default {
  props: ['dados'],
  data(){
    return {
      visible: false
    }
  },
  methods: {
    direcionar(rota){
      this.$emit('direcionar', rota)
    },
    open(){
      this.visible = true
    },
    close(){
      this.visible = false
    }
  }
}
</script>

<style lang='scss' scoped>
.header-wrapper{
  position: sticky;
  background-color: #1760e5;
  height: 4em;
  padding: 0 .5em;
  display: flex;
  align-items: flex-start;
  padding-top: 1em;
  color: #f9f9fe;
  width: 30vw;
  margin: 0 auto;
  top: 0;
  // border-radius: 0 0 1em 1em;
  @media screen and (max-width: 768px){
    border-radius: unset;
    width: 100%;
    top: 0;
  }
}


.header-info{
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1{
    font-size: 16px !important;
    letter-spacing: 0.02em;
    color: #f9f9fe;
  }

  >span{
    display: flex;
    align-items: center;
    p{
      font-size: 14px;
      font-weight: bold !important;
      letter-spacing: 0.02em;
      color: #3BA0EE;
      &:nth-child(1){
        margin-right: .5em;
      }
    }
  }
}

//css indesejado
.v-application p{
  margin-bottom: unset !important;
}



</style>