<template>
  <v-card>
    <v-toolbar color="blue">
      <span style="width:95%">Procedência e Destino</span>
      <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Origem/Destino"/>
      </span>
      <span style="width:5%"> 
          <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
      </span>
    </v-toolbar>
    <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
    <v-form v-model="valid" lazy-validation ref="form">
      <v-card-text style="display: flex;">
        <v-col class="mx-1">
          <v-row>
            <v-flex>
              <div data-cy="titulo-procedencia" style="padding-bottom:1vh;">
                <span><strong>Última Procedência</strong></span>
              </div>
            </v-flex>
          </v-row>
          <v-row class="my-2">
            <span data-cy="origem-paisorigem-span">
              <v-select
                data-cy="origem-paisorigem"
                id="origem-paisorigem"
                :items="paisOrgList"
                item-value="id"
                item-text="nome"
                class="ml-2"
                label="País Origem"
                append-icon="mdi-map"
                style="width: 200px; padding-top: 5px"
                v-model="paisOrigem"
                :rules="selectsRules"
                @change="[listarUfsOrigem(),validate()]"
                required
              >
              </v-select>
            </span>
            <span data-cy="origem-uforigem-span">
              <v-select
                data-cy="origem-uforigem"
                id="origem-uforigem"
                :items="ufOrgList"
                item-value="sigla"
                item-text="sigla"
                class="ml-2"
                label="UF Origem"
                append-icon="mdi-map-marker-multiple"
                style="width: 200px; padding-top: 5px"
                v-model="ufOrigem"
                :rules="selectsRules"
                @change="[listarCidadesOrigem(),validate()]"
                required
              >
              </v-select>
            </span>
            <span data-cy="origem-cidadeorigem-span">
              <v-select
                :items="cidadeOrgList"
                data-cy="origem-cidadeorigem"
                id="origem-cidadeorigem"
                item-value="id"
                item-text="nome"
                class="ml-2"
                label="Cidade Origem"
                append-icon="mdi-map-marker"
                style="width: 200px; padding-top: 5px"
                v-model="cidadeOrigem"
                :rules="selectsRules"
                @change="validate()"
                required
              >
              </v-select>
            </span>

            <v-text-field
              class="ml-2"
              type="time"
              label="Horario IN"
              style="width: 100px; padding-top: 5px"
              value="14:00"
              v-model="horaCheckin"
              @blur="[validate()]"
              data-cy="origem-checkin"
              required
            />
            <div style="width:100%;display:flex;">
            <v-select
              class="ml-2"
              :items="meiosTransporte"
              data-cy="origem-transporte"
              id="origem-transporte"
              item-value="id"
              item-text="descricao"
              label="Meio de Transporte"
              v-model="meioTransporte"
              :rules="selectsRules"
              @change="[checkTransporte(),validate()]"
              style="max-width:40%!important;"
              required
            ></v-select>
            <div id="textPlaca" style="display:none!important;width: 30%;">
              <v-text-field 
                data-cy="origem-placa"
                class="ml-2"
                label="Placa"
                style=""
                v-model="placa"
                :counter="8"
                maxlength="8"
              />
            </div>
            <div id="textModelo" style="display:none!important;width: 30%;">
              <v-text-field 
                data-cy="origem-modelo"
                class="ml-2"
                label="Modelo"
                counter="20"
                style=""
                v-model="modelo"
                @keyup="checkLength()"
              />
            </div>
            </div>
          </v-row>
        </v-col>
        <v-col class="mx-1">
          <v-row>
            <v-flex>
              <div style="padding-bottom:1vh;">
                <span><strong>Próximo Destino</strong></span>
              </div>
            </v-flex>
          </v-row>
          <v-row>
            <span data-cy="destino-pais-span">
              <v-select
                :items="paisDestList"
                item-value="id"
                item-text="nome"
                class="ml-2"
                label="País Destino"
                data-cy="destino-pais"
                id="destino-pais"
                append-icon="mdi-map"
                style="width: 200px; padding-top: 5px"
                v-model="paisDestino"
                :rules="selectsRules"
                @change="[listarUfsDest(),validate()]"
                required
              >
              </v-select>
            </span>

            <span data-cy='destino-uf-span'>
              <v-select
                :items="ufDestList"
                item-value="sigla"
                item-text="sigla"
                class="ml-2"
                label="UF Destino"
                data-cy="destino-uf"
                id="destino-uf"
                append-icon="mdi-map-marker-multiple"
                style="width: 200px; padding-top: 5px"
                v-model="ufDestino"
                :rules="selectsRules"
                @change="[validate(), listarCidadesDestino()]"
                required
              >
              </v-select>
            </span>
            <span data-cy="destino-cidade-span">
              <v-select
                :items="cidadeDestList"
                item-value="id"
                item-text="nome"
                class="ml-2"
                label="Cidade Destino"
                append-icon="mdi-map-marker"
                style="width: 200px; padding-top: 5px"
                v-model="cidadeDestino"
                :rules="selectsRules"
                @change="validate()"
                required
                data-cy="destino-cidade"
                id="destino-cidade"
              >
              </v-select>
            </span>

            <v-text-field
              class="ml-2"
              type="time"
              label="Horario OUT"
              style="width: 100px; padding-top: 5px"
              value="12:00"
              data-cy="destino-checkout"
              v-model="horaCheckout"
              @blur="[validate()]"
              required
            />
          </v-row>
          <v-row>
            <v-select
              class="ml-2"
              :items="motivosViagem"
              item-text="descricao"
              item-value="id"
              label="Motivo da Viagem"
              data-cy="destino-motivo"
              id="destino-motivo"
              v-model="motivoViagem"
              :rules="selectsRules"
              @change="validate()"
              required
            ></v-select>
          </v-row>
        </v-col>
      </v-card-text>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
            <span>{{ snackText }}</span>
            <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
          </div>
      </v-snackbar>
      <div style="text-align: center">
        <v-spacer></v-spacer>
        <!-- <v-btn style="color:white;"
          color="light-green"
          class="mb-4"
          small
          @click="salvarPasso()"
          :disabled="!valid"
          >Salvar</v-btn
        > -->
         <v-btn style="color:white;" v-if="btnSalvarHabilidado&&possuiEmpresaReservante"
              color="light-green"
               class="mb-4 mt-4"
              medium
              @click="salvarPasso()"
              data-cy="destino-btnsalvar"
              :disabled="!valid"
              >Salvar</v-btn
            >
            <v-btn style="color:white;font-weight:bold" v-else
              color="light-green"
               class="mb-4 mt-4"
              medium
              :disabled="true"
              >{{possuiEmpresaReservante?'FICHA ESTÁ FINALIZADA':'FICHA BLOQUEADA'}}</v-btn
            >
        <v-spacer></v-spacer>
      </div>
    </v-form>
  </v-card>
</template>
<script>

import dayjs from 'dayjs'
import orto from '../../utils/ortografia'
import {BUILD_PAIS_INICIAL_PADRAO} from '@/constantes.js'
import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  props: ["idreserva", "idhospede", "possuiEmpresaReservante"],
  name: "OrigemDestinoFd",
  components: {
    BotaoTutorial
  },
  data: () => ({
    idreservapms:null,
    idAtualizarDataHospede: null,
    idhospedepms:null,
    paisOrgList: [],
    principal:null,
    ufOrgList: [],
    cidadeOrgList: [],

    paisOrigem: BUILD_PAIS_INICIAL_PADRAO,
    ufOrigem: "",
    cidadeOrigem: "",

    paisDestino: BUILD_PAIS_INICIAL_PADRAO,
    ufDestino: "",
    cidadeDestino: "",

    paisDestList: [],
    ufDestList: [],
    cidadeDestList: [],

    meioTransporte: "",
    placa: '',
    modelo: '',
    meiosTransporte: [],
    motivoViagem: "",
    motivosViagem: [],
    horaCheckin: "",
    horaCheckout: "",

    btn: false,
    valid: true,
    fieldsRules: [(v) => !!v || "Campo Obrigatório"],
    selectsRules: [(v) => !!v || "Campo Obrigatório"],
    select: null,

    snack: false,
    snackColor: "",
    snackText: "",
    btnSalvarHabilidado:true,
    respTemp:null,

    ufOrigemOBJ: {},
    ufDestinoOBJ: {},
  }),
  methods: {
    checkLength() {
      if (this.modelo.length > 20) {
        this.modelo = this.modelo.substring(0,20)
      }
    },
    translate(texto,find,subst) {
      var result = texto
      if (find.length == subst.length) {
        for (var j = 0; j < find.length; j++) {
          result = result.replace(find[j],subst[j])
        }
      }
      return result
    },
    noaccent(texto) {
      var aux = '';
      aux = this.translate(texto , 'áàâãä', 'aaaaa');
      aux = this.translate(aux , 'ÁÀÂÃÄ', 'AAAAA');
      aux = this.translate(aux , 'éèêë', 'eeee');
      aux = this.translate(aux , 'ÉÈÊË', 'EEEE');
      aux = this.translate(aux , 'íìîï', 'iiii');
      aux = this.translate(aux , 'ÍÌÎÏ', 'IIII');
      aux = this.translate(aux , 'óòõôö', 'ooooo');
      aux = this.translate(aux , 'ÓÒÕÔÖ', 'OOOOO');
      aux = this.translate(aux , 'úùûü', 'uuuu');
      aux = this.translate(aux , 'ÚÙÛÜ', 'UUUU');
      aux = this.translate(aux , 'ç', 'c');
      aux = this.translate(aux , 'Ç', 'C');	
      return aux;
    },
    prepare(x) {
      var y = ''
      if (x) {
        y = x.trim()
        y = this.noaccent(x)
        y = x.toLowerCase()
      }
      return y
    },
    checkTransporte(){
      var index = -1
      for (var i = 0; i < this.meiosTransporte.length; i++) {
        if (this.meiosTransporte[i]['id'] == this.meioTransporte){
          index = i
          break
        }
      }
      if (index > -1) {
        var transporte = this.meiosTransporte[index]['descricao']
        transporte = this.prepare(transporte)
        var textPlaca = document.getElementById('textPlaca')
        var textModelo = document.getElementById('textModelo')
        if (transporte.includes('auto')||transporte.includes('carro')) {
          if (textPlaca && textModelo) {
            textPlaca.style.display = 'block'
            textModelo.style.display = 'block'
          }
        } else {
          if (textPlaca && textModelo) {
            textPlaca.style.display = 'none'
            textModelo.style.display = 'none'
          }
        }
      }
    },
    validate() {
      this.btn = this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    paisOrigemToUpper() {
      this.paisOrigem = this.paisOrigem.toUpperCase();
    },
    ufOrigemToUpper() {
      this.ufOrigem = this.ufOrigem.toUpperCase();
    },
    cidadeOrigemToUpper() {
      this.cidadeOrigem = this.cidadeOrigem.toUpperCase();
    },
    paisDestinoToUpper() {
      this.paisDestino = this.paisDestino.toUpperCase();
    },
    ufDestinoToUpper() {
      this.ufDestino = this.ufDestino.toUpperCase();
    },
    cidadeDestinoToUpper() {
      this.cidadeDestino = this.cidadeDestino.toUpperCase();
    },
    async listarPaisesOrigem() {
      await this.$http.post("/geo/pais/select", {
        hotel: localStorage.getItem("hotel"),
        orderby: 'noaccent(nome)'}).then((response) => {
        this.paisOrgList = response.data;
        try {
            orto.ordenarArray(this.paisOrgList,'nome')  
        } catch (error) {
            this.paisOrgList = response.data;
        }

        if(!this.paisOrigem){
          this.paisOrigem = this.paisOrgList[0].id
          console.log("this.paisorigem")
          console.log(this.paisOrigem)
        }
        
      });
    },
    async listarPaisesDest() {

      await this.$http.post("/geo/pais/select", {
        hotel: localStorage.getItem("hotel"),
        orderby: 'noaccent(nome)'}).then((response) => {
        this.paisDestList = response.data;
        try {
            orto.ordenarArray(this.paisDestList,'nome')  
        } catch (error) {
            this.paisDestList = response.data;
        }
        if(!this.paisDestino){
          this.paisDestino = this.paisDestList[0].id
        }
      });
    },
    async listarUfsOrigem() {

      await this.$http.post("/geo/uf/select", {pais: this.paisOrigem}).then((response) => {
        this.ufOrgList = response.data;
        try {
            this.ufOrgList = orto.ordenarArray(this.ufOrgList,'sigla')  
        } catch (error) {
            this.ufOrgList = response.data;
        }

        if(!this.ufOrigem){
          this.ufOrigem = this.ufOrgList[0].sigla
        }

      }).catch(()=>{
          // alert("listarUfsOrigem")
          // alert(error)
        });
    },
    async listarUfsDest() {

      await this.$http.post("/geo/uf/select", {pais: this.paisDestino}).then((response) => {
        this.ufDestList = response.data;
        try {
          this.ufDestList = orto.ordenarArray(this.ufDestList,'sigla')  
        } catch (error) {
            this.ufDestList = response.data;
        }

        if(response.data.length > 0) {   
          if(!this.ufDestino){
            this.ufDestino = this.ufDestList[0].id
          }
        }

      });
    },
    async listarCidadesOrigem() {
      if(this.ufOrigem) {
        this.ufOrigemOBJ = {
                            sigla: this.ufOrgList.filter(el => el.sigla.toUpperCase() == this.ufOrigem.toUpperCase())[0].sigla, 
                            id: this.ufOrgList.filter(el => el.sigla.toUpperCase() == this.ufOrigem.toUpperCase())[0].id
                            }
                            await this.$http
                              .post("/geo/cidade/select", { uf: this.ufOrigemOBJ.id, orderby: 'nome' })
                              .then((response) => {
                                this.cidadeOrgList = response.data;
                                try {
                                    this.cidadeOrgList = orto.ordenarArray(this.cidadeOrgList,'nome')  
                                } catch (error) {
                                  alert(error)
                                    this.cidadeOrgList = response.data;
                                }
                              }).catch(error => alert(error));
      }

                          console.log('depois',this.ufOrigemOBJ)
    },
    async listarCidadesDestino() {
      console.log('chegou aui')

      if(this.ufDestList.length > 0) {
        this.ufDestinoOBJ = {
                            sigla: this.ufDestList.filter(el => el.sigla.toUpperCase() == this.ufDestino.toUpperCase())[0].sigla, 
                            id: this.ufDestList.filter(el => el.sigla.toUpperCase() == this.ufDestino.toUpperCase())[0].id
                            }

        await this.$http
          .post("/geo/cidade/select", { uf: this.ufDestinoOBJ.id, orderby: 'nome' })
          .then((response) => {
            this.cidadeDestList = response.data;
            try {
                this.cidadeDestList = orto.ordenarArray(this.cidadeDestList,'nome')  
            } catch (error) {
                this.cidadeDestList = response.data;
            }
          });
      }
      
    },
    listarMeiosTransporte() {
      this.$http.post("/geo/transporte/select").then((response) => {
        console.log(response.data)
        this.meiosTransporte = response.data;
      });
    },
    listarMotivosViagem() {
      this.$http.post("/geo/motivo/select").then((response) => {
        this.motivosViagem = response.data;
      });
    },
    async atualizaDataAlteracaoHospede() {
      try {
        await this.$http.post('/reserva/atualizar-data-alteracao-reserva', {
          id: this.idAtualizarDataHospede
        })
      } catch (error) {
        alert('Erro ao atualizar data de atualização do hospede');
      }
    },
    async salvarPasso() {


      let body = {
        reserva: {
          id: this.idreserva,
          hotel: localStorage.getItem("hotel"),
          idreservapms:this.idreservapms
        },
        hospede: {
          id : this.idhospede,
          idhospedepms:this.idhospedepms,
          paisorigem: this.paisOrigem,
          cidadeorigem: this.cidadeOrigem,
          // uforigem: this.ufOrigem,
          uforigem: this.ufOrigemOBJ.id,
          principal:this.principal,
          paisdestino: this.paisDestino,
          // ufdestino : this.ufDestino,
          ufdestino : this.ufDestinoOBJ.id,
          cidadedestino: this.cidadeDestino,
          horariochegada: "2025-12-31 " + this.horaCheckin,
          horariopartida: "2025-12-31 " + this.horaCheckout,
          motivo: this.motivoViagem,
          transporte: this.meioTransporte,
          placa: this.placa,
          modelo: this.modelo
        },
      };


      //passo EXTREMAMENTE necessário devido a questão das duplicidades de cidades,estados e paises
      //baseado na cidade, vou verificar o estado e o pais real.
      if(this.cidadeorigem){

          await this.$http.get('/geo/checkpaisestado/'+this.cidadeorigem).then(response =>{
            
              let dados = response.data
              if(dados && dados.length > 0 && dados.id > 0 && dados.estado > 0 && dados.pais > 0 ){

                  body.hospede.paisorigem      = dados.pais
                  body.hospede.uforigem        = dados.estado
                  body.hospede.cidadeorigem    = dados.id

              }

          })

      }


      //passo EXTREMAMENTE necessário devido a questão das duplicidades de cidades,estados e paises
      //baseado na cidade, vou verificar o estado e o pais real.
      if(this.cidadeDestino){

          await this.$http.get('/geo/checkpaisestado/'+this.cidadeDestino).then(response =>{

              let dados = response.data
              if(dados && dados.length > 0 && dados.id > 0 && dados.estado > 0 && dados.pais > 0 ){
                  body.hospede.paisdestino      = dados.pais
                  body.hospede.ufdestino        = dados.estado
                  body.hospede.cidadedestino    = dados.id
              }
              


          })
      }

      body.tipo_edicao = 1; // 1 = Ficha; 2 = Web Checkin

      this.$http
        .post("/reserva/upinsert", body)
        .then(async () => {
          //console.log(response.data);
          //console.log(JSON.stringify(body));
          await this.atualizaDataAlteracaoHospede();
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Salvo com sucesso!";
          this.btn = false;
          this.reset();
              //setTimeout(() => {
                this.$emit("avancar");
              //}, 1500);
          this.$emit("update")
        })
        .catch((error) => {
          console.log(error);
          console.log(JSON.stringify(body));
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível salvar os dados. Verifique.";
        });
    },
    buscarPaisPadrao(listaPaises) {
      var result = null
      for (var i = 0; i < listaPaises.length; i++) {
        if (listaPaises[i]['padrao']) {
          result = listaPaises[i]
          break
        }
      }
      return result
    },    
    listarDados() {

      this.$http.post("configuracao/buscarParametro", {  
                "idhotel" : localStorage.getItem('hotel'),
                "chave" : 'HORARIOPADRAOWEBCHECKIN'
            }).then(resp =>{
                if(resp.status == 200){

                  const [datePart, timePart] = resp.data[0].valor.split('T');
                    const [datePart2, timePart2] = resp.data[0].valor2.split('T');
                    console.log(timePart)
                    console.log(datePart)
                    console.log(datePart2)
                    if (timePart?.split('.')[0]) {
                        this.horaCheckin = timePart.split('.')[0]
                    } else {
                        this.horaCheckin = resp.data[0].valor
                    }

                    if (timePart2?.split('.')[0]) {
                        this.horaCheckout = timePart2.split('.')[0]
                    } else {
                        this.horaCheckout = resp.data[0].valor2
                    }

                    // this.horaCheckin  =  dayjs(resp.data[0].valor).format("HH:mm")
                    // this.horaCheckout =  dayjs(resp.data[0].valor2).format("HH:mm")
                }else{
                    alert("Não foi possivel buscar os dados")
                }

                this.$http.post("/reserva/select", {
                    hotel: localStorage.getItem("hotel"),
                    idhospede: this.idhospede,
                    idreserva: this.idreserva
                  })
                  .then(async (response) => {
                    this.idAtualizarDataHospede = response.data[0].id
                    this.respTemp = response
                    this.btnSalvarHabilidado = !response.data[0].enviadopms
                    this.idreservapms = response.data[0].idreservapms;
                    this.idhospedepms = response.data[0].idhospedepms;

                    this.principal = response.data[0].principal
                    if(response.data[0].idpaisorigem){
                      this.paisOrigem = response.data[0].idpaisorigem?response.data[0].idpaisorigem:this.buscarPaisPadrao(this.paisOrgList)['id'];
                    }else{
                      this.paisOrigem = response.data[0].idpais?response.data[0].idpais:(response.data[0].idpaisorigem?response.data[0].idpaisorigem:this.buscarPaisPadrao(this.paisOrgList)['id']);
                    }
                    console.log('1')

                    await this.listarUfsOrigem();
                    console.log('2')
                    if(response.data[0].ufsigla){
                      console.log('3')
                      if(response.data[0].iduforigem){
                        console.log('4')
                        this.ufOrigemOBJ = {sigla: response.data[0].ufsigla, id: response.data[0].iduforigem }
                      }else{
                        console.log('5')
                        this.ufOrigemOBJ = {sigla: response.data[0].ufsigla, id: this.ufOrgList.filter(el => el.sigla.toUpperCase() == response.data[0].ufsigla.toUpperCase())[0].id}
                      }

                    
                    }

                    console.log('6')
                    this.ufOrigem = response.data[0].uforigem?response.data[0].uforigem:response.data[0].ufsigla;
                    console.log('7')
                    await this.listarCidadesOrigem(); 
                    console.log('8')
                    // this.cidadeOrigem = response.data[0].idcidade?response.data[0].idcidade:response.data[0].idcidadeorigem;
                    this.cidadeOrigem = response.data[0].idcidadeorigem?response.data[0].idcidadeorigem:response.data[0].idcidade;
                    if(response.data[0].idpaisdestino){
                       this.paisDestino = response.data[0].idpaisdestino
                    }else{
                      this.paisDestino = response.data[0].idpais?response.data[0].idpais:response.data[0].idpaisdestino;
                    }
                    console.log('8.1')
                    await this.listarUfsDest();
                    console.log('9')
                    if(response.data[0].ufsigla){

                      if(response.data[0].idufdestino){
                        this.ufDestinoOBJ = {sigla: response.data[0].ufsigla, id: response.data[0].idufdestino }
                      }else{
  
                        this.ufDestinoOBJ = {sigla: response.data[0].ufsigla, id: this.ufDestList.filter(el => el.sigla.toUpperCase() == response.data[0].ufsigla.toUpperCase())[0].id}
                      }
                    }

                    this.ufDestino = response.data[0].ufdestino?response.data[0].ufdestino:response.data[0].ufsigla;
                    console.log('teste 1')
                    await this.listarCidadesDestino()
                    console.log('teste')
                    // this.cidadeDestino = response.data[0].idcidade?response.data[0].idcidade:response.data[0].idcidadedestino;
                    this.cidadeDestino = response.data[0].idcidadedestino?response.data[0].idcidadedestino:response.data[0].idcidade;
                    console.log(response.data[0])
                    this.meioTransporte = response.data[0].idtransporte;
                    await this.checkTransporte();
                    this.placa = response.data[0].placa;
                    this.modelo = response.data[0].modelo;
                    if (!this.meioTransporte) this.meioTransporte = this.meiosTransporte[0].id;
                    this.motivoViagem = response.data[0].idmotivo;
                    if (!this.motivoViagem) this.motivoViagem = this.motivosViagem[0].id;
                    if( response.data[0].horariochegada ){
                      let horachegadaAux = response.data[0].horariochegada
                      horachegadaAux = horachegadaAux.replace(" ","T")
                      horachegadaAux = horachegadaAux.split("+")[0]
                      this.horaCheckin = dayjs(horachegadaAux).format("HH:mm");
                    }

                    if(response.data[0].horariopartida){
                      let horapartidaAux = response.data[0].horariopartida
                      horapartidaAux = horapartidaAux.replace(" ","T")
                      horapartidaAux = horapartidaAux.split("+")[0]
                      this.horaCheckout = dayjs(horapartidaAux).format("HH:mm");
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });

            })
            .catch(error =>{
                console.log(error)
            })

      
    },
  },
  async mounted() {

    await this.listarPaisesOrigem();
    await this.listarPaisesDest();
    if(!this.horaCheckin){
        await this.listarMeiosTransporte();
        await this.listarMotivosViagem();
        await this.listarDados();
    }else{
        await this.listarUfsOrigem();
        await this.listarUfsDest();
        await this.listarCidadesOrigem();
        await this.listarCidadesDestino();
        await this.listarDados();
    }
    
    await this.validate();
    
  },
};
</script>
