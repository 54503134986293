<template>
    <div  >
        <div class="captura_container" >
            <br>
            <br>
            <button @click="setarModal()" type="button" >EXIBIR MODAL</button>
            <br>
            <br>
            <br>
            
        </div>
        
        
        <ModalSolicitacoes @setarModal="(valor) => setarModal(valor)" :exibirModal="exibirModal" />
    </div>
    

    
</template>

<script>

import ModalSolicitacoes from './ModalSolicitacoes'
export default {
  data: () => ({
    exibirModal:false
  }),
  components:{
       ModalSolicitacoes: ModalSolicitacoes
  },
  methods: {
    setarModal(){
        console.log("MODAL")
        console.log(this.exibirModal)
        this.exibirModal = !this.exibirModal
        console.log(this.exibirModal)
    }
  },
  mounted() {
    
  },
};
</script>

<style scoped>
    *{
        box-sizing: border-box;
        margin:0px;
        padding:0px;
    }

    h1{
        font-family:Arial, Helvetica, sans-serif;
        /* color:#808080; */
        margin-bottom:10px;
        font-size:30px!important;
        font-weight:bold;
        text-align: center;
    }

    .imgExemplo{
        width:200px;
        max-width:90vw;
    }

    .imgUpload{
        width:400px;
        max-width:90vw;
        margin-top:20px;
    }

    .captura_container{
        /* background-color:red; */
        width:100vw;
        height:90vh;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family:Arial, Helvetica, sans-serif;
        padding-left:10%;
        padding-right:10%;
    }

    .captura_container_tipos{
        /* background-color:red; */
        width:80vw;
        height:60vh;
        padding-left:5%;
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        align-items:flex-start;
        justify-content:flex-start;
    }

    .captura_container_doc{
        /* background-color:red; */
        width:80vw;
        /* height:60vh; */
        padding-left:5%;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }

    .tipos{
        display: flex;
        justify-content: center;
        align-items:center;
        background-color:#03a9f4;
        width:600px;
        max-width:90vw;
        height:70px;
        border-radius:5px;
        margin-right:10px;
        /* padding-top:15px; */
        
        text-align:center;
        color:white;
        font-weight:bold;
        font-family:Arial, Helvetica, sans-serif;
    }

    .botaoRemover{
        background-color:red;
        height:50px!important;
        padding: 0px 10px 0px 10px;
        border-radius:5px;
        color:white;
        font-weight:bold;
        margin-top:10px;
    }
</style>