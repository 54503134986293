<template>
 <v-dialog v-model="show" width="90vw" max-width="1000px" persistent>
  <v-toolbar color="blue" style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;width: 100%;">
    <div style="min-width:100%!important;display:flex;justify-content:space-between; align-items: center;">
      <span class="toolbar-title" style="width:80%;padding:10px;"> Boas Vindas </span>
      <span style="width:5%"> 
        <v-btn x-small color="red" @click="() => this.$emit('finalizado')" title="Fechar">
          <v-icon x-small style="color:white;">mdi-close</v-icon>
        </v-btn>
      </span>
    </div>
  </v-toolbar>
  <Loading v-if="loading"/>
   <div v-if="!loading" style="background-color:white;width:100%;display:flex;flex-direction:row;">
    <div class="containerInputs">
      <div style="display:flex; justify-content: center; margin-bottom: 10px; margin-top: 10px;">
        <p>INFORMAÇÕES</p>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;">
        <p style="margin: 0; padding: 0; font-size: small;">Logo do hotel:</p>
        <input class="input" id="logoHotel" name="logoHotel" type="file" accept="image/*" @change="imagemLogoHotel" />
      </div>
      <div  v-show="page === 1" style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;  margin-top: 10px;">
        <p style="margin: 0; padding: 0; font-size: small;">Imagem do hotel:</p>
        <input class="input" id="file-upload" type="file" accept="image/*" @change="imagemHotel" />
      </div>

      <div  v-show="this.page === 2" style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;  margin-top: 10px;">
        <p style="margin: 0; padding: 0; font-size: small;">Imagem do hotel:</p>
        <input class="input" id="imagem2" name="imagem2" type="file" accept="image/*" @change="imagemHotel2" />
      </div>

      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start; margin: 20px 0 0 0 ">
        <v-select
          label="Idioma"
          v-model="idiomaSelecionado"
          :items="listaDeIdiomas"
          item-value="sigla"
          item-text="idioma"
          @input="() => selecionarIdioma(idiomaSelecionado)"
          return-object>
        </v-select>
        <p style="margin: 0; padding: 0; font-size: small;">Título:</p>
        <input class="input" type="text" v-if="this.page === 1" placeholder="Título" v-model="titulo_1"/>
        <input class="input" type="text" placeholder="Título" v-if="this.page === 2" v-model="titulo_2"/>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;  margin-top: 10px;">
        <p style="margin: 0; padding: 0; font-size: small;">Descrição:</p>
        <input class="input" type="text" placeholder="Descrição" v-if="this.page === 1" v-model="descricao_1"/>
        <input class="input" type="text" placeholder="Descrição" v-if="this.page === 2" v-model="descricao_2"/>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;justify-content: center;  margin-top: 10px;">
        <!-- <p style="margin: 0; padding: 0; font-size: small;">*Página obrigatória:</p> -->
          <div style="display: flex; align-items: center; justify-content: center;text-align: center;">
            <span v-if="page === 2" style="font-size: 12px; margin: 0; padding: 0; margin-left: 5px;text-align: center;display: flex;align-items: center;justify-content: center;"><input type="checkbox" style="margin:0; padding:0; margin-right: 5px;" v-model="exibirPagina2"/>*{{exibirPagina2 ? 'Esta página é opcional, se desejar não exibi-la basta desmarcar a caixa.' : 'Esta página é opcional, se desejar exibi-la basta marcar a caixa.'}}</span>
          </div>
      </div>
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;  margin-top: 10px;">
        <button class="buttonsalvar" @click="salvarConfig">{{ textBotaoSalvar }}</button>
        <p style="font-size: 12px; margin-top: 5px;" v-if="configSalvas">Configurações salvas com sucesso!</p>
      </div>
    </div>
    <div v-if="!loading" class="containerPreVisualizar">
      <div style="display:flex; justify-content: center; margin-bottom: 10px; width: 100%; margin-top: 10px;">
        <p>PRÉ-VISUALIZAR</p>
      </div>
      <TelaInicial1 :dados="{logoHotel: this.imgLogoHotel, imagem_hotel, imagem_hotel2, titulo_1, titulo_2, descricao_1, descricao_2, page}"/>
    </div>
  </div>
  <div v-if="!loading" class="containerProximaPagina">
    <button class="buttonPaginas" @click="ProximaPagina">{{ this.page === 1 ? 'Próxima página' : 'Página anterior' }}</button>
  </div>
 </v-dialog>
</template>

<script>
  import { serializarImagemNew } from '../../../servicos/serializar';
  import TelaInicial1 from './telaInicialEtapas/telaInicial1.vue';
  import Loading from './Loading.vue';
  export default {
    components: {
      TelaInicial1,
      Loading,
    },
    props: ['show'],
    data() {
      return {
        imgLogoHotel: '',
        imagem_hotel: '',
        imagem_hotel2: '',
        titulo_1: '',
        titulo_2: '',
        descricao_1: '',
        descricao_2: '',
        page: 1,
        loading: true,
        exibirPagina2: true,
        textBotaoSalvar: 'Salvar',
        configSalvas: false,
        listaDeIdiomas: [{
          id: 1,
          sigla: 'pt-BR',
          idioma: 'Português'
        },
        {
          id: 2,
          sigla: 'en',
          idioma: 'Inglês'
        },
        {
          id: 3,
          sigla: 'es',
          idioma: 'Espanhol'
        }],
        idiomaSelecionado: {
          id: 1,
          sigla: 'pt-BR',
          idioma: 'Português'
        },
      }
    },
    methods: {
      async selecionarIdioma(idioma) {
        console.log(idioma)
        try {
          this.loading = true;
          await this.buscarConfigBoasVindas({ idioma: idioma.sigla });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },
      imagemLogoHotel(e) {
        serializarImagemNew(e).then(resp =>{ 
            this.imgLogoHotel = 'data:image.png;base64,' + resp.dados
            console.log(this.imgLogoHotel)
        }).catch(error =>{
          console.log(error)
        })
      },

      imagemHotel(e) {
        serializarImagemNew(e).then(resp =>{ 
          if(this.page === 1) {
            this.imagem_hotel = 'data:image.png;base64,' + resp.dados
          } else {
            this.imagem_hotel2 = 'data:image.png;base64,' + resp.dados
          }
        }).catch(error =>{
          console.log(error)
        })
      },

      imagemHotel2(e) {
        serializarImagemNew(e).then(resp =>{ 
          if(this.page === 1) {
            this.imagem_hotel = 'data:image.png;base64,' + resp.dados
          } else {
            this.imagem_hotel2 = 'data:image.png;base64,' + resp.dados
          }
        }).catch(error =>{
          console.log(error)
        })
      },

      async buscarConfigBoasVindas(idioma) {
        await this.$http
        .post("configWebCheckin/buscarConfigTelaBoasVindas", idioma)
        .then((resp) => {
          this.imgLogoHotel = resp.data[0].logo_hotel;
          this.imagem_hotel = resp.data[0].imagem_hotel;
          this.imagem_hotel2 = resp.data[0].imagem_hotel_2;
          this.descricao_1 = resp.data[0].descricao_1;
          this.descricao_2 = resp.data[0].descricao_2;
          this.titulo_1 = resp.data[0].titulo_1;
          this.titulo_2 = resp.data[0].titulo_2;
          this.exibirPagina2 = resp.data[0].tela_2;
          console.log(resp.data)
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
      },

      async salvarConfig() {
        this.textBotaoSalvar = 'Aguarde...'
        const body = {
          logo_hotel: this.imgLogoHotel,
          imagem_hotel: this.imagem_hotel,
          titulo_1: this.titulo_1 ? this.titulo_1 : '',
          descricao_1: this.descricao_1 ? this.descricao_1 : '',
          imagem_hotel_2: this.imagem_hotel2,
          titulo_2: this.titulo_2 ? this.titulo_2 : '',
          descricao_2: this.descricao_2 ? this.descricao_2 : '',
          tela_2: this.exibirPagina2,
          idioma: this.idiomaSelecionado.sigla
        }
        console.log(body)
        await this.$http
        .post("configWebCheckin/salvarConfig", body)
        .then(() => {
         this.textBotaoSalvar = 'Salvar'
         this.configSalvas = true;
         setTimeout(() => {
          this.configSalvas = false;
            console.log("ok");
          }, "4000")
        })
        .catch((error) => {
          console.log(error.response.data);
        });
      },

      ProximaPagina() {
        if(this.page === 1) {
          this.page += 1
          return
        }

        if(this.page === 2) {
          this.page -= 1
          return
        }
      }
    },
    
    async mounted() {
      await this.buscarConfigBoasVindas({ idioma: 'pt-BR' });
    }
  }
</script>

<style scoped>
.containerInputs {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100% !important;
}

.containerPreVisualizar {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 100% !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.input {
  background-color: white;
  border: 1px solid rgb(165, 165, 165);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.containerProximaPagina {
  background-color: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 1px solid rgb(180, 180, 180);
}

.buttonPaginas {
  background-color: #2B81D6;
  color: white;
  border-radius: 5px;
  padding: 8px;
  font-size: small;
  font-weight: 100;
}

.buttonsalvar {
  background-color: #2B81D6;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: small;
  font-weight: 100;
  width: 100px;
}
</style>