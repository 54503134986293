<template>
    <v-dialog v-model="exibirModalSolicitacoes" width="1000" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex style="display: flex" class="pa-2">
                    <span style="width:95%;color:white;font-weight:bold;" >
                        Solicitações
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="$emit('setModalSolicitacoes',false)" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>

            <v-card-text>
                <v-btn medium elevation="" color="info" style="margin-bottom:20px;font-weight:bold;"
                    @click="setModalHistoricoSolicitacoes()">Ver Histórico Atendimentos</v-btn>
                
                <template>
                    <v-tabs v-model="tab">
                        <v-tab>Observações Webcheckin</v-tab>
                        <v-tab>Pergunta Extra</v-tab>
                        <v-tab>Horário do Voo</v-tab>
                    </v-tabs>
                </template>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card min-height="290px">
                            <div style="padding: 1.0rem;">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                            <v-data-table :headers="headers" :items="solicitacoes" :search="search">
                            
                                <template v-slot:[`item.reservahospede`]="{ item }">
                                    <v-btn color="blue" style="color:white;font-weight:bold;padding-top:1px;padding-bottom:1px;" @click="setExibirModalResponder(item)" title="Fechar">
                                        <v-icon small style="color:white; margin-right:5px;">mdi-account-arrow-left</v-icon>
                                        Responder
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.atendida`]="{ item }">
                                    <v-chip :color="item.atendida? 'blue': 'red'" dark>
                                        {{item.atendida? 'Respondida': 'Não Respondida' }}
                                    </v-chip>
                                </template>

                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="flex-row justify-center">
                        <v-card min-height="290px">
                            <div style="padding: 1.0rem;">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                            <v-data-table :headers="headersRespostas" :items="respostasExtras" :search="search">
                            
                                <template v-slot:[`item.reservahospede`]="{ item }">
                                    <v-btn color="blue" style="color:white;font-weight:bold;padding-top:1px;padding-bottom:1px;" @click="setExibirModalResponder(item)" title="Fechar">
                                        <v-icon small style="color:white; margin-right:5px;">mdi-account-arrow-left</v-icon>
                                        Responder
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.atendida`]="{ item }">
                                    <v-chip :color="item.atendida? 'blue': 'red'" dark>
                                        {{item.atendida? 'Respondida': 'Não Respondida' }}
                                    </v-chip>
                                </template>

                            </v-data-table>
                        </v-card>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="flex-row justify-center">
                        <v-card min-height="290px">
                            <div style="padding: 1.0rem;">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                >
                                </v-text-field>
                            </div>
                            <v-data-table :headers="headersVoo" :items="horarioVoo" :search="search">
                            
                                <template v-slot:[`item.reservahospede`]="{ item }">
                                    <v-btn color="blue" style="color:white;font-weight:bold;padding-top:1px;padding-bottom:1px;" @click="setExibirModalResponder(item)" title="Fechar">
                                        <v-icon small style="color:white; margin-right:5px;">mdi-account-arrow-left</v-icon>
                                        Responder
                                    </v-btn>
                                </template>

                                <template v-slot:[`item.atendida`]="{ item }">
                                    <v-chip :color="item.atendida? 'blue': 'red'" dark>
                                        {{item.atendida? 'Respondida': 'Não Respondida' }}
                                    </v-chip>
                                </template>

                            </v-data-table>
                        </v-card>
                        </div>
                    </v-tab-item>
                </v-tabs-items>

            </v-card-text>
            
        </v-card>

        <ModalHistoricoSolicitacoes 
            v-if="exibirModalHistoricoSolicitacoes"
            @setModalHistoricoSolicitacoes="(valor) => setModalHistoricoSolicitacoes(valor)"
            :exibirModalHistoricoSolicitacoes="exibirModalHistoricoSolicitacoes" 
            :itemselecionado="itemselecionado"
            :flagatualizar="flagatualizar"
             />

        <ModalSolicitacoesResponder 
            @consultarSolicitacoes="(valor) => consultarSolicitacoes(valor)"  
            @setModalResponder="(valor) => setModalResponder(valor)" 
            @setFlagAtualizar="() => setFlagAtualizar()" 
            :itemselecionado="itemselecionado"
            :exibirModalResponder="exibirModalResponder" :solicitacao="solicitacao"  />
    </v-dialog>
</template>          

<script>


import ModalSolicitacoesResponder from './ModalSolicitacoesResponder'
import ModalHistoricoSolicitacoes from './ModalHistoricoSolicitacoes'

export default {
    data: () => ({
        exibirModalResponder:false,
        exibirModalHistoricoSolicitacoes:false,
        solicitacao:{},
        search: '',
        flagatualizar:false,
        headers: [
            {text: 'Id',align: 'center',sortable: true,value: 'id'},
            {text: 'Descrição',sortable: true,value: 'descricao'},
            {text: 'Status',sortable: true,value: 'atendida'},
            {text: 'Responder',sortable: true,value: 'reservahospede'},
        ],
        headersRespostas: [
            {text: 'Id', align: 'center', value: 'id'},
            {text: 'Pergunta',sortable: true,value: 'textoperguntaextra'},
            {text: 'Resposta', align: 'center', value: 'respostaextra.resposta'},
            {text: 'Observação', align: 'center', value: 'observacao'},
            {text: 'Status',sortable: true,value: 'atendida'},
            {text: 'Responder',sortable: true,value: 'reservahospede'},
        ],
        headersVoo: [
            {text: 'Id', align: 'center', value: 'id'},
            {text: 'Chegada',sortable: true,value: 'horariochegada'},
            {text: 'Partida', align: 'center', value: 'horariopartida'},
            {text: 'Status',sortable: true,value: 'atendida'},
            {text: 'Responder',sortable: true,value: 'reservahospede'},
        ],
        tab: null
        // solicitacoes: []
    }),
    props:["exibirModalSolicitacoes","solicitacoes","itemselecionado","respostasExtras","horarioVoo"],
    components:{
        ModalSolicitacoesResponder,
        ModalHistoricoSolicitacoes
    },
    methods: {
        setFlagAtualizar(){
            this.flagatualizar = !this.flagatualizar
        },
        consultarSolicitacoes(reservahospede){
            this.$emit('consultarSolicitacoes',reservahospede)
        },
        setExibirModalResponder(item){
            this.solicitacao = item
            this.setModalResponder(!this.exibirModalResponder)
            this.flagatualizar = !this.flagatualizar
        },
        setModalResponder(valor){
            // alert(JSON.stringify(this.solicitacao))
            this.exibirModalResponder = valor
        },
        setModalHistoricoSolicitacoes(){
            this.exibirModalHistoricoSolicitacoes = !this.exibirModalHistoricoSolicitacoes
        },
    },
    mounted() {
        // await this.$http.post('reserva/solicitacao/select',{
        //             reservahospede:1
        //         })

    },
};
</script>