<template>
        
        <div class="" style="flex-wrap:wrap;width:100%;">
            <p v-if="hospedesprincipais.length">Reservas</p>
            <h2 style="text-align:center;padding-top:3vh;margin-bottom:50px;font-size:20px!important;" >{{ hospedesprincipais.length ? "Clique no Hospede Principal da Sua Reserva" : "Nenhuma reserva encontrada para esse hospede."}}</h2>
            <div style="display: flex; justify-content: center;align-items: center;">
                <v-btn style="width:50%;margin-bottom:0px; margin:auto" @click="()=>  this.$emit('voltar',)" color="primary">Voltar</v-btn>
            </div>
                    <div class="resumoInicialHospedes"  v-for="hospede in hospedesprincipais" :key="hospede.id"
                        style="margin-bottom:30px;margin-top:20px;" @click="direcionarParaReservas(hospede.reserva)">
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:10px;font-weight:bold!important;">Numero da Reserva:</div>
                            <div>{{hospede.reserva}}</div>
                        </div>
                        <div style="margin-bottom:10px;display:flex;">
                            <div style="margin-right:10px;font-weight:normal!important;">Nome:</div>
                            <div>{{hospede.nome+' '+hospede.sobrenome}}</div>
                        </div>
                        <div style="display:flex;justify-content:space-between;">
                            <div style="margin-bottom:10px;">
                                <div><b>Chegada</b></div>
                                <div>{{dados.datacheckinprevisto | formatarData}}</div>
                            </div>
                            <div style="margin-bottom:10px;">
                                <div><b>Saída</b></div>
                                <div>{{dados.datacheckoutprevisto | formatarData}}</div>
                            </div>
                        </div>
                        <div style="margin-bottom: 10px!important;" v-if="hospede.acompanhantes && hospede.acompanhantes.length > 0">
                            <div style="margin-right:20px;font-weight:normal!important;"><b>Outros hospedes: </b></div>
                            <div style="margin-left: 5px;" v-for="(acompanhante, index) in hospede.acompanhantes" :key="index"> - {{acompanhante.nome+' '+acompanhante.sobrenome}}</div>
                        </div>
                        
                    </div>
        </div>

</template>

<script>

// import moment from 'moment'
export default { 
    props:["dados"],
    data: () =>{
        return {
            // // os dados que salvarei na base do ficha
            hospedesprincipais:[],
            hospedeprincipal:null

        }
    },
    methods:{
        direcionarParaReservas(numeroreserva){
            console.log(numeroreserva)

            var dadossalvar     = this.dados
            dadossalvar.numeroreserva   = numeroreserva

           
            this.$emit('atualizarDados',dadossalvar,'Reservas')
        },
        listarHospedesPrincipais(){
       
            var nome = null
            var sobrenome = null
         

            if(this.dados.nome){
             
                const nomeCompleto = this.dados.nome.trim();
                      const partes = nomeCompleto.split(/\s+/);
                     nome = '%'+partes[0].trim()+'%';
                    //  sobrenome = partes.slice(1).join(' ');
                    sobrenome = '%'
                     for(let i= 1, len = partes.length; i < len; i++){
                        if(partes[i] && partes[i].length > 2){
                            sobrenome += partes[i].trim()+'%';
                        }
                     }
                     

       
            }

            this.$http.post("reserva/selecionarReservasCheckinExpress",{
                data_checkin:this.dados.datacheckinprevisto,
                data_checkout:this.dados.datacheckoutprevisto,
                nome:nome,
                sobrenome:sobrenome
                // hotel:localStorage.getItem('hotel'),
                })
            .then(resp =>{
                console.log(resp.data)
                    // alert(JSON.stringify(resp.data))
                    // this.hospedesprincipais = resp.data.filter(p => {
                    //     return p.nome != undefined;
                    // }).sort((a, b) => (a.nome > b.nome) ? 1 : -1);
                    
                    this.hospedesprincipais = resp.data;
                    

            }).catch(error =>{
                alert(error)
            })
        },
        
        salvarDados(){
            
            // var dadossalvar     = this.dados
            // dadossalvar.canal   = this.canal.idempresareservante
           
            // this.$emit('atualizarDados',dadossalvar,'Reservas')
            

        },
        async avancar(){

            if( await this.validarFormulario() ){
                this.salvarDados()
            }  
            
        }
    },
    mounted(){
        this.listarHospedesPrincipais()
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>