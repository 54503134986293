<template>
    <div class="page">
        <span class="card-wrapper">
            <span class="info-wrapper">
                <v-icon size="64" color="rgb(40, 163, 63)" class="check-icon">mdi-lan-connect</v-icon>
                <h1 class="info-title" data-cy="info">Processando...</h1>
            </span>
            <!-- <button type="button" data-cy="btn-continuar"><p>continuar</p></button> -->
        </span>
    </div>
</template>
<script>
export default {
    data: () => {
        return {
            
        }
    },
    methods:{
        startAnimation: (entries) => {
          entries.forEach(entry => {
            entry.target.classList.toggle("initial-animation", entry.isIntersecting);
         });
        },
    },
    mounted(){
        window.scrollTo(0, 0);
        const observer = new IntersectionObserver(this.startAnimation);
        const options = { root: null, rootMargin: '0px', threshold: 1 }; 

        const element = document.querySelector('.check-icon');
        observer.observe(element, options);
    }

}
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100vh;
    background: #EBEBF5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.card-wrapper{
    min-width: 30vw;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 2em;
    border-radius: 37px;
    background: linear-gradient(145deg, #ffffff, #EBEBF5);
    box-shadow:  13px 13px 26px #e5e5ea,
             -13px -13px 26px #ffffff;
}

.info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    >.info-title{
        margin: 1em 0;
        text-align: center;
    }
}

button{
    align-self: center;
    padding: .5em 1em;
    border-radius: 1em;
    background: rgb(40, 163, 63);
    color: white;
    border: none;
    >p{
      font-weight: bold;
      letter-spacing: .02em;
      text-transform: capitalize;
      font-size: 16px;
    }
}
.check-icon{
    transition: ease-in 1.6s;
    &:hover{
      transform: scale(1.2);
    }
}
.initial-animation{
    animation: initial-animation 1s initial;
}
@keyframes initial-animation {
    0%{
        transform: rotateZ(20deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}
//css indesejados
.v-application p {
    margin-bottom: unset;
}
</style>